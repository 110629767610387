import styled from 'styled-components'

const NavigationFooter = styled.div`
  padding: 20px 0;
  background: ${(props) => props.theme.colors.white};

  .footer_container {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
  }

  .footer__copyright {
    display: flex;
    margin: 0;
    padding: 0 1.25rem 0 0;
    font-size: 1rem;
    font-weight: 400;
    justify-content: flex-start;
  }

  a {
    position: relative;
    color: #74788d;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      top: 1rem;
      left: 0;
      width: 0%;
      transition: width 0.3s ease;
    }
    &:hover {
      color: #5d78ff;
      &::after {
        border-bottom: 1px solid #3954de;
        opacity: 0.5;
        width: 100%;
      }
    }
  }

  .footer__menu {
    display: flex;
    justify-content: flex-end;
    padding: 0 0 0 0.75rem;
    margin: 0;

    a {
      margin: 0 0 0 1.25rem;
      padding: 0;
      font-size: 1rem;
      font-weight: 400;
    }
  }
`

export const Styled = {
  NavigationFooter,
}
