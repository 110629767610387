import styled from 'styled-components'

export const RecurringSection = styled.div`
  border: 1px solid #c6c9e5;
  border-radius: 5px;
  padding: 25px 10px;
  background-color: #afb6da45;
`

export const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
    min-width: 300px;
  }
`