import React, { useState, useRef, FunctionComponent } from 'react'
import { globalState } from '../../../../store'
import { toast } from 'react-toastify'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { WavePreview } from './wave-preview'
import { WaveDirectiveSettings } from './index'
import { getOrdinal } from '../../../../components/component-items/utils'
import GenericModal from '../../../../components/component-items/modal'
import { Form, Col } from 'react-bootstrap'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPlayCircle, faList, faEdit } from '@fortawesome/pro-duotone-svg-icons'
import { useDelete, usePost, usePut, notify } from '../../../../components/component-items/helpers'

import { WaveDirectivesProps } from './wave-profile'
import { FiltersProps, SortProps } from './index'
import { Tooltip } from './styles'

type EditDirectiveProps = {
  open: boolean
  setOpen: (open: boolean) => void
  id: number
  profile: string
  name: string
  index: number
  settings: any
  sort: SortProps[]
  filters: FiltersProps
}

const EditDirective: FunctionComponent<EditDirectiveProps> = ({
  open,
  setOpen,
  id,
  profile,
  index,
  settings: currentSettings,
  sort: currentSort,
  filters: currentFilters,
  name: currentName,
}) => {
  const [settings, setSettings] = useState<any>(currentSettings)
  const node = useRef<HTMLFormElement | null>(null)
  const [validated, setValidated] = useState(false)
  const [name, setName] = useState(currentName)
  const [sort, setSort] = useState<SortProps[]>(currentSort)
  const [filters, setFilters] = useState<FiltersProps>(currentFilters)

  const {
    state: { csrf },
  } = globalState()

  const checkValidity = () => {
    setValidated(true)
    if (node?.current?.checkValidity() === false) {
      return false
    } else if (!settings.companies.length) {
      notify({ type: 'error', message: 'Choose at least one company first' })
      return false
    }
    return true
  }

  const handleSubmit = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)
    if (!checkValidity()) {
      return
    }
    usePut(
      `/api/inventory/wave-directive/${id}/`,
      { ...{ profile, index, settings, sort, name, filters } },
      csrf,
      false,
      true,
      true
    ).then((response) => {
      if (response?.error) {
        notify({ type: 'warning', message: response?.error })
      }
    })

    history.go(0)
  }

  return (
    <GenericModal
      heading={'Edit Directive'}
      show={open}
      size="xl"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button type="button" style={{ minWidth: 125, float: 'right' }} onClick={handleSubmit}>
          Update
        </GlobalStyles.Button>
      }
    >
      <div style={{ minHeight: 500, padding: '0 1em 2em' }}>
        <Form ref={node} validated={validated} onSubmit={handleSubmit} style={{ padding: '0 1em 2em' }}>
          <Form.Group as={Col} md="6">
            <Form.Label>Directive Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Example: 'Under 1lb, excluding B2B'"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <WaveDirectiveSettings {...{ settings, setSettings, sort, setSort, filters, setFilters }} />
        </Form>
      </div>
    </GenericModal>
  )
}

const WaveDirective: FunctionComponent<WaveDirectivesProps> = ({
  id,
  profile,
  name,
  last_run,
  order_counts,
  index,
  settings,
  sort,
  filters,
  dragOverlay,
  style: outerStyle,
}) => {
  const {
    state: { csrf },
  } = globalState()

  const [preview, setPreview] = useState(false)
  const [edit, setEdit] = useState(false)

  const runDirective = (id: number) => {
    usePost(`/api/inventory/wave-directive/${id}/run_directive/`, {}, csrf, false).then((response) => {
      if (response?.error) {
        notify({ type: 'warning', message: response?.error })
      } else {
        notify({ type: 'success', message: 'Batched successfully' })
      }
    })
  }

  const style = { width: `${100 / 6}%`, background: dragOverlay ? '#f6f9ff' : 'default', ...outerStyle }
  return (
    <React.Fragment>
      <TableCell center style={style}>
        {getOrdinal(index)}
      </TableCell>
      <TableCell center style={style}>
        {name}
      </TableCell>
      <TableCell center style={style}>
        <StatusBoxStyled className="silver" style={{ margin: '1em auto', width: 100, textAlign: 'center' }}>
          {last_run ? new Date(last_run).toLocaleString() : 'Never'}
        </StatusBoxStyled>
      </TableCell>
      <TableCell center style={style}>
        <StatusBoxStyled className="blue" style={{ margin: '1em auto', width: 75, textAlign: 'center' }}>
          {order_counts['received']}
        </StatusBoxStyled>
      </TableCell>
      <TableCell center style={style}>
        <StatusBoxStyled className="yellow" style={{ margin: '1em auto', width: 75, textAlign: 'center' }}>
          {order_counts['backordered']}
        </StatusBoxStyled>
      </TableCell>
      <TableCell center style={style}>
        <div className="dropdown__container" data-no-dnd="true">
          <Tooltip data-title="Run Wave Directive">
            <button onClick={() => runDirective(id)}>
              <FontAwesomeIcon icon={faPlayCircle} />
            </button>
          </Tooltip>
          <Tooltip data-title="Preview Wave Directive">
            <button onClick={() => setPreview(true)}>
              <FontAwesomeIcon icon={faList} />
            </button>
            <WavePreview {...{ settings, sort, filters }} open={preview} setOpen={setPreview} />
          </Tooltip>
          <Tooltip data-title="Edit Wave Directive">
            <button onClick={() => setEdit(true)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <EditDirective {...{ id, profile, index, settings, sort, filters, name }} open={edit} setOpen={setEdit} />
          </Tooltip>
          <Tooltip data-title="Delete Wave Directive">
            <button onClick={() => useDelete(`/api/inventory/wave-directive/${id}/`, csrf)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </Tooltip>
        </div>
      </TableCell>
    </React.Fragment>
  )
}

export default WaveDirective
