import React from 'react'
import { Link } from 'react-router-dom'
import { BackgroundLayer } from './styles'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'

import { VariantCardProps } from './types'

export const VariantCard = ({ variants, is_packaging }: VariantCardProps) => {
  return (
    <BackgroundLayer style={{ display: 'block', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            {is_packaging ? 'Packaging' : 'Product'} Variants<small>{variants ? variants.length : 0} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {/* <GlobalStyles.Button style={{ height: 40, marginRight: '-0.5em' }} onClick={() => setOpen(true)}>Add Variant</GlobalStyles.Button> */}
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text"></span>
              </th>
              <th>
                <span className="text">SKU</span>
              </th>
              <th>
                <span className="text">Scancode</span>
              </th>
              <th>
                <span className="text center">Price</span>
              </th>
              <th>
                <span className="text center">On Hand</span>
              </th>
              <th>
                <span className="text center">Available</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {variants.map((v, id) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell
                  input={<img src={v.img} alt="Product Variant" className="product_image" style={{ maxWidth: 50 }} />}
                />
                <TableCell
                  input={
                    <Link to={`/product/variant/${v.id}/`} rel="noreferrer" target="__none">
                      {v.sku}
                    </Link>
                  }
                />
                <TableCell input={v.barcode} />
                <TableCell input={v.price} center />
                <TableCell input={v.onHand} center />
                <TableCell input={v.available} center />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
    </BackgroundLayer>
  )
}
