import styled from 'styled-components'
import GlobalStyles from '../../components/component-items/styles'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

export const BackgroundLayer = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
`

export const OrderInfo = styled(BackgroundLayer)`
  justify-content: space-between;
  padding: 1em;
  margin-bottom: 0;
  height: 100%;
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 1em;
    color: #4a485d;
    div {
      width: 100%;
    }
    span {
      margin-left: 1rem;
      float: right;
      font-weight: 500;
      max-width: 60%;
      text-align: right;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    p {
      margin-bottom: 0;
    }
  }
  .return-empty {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5em;
    div {
      font-size: 1em;
    }
  }
`

export const Line = styled.div`
  position: absolute;
  left: calc(50% - 1px);
  top: 100px;
  width: 2px;
  height: calc(100% - 125px);
  background-color: #cdd1e1;
`

export const CardHeader = styled(GlobalStyles.CardHeader)`
  h3 {
    margin-right: 2em;
  }
`

export const H4 = styled.h4`
  position: relative;
  text-transform: capitalize;
  margin-top: 1em;
  &:before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    left: -44px;
    top: 8px;
    z-index: 10;
    border-radius: 100%;
    background-color: #5c78ff;
  }
`
