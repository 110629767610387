import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch, useDelete, usePut, notify } from '../../../components/component-items/helpers'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { globalState } from '../../../store'
import { toast } from 'react-toastify'

// Components
import { Form, InputGroup, Col, Row } from 'react-bootstrap'
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { TableCell } from '../../../components/component-items/datatable'
import { PrintLabelsModal } from '../../../components/modals/print-labels'

export const LocationView = () => {
  const {
    state: { csrf },
  } = globalState()
  const history = useHistory()
  const [validated, setValidated] = useState(false)
  const [printModal, setPrintModal] = useState(false)
  const [location, setLocation] = useState({
    barcode: '',
    warehouse: '',
    aisle: '',
    bay: '',
    level: '',
    slot: '',
    zone: '',
    description: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    velocity: '',
    capacity_used: '',
    capacity_remaining: '',
    weight_used: '',
    weight_remaining: '',
    location_type: '',
  })

  const locationId = useParams<{ id: string }>().id
  const res: any = useFetch(`/api/inventory/location/${locationId}/`, {})
  const resp = res.response && res.loaded ? res.response : {}
  const items = resp.items ? resp.items : []
  const locationTypesOptions = [
    { value: 'bin', label: 'Bin = 18x12x12 20lbs' },
    { value: 'pallet', label: 'Pallet = 48x40x72 1000lbs' },
    { value: 'shelf', label: 'Shelf = 48x24x24 80lbs' },
  ]
  const velocityOptions = [
    { value: 'A', label: 'A = 20% of inventory - 80% of movement' },
    { value: 'B', label: 'B = 30% of inventory - 15% of movement' },
    { value: 'C', label: 'C = 50% of inventory - 5% of movement' },
  ]

  useEffect(() => {
    if (res.loaded) {
      setLocation({
        barcode: resp.barcode || '',
        warehouse: resp.warehouse || '',
        zone: resp.zone || '',
        description: resp.description || '',
        aisle: resp.aisle || '',
        bay: resp.bay || '',
        level: resp.level || '',
        slot: resp.slot || '',
        length: resp.length || '',
        width: resp.width || '',
        height: resp.height || '',
        weight: resp.weight || '',
        velocity: resp.velocity || '',
        location_type: resp.location_type || '',
        capacity_used: resp.capacity_used || '',
        capacity_remaining: resp.capacity_remaining || '',
        weight_used: resp.weight_used || '',
        weight_remaining: resp.weight_remaining || '',
      })
    }
  }, [res.loaded])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }
    await usePut(`/api/inventory/location/${locationId}/`, { ...location }, csrf, false).then((response) => {
      if (response && !response.error) {
        notify({ type: 'success', message: 'Location has been updated!' })
      }
    })
  }

  const handleDelete = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    await useDelete(`/api/inventory/location/${locationId}/`, csrf, false, true, true, (response: any) => {
      if (response.ok) {
        history.push('/location/')
        notify({ type: 'success', message: 'Location has been deleted!' })
      }
    })
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Location - {resp.barcode}</h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar>
            {!items.length ? (
              <GlobalStyles.Button type="button" className="secondary" style={{ minWidth: 125 }} onClick={handleDelete}>
                Delete Location
              </GlobalStyles.Button>
            ) : null}
            <GlobalStyles.Button
              type="button"
              className="secondary"
              style={{ minWidth: 125 }}
              onClick={() => setPrintModal(true)}
            >
              Print Labels
            </GlobalStyles.Button>
            <GlobalStyles.Button type="submit" style={{ minWidth: 125 }} onSubmit={handleSubmit}>
              Update
            </GlobalStyles.Button>
          </GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '65%' }}>
          <GlobalStyles.DataTable style={{ marginBottom: '2em' }}>
            <thead>
              <tr>
                <th>
                  <span className="center text">Capacity Used</span>
                </th>
                <th>
                  <span className="center text">Capacity Remaining</span>
                </th>
                <th>
                  <span className="center text">Weight Used</span>
                </th>
                <th>
                  <span className="center text">Weight Remaining</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <GlobalStyles.TableRow>
                <TableCell
                  center
                  input={
                    <StatusBoxStyled
                      className={Number(location.capacity_used || 0) > 0 ? 'blue' : 'silver'}
                      style={{ width: 100, margin: 'auto' }}
                    >
                      {
                        <span>
                          {location.capacity_used || 0} ft<sup>3</sup>
                        </span>
                      }
                    </StatusBoxStyled>
                  }
                />
                <TableCell
                  center
                  input={
                    <StatusBoxStyled
                      className={Number(location.capacity_remaining || 0) > 0 ? 'blue' : 'red'}
                      style={{ width: 100, margin: 'auto' }}
                    >
                      {location.capacity_remaining ? (
                        <span>
                          {location.capacity_remaining} ft<sup>3</sup>
                        </span>
                      ) : (
                        'N/A'
                      )}
                    </StatusBoxStyled>
                  }
                />
                <TableCell
                  center
                  input={
                    <StatusBoxStyled
                      className={Number(location.weight_used || 0) > 0 ? 'blue' : 'silver'}
                      style={{ width: 100, margin: 'auto' }}
                    >
                      {<span>{location.weight_used || 0} lbs</span>}
                    </StatusBoxStyled>
                  }
                />
                <TableCell
                  center
                  input={
                    <StatusBoxStyled
                      className={Number(location.weight_remaining) > 0 ? 'blue' : 'red'}
                      style={{ width: 100, margin: 'auto' }}
                    >
                      {location.weight_remaining ? <span>{location.weight_remaining} lbs</span> : 'N/A'}
                    </StatusBoxStyled>
                  }
                />
              </GlobalStyles.TableRow>
            </tbody>
          </GlobalStyles.DataTable>
          <Row>
            <Form.Group as={Col} md="3">
              <Form.Label>Barcode</Form.Label>
              <Form.Control
                type="text"
                name="barcode"
                value={location.barcode}
                disabled
                onChange={(e) => setLocation({ ...location, barcode: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Location Type</Form.Label>
              <Form.Select
                name="location_type"
                value={location.location_type}
                required
                onChange={(e) => setLocation({ ...location, location_type: e.target.value })}
              >
                {locationTypesOptions.map(({ value, label }, id) => (
                  <option value={value} key={id}>
                    {label}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid location type.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Zone</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="zone"
                  required
                  value={location.zone}
                  onChange={(e) => setLocation({ ...location, zone: e.target.value })}
                />
                <Form.Control.Feedback type="invalid">Please choose a zone.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>Description</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="description"
                  value={location.description}
                  onChange={(e) => setLocation({ ...location, description: e.target.value })}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Length (in)</Form.Label>
              <Form.Control
                type="text"
                name="length"
                value={location.length}
                onChange={(e) => setLocation({ ...location, length: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid length.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Width (in)</Form.Label>
              <Form.Control
                type="text"
                name="width"
                value={location.width}
                onChange={(e) => setLocation({ ...location, width: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid width.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Height (in)</Form.Label>
              <Form.Control
                type="text"
                name="height"
                value={location.height}
                onChange={(e) => setLocation({ ...location, height: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid height.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Weight (lbs)</Form.Label>
              <Form.Control
                type="text"
                name="weight"
                value={location.weight}
                onChange={(e) => setLocation({ ...location, weight: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid weight.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Velocity</Form.Label>
              <Form.Select
                name="velocity"
                value={location.velocity}
                required
                onChange={(e) => setLocation({ ...location, velocity: e.target.value })}
              >
                {velocityOptions.map(({ value, label }, id) => (
                  <option value={value} key={id}>
                    {label}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid location type.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <GlobalStyles.DataTable style={{ marginTop: '2em' }}>
            <thead>
              <tr>
                <th>
                  <span className="center text"></span>
                </th>
                <th>
                  <span className="center text">Product</span>
                </th>
                <th>
                  <span className="center text">Variant</span>
                </th>
                <th>
                  <span className="center text">Available</span>
                </th>
                <th>
                  <span className="center text">Reserved</span>
                </th>
                <th>
                  <span className="center text">On Hand</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map(({ pid, pvid, img, name, sku, available, reserved, on_hand }: any) => (
                <GlobalStyles.TableRow key={pvid}>
                  <TableCell center>
                    <img src={img} width="100" style={{ maxWidth: 100, maxHeight: 100 }} />
                  </TableCell>
                  <TableCell center>
                    <Link to={`/product/${pid}/`}>{name}</Link>
                  </TableCell>
                  <TableCell center>
                    <Link to={`/product/variant/${pvid}/`}>{sku}</Link>
                  </TableCell>
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled className={available ? 'blue' : 'silver'} style={{ width: 100, margin: 'auto' }}>
                        {available}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled className={reserved ? 'blue' : 'silver'} style={{ width: 100, margin: 'auto' }}>
                        {reserved}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled className={on_hand ? 'blue' : 'silver'} style={{ width: 100, margin: 'auto' }}>
                        {on_hand}
                      </StatusBoxStyled>
                    }
                  />
                </GlobalStyles.TableRow>
              ))}
            </tbody>
          </GlobalStyles.DataTable>
        </div>
        {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
      </GlobalStyles.FullPageCard>
      <PrintLabelsModal
        id={Number(locationId)}
        location_id={Number(locationId)}
        qty={1}
        scancode={location.barcode}
        open={printModal}
        setOpen={setPrintModal}
      />
    </Form>
  )
}
