import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { OrderInfo } from './styles'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'
import GenericModal from '../../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import { DragNDrop } from '../../../components/component-items/dragndrop'

import { StatusRowProps, ProductAllocationProps } from './types'

export const UploadImageModal = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const productVariant = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()
  const [file, setFile] = useState(null)
  const uploadFile = () => {
    // Check there is a file first
    if (!file) {
      return
    }
    const name = 'photo'
    const extension = '.jpg'
    var formData = new window.FormData()
    formData.append(name, file, name + extension)
    usePost(`/api/product/${productVariant}/upload_photo/`, formData, csrf, true, false)
  }

  return (
    <GenericModal
      heading={'Upload Product Image'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={uploadFile}>
          Upload
        </GlobalStyles.Button>
      }
    >
      <div style={{ justifyContent: 'center', margin: 'auto', padding: '1em' }}>
        <Form.Label style={{ marginTop: '1em' }}>Image File Upload</Form.Label>
        <DragNDrop {...{ file, setFile }} fileTypes={'.jpg, .jpeg, .png'} />
      </div>
    </GenericModal>
  )
}

const StatusRow = ({ status, url, count }: StatusRowProps) => {
  return (
    <GlobalStyles.TableRow>
      <TableCell input={<Link to={url}>{status}</Link>} />
      <TableCell center input={count} />
    </GlobalStyles.TableRow>
  )
}

const ProductImage = styled.div`
  background-color: #fefefe;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  button {
    position: absolute;
    margin: 0;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }
  &:hover button,
  &:hover .shade {
    opacity: 1;
  }
  .shade {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.11);
  }
`

export const ProductAllocation = ({ img, unfulfilled, inFulfillment, fulfilled, total }: ProductAllocationProps) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="row" style={{ marginBottom: '20px' }}>
      <div className="col-xl-6">
        <OrderInfo>
          <ProductImage onClick={() => setOpen(true)}>
            <img style={{ width: '100%', height: 'auto' }} src={img} />
            <div className="shade" />
            <GlobalStyles.Button className="secondary">Update</GlobalStyles.Button>
          </ProductImage>
          <UploadImageModal {...{ open, setOpen }} />
        </OrderInfo>
      </div>
      <div className="col-xl-6">
        <OrderInfo style={{ display: 'block' }}>
          <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
            <GlobalStyles.CardTitle>
              <h3>
                Allocation<small>{total} Total</small>
              </h3>
            </GlobalStyles.CardTitle>
          </GlobalStyles.CardHeader>
          <div style={{ width: '100%' }}>
            <GlobalStyles.DataTable>
              <tbody>
                <StatusRow status={'All'} url={'/order/'} count={total} />
                <StatusRow status={'Unfulfilled'} url={'/order/?fulfillment=unfulfilled'} count={unfulfilled} />
                <StatusRow status={'In Fulfillment'} url={'/order/?fulfillment=in_fulfillment'} count={inFulfillment} />
                <StatusRow status={'Fulfilled'} url={'/order/?fulfillment=fulfilled'} count={fulfilled} />
              </tbody>
            </GlobalStyles.DataTable>
          </div>
        </OrderInfo>
      </div>
    </div>
  )
}
