import React from 'react'
import styled from 'styled-components'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'
import { ShippingStyled } from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink, faPrint } from '@fortawesome/pro-duotone-svg-icons'

import { ShippingTableRowProps } from './types'

const DataTable = styled(GlobalStyles.DataTable)`
  max-height: 500px;
`

const ShippingTableRow = ({ carrier, serviceLevel, tracking, cost, url, printUrl, voided }: ShippingTableRowProps) => {
  return (
    <GlobalStyles.TableRow>
      <TableCell input={carrier}>
        <div className="input">{serviceLevel}</div>
      </TableCell>
      <TableCell
        input={
          <a href={url} rel="noreferrer" target="_blank" className={voided ? 'strikeout' : ''}>
            {tracking}
          </a>
        }
      />
      <TableCell input={<span>{voided ? 'VOID' : '$' + cost}</span>} center />
      <TableCell
        input={
          <div className="dropdown__container">
            <a href={url} rel="noreferrer" target="_blank">
              <button>
                <FontAwesomeIcon icon={faExternalLink} />
              </button>
            </a>
            <a href={printUrl}>
              <button>
                <FontAwesomeIcon icon={faPrint} />
              </button>
            </a>
          </div>
        }
        center
      />
    </GlobalStyles.TableRow>
  )
}

export const Shipping = ({ returns }: { returns: any[] }) => {
  return (
    <ShippingStyled>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>Shipping</h3>
        </div>
        <div className="datatable__toolbar"></div>
      </div>
      <DataTable>
        <thead>
          <tr>
            <th>
              <span className="text">Shipping Method</span>
            </th>
            <th>
              <span className="text">Tracking</span>
            </th>
            <th>
              <span className="text center">Cost</span>
            </th>
            <th>
              <span className="text center">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {returns.map((r, id) => (
            <ShippingTableRow
              carrier={r.carrier}
              serviceLevel={r.serviceLevel}
              tracking={r.tracking}
              cost={r.cost}
              url={r.labelUrl}
              printUrl={r.printUrl}
              voided={r.voided}
              key={id}
            />
          ))}
        </tbody>
      </DataTable>
    </ShippingStyled>
  )
}
