import React, { useState } from 'react'
import styled from 'styled-components'
import { usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GenericModal from '../../../components/component-items/modal'
import { Form, InputGroup } from 'react-bootstrap'
import GlobalStyles from '../../../components/component-items/styles'
import { DragNDrop } from '../../../components/component-items/dragndrop'
import Checkbox from '../../../components/component-items/checkbox'
import { HoverNotes } from '../../../components/component-items/hover-notes'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags, faUsdCircle } from '@fortawesome/pro-duotone-svg-icons'

export const ImportProductsModal = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const [file, setFile] = useState(null)
  const {
    state: { csrf },
  } = globalState()

  const uploadFile = () => {
    if (!file) {
      return
    }
    var formData = new window.FormData()
    formData.append('products', file, 'products.csv')
    usePost('/api/product/', formData, csrf, true, false)
  }

  return (
    <GenericModal
      heading={'Upload Product from CSV'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => uploadFile()}>
          Upload Products
        </GlobalStyles.Button>
      }
    >
      <div style={{ justifyContent: 'center', margin: 'auto', padding: '1em' }}>
        <Form.Label style={{ marginTop: '1em' }}>
          CSV File Upload (<a href="/api/static/templates/product_upload_template.csv">Download Template</a>)
        </Form.Label>
        <DragNDrop {...{ file, setFile }} fileTypes={'.csv'} />
      </div>
    </GenericModal>
  )
}

const Label = styled(Form.Label)`
  margin-top: 1em;
`

export const CreateProduct = () => {
  const [validated, setValidated] = useState(false)
  const [open, setOpen] = useState(false)
  const [product, setProduct] = useState({
    title: '',
    description: '',
    sku: '',
    price: '',
    hsCode: '',
    inventoryControl: 'fifo',
    inventory_control_category: 'received_date',
    promo: false,
    mediaMail: false,
    scanSerialNumber: false,
  })

  const {
    state: { csrf },
  } = globalState()

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget

    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }

    usePost('/api/product/', { ...product }, csrf)
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Create Product</h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar>
            <GlobalStyles.Button onClick={() => setOpen(true)} style={{ height: 40, width: 170 }}>
              Upload Products
            </GlobalStyles.Button>
            <ImportProductsModal {...{ open, setOpen }} />
          </GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <div style={{ minHeight: 500, width: 500, margin: '2em auto' }}>
          <Form.Group>
            <Label>Title</Label>
            <InputGroup>
              <Form.Control
                type="text"
                value={product.title}
                onChange={(e) => setProduct({ ...product, title: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a title</Form.Control.Feedback>
            </InputGroup>
            <Label>Description</Label>
            <InputGroup>
              <Form.Control
                as="textarea"
                aria-label="Product Description"
                value={product.description}
                onChange={(e) => setProduct({ ...product, description: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter a description</Form.Control.Feedback>
            </InputGroup>
            <Label>SKU</Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faTags} />
              </InputGroup.Text>
              <Form.Control
                required
                type="text"
                placeholder="SKU"
                value={product.sku}
                onChange={(e) => setProduct({ ...product, sku: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a SKU</Form.Control.Feedback>
            </InputGroup>
            <Label>Price</Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faUsdCircle} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Price"
                value={product.price}
                required
                onChange={(e) => setProduct({ ...product, price: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid price.</Form.Control.Feedback>
            </InputGroup>
            <Label>HS Code</Label>
            <Form.Control
              type="text"
              value={product.hsCode}
              onChange={(e) => setProduct({ ...product, hsCode: e.target.value })}
            />
            <Label>Inventory Control</Label>
            <Form.Select
              value={product.inventoryControl}
              onChange={(e) => setProduct({ ...product, inventoryControl: e.target.value })}
            >
              <option value={'fifo'}>Lot Control - FIFO</option>
              <option value={'lifo'}>Lot Control - LIFO</option>
            </Form.Select>
            <Label>
              <HoverNotes description="Inventory will be ordered by this field." title="Inventory Control Field" />
            </Label>
            <Form.Select
              value={product.inventory_control_category}
              onChange={(e) => setProduct({ ...product, inventory_control_category: e.target.value })}
            >
              <option value={'lot_number'}>Lot Number</option>
              <option value={'received_date'}>Received Date</option>
              <option value={'manufacture_date'}>Manufacture Date</option>
              <option value={'best_before_date'}>Best Before Date</option>
              <option value={'expiry_date'}>Expiry Date</option>
            </Form.Select>
            <InputGroup style={{ marginTop: '2em' }}>
              <Checkbox
                selected={product.promo}
                setSelected={() => setProduct({ ...product, promo: !product.promo })}
              />
              <p style={{ padding: '0 1em', lineHeight: '24px' }}>Promotional Item</p>
            </InputGroup>
            <InputGroup>
              <Checkbox
                selected={product.mediaMail}
                setSelected={() => setProduct({ ...product, mediaMail: !product.mediaMail })}
              />
              <p style={{ padding: '0 1em', lineHeight: '24px' }}>Eligible for Media Mail</p>
            </InputGroup>
            <InputGroup>
              <Checkbox
                selected={product.scanSerialNumber}
                setSelected={() => setProduct({ ...product, scanSerialNumber: !product.scanSerialNumber })}
              />
              <p style={{ padding: '0 1em', lineHeight: '24px' }}>Product Requires Scanning Serial Numbers</p>
            </InputGroup>
            <GlobalStyles.Button type="submit" style={{ float: 'right' }}>
              Create Product
            </GlobalStyles.Button>
          </Form.Group>
        </div>
      </GlobalStyles.FullPageCard>
    </Form>
  )
}
