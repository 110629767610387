import { toast } from 'react-toastify'
import { notify } from './components/component-items/helpers'

const scanMapping: Record<string, Array<string>> = {
  // Global Paths
  '/warehouse/start-shipping': ['global', 's'],
  '/warehouse/exit-scan/': ['global', 'e'],
  '/batch/': ['global', 'b'],

  // Order Paths
  '/order/[id]/pack': ['order', 'p'],
  '/order/ship-next-order': ['order', 'f'],
  '/order/[id]/confirm-dimensions': ['order', 'c'],
  '/order/[id]/label-purchase': ['order', 'l'],

  // Pack Order Page
  '/order/shipment-up': ['order', 'u'],
  '/order/shipment-down': ['order', 'd'],
  '/order/add-package': ['order', 'a'],
  '/order/shipment/no-packaging': ['order', 'n'],
  '/order/shipment/delete': ['order', 'x'],
}

type KeyboardShortcut = {
  url?: string
  data?: any
  id?: string
}

export const keyboardMapping: Record<string, Record<string, KeyboardShortcut>> = {
  global: {
    s: {
      url: '/warehouse/start-shipping',
    },
    e: {
      url: '/warehouse/exit-scan/',
    },
    b: {
      url: '/batch/',
    },
  },
  order: {
    p: {
      url: 'pack/',
    },
    c: {
      url: 'confirm-dimensions/',
    },
    l: {
      url: 'label-purchase/',
    },
    f: {
      // Ship Next order
      id: 'ship-next-order',
    },
    a: {
      // Add Package
      id: 'add-package',
    },
    n: {
      // No Packaging
      data: {},
      id: 'no-packaging',
    },
    x: {
      // Delete Shipment
      data: {},
      id: 'delete-shipment',
    },
    u: {
      // Up One Shipment
      data: {
        shipment_count: 0,
      },
      id: 'shipment',
    },
    d: {
      // Down One Shipment
      data: {
        shipment_count: 0,
      },
      id: 'shipment',
    },
  },
}

var sequence = ''
var currentKey = ''
var last_recorded = Date.now()
var currentShipmentId = -1
export function connectShortcuts(event: object, id: number | string, page: string, history: any) {
  let { key, ctrlKey, shiftKey }: any = event
  if (!key) return

  // Prevent storing too large of a sequence
  var now = Date.now()
  var seconds = 5
  var five_seconds_ago = now - seconds * 1000
  if (last_recorded < five_seconds_ago) sequence = ''
  last_recorded = now

  if (key.includes('*')) {
    const currentShipment = document.activeElement?.id
    if (currentShipment?.includes('shipment')) {
      currentShipmentId = parseInt(currentShipment.split('-')[1])
    }
    let element = document.activeElement as HTMLElement
    element?.blur()
  }

  // Begin listening to key presses
  // Clear keypress if '*' entered
  if (page === 'global') sequence = key.includes('*') ? (sequence = '') : (sequence += key)

  // Check for any mapping that is contained in sequence
  // Ex: pack/order/[id]/pack would match with /order/[id]/pack
  let found_match = false
  for (const mapping in scanMapping) {
    if (sequence.includes(mapping)) {
      sequence = mapping
      found_match = true
      break
    }
  }

  // Handle Keyboard Press or Barcode Scan
  if ((ctrlKey && shiftKey) || found_match) {
    // Check for sequence
    currentKey = sequence in scanMapping ? scanMapping[sequence][1] : key.toLowerCase()
    // Global Keyboard Shortcuts
    if (page === 'global' && currentKey in keyboardMapping['global']) {
      const timeout = setTimeout(() => {
        let url = keyboardMapping['global'][currentKey].url
        history.push(url)
        sequence = ''
        currentKey = ''
      }, 200)
    }
    // Page Specific Keyboard Shortcuts
    else if (id) {
      const baseUrl = `${page}/${id}`
      // Check if page has shortcuts and if shortcut given is attached to that page
      if (page in keyboardMapping && currentKey in keyboardMapping[page]) {
        // Order Shorcuts
        if (page === 'order') handle_order_shortcuts(baseUrl, page, currentKey, history)
      }
    }
  }
}

function handle_order_shortcuts(baseUrl: string, page: string, key: string, history: any) {
  if (document === null) return
  // Grabbing element by ID (Pack page specific)
  if (keyboardMapping[page][key].id) {
    sequence = ''
    currentKey = ''
    // Add package or ship next order
    switch (key) {
      case 'a':
      case 'f':
        setTimeout(() => {
          const id_selector = keyboardMapping[page][key].id
          let packingElement = null
          if (id_selector) packingElement = document.getElementById(id_selector)
          if (packingElement) packingElement.click()
        }, 200)
        return
    }

    // Input Traversal: Up and Down Shipments, Delete Shipment, No Packaging (Shipment specific)
    if (currentShipmentId > 0) {
      const data = keyboardMapping[page][key].data
      switch (key) {
        case 'u': // Up One Shipment
          if (currentShipmentId < data.shipment_count) {
            currentShipmentId += 1
            const timeout = setTimeout(() => {
              sequence = ''
              currentKey = ''
              document.getElementById('shipment-' + currentShipmentId)!.focus()
            }, 200)
          }

          return
        case 'd': // Down One Shipment
          if (currentShipmentId > 1) {
            currentShipmentId -= 1
            const timeout = setTimeout(() => {
              sequence = ''
              document.getElementById('shipment-' + currentShipmentId)!.focus()
            }, 200)
          }
          return
        case 'n': // No Packaging
          var noPackagingElement = document.getElementById('no-packaging-' + currentShipmentId)
          noPackagingElement!.click()
          setTimeout(() => {
            sequence = ''
            document.getElementById('shipment-' + currentShipmentId)!.focus()
          }, 200)

          return
        case 'x': // Delete Shipment
          var deleteShipmentElement = document.getElementById('delete-shipment-' + currentShipmentId)
          sequence = ''
          deleteShipmentElement!.click()
          return
      }
    }
  }
  // URL redirection: pack order, confirm dimenions, purchase label, next order
  else if ('url' in keyboardMapping[page][key]) {
    const timeout = setTimeout(() => {
      history.push(`/${baseUrl}/${keyboardMapping[page][key].url}`)
      sequence = ''
      currentKey = ''
    }, 200)
  }
}

export function handleOrderPermissions(
  id: number | string,
  packed: boolean,
  needs_dimensions: boolean,
  labelled: boolean,
  history: any
) {
  if (['c', 'l', 'f'].includes(currentKey) && !packed) {
    notify({ type: 'warning', message: 'Cannot perform action. Order is not fully packed.' })
    history.push(`/order/${id}/pack/`)
    currentKey = ''
    sequence = ''
  }
  // Only allow access to purchase label or ship next order if order has dimensions
  else if (['l', 'f'].includes(currentKey) && needs_dimensions) {
    notify({ type: 'warning', message: 'Cannot perform action. Order needs dimensions.' })
    history.push(`/order/${id}/confirm-dimensions/`)
    currentKey = ''
    sequence = ''
  } else if (['f'].includes(currentKey) && !labelled) {
    notify({ type: 'warning', message: 'Cannot perform action. Order is not labeled.' })
    history.push(`/order/${id}/label-purchase/`)
    currentKey = ''
    sequence = ''
  }
}
