import styled from 'styled-components'
import React, { isValidElement } from 'react'

import { ToastProps } from './types'

const Button = styled.button`
  border: 1px solid ${(props) => props.theme.colors.doveGray};
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
  border: 1px solid rgba(117, 117, 117);
  background-color: ${(props) => props.theme.colors.white};
  color: rgba(117, 117, 117);
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.doveGray};
    background-color: #eaeaea;
    color: ${(props) => props.theme.colors.doveGray};
  }
  &:active {
    border: 1px solid ${(props) => props.theme.colors.doveGray};
    background-color: #eaeaea;
    color: ${(props) => props.theme.colors.doveGray};
  }
`

export const ConfirmToast = ({ title, body, onSubmit, onDismiss, requestConfirmation }: ToastProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <b style={{ fontSize: '17px', marginBottom: '8px' }}>{title || 'Warning!'}</b>
      <span style={{ fontSize: '13px' }}>{isValidElement(body) ? body : String(body)}</span>
      {requestConfirmation && (
        <>
          <br />
          <div className="confirm" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button onClick={onDismiss}>Dismiss</Button>
            <Button onClick={onSubmit}>Confirm</Button>
          </div>
        </>
      )}
    </div>
  )
}
