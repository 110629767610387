import styled, { keyframes } from 'styled-components'

const move = keyframes`
    from { background-position: 0 0; }
    to { background-position: -400px 0; }
`

export const PostPage = styled.div`
    position: fixed;
    left: 0;
    right: 0
    top: 0;
    bottom: 0;
    z-index: 10000;
    background: #242939 url('/api/static/assets/login/background.png') 100px 100px repeat;
    background-size: 400px;
    color: ${(props) => props.theme.colors.white};
    width: 100vw;
    height: 100vh;
    animation: ${move} 40s linear infinite;
`

export const Background = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  box-shadow: rgba(228, 255, 248, 0.13) 5px 8px 20px 0px;
`

export const SignIn = styled.div`
  display: flex;
  min-width: 900px;
  min-height: 500px;
`

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: stretch;
  height: 100%;
  padding: 2em 4em;
  h1 {
    margin-top: 1em;
  }
  p {
    margin: 4em 10em;
  }
`

export const Logo = styled.img`
  width: 30%;
  height: auto;
  margin: 1em auto 2em;
`
