import styled from 'styled-components'
import GlobalStyles from '../../../components/component-items/styles'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

export const BatchListRowStyled = styled.tr`
  left: 0px;
  &.active td {
    transition: all 0.3s ease !important;
    background: #f7f8fa;
  }
`

export const DataTable = styled.table`
  width: 100%;
  max-height: 500px;
  thead {
    th {
      padding: 16px 10px;
      vertical-align: middle;
      padding: 10px;
      span.text {
        cursor: pointer;
        display: block;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #595d6e;
        min-width: 20px;
      }
      &:first-child {
        > span {
          position: relative;
          top: 3px;
        }
      }
    }
    /*Account for scroll bar on table*/
    padding-right: 3px;
  }
  tbody {
    max-height: 200px;
    overflow: scroll;
    position: relative;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
  tr {
    width: 100%;
    border-bottom: ${(props) => `1px solid ${props.theme.colors.zircon}`};
  }
`

export const BatchListCellStyled = styled.td`
  background: ${(props) => props.theme.colors.white};
  vertical-align: middle;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.3s ease !important;
  &:last-child {
    cursor: pointer;
  }
  button {
    color: #595d6e;
  }
  .input {
    min-width: 20px;
    font-weight: 500 !important;
  }
  label {
    top: 4px;
  }
  .product_image {
    max-width: 60px;
    max-height: 60px;
  }
  .dropdown__container {
    position: relative;
    button {
      cursor: pointer;
      height: 2rem;
      width: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      text-align: center;
      border-radius: 0.2rem;
      &:hover {
        background: ${(props) => props.theme.colors.zircon};
        svg {
          color: ${(props) => props.theme.colors.dodgerBlue};
        }
      }
    }
  }
`
