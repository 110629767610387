import React from 'react'
import styled, { keyframes } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

const moveUp = keyframes`
    from   { opacity: 0; margin-top: 10px }
    to { opacity: 1; margin-top: 0; }
`

const Spinner = keyframes`
    to {
        transform: rotate(360deg);
    }
`

const Tooltip = styled.div<{ bottom?: boolean }>`
  position: relative;
  /* Hide the tooltip content by default */
  &:before,
  &:after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: 200;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    transition: all 0.3s !important;
  }

  /* Position tooltip above the element */
  &:before {
    position: absolute;
    bottom: ${(props) => (props.bottom ? 'unset' : '100%')};
    top: ${(props) => (props.bottom ? '100%' : 'unset')};
    margin: 5px 0;
    padding: 8px 20px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.colors.white};
    color: #6c7293;
    content: attr(data-title);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
  }

  /* Triangle hack to make tooltip look like a speech bubble */
  &:after {
    position: absolute;
    bottom: ${(props) => (props.bottom ? 'unset' : '100%')};
    top: ${(props) => (props.bottom ? '100%' : 'unset')};
    transform: ${(props) => (props.bottom ? 'rotate(180deg)' : 'unset')};
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: ${(props) => `5px solid ${props.theme.colors.white}`};
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: ' ';
  }

  /* Show tooltip content on hover */
  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5s !important;
  }
`

const ArrowStyled = styled.i`
  display: inline-block;
  font: normal normal normal 16px/1 'LineAwesome';
  font-size: inherit;
  transition: all 0.3s ease-in;
  &.active {
    transform: rotate(90deg);
  }
`

const Arrow = ({ className }: { className?: string }) => (
  <ArrowStyled className={className}>
    <FontAwesomeIcon icon={faChevronRight} />
  </ArrowStyled>
)

const Button = styled.button`
  position: relative;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  margin: 0 0.5em;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  user-select: none;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.colors.dodgerBlue};
  color: ${(props) => props.theme.colors.white};
  border: 1px solid transparent;
  transition: background-color 0.2s ease-in;
  .badge {
    position: absolute;
    right: -5px;
    top: -5px;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.freshEggplant};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.dodgerBlueLight};
  }
  &.secondary {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.dodgerBlue};
    border: 1px solid rgba(93, 120, 255, 0.48);
    &:hover {
      background-color: ${(props) => props.theme.colors.zircon};
    }
    &:active {
      background-color: #e9ebf4;
    }
    &:disabled {
      color: grey;
      background-color: ${(props) => props.theme.colors.white};
      border-color: #eee;
    }
  }
  &.royal {
    background-color: rgb(0, 22, 130);
    &:hover {
      background-color: ${(props) => props.theme.colors.dodgerBlueLight};
    }
  }
  &.red {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.crimson};
    border: 1px solid ${(props) => props.theme.colors.crimson};
    &:hover {
      background-color: ${(props) => props.theme.colors.crimson};
      color: white;
    }
    &:active {
      background-color: ${(props) => props.theme.colors.crimson};
      color: white;
    }
  }
`

const ClearInputButton = styled.button`
  position: absolute;
  right: 40px;
  top: 0;
  bottom: 0;
  z-index: 100;
  margin-top: auto;
  margin-bottom: auto;
  height: 22px;
  &:hover {
    background-color: #eaeaea;
    border-radius: 4px;
  }
`

const DataTable = styled.table`
  width: 100%;
  thead {
    th {
      vertical-align: middle;
      padding: 10px;
    }
    /*Account for scroll bar on table*/
    padding-right: 3px;
  }
  tbody {
    max-height: 200px;
    overflow: scroll;
    position: relative;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
  tr {
    width: 100%;
    border-bottom: ${(props) => `1px solid ${props.theme.colors.zircon}`};
  }
  span.text {
    cursor: pointer;
    display: block;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #595d6e;
    min-width: 20px;
    &.center {
      text-align: center;
    }
  }
`

const TableRow = styled.tr`
  left: 0px;
  &.active td {
    transition: all 0.3s ease !important;
    background: #f7f8fa;
  }
`

const TableCell = styled.td`
  background: ${(props) => props.theme.colors.white};
  vertical-align: middle;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.3s ease !important;
  button {
    color: #595d6e;
  }
  .input {
    min-width: 20px;
    font-weight: 500 !important;
  }
  label {
    top: 4px;
  }
  .strikeout {
    text-decoration: line-through;
  }
  .dropdown__container {
    position: relative;
    button {
      cursor: pointer;
      height: 2rem;
      width: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      text-align: center;
      border-radius: 0.2rem;
      &:hover {
        background: ${(props) => props.theme.colors.zircon};
        svg {
          color: ${(props) => props.theme.colors.dodgerBlue};
        }
      }
    }
  }
`

const FullPageCard = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
`

const CardHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  padding: 0 25px;
  min-height: 60px;
  border-bottom: 1px solid #ececec;
`

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  h3 {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #48465b;
    small {
      font-weight: 300;
      padding-left: 1rem;
      font-size: 1rem;
      color: #74788d;
    }
  }
`

const CardToolbar = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-end;
  button {
    min-width: 125px;
  }
`

const CardFooter = styled.div`
  margin: 0;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const ScrollTable = styled.div`
  height: 500px;
  overflow-y: auto;
  position: relative;
  padding: 0 1em;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bbc2e5;
    border-radius: 5px;
  }
`

export const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
  }
`

export const GlobalStyled = {
  moveUp,
  Spinner,
  Tooltip,
  Arrow,
  Button,
  ClearInputButton,
  DataTable,
  TableRow,
  TableCell,
  FullPageCard,
  CardHeader,
  CardTitle,
  CardToolbar,
  CardFooter,
  ScrollTable,
  MultiSelect,
}

export default GlobalStyled
