// React
import React, { FunctionComponent } from 'react'
import { useHistory, Link } from 'react-router-dom'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { TableCell } from '../../../../components/component-items/datatable'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'

import { AsnOptionProps, AsnItemProps } from './types'

export const AsnOption: FunctionComponent<AsnOptionProps> = ({ url, onClick, icon, title, arrow, children, node }) => {
  return (
    <li ref={node} onClick={onClick}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const AsnItem: FunctionComponent<AsnItemProps> = ({
  asn,
  id,
  pId,
  img,
  name,
  barcode,
  sku,
  sent,
  over_short = 0,
  defective,
  status,
  received,
  single,
  compliant,
}) => {
  const history = useHistory()
  return (
    <GlobalStyles.TableRow>
      <TableCell center>
        <img src={img} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
      </TableCell>
      <TableCell input={<Link to={`/product/variant/${pId}/`}>{name}</Link>} style={{ userSelect: 'none' }} />
      <TableCell center input={<Link to={`/product/variant/${pId}/`}>{sku}</Link>}>
        <div>{barcode}</div>
      </TableCell>
      <TableCell
        center
        input={
          <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className="silver">
            {sent}
          </StatusBoxStyled>
        }
      />
      <TableCell
        center
        input={
          <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className="green">
            {received}
          </StatusBoxStyled>
        }
      />
      <TableCell
        center
        input={
          <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className="silver">
            {defective}
          </StatusBoxStyled>
        }
      />
      {status !== 'Received' && (
        <TableCell
          center
          input={
            <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={over_short < 0 ? 'yellow' : 'silver'}>
              {over_short > 0 ? `+${over_short}` : over_short}
            </StatusBoxStyled>
          }
        />
      )}

      <TableCell
        center
        input={
          <StatusBoxStyled style={{ width: 90, margin: 'auto' }} className={compliant ? 'green' : 'yellow'}>
            {compliant === undefined || compliant === null ? '--' : compliant ? 'Compliant' : 'Non-Compliant'}
          </StatusBoxStyled>
        }
      />

      {!single && (
        <TableCell center>
          {status == 'Received' ? (
            <button onClick={() => history.push(`/asn/${asn}/item/${id}/non-compliance/`)}>Select</button>
          ) : (
            <button onClick={() => history.push(`/asn/${asn}/item/${id}/confirm-details/`)}>Select</button>
          )}
        </TableCell>
      )}
    </GlobalStyles.TableRow>
  )
}
