import React from 'react'
import { useParams } from 'react-router-dom'

// Components
import { ProgressList } from '../styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxAlt, faRulerCombined, faPrint, faChevronRight } from '@fortawesome/pro-duotone-svg-icons'

import { MenuOptionProps, PrePackWorkflowProps } from './types'

const MenuOption = ({
  url,
  onClick,
  icon,
  title,
  arrow,
  children,
  node,
  active,
  disabled,
  complete,
}: MenuOptionProps) => {
  return (
    <li ref={node} onClick={onClick} className={active ? 'active' : disabled ? 'disabled' : complete ? 'complete' : ''}>
      <a href={url && !disabled ? url : '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const PrePackWorkflow = ({ current, batch, fully_packed, needs_dimensions }: PrePackWorkflowProps) => {
  const order = useParams<{ id: string }>().id
  const url = `/order/${order}`

  return (
    <ProgressList>
      <ul>
        <MenuOption
          url={url + '/pre-pack/'}
          active={current === 'pre-pack'}
          icon={faBoxAlt}
          title={'Pack Golden Sample'}
        />
        <MenuOption
          url={url + '/pre-pack-dimensions/'}
          active={current === 'pre_pack_dimensions'}
          icon={faRulerCombined}
          disabled={!fully_packed}
          title={'Confirm Dimensions'}
        />
        <MenuOption
          url={`/api/inventory/batch/${batch}/prebuy_labels/`}
          active={current === 'label_purchase'}
          icon={faPrint}
          disabled={!fully_packed || needs_dimensions}
          title={'Pre-Pack & Buy Labels'}
        />
      </ul>
    </ProgressList>
  )
}
