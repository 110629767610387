import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFetch, usePost } from '../../components/component-items/helpers'
import { globalState } from '../../store'
import { useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

// Components
import GlobalStyles from '../../components/component-items/styles'
import { CenteredSpinner } from '../../components/component-items/loading-popover'
import Form from 'react-bootstrap/Form'

export const ReceiveASN = () => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [barcode, setBarcode] = useState('')

  const {
    state: { csrf },
  } = globalState()

  const handleSubmit = async () => {
    if (!barcode) {
      toast('No barcode entered', { type: 'error' })
      return
    }
    if (loading) {
      return
    }
    setLoading(true)
    const resp = await usePost('/api/inventory/asn/start_receiving/', { barcode }, csrf, false)
    if (!resp.error) {
      history.push(`/asn/${resp.asn}/receive/`)
    } else {
      setLoading(false)
    }
  }

  // Add cursor to input field on render
  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputElement.current) {
      inputElement?.current?.focus()
      inputElement?.current?.select()
    }
  }, [])

  const handleKeyPressed = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>Receive ASN</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 110, margin: '2em' }}>
        <Form.Label>
          <strong>Receive</strong> (Tracking, PO, BOL, or PRO number)
        </Form.Label>
        <div style={{ display: 'flex' }}>
          <Form.Control
            type="text"
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            placeholder="Barcode"
            onKeyDown={handleKeyPressed}
            ref={inputElement}
            required
          />
          {!loading ? (
            <GlobalStyles.Button className="royal" style={{ minWidth: 125 }} onClick={() => handleSubmit()}>
              Submit
            </GlobalStyles.Button>
          ) : (
            <GlobalStyles.Button className="royal" style={{ minWidth: 125, cursor: 'auto' }}>
              <CenteredSpinner />
              &nbsp;
            </GlobalStyles.Button>
          )}
        </div>
      </div>
    </GlobalStyles.FullPageCard>
  )
}
