import React, { useState } from 'react'
import { globalState } from '../../store'

// Cards
import { Automations } from './automations/automation'
import { Schedules } from './schedules/schedule'

// Components
import GlobalStyles from '../../components/component-items/styles'
import { StickyElement } from '../../components/component-items/helpers'
import { FullTabMenuCard } from '../../components/component-items/tab-menu'
import { MenuOptions } from './styles'

// Cards
import { StatusBar } from './status-bar'
import { ActionActivity } from './activity'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { faPlusHexagon, faMagicWandSparkles, faCalendarDay, faVial } from '@fortawesome/pro-duotone-svg-icons'
import { AutomationList } from './automations'
import { ScheduleList } from './schedules'

const MenuOption = ({ url, onClick, icon, title, arrow, children, node }: any) => {
  return (
    <li ref={node} onClick={onClick}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const Actions = () => {
  const {
    state: { userIsWarehouse, actAs },
  } = globalState()

  const tabMapping = [
    {
      label: 'Create Automation',
      component: <Automations />,
      listComponent: <AutomationList />,
      icon: faMagicWandSparkles,
    },
    {
      label: 'Create Schedule',
      component: <Schedules />,
      listComponent: <ScheduleList />,
      icon: faCalendarDay,
    },
  ]
  const [activeTab, setActiveTab] = useState('Create Automation')
  return (
    <>
      <StatusBar />
      <div className="row">
        <div className="col-xl-2">
          <StickyElement>
            <MenuOptions>
              <ul>
                <GlobalStyles.Tooltip data-title="Add a new trade request using Orderful">
                  <MenuOption onClick={() => null} icon={faVial} title={'Example Triggers'} />
                </GlobalStyles.Tooltip>
              </ul>
            </MenuOptions>
          </StickyElement>
        </div>
        <div className="col-xl-7">
          <FullTabMenuCard {...{ tabMapping, activeTab, setActiveTab }}>
            {tabMapping.find((tab) => tab.label === activeTab)?.component}
          </FullTabMenuCard>
          {tabMapping.find((tab) => tab.label === activeTab)?.listComponent}
        </div>
        <div className="col-xl-3">
          <StickyElement>
            <ActionActivity />
          </StickyElement>
        </div>
      </div>
    </>
  )
}
