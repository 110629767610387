import React, { useState, useEffect, FunctionComponent, useRef } from 'react'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import { Form, InputGroup } from 'react-bootstrap'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import Select from 'react-select'
import { MultiSelect } from '../../schedules/schedule/styles'
import AsyncSelect from 'react-select/async'
import { Calendar } from 'react-date-range'
import { DateRangeStyled } from './action'
import { useClick } from '../../../../components/component-items/helpers'

// Types
import {
  FiltersProps,
  ConditionProps,
  defaultFilters,
  OrderFieldTypes,
  AddressFieldTypes,
  orderItemFieldTypes,
  BoolFieldTypes,
  StringFieldTypes,
  NumberFieldTypes,
  DateFieldTypes,
  AllFieldTypes,
  ASNFieldTypes,
  ReturnFieldTypes,
  OrderConditionOptions,
  ASNConditionOptions,
  ReturnConditionOptions,
  InventoryConditionOptions,
  ProductVariantConditionOptions,
  BillingConditionOptions,
  InventoryFieldTypes,
  ProductVariantFieldTypes,
  BillingFieldTypes,
} from './types'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faLayerPlus, faCalendarWeek } from '@fortawesome/pro-duotone-svg-icons'

export const Condition: FunctionComponent<ConditionProps> = ({
  automationType,
  filters,
  setFilters,
  children,
  r,
  g,
  b,
}) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const ref = useRef<HTMLLIElement>(null)
  useClick(ref, setShowCalendar)

  const [logical, setLogical] = useState(filters.logical)
  useEffect(() => {
    setFilters({ ...filters, logical: logical })
  }, [logical])

  function addRow() {
    setLogical((logical: FiltersProps[]) => [...logical, defaultFilters])
  }

  const setType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'logical_not') {
      setLogical([defaultFilters])
    } else {
      setLogical([defaultFilters, defaultFilters])
    }
    setFilters({ ...defaultFilters, type: e.target.value })
  }

  const isLogical = ['logical_and', 'logical_or', 'logical_not'].includes(filters.type)
  const noConditions = [
    'all_orders',
    'all_asns',
    'all_returns',
    'all_inventory',
    'all_product_variants',
    'all_billing_transactions',
  ].includes(filters.type)
  const isOrderItem = ['order_item_any', 'order_item_all', 'order_item_none'].includes(filters.type)

  // Instantiate Field Types
  const orderFieldList = OrderFieldTypes()
  const orderItemFieldList = orderItemFieldTypes()
  const addressFieldList = AddressFieldTypes()
  const asnFieldList = ASNFieldTypes()
  const returnFieldList = ReturnFieldTypes()
  const inventoryFieldList = InventoryFieldTypes()
  const productVariantFieldList = ProductVariantFieldTypes()
  const billingFieldList = BillingFieldTypes()

  // Set Field List, Field Operator, Field Options, and Field Type
  let fieldList: any = []
  if (automationType === 'order') {
    if (filters.type === 'order_field') {
      fieldList = [...orderFieldList]
    } else if (filters.type === 'address_field') {
      fieldList = [...addressFieldList]
    } else if (isOrderItem) {
      fieldList = [...orderItemFieldList]
    }
  } else if (automationType === 'asn') {
    fieldList = [...asnFieldList]
  } else if (automationType === 'return') {
    fieldList = [...returnFieldList]
  } else if (automationType === 'inventory') {
    fieldList = [...inventoryFieldList]
  } else if (automationType === 'product_variant') {
    fieldList = [...productVariantFieldList]
  } else if (automationType === 'billing') {
    fieldList = [...billingFieldList]
  }

  const fieldOperator: any = fieldList.find((t: any) => t.value === filters.result.field_type)
  const fieldOptions: any = fieldOperator?.options
  const fieldType: any = fieldOperator?.type

  useEffect(() => {
    let field_value: any = ''
    if (fieldType === 'date') {
      field_value = new Date().toLocaleDateString('en-GB')
    } else if (fieldType === 'boolean') {
      field_value = false
    }
    setFilters({
      ...filters,
      result: { ...filters.result, field_value: field_value },
    })
  }, [filters.result.field_type])

  const [asyncFieldOptions, setAsyncFieldOptions]: any = useState([])
  useEffect(() => {
    // Load async options and set them when they are ready
    const loadOptionsAsync = async () => {
      const options = await fieldOptions() // Assuming fieldOptions is a function that fetches options
      setAsyncFieldOptions(options)
    }

    if (fieldOperator?.async) {
      loadOptionsAsync()
    }
  }, [])

  const calculateDate = (dateString: string) => {
    if (dateString == null || dateString === '') {
      return new Date()
    }

    // Split the date string by '/' to extract day, month, and year
    const [day, month, year] = dateString.split('/').map(Number)

    // Create a new Date object (month is 0-indexed, so subtract 1)
    return new Date(year, month - 1, day)
  }

  return (
    <GlobalStyles.DataTable>
      <tbody>
        <tr style={{ border: 'unset' }}>
          <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
            <Form.Select required value={filters.type} onChange={(e) => setType(e)}>
              {automationType === 'order' ? (
                <OrderConditionOptions />
              ) : automationType === 'asn' ? (
                <ASNConditionOptions />
              ) : automationType === 'return' ? (
                <ReturnConditionOptions />
              ) : automationType === 'inventory' ? (
                <InventoryConditionOptions />
              ) : automationType === 'product_variant' ? (
                <ProductVariantConditionOptions />
              ) : automationType === 'billing' ? (
                <BillingConditionOptions />
              ) : null}
            </Form.Select>
            {isLogical ? (
              <GlobalStyles.DataTable>
                <tbody>
                  <tr style={{ border: 'unset' }}>
                    <TableCell style={{ background: `rgb(${r}, ${g}, ${b})`, border: '1px solid #e1eaf1' }}>
                      {logical.map((newFilters: FiltersProps, index: number) => (
                        <React.Fragment key={index}>
                          <Condition
                            automationType={automationType}
                            filters={newFilters}
                            setFilters={(e: any) =>
                              setLogical((logical: FiltersProps[]) => [
                                ...logical.slice(0, index),
                                { ...e },
                                ...logical.slice(index + 1),
                              ])
                            }
                            r={r * 0.95}
                            g={g * 0.95}
                            b={b * 0.95}
                          >
                            {filters.type !== 'logical_not' ? (
                              <TableCell
                                center
                                style={{ verticalAlign: 'unset', width: 50, background: 'rgb(0,0,0,0)' }}
                                input={
                                  <div className="dropdown__container" style={{ minWidth: 60 }}>
                                    {logical.length > 2 ? (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          setLogical((logical: FiltersProps[]) =>
                                            logical.filter((c) => c !== newFilters)
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                      </button>
                                    ) : null}
                                    <button type="button" onClick={() => addRow()}>
                                      <FontAwesomeIcon icon={faLayerPlus} />
                                    </button>
                                  </div>
                                }
                              />
                            ) : null}
                          </Condition>
                          {index + 1 !== logical.length ? (
                            <StatusBoxStyled
                              className={'blue'}
                              style={{ margin: '1em auto', width: 100, textAlign: 'center' }}
                            >
                              {filters.type === 'logical_and' ? 'AND' : 'OR'}
                            </StatusBoxStyled>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </TableCell>
                  </tr>
                </tbody>
              </GlobalStyles.DataTable>
            ) : null}
          </TableCell>
          {!isLogical && !noConditions ? (
            <>
              <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                <Form.Select
                  required
                  value={filters.result.field_type}
                  onChange={(e) =>
                    setFilters({ ...filters, result: { ...filters.result, field_type: e.target.value } })
                  }
                >
                  <option value="" disabled hidden>
                    - Field Type -
                  </option>
                  {fieldList?.map(({ value, label }: any) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </TableCell>
              <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                <Form.Select
                  required
                  value={filters.result.field_operator}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      result: { ...filters.result, field_operator: e.target.value },
                    })
                  }
                >
                  <option value={''} disabled hidden>
                    - Field Operator -
                  </option>
                  {fieldOperator?.type === 'boolean'
                    ? BoolFieldTypes.map(({ value, label }: any) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))
                    : fieldOperator?.type === 'string'
                      ? StringFieldTypes.map(({ value, label }: any) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))
                      : fieldOperator?.type === 'number'
                        ? NumberFieldTypes.map(({ value, label }: any) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))
                        : fieldOperator?.type === 'date'
                          ? DateFieldTypes.map(({ value, label }: any) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))
                          : AllFieldTypes.map(({ value, label }: any) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                </Form.Select>
              </TableCell>
              {fieldOperator?.async ? (
                <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                  <MultiSelect>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions={true}
                      loadOptions={fieldOptions}
                      value={asyncFieldOptions.find(({ value }: any) => value === filters.result.field_value)}
                      onChange={(e: any) =>
                        setFilters({
                          ...filters,
                          result: { ...filters.result, field_value: e.value },
                        })
                      }
                      closeMenuOnSelect={false}
                      className="basic-multi-select"
                    />
                  </MultiSelect>
                </TableCell>
              ) : fieldOptions?.length ? (
                <TableCell style={{ background: 'rgb(0,0,0,0)', minWidth: 200 }}>
                  <Select
                    options={fieldOptions}
                    value={fieldOptions.find((o: any) => o === filters.result.field_value)}
                    onChange={(e: any) =>
                      setFilters({
                        ...filters,
                        result: { ...filters.result, field_value: e.value },
                      })
                    }
                  />
                </TableCell>
              ) : fieldOperator?.type === 'boolean' ? (
                <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                  <Select
                    options={[
                      { label: 'True', value: true },
                      { label: 'False', value: false },
                    ]}
                    value={
                      filters.result.field_value ? { label: 'True', value: true } : { label: 'False', value: false }
                    }
                    onChange={(e: any) =>
                      setFilters({
                        ...filters,
                        result: { ...filters.result, field_value: e.value },
                      })
                    }
                  />
                </TableCell>
              ) : fieldType === 'date' ? (
                <TableCell style={{ background: 'rgb(0,0,0,0)', position: 'relative' }} ref={ref}>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={filters.result.field_value?.replace(/\//g, '-')}
                      placeholder="Choose A Date"
                      onFocus={() => setShowCalendar(true)}
                    />
                    <InputGroup.Text id="inputGroupAppend">
                      <FontAwesomeIcon icon={faCalendarWeek} />
                    </InputGroup.Text>
                  </InputGroup>
                  {showCalendar && (
                    <DateRangeStyled>
                      <Calendar
                        onChange={(e: any) => {
                          setFilters({
                            ...filters,
                            result: { ...filters.result, field_value: e.toLocaleDateString('en-GB') },
                          })
                          setShowCalendar(false)
                        }}
                        date={calculateDate(filters.result.field_value)}
                        className="hide-in-percy"
                      />
                    </DateRangeStyled>
                  )}
                </TableCell>
              ) : !['is_blank', 'is_not_blank'].includes(filters.result.field_operator) ? (
                <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                  <Form.Control
                    type="text"
                    required
                    placeholder=""
                    value={filters.result.field_value}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        result: { ...filters.result, field_value: e.target.value },
                      })
                    }
                  />
                </TableCell>
              ) : null}
            </>
          ) : null}
          {children}
        </tr>
      </tbody>
    </GlobalStyles.DataTable>
  )
}
