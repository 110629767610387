import React, { useState, useEffect } from 'react'
import { useFetch, usePost, usePut, useDelete } from '../../components/component-items/helpers'
import { globalState } from '../../store'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'
import { TableCell } from '../../components/component-items/datatable'
import { TableRow } from './styles'
import { Form } from 'react-bootstrap'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/pro-duotone-svg-icons'

export const EditSubsciber = ({
  subscriber,
  subscriptions,
  open,
  setOpen,
}: {
  subscriber: any
  subscriptions: any
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const defaultSubsciption = { email: '', code: '1', id: null }
  const [subscription, setSubscription] = useState(defaultSubsciption)
  const {
    state: { csrf, companyId },
  } = globalState()

  useEffect(() => {
    if (Object.keys(subscriber).length === 0 && subscriber.constructor === Object) {
      setSubscription(defaultSubsciption)
    } else {
      setSubscription(subscriber)
    }
  }, [subscriber])

  const handleSubmit = () => {
    if (subscription.id) {
      usePut(`/api/company/${companyId}/email-subscriber/${subscription.id}/`, { ...subscription }, csrf)
    } else {
      usePost(`/api/company/${companyId}/email-subscriber/`, { ...subscription }, csrf)
    }
  }

  return (
    <GenericModal
      heading={subscriber.id ? 'Update Email Subscription' : 'Create Email Subscription'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>{subscriber.id ? 'Update' : 'Create'}</GlobalStyles.Button>}
    >
      <Form.Group style={{ margin: '2em' }}>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="text"
          value={subscription.email}
          onChange={(e) => setSubscription({ ...subscription, email: e.target.value })}
        />
        <Form.Label style={{ marginTop: '1em' }}>Subscription Type</Form.Label>
        <Form.Select
          value={subscription.code}
          onChange={(e) => setSubscription({ ...subscription, code: e.target.value })}
        >
          {Object.keys(subscriptions).map((type, id) => (
            <option value={type} key={id}>
              {subscriptions[type]}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </GenericModal>
  )
}

export const EmailNotificationList = () => {
  const {
    state: { csrf, companyId },
  } = globalState()
  const res: any = useFetch(`/api/company/${companyId}/email-subscriber/`, {})

  const subscribers = res.loaded && res.response.subscribers ? res.response.subscribers : []
  const subscriptions = res.loaded && res.response.subscriptions ? res.response.subscriptions : {}
  const total = res.loaded ? res.response.total : 0

  const [open, setOpen] = useState(false)
  const [subscriber, setSubscriber] = useState({})

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Email Notification List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button
            onClick={() => {
              setSubscriber({})
              setOpen(true)
            }}
          >
            Add Subscriber
          </GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Email</span>
              </th>
              <th>
                <span className="text">Subscription Type</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {subscribers.map((s: any, id: number) => (
              <TableRow key={id}>
                <TableCell input={s.email} />
                <TableCell
                  input={
                    <Form.Select
                      value={s.code}
                      onChange={(e) =>
                        usePut(
                          `/api/company/${companyId}/email-subscriber/${s.id}/`,
                          { code: e.target.value, email: s.email },
                          csrf
                        )
                      }
                      style={{ maxWidth: 300 }}
                    >
                      {Object.keys(subscriptions).map((type, id) => (
                        <option value={type} key={id}>
                          {subscriptions[type]}
                        </option>
                      ))}
                    </Form.Select>
                  }
                />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container">
                      <button
                        onClick={() => {
                          setSubscriber(s)
                          setOpen(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={() => useDelete(`/api/company/${companyId}/email-subscriber/${s.id}/`, csrf)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  }
                />
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
      <EditSubsciber {...{ open, setOpen, subscriptions, subscriber }} />
    </GlobalStyles.FullPageCard>
  )
}
