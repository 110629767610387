import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

// Components
import { useClick } from './helpers'

//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons'

import { PagesProps, PagerProps, ShowingNumberOfRowsProps } from './types'

const DatatablePager = styled.div`
  display: flex;
  align-items: center;
  .datatable__dropdown {
    position: relative;
    width: 60px;
    margin-right: 1em;
    button {
      display: inline-flex;
      width: 100%;
      height: 2.25rem;
      padding: 0.45rem 1rem;
      font-size: 1rem;
      font-weight: 500;
      border: 0 !important;
      color: #93a2dd;
      background: ${(props) => props.theme.colors.zircon};
      border-radius: 4px !important;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s ease !important;
      &:hover,
      &.active {
        color: ${(props) => props.theme.colors.white}!important;
        background: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
  .dropdown__menu {
    position: absolute;
    background: ${(props) => props.theme.colors.white};
    z-index: 10;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    border-radius: 4px;
    width: 100%;
    padding: 1em 0;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li {
      cursor: pointer;
      text-align: center;
      padding: 10px 15px;
      color: #595d6e;
      font-weight: 400;
      transition: all 0.3s ease !important;
      &.active,
      &:hover {
        background: ${(props) => props.theme.colors.gallery};
      }
    }
  }
`

export const ShowingNumberOfRows = ({ numRows, page, total }: ShowingNumberOfRowsProps) => (
  <span>
    Showing {(page - 1) * numRows + 1} - {Math.min(page * numRows, total)} of {total}
  </span>
)

export const Pager = ({ options, total, page, rows, setRows }: PagerProps) => {
  const [isOpen, setOpen] = useState(false)
  const node = useRef<HTMLDivElement>(null)
  useClick(node, setOpen)

  const clickRow = (row: number) => {
    setRows(row)
    setOpen(!isOpen)
  }
  return (
    <DatatablePager>
      <div className="datatable__dropdown" ref={node}>
        <button className={isOpen ? 'active' : ''} onClick={() => setOpen(!isOpen)} type="button">
          {rows}
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
        {isOpen ? (
          <div className="dropdown__menu">
            <ul>
              {options.map((row, id) => (
                <li key={id} className={rows === row ? 'active' : ''} onClick={() => clickRow(row)}>
                  {row}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
      {total && page && <ShowingNumberOfRows numRows={rows} page={page} total={total} />}
    </DatatablePager>
  )
}

const DatatablePages = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  li {
    padding: 0;
    margin-right: 5px;
    display: inline-block;
  }
  .datatable__page-change {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.25rem;
    min-width: 2.25rem;
    padding: 0.5rem;
    border-radius: 3px;
    position: relative;
    line-height: 1rem;
    font-weight: 500;
    color: #93a2dd;
    border: none;
    font-size: 1rem;
    text-align: center;
    display: inline-block;
    transition: all 0.3s ease !important;
    &.active,
    &:hover {
      background: ${(props) => props.theme.colors.dodgerBlue};
      color: ${(props) => props.theme.colors.white};
    }
  }
  .navigator {
    font-size: 0.8rem;
    background: ${(props) => props.theme.colors.zircon};
    border: none;
  }
  button:disabled,
  button:disabled:hover {
    cursor: default;
    color: #93a2dd;
    background: ${(props) => props.theme.colors.zircon};
    opacity: 0.3;
  }
`

const getList = (page: number, show: number, total: number) => {
  const toShow = show ? Math.min(show, total) : Math.min(5, total)
  const array = [...Array(total).keys()]
  const split = ~~((toShow - 1) / 2) // Number of pages on either side
  const left = Math.max(page - split - 1, 0) // Page indice on left in array
  const right = Math.min(page + split, total) // Page indice on right in array
  const finalLeft = Math.max(left + right - page - split, 0) // Adjust for slices close to the total
  const finalRight = Math.min(right + left - page + split, total) + 1 // Adjust for slices close to 1
  return array.slice(finalLeft, finalRight)
}

export const Pages = ({ page, setPage, total, show = 0 }: PagesProps) => {
  const [display, setDisplay] = useState(getList(page, show, total))
  const clickPage = (newPage: any) => {
    if (!(page === 1 && newPage === 1) && !(page === total && newPage === total)) {
      setPage(newPage)
    }
  }

  useEffect(() => {
    setDisplay(getList(page, show, total))
  }, [page, show, total])

  return (
    <DatatablePages>
      <li>
        <button
          onClick={() => clickPage(1)}
          title="First"
          className="datatable__page-change navigator"
          disabled={page === 1}
        >
          <FontAwesomeIcon icon={faChevronDoubleLeft} />
        </button>
      </li>
      <li>
        <button
          onClick={() => clickPage(page - 1)}
          title="Previous"
          className="datatable__page-change navigator"
          disabled={page === 1}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </li>
      {display.map((i) => (
        <li key={i}>
          <button
            onClick={() => clickPage(i + 1)}
            className={'datatable__page-change ' + (i + 1 === page ? 'active' : '')}
          >
            {i + 1}
          </button>
        </li>
      ))}
      <li>
        <button
          onClick={() => clickPage(page + 1)}
          title="Next"
          className="datatable__page-change navigator"
          disabled={page === total}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </li>
      <li>
        <button
          onClick={() => clickPage(total)}
          title="Last"
          className="datatable__page-change navigator"
          disabled={page === total}
        >
          <FontAwesomeIcon icon={faChevronDoubleRight} />
        </button>
      </li>
    </DatatablePages>
  )
}

export default Pager
