import React, { useState, useEffect, FunctionComponent, useRef } from 'react'
import { useFetch, usePut, useDelete, usePost, notify } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'
import { useResizeObserver } from 'usehooks-ts'

// Components
import CardLoading from '../../../../components/component-items/loading-popover'
import GlobalStyles from '../../../../components/component-items/styles'
import Directive from './wave-directive'
import { TableCell } from '../../../../components/component-items/datatable'
import styled from 'styled-components'
import {
  PointerSensor,
  removeAtIndex,
  insertAtIndex,
  arrayMove,
  SortableItem,
} from '../../../../components/component-items/sortable'
import { DndContext, DragOverlay, TouchSensor, useSensor, useSensors, useDroppable } from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { Tooltip } from './styles'

// Props
import { FiltersProps, SortProps } from './index'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPlayCircle } from '@fortawesome/pro-duotone-svg-icons'

const DataTable = styled(GlobalStyles.DataTable)`
  tr:last-child {
    border-bottom: none;
  }
`

type DroppableProps = {
  id: string
  items: any[]
}
const Droppable: FunctionComponent<DroppableProps> = ({ id, items }) => {
  const { setNodeRef } = useDroppable({ id })

  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
      <DataTable className="droppable" ref={setNodeRef} style={{ padding: 0, margin: 0, minHeight: 60 }}>
        {items.map((item: WaveDirectivesProps) => (
          <SortableItem key={item.id} id={item.id}>
            <Directive {...item} />
          </SortableItem>
        ))}
      </DataTable>
    </SortableContext>
  )
}

export type WaveDirectivesProps = {
  id: number
  profile: string
  name: string
  last_run: string
  order_counts: {
    received: number
    backordered: number
    total: number
  }
  company_names: any[]
  index: number
  settings: any
  sort: SortProps[]
  filters: FiltersProps
  dragOverlay?: any
  style?: any
}

export type ProfilesProps = {
  id: string
  index: number
  companies: any[]
  wave_directives: WaveDirectivesProps[]
}

export const WaveProfile = () => {
  const {
    state: { csrf },
  } = globalState()

  const url = '/api/inventory/wave-profile/'
  const { response, loaded, error }: any = useFetch(url, {})
  const [profiles, setProfiles] = useState<any[]>([])

  useEffect(() => {
    if (loaded) {
      const sortedProfiles = response.map((profile: any) => {
        return {
          ...profile,
          wave_directives: profile.wave_directives.sort((a: any, b: any) => a.index - b.index),
        }
      })
      sortedProfiles.sort((a: any, b: any) => a.index - b.index)
      setProfiles(sortedProfiles)
    }
  }, [loaded])

  const [activeId, setActiveId] = useState(null)
  const ref = useRef<HTMLDivElement>(null)
  const { width = 950, height = 0 } = useResizeObserver<HTMLDivElement>({ ref })
  const [moved, setMoved] = useState<boolean>(false)

  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor))

  const handleDragStart = ({ active }: { active: any }) => setActiveId(active.id)

  const handleDragCancel = () => setActiveId(null)

  const handleDragOver = ({ active, over }: { active: any; over: any }) => {
    const overId = over?.id

    if (!overId) {
      return
    }

    const activeContainer = active.data.current.sortable.containerId
    const overContainer = over.data.current?.sortable.containerId || over.id

    if (activeContainer !== overContainer) {
      setProfiles((profiles: any) => {
        const activeIndex = active.data.current.sortable.index
        const groupIndex = profiles.findIndex(({ id }: any) => over.id === id)
        const overIndex =
          groupIndex !== -1 ? profiles[groupIndex]['wave_directives'].length + 1 : over.data.current.sortable.index

        return moveBetweenContainers(profiles, activeContainer, activeIndex, overContainer, overIndex, active.id)
      })
      setMoved(true)
    }
  }

  const handleDragEnd = ({ active, over }: { active: any; over: any }) => {
    // This condition is necessary to avoid calling updates on the backend multiple times since
    // handleDragOver will move between containers and handleDragEnd won't be called if
    // active.id equal to over.id or over is null
    if ((!over || active.id === over.id) && moved) {
      setProfiles((profiles) => {
        return reIndexList(profiles)
      })
      setMoved(false)
    }
    if (!over) {
      setActiveId(null)
      return
    }
    if (active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId
      const overContainer = over.data.current?.sortable.containerId || over.id
      const activeIndex = active.data.current.sortable.index
      const groupIndex = profiles.findIndex(({ id }) => over.id === id)
      const overIndex =
        groupIndex !== -1 ? profiles[groupIndex]['wave_directives'].length + 1 : over.data.current.sortable.index
      setProfiles((profiles) => {
        const newItems = [...profiles]
        const activeContainerIndex = newItems.findIndex(({ id }) => id === activeContainer)
        const activeItem = newItems[activeContainerIndex]
        if (activeContainer === overContainer) {
          newItems.splice(activeContainerIndex, 1, {
            ...activeItem,
            wave_directives: arrayMove(activeItem['wave_directives'], activeIndex, overIndex),
          })
        }
        return reIndexList(newItems)
      })
    }
    setActiveId(null)
  }

  const moveBetweenContainers = (
    items: any[],
    activeContainer: any,
    activeIndex: number,
    overContainer: any,
    overIndex: number,
    itemId: string
  ) => {
    const newItems = [...items]
    const activeContainerIndex = newItems.findIndex(({ id }) => id === activeContainer)
    const overContainerIndex = newItems.findIndex(({ id }) => id === overContainer)
    const item = newItems[activeContainerIndex]['wave_directives'].find(({ id }: { id: string }) => id === itemId)
    item['settings']['companies'] = newItems[overContainerIndex]['companies']
    item['order_count'] = '(Reload)'
    newItems.splice(activeContainerIndex, 1, {
      ...newItems[activeContainerIndex],
      wave_directives: removeAtIndex(newItems[activeContainerIndex]['wave_directives'], activeIndex),
    })
    newItems.splice(overContainerIndex, 1, {
      ...newItems[overContainerIndex],
      wave_directives: insertAtIndex(newItems[overContainerIndex]['wave_directives'], overIndex, item),
    })
    return newItems
  }

  const reIndexList = (items: any[]) => {
    const newItems = items.map((profile) => {
      return {
        ...profile,
        wave_directives: profile.wave_directives.map((wave_directive: any, index: number) => {
          return { ...wave_directive, index: index + 1 }
        }),
      }
    })
    usePut(`/api/inventory/wave-profile/index_directives/`, { profiles: newItems }, csrf, false, true, false, false)
    notify({ type: 'success', message: 'Successfully rearranged Wave Profile order' })
    return newItems
  }

  const runWave = async (id: string) => {
    if (confirm('Are you sure you want to run this group of directives?')) {
      await usePost(`/api/inventory/wave-profile/${id}/run_directives/`, {}, csrf, false)
      notify({ type: 'success', message: 'Batched Successfully!' })
    }
  }

  return (
    <>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Wave Profiles</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ minHeight: 100, padding: '1em 1em 2em' }}>
          <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            <GlobalStyles.DataTable>
              <thead>
                <tr style={{ background: '#f6f9ff' }}>
                  <th>
                    <span className="text center">Companies</span>
                  </th>
                  <th style={{ width: '12%' }}>
                    <span className="text center">Run Order</span>
                  </th>
                  <th style={{ width: '12%' }}>
                    <span className="text center">Name</span>
                  </th>
                  <th style={{ width: '12%' }}>
                    <span className="text center">Last Requested</span>
                  </th>
                  <th style={{ width: '12%' }}>
                    <span className="text center">Received</span>
                  </th>
                  <th style={{ width: '12%' }}>
                    <span className="text center">Backordered</span>
                  </th>
                  <th style={{ width: '12%' }}>
                    <span className="text center">Directive</span>
                  </th>
                  <th>
                    <span className="text center">Wave Action</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {profiles?.map(({ id: profileID, companies, wave_directives }) => (
                  <GlobalStyles.TableRow key={profileID}>
                    <TableCell center style={{ background: '#f6f9ff', borderTop: '1px solid #ced4da' }}>
                      <strong>
                        {companies.map(({ label }: { label: string }, key: React.Key) => (
                          <div key={key}>{label}</div>
                        ))}
                      </strong>
                    </TableCell>
                    <TableCell
                      colspan={6}
                      style={{ borderTop: '1px solid #ced4da', width: '72%', padding: 0 }}
                      ref={ref}
                    >
                      <Droppable id={profileID} items={wave_directives} />
                    </TableCell>
                    <TableCell center style={{ background: '#f6f9ff', borderTop: '1px solid #ced4da' }}>
                      <div className="dropdown__container" data-no-dnd="true">
                        <Tooltip data-title="Run Wave">
                          <button onClick={() => runWave(profileID)}>
                            <FontAwesomeIcon icon={faPlayCircle} />
                          </button>
                        </Tooltip>
                        <Tooltip data-title="Delete Wave">
                          <button onClick={() => useDelete(`/api/inventory/wave-profile/${profileID}/`, csrf)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </GlobalStyles.TableRow>
                ))}
              </tbody>
            </GlobalStyles.DataTable>
            <DragOverlay>
              {activeId
                ? profiles.map(({ wave_directives }) =>
                    wave_directives.map((directive: any) =>
                      directive.id === activeId ? (
                        <Directive key={directive.id} dragOverlay {...directive} style={{ width: width / 6 }} />
                      ) : null
                    )
                  )
                : null}
            </DragOverlay>
          </DndContext>
        </div>
        {!loaded ? <CardLoading error={error} /> : null}
      </GlobalStyles.FullPageCard>
    </>
  )
}
