import React, { FunctionComponent } from 'react'

// Components
import { StatusBarStyled } from '../../../components/component-items/status-bar'

// Fontawesome

type WebhookHeaderProps = {
  webhook: {
    name: string
    url: string
    description: string
  }
}
export const WebhookHeader: FunctionComponent<WebhookHeaderProps> = ({ webhook }) => {
  const { name, url, description } = webhook || {}

  return (
    <StatusBarStyled>
      <div className="header-item center" style={{ textAlign: 'center', width: '100%' }}>
        <p style={{ marginBottom: '0' }}>
          {name}
          {description && (
            <>
              <br />
              <span style={{ fontSize: '12px', color: '#999' }}>{description}</span>
            </>
          )}
        </p>
      </div>
      <div className="border-line"></div>
      <div className="header-item center" style={{ textAlign: 'center', width: '100%', alignItems: 'center' }}>
        {url}
      </div>
    </StatusBarStyled>
  )
}
