import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GlobalStyles from './styles'

import { TabMenuProps, FullTabMenuProps, FullTabMenuCardProps } from './types'

const CardTitle = styled.div`
  display: flex;
  h3 {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 4em 0 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    color: #48465b;
    border-bottom: 1px solid #ffffff;
    &:hover {
      border-bottom: 1px solid #abb8ff;
    }
    &.active {
      border-bottom: 1px solid #5d78ff;
    }
    small {
      font-weight: 300;
      padding-left: 1rem;
      font-size: 1rem;
      color: #74788d;
    }
  }
`
export const TabMenu = ({ tabOptions, activeTab, setActiveTab }: TabMenuProps) => (
  <CardTitle>
    {tabOptions.map((tab) => (
      <h3 className={activeTab === tab ? 'active' : ''} onClick={() => setActiveTab(tab)} key={tab}>
        {tab}
      </h3>
    ))}
  </CardTitle>
)

const TabBackground = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* Required to remove boxshadow of sibling attributes */
  position: relative;
  z-index: 1;
`

const TabStyled = styled.div<{ count: number; active: boolean }>`
  cursor: pointer;
  display: flex;
  height: 5em;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: center;
  flex: 0 0 calc(${(props) => 100 / props.count}% - 0.25rem);
  width: calc(${(props) => 100 / props.count}% - 0.25rem);
  background: ${(props) => (props.active ? props.theme.colors.white : props.theme.colors.gallery)};
  transition: background-color 0.1s ease-in;
  &:hover {
    background: ${(props) => props.theme.colors.white};
  }
  &.active {
    background: ${(props) => props.theme.colors.white};
  }
  svg {
    height: 1.5em;
    width: 1.5em;
    margin-left: 2em;
  }
  h3 {
    display: flex;
    align-items: center;
    margin: 0 4em 0 1em;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.riverBed};
    small {
      font-weight: 300;
      padding-left: 1rem;
      font-size: 1rem;
      color: ${(props) => props.theme.colors.raven};
    }
  }
`
export const FullTabMenu = ({ tabMapping, activeTab, setActiveTab }: FullTabMenuProps) => (
  <TabBackground>
    {tabMapping.map((tab) => (
      <TabStyled
        count={tabMapping.length}
        active={activeTab === tab.label}
        onClick={() => setActiveTab(tab.label)}
        key={tab.label}
      >
        <FontAwesomeIcon icon={tab.icon} />
        <h3>{tab.label}</h3>
      </TabStyled>
    ))}
  </TabBackground>
)

export const FullTabMenuCard = ({ tabMapping, activeTab, setActiveTab, children }: FullTabMenuCardProps) => (
  <>
    <FullTabMenu {...{ tabMapping, activeTab, setActiveTab }} />
    <GlobalStyles.FullPageCard style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      {children}
    </GlobalStyles.FullPageCard>
  </>
)
