import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFetch } from '../../components/component-items/helpers'
import { useTheme } from 'styled-components'
// @ts-ignore
import ReactTimeAgo from 'react-time-ago'

// Components
import GlobalStyles from '../../components/component-items/styles'
import { StatusBoxStyled } from '../../components/component-items/status-box'
import CardLoading from '../../components/component-items/loading-popover'
import { TableRow } from '../orders/styles'
import { TableCell } from '../../components/component-items/datatable'
import Pager, { Pages } from '../../components/component-items/pager'
import { SearchBox } from '../../components/component-items/search'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/pro-duotone-svg-icons'

const RunDownRate = () => {
  const theme: any = useTheme()

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')

  // Pagination
  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 50, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])

  const defaultQueryParamsString = {
    range: rows,
    page: page - 1,
    order_by: ['shipped'],
    sort: ['desc'],
    q: search,
  }
  const url =
    '/api/product/variant/report_run_down_rate/' +
    `?${Object.entries(defaultQueryParamsString)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`

  const { response: resp, loaded, error }: any = useFetch(url, {})
  const variants = loaded ? resp.variants : []
  const last_updated = loaded ? resp.last_cache : new Date()
  const total = loaded ? resp.total : 0
  const totalPages = loaded && total ? Math.ceil(total / rows) : 1

  useEffect(() => {
    if (loaded && !error) {
      setLoading(!loaded)
    }
  }, [loaded])
  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Inventory Report - 30 Day Run Down Rate
            <small className="ps-3">
              Last Updated: <ReactTimeAgo date={Date.parse(last_updated)} locale="en-US" />
            </small>
            <br />
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Product</span>
              </th>
              <th>
                <span className="text">SKU</span>
              </th>
              <th>
                <span className="text center">Units</span>
              </th>
              <th>
                <span className="text center">Units / Day</span>
              </th>
              <th>
                <span className="text center">Change</span>
              </th>
              <th>
                <span className="text center">Days Left</span>
              </th>
              <th>
                <span className="text center">Available</span>
              </th>
              <th>
                <span className="text center">Inbound</span>
              </th>
              <th>
                <span className="text center">Total</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {loaded
              ? variants.map((variant: any, k_id: number) => (
                  <TableRow key={k_id}>
                    <TableCell input={<Link to={`/product/${variant.productId}/`}>{variant.productName}</Link>} />
                    <TableCell input={<Link to={`/product/variant/${variant.id}/`}>{variant.sku}</Link>} />
                    <TableCell center input={<span>{variant.shipped}</span>} />
                    <TableCell center input={Math.round(variant.burnRate * 100) / 100} />
                    <TableCell
                      center
                      input={
                        variant.shippedPrevious == 0 ? (
                          <span style={{ color: theme.colors.black }}>N/A</span>
                        ) : variant.shipped >= variant.shippedPrevious ? (
                          <span style={{ color: theme.colors.emeraldGreen }}>
                            <FontAwesomeIcon style={{ marginRight: '.5em' }} icon={faArrowUp} />
                            {variant.change.toFixed(0)}%
                          </span>
                        ) : (
                          <span style={{ color: theme.colors.crimson }}>
                            <FontAwesomeIcon style={{ marginRight: '.5em' }} icon={faArrowDown} />
                            {variant.change.toFixed(0)}%
                          </span>
                        )
                      }
                    />
                    <TableCell
                      center
                      input={
                        <StatusBoxStyled
                          className={
                            Math.round(variant.daysLeft * 100) / 100 < 0
                              ? 'red'
                              : Math.round(variant.daysLeft * 100) / 100 < 10
                                ? 'yellow'
                                : 'green'
                          }
                        >
                          {Math.round(variant.daysLeft * 100) / 100}
                        </StatusBoxStyled>
                      }
                    />
                    <TableCell center input={variant.available} />
                    <TableCell center input={variant.inbound} />
                    <TableCell center input={variant.total} />
                  </TableRow>
                ))
              : null}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} show={5} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading error={error} text={'Fetching report... This may take a moment.'} /> : null}
    </GlobalStyles.FullPageCard>
  )
}

export const RunDownRatePage = () => {
  return <RunDownRate />
}
