import React from 'react'
import { Link } from 'react-router-dom'

// Components
import ProgressBar from 'react-bootstrap/ProgressBar'
import CardLoading from '../../components/component-items/loading-popover'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { useFetch } from '../../components/component-items/helpers'
import { CarouselItemStyled, AsnStyled } from './styles'

// Enums
import { ShipNoticeStatus } from '../../enums'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faBoxOpen } from '@fortawesome/pro-regular-svg-icons'

import { CarouselItemProps } from './types'

const CarouselItem = ({
  title,
  desc,
  arriving,
  inOrders,
  dateArrival,
  previewUrl,
  detailUrl,
  progress,
}: CarouselItemProps) => {
  return (
    <CarouselItemStyled>
      <div className="body">
        <div className="widget__product">
          <div className="thumb">
            <Link to={detailUrl}>
              <i>
                <FontAwesomeIcon icon={faBoxOpen} />
              </i>
            </Link>
          </div>
          <div className="widget__content">
            <Link to={detailUrl}>
              <h3>{title}</h3>
            </Link>
            <div className="desc">{desc}</div>
          </div>
        </div>
        <div className="widget__data">
          <div className="widget-info">
            <div className="info">{arriving}</div>
            <div className="desc">Arriving</div>
          </div>
          <div className="widget-info">
            <div className="title">{inOrders}</div>
            <div className="desc">In Orders</div>
          </div>
        </div>
      </div>
      <div className="footer">
        {progress ? (
          <div className="progress_bar">
            <div className="label">Progress</div>
            <div className="value">{progress}%</div>
            <ProgressBar striped animated variant="success" now={progress} key={1} />
          </div>
        ) : (
          <>
            <div className="footer__info">
              <div className="label">{dateArrival}</div>
              <div className="desc">Date of Expected Arrival</div>
            </div>
            <div className="footer__toolbar">
              <Link to={previewUrl}>Preview</Link>
              <Link to={detailUrl}>Details</Link>
            </div>
          </>
        )}
      </div>
    </CarouselItemStyled>
  )
}

export const AsnCard = ({ url }: { url: string }) => {
  var resp: any = useFetch(url, {})
  var asns = resp.loaded ? resp.response.asns : null
  var total = resp.loaded ? resp.response.total : 0

  return (
    <AsnStyled>
      <CarouselProvider
        naturalSlideWidth={300}
        naturalSlideHeight={125}
        totalSlides={asns ? asns.length : 0}
        infinite
        isPlaying
      >
        <div className="head">
          <div className="label">ASNs - {total}</div>
          <div className="nav">
            <ButtonBack>
              <i>
                <FontAwesomeIcon icon={faChevronLeft} />
              </i>
            </ButtonBack>
            <ButtonNext>
              <i>
                <FontAwesomeIcon icon={faChevronRight} />
              </i>
            </ButtonNext>
          </div>
        </div>
        <Slider>
          {asns
            ? asns.map((a: any, id: number) => (
                <Slide index={id} key={id}>
                  <CarouselItem
                    title={a.company}
                    desc={'Sent By: ' + a.sender}
                    arriving={a.sent}
                    inOrders={a.inOrder}
                    dateArrival={a.deliveryDate}
                    previewUrl={'/asn/'}
                    detailUrl={`/asn/${a.id}/`}
                    progress={a.status === ShipNoticeStatus.Arrived ? a.progress : null}
                  />
                </Slide>
              ))
            : null}
        </Slider>
        {resp.loaded ? (
          <div className="no-issues">
            <div role="img" aria-label="celebrate">
              📦
            </div>
            No Incoming ASNs!
          </div>
        ) : null}
      </CarouselProvider>
      {!resp.loaded ? <CardLoading text={resp.placeholder} error={resp.error} /> : null}
    </AsnStyled>
  )
}

export default AsnCard
