import React from 'react'
import { Link } from 'react-router-dom'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { useFetch } from '../../components/component-items/helpers'
import { CarouselItemStyled, ProjectStyled } from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faBoxOpen } from '@fortawesome/pro-regular-svg-icons'

import { CarouselItemProps } from './types'

const CarouselItem = ({
  title,
  dateCreated,
  previewUrl,
  detailUrl,
  bomVariant,
  bomVariantId,
  explode,
  quantity,
}: CarouselItemProps) => {
  return (
    <CarouselItemStyled>
      <div className="body">
        <div className="widget__product">
          <div className="thumb">
            <Link to={detailUrl}>
              <i>
                <FontAwesomeIcon icon={faBoxOpen} />
              </i>
            </Link>
          </div>
          <div className="widget__content">
            <Link to={detailUrl}>
              <h3>{title}</h3>
            </Link>
            <div className="desc">
              Product Variant:{' '}
              <strong>
                <Link to={`/product/variant/${bomVariantId}/`}>{bomVariant}</Link>
              </strong>
            </div>
            <div>
              {explode ? 'Exploding' : 'Building'}: {quantity}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer__info">
          <div className="label">Created {dateCreated}</div>
        </div>
        <div className="footer__toolbar">
          <Link to={previewUrl}>Preview</Link>
          <Link to={detailUrl}>Details</Link>
        </div>
      </div>
    </CarouselItemStyled>
  )
}

export const SlidingInfoCard = ({ url }: { url: string }) => {
  var resp: any = useFetch(url, {})
  var projects = resp.loaded ? resp?.response?.projects : []
  var total = projects ? projects.length : 0

  return (
    <ProjectStyled>
      <CarouselProvider
        naturalSlideWidth={300}
        naturalSlideHeight={125}
        totalSlides={total}
        infinite={true}
        isPlaying={true}
      >
        <div className="head">
          <div className="label">Projects - {total}</div>
          <div className="nav">
            <ButtonBack>
              <i>
                <FontAwesomeIcon icon={faChevronLeft} />
              </i>
            </ButtonBack>
            <ButtonNext>
              <i>
                <FontAwesomeIcon icon={faChevronRight} />
              </i>
            </ButtonNext>
          </div>
        </div>
        <Slider>
          {projects.map((p: any, id: number) => (
            <Slide index={id} key={id}>
              <CarouselItem
                title={p.company}
                explode={p.explode}
                bomVariant={p.bomVariant}
                bomVariantId={p.bomVariantId}
                quantity={p.qty}
                dateCreated={p.created}
                previewUrl={'/project/'}
                detailUrl={`/project/${p.id}/`}
              />
            </Slide>
          ))}
        </Slider>
        {resp.loaded ? (
          <div className="no-issues">
            <div role="img" aria-label="celebrate">
              🛠
            </div>
            No Active Projects!
          </div>
        ) : null}
      </CarouselProvider>
      {!resp.loaded ? <CardLoading text={resp.placeholder} error={resp.error} /> : null}
    </ProjectStyled>
  )
}

export default SlidingInfoCard
