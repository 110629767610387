import React from 'react'

// Components
import GraphCard from './graph-card'
import { useFetch } from '../../components/component-items/helpers'

export const UnShippedOrders = ({ url }: { url: string }) => {
  var resp: any = useFetch(url, {}, 30)
  var total = resp.loaded ? resp.response.total : 0
  var defaultData = [
    { x: 0, y: 0 },
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
    { x: 5, y: 0 },
    { x: 6, y: 0 },
    { x: 7, y: 0 },
    { x: 8, y: 0 },
    { x: 9, y: 0 },
    { x: 10, y: 0 },
    { x: 11, y: 0 },
    { x: 12, y: 0 },
  ]
  var graphData = resp.loaded ? resp.response.asns.map((a: any) => ({ x: a.created_Hour, y: a.count })) : defaultData

  var data = [
    {
      id: 'ASNs',
      data: resp.loaded && resp.response.asns.length > 1 ? graphData : defaultData,
    },
  ]

  return (
    <GraphCard
      title={'ASNs'}
      desc={0 + ' Daily Avg'}
      total={total}
      color={'#63cc9E'}
      loaded={resp.loaded}
      data={data}
      pointName={''}
      yLabel={'ASNs '}
      error={resp.error}
    />
  )
}

export default UnShippedOrders
