import styled from 'styled-components'

export const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
    div[id^='react-select-3-option'] {
      &:hover {
        background-color: #deebff;
      }
      :not(:hover) {
        transition: background-color 0.1s linear;
        -webkit-transition: background-color 0.1s linear;
      }
    }
  }
  div[id^='react-select-3']:not(:hover) {
    background-color: transparent;
  }
  div[id^='react-select-'][id$='-group-'][id*='-heading']:not(:hover) {
    background-color: transparent;
  }
  div[id^='react-select-3-group']:hover + div > div {
    background-color: #deebff !important;
  }
`

export const HeaderHover = styled.div`
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
`
