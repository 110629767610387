import styled from 'styled-components'
import { useTheme } from 'styled-components'
import React, { useContext } from 'react'
import { store } from '../../store'
import { globalState } from '../../store'

// Components
import CompanyProgress from './company-progress'
import AgedOrders from './aged-orders'
import OrderGeography from './order-geography'
import OrderIssues from './order-issues'
import OrderListSmall from './order-list-small'
import OrdersByMerchant from './order-stats'
import GraphCardUnshipped from './graph-card-unshipped'
import GraphCardAsn from './graph-card-asn'
import Notifications from './notifications'
import ShippedOrders from './orders-shipped'
import ReturnsReceived from './returns'
import GeneralStatisticsMerchant from './general-stats-merchant'
import GeneralStatistics from './general-stats-warehouse'
import AsnCard from './asn'
import SlidingInfoCard from './projects'
import InventoryVelocity from './inventory-velocity'

// Bootstrap
import { Col, Row } from 'react-bootstrap'

const Dashboard = styled.div`
  // width: 100%;
  // margin: 0;
  .dashboard__card {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    background-color: ${(props) => props.theme.colors.white};
    margin-bottom: 20px;
    border-radius: 4px;
  }
  .card__height-fluid-half {
    height: calc(50% - 20px);
  }
  .card__height-fluid {
    height: calc(100% - 20px);
  }
`

export const WarehouseDashboard = () => {
  const theme = useTheme()
  const {
    state: { warehouseActive },
  } = globalState()
  return (
    <Dashboard>
      <Row className="row hide-in-percy">
        <Col lg={{ span: 12, order: 1 }} xl={{ span: 4, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <OrdersByMerchant />
          </div>
        </Col>
        <Col lg={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <GraphCardUnshipped url={`/api/warehouse/${warehouseActive.id}/dashboard/orders_unshipped/`} />
          <div className="dashboard__card card__height-fluid-half">
            <ShippedOrders
              url={`/api/warehouse/${warehouseActive.id}/dashboard/graph_orders_shipped/`}
              color={theme.colors.cornflowerBlue}
            />
          </div>
        </Col>
        <Col lg={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <GraphCardAsn url={`/api/warehouse/${warehouseActive.id}/dashboard/graph_asn/`} />
          <div className="dashboard__card card__height-fluid-half">
            <ReturnsReceived url={`/api/warehouse/${warehouseActive.id}/dashboard/graph_returns/`} />
          </div>
        </Col>
        <Col lg={{ span: 12, order: 1 }} xl={{ span: 6, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <CompanyProgress />
          </div>
        </Col>
        <Col lg={{ span: 12, order: 1 }} xl={{ span: 6, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <AgedOrders />
          </div>
        </Col>
        <Col lg={{ span: 12, order: 1 }} xl={{ span: 8, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <GeneralStatistics
              url={`/api/warehouse/${warehouseActive.id}/dashboard/general_stats/`}
              color={[theme.colors.tealishBlue, theme.colors.greenishCyan]}
            />
          </div>
        </Col>
        <Col lg={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <div className="dashboard__card">
            <OrderIssues />
          </div>
        </Col>
        <Col lg={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <Notifications />
          </div>
        </Col>
        <Col lg={{ span: 12, order: 1 }} xl={{ span: 8, order: 1 }}>
          <div className="dashboard__card card__height-fluid-half">
            <AsnCard url={`/api/warehouse/${warehouseActive.id}/dashboard/asn/`} />
          </div>
          <div className="dashboard__card card__height-fluid-half">
            <SlidingInfoCard url={`/api/warehouse/${warehouseActive.id}/dashboard/project`} />
          </div>
        </Col>
      </Row>
    </Dashboard>
  )
}

export const MerchantDashboard = () => {
  const theme = useTheme()
  const globalState = useContext(store)
  const {
    state: { companyId },
  } = globalState
  return (
    <Dashboard>
      <Row className="hide-in-percy">
        <Col lg={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <InventoryVelocity />
          </div>
        </Col>
        <Col lg={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <GraphCardUnshipped url={`/api/company/${companyId}/dashboard/orders_unshipped/`} />
          <div className="dashboard__card card__height-fluid-half">
            <ShippedOrders
              url={`/api/company/${companyId}/dashboard/graph_orders_shipped/`}
              color={theme.colors.tealishBlue}
            />
          </div>
        </Col>
        <Col lg={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <GraphCardAsn url={`/api/company/${companyId}/dashboard/graph_asn/`} />
          <div className="dashboard__card card__height-fluid-half">
            <ReturnsReceived url={`/api/company/${companyId}/dashboard/graph_returns/`} />
          </div>
        </Col>
        <Col lg={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <OrderListSmall />
          </div>
        </Col>
        <Col lg={{ span: 12, order: 1 }} xl={{ span: 8, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <OrderGeography />
          </div>
        </Col>
        <Col lg={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <SlidingInfoCard url={`/api/company/${companyId}/dashboard/project/`} />
          </div>
        </Col>
        <Col lg={{ span: 12, order: 1 }} xl={{ span: 8, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <AsnCard url={`/api/company/${companyId}/dashboard/asn/`} />
          </div>
        </Col>
        <Col lg={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <Notifications />
          </div>
        </Col>
        <Col lg={{ span: 12, order: 1 }} xl={{ span: 8, order: 1 }}>
          <div className="dashboard__card card__height-fluid">
            <GeneralStatisticsMerchant
              url={`/api/company/${companyId}/dashboard/general_stats/`}
              color={[theme.colors.tealishBlue, theme.colors.greenishCyan]}
            />
          </div>
        </Col>
      </Row>
    </Dashboard>
  )
}
