import React, { Link } from 'react-router-dom'

// Components
import GlobalStyles from '../../components/component-items/styles'
import { PostPage, Background, SignIn, Information, Logo } from './styles'

export const NotFoundPage = () => {
  return (
    <PostPage>
      <Background>
        <SignIn>
          <Information>
            <Logo src={'/api/static/assets/login/logo.png'} />
            <div style={{ textAlign: 'center', fontSize: '6em' }}>😢</div>
            <h1>404</h1>
            <p>
              We&#39;re sorry, this page could not be found - if you were expecting to find a page here, please contact
              support at <a href="mailto:support@shippingtree.co">support@shippingtree.co</a>.
            </p>
            <Link to="/">
              <GlobalStyles.Button style={{ minWidth: 125 }}>Home</GlobalStyles.Button>
            </Link>
          </Information>
        </SignIn>
      </Background>
    </PostPage>
  )
}
