import React, { useState, useEffect, FunctionComponent } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { formatBytes } from './utils'

import { DragNDropProps, DragNDropManyProps, DragNDropThumbnailProps } from './types'

const DragNDropStyled = styled.section`
  margin: 1em 0;
  .dropzone {
    border: dashed 2px grey;
    padding: 1em;
    justify-content: center;
    display: flex;
  }
  h4 {
    margin-top: 1em;
  }
`

const mb = 1048576 // 1MB
const maxSize = 5 * mb

type AcceptObject = Record<string, string[]>

const createAcceptObject = (fileTypes: string[] | string | undefined | null): AcceptObject => {
  const accept: AcceptObject = {
    '': ['.png', '.jpg', '.jpeg', '.heic', '.heif', '.pdf'],
  }

  // Check if fileTypes is not null or undefined
  if (fileTypes !== null && fileTypes !== undefined) {
    // Ensure fileTypes is an array
    const typesArray = Array.isArray(fileTypes) ? fileTypes : [fileTypes]

    typesArray.forEach((type) => {
      if (type.endsWith('/*')) {
        // Handle wildcard entry like "image/*"
        const baseType = type.slice(0, -2) // Remove "/*" to get the base mime type

        Object.keys(accept).forEach((key) => {
          if (key.startsWith(baseType)) {
            accept[key].push(`.${key.split('/')[1]}`)
          }
        })
      } else {
        // Handle specific mime type
        accept[type] = [`.${type.split('/')[1]}`]
      }
    })
  }

  return accept
}

export const DragNDrop: FunctionComponent<DragNDropProps> = ({ file, setFile, fileTypes, multiple, size }) => {
  const maximumSize = size ? size * mb : maxSize
  const { acceptedFiles, isDragReject, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => setFile(multiple ? { ...file, files } : files[0]),
    accept: createAcceptObject(fileTypes),
    maxSize: maximumSize,
  })

  const acceptedFilesItems = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <DragNDropStyled>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div style={{ textAlign: 'center' }}>
          <p style={{ margin: '1em' }}>Drag 'n' drop files here, or click to select files</p>
          {fileTypes ? <em>Only {fileTypes} file types will be accepted</em> : null}
          <p style={{ margin: '1em' }}>File sizes must be less than {size || 5}MB</p>
        </div>
      </div>
      <aside>
        {file && acceptedFilesItems.length ? (
          <>
            <h4>Accepted files</h4>
            <ul>{acceptedFilesItems}</ul>
          </>
        ) : null}
        {isDragReject ? <h4>File Rejected</h4> : null}
      </aside>
    </DragNDropStyled>
  )
}

const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`

const Thumbnail = styled.div`
  width: 100px;
  margin-right: 2em;
`

const ThumbInner = styled.div`
  position: relative;
  display: flex;
  font-size: 2em;
  width: 100px;
  height: 100px;
  padding: 4px;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  &:hover .trash-icon {
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
`

const TrashIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgb(80 80 80 / 60%);
  transition: opacity 0.2s ease-in;
`

const img: React.CSSProperties = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

export const Thumbnails: FunctionComponent<DragNDropManyProps> = ({ files, setFiles }) => {
  const thumbs = files.map((file: any) => {
    const removeFile = () => {
      setFiles((prevFiles: File[]) => prevFiles.filter((f) => f !== file))
    }
    return (
      <Thumbnail key={file.name}>
        {file.is_image ? (
          <ThumbInner onClick={removeFile}>
            <img src={file.preview} style={img} />
            <TrashIcon className="trash-icon" onClick={removeFile}>
              <FontAwesomeIcon icon={faTrash} color="black" />
            </TrashIcon>
          </ThumbInner>
        ) : (
          <ThumbInner style={{ alignItems: 'center' }} onClick={removeFile}>
            <FontAwesomeIcon icon={faFile} />
            <TrashIcon className="trash-icon" onClick={removeFile}>
              <FontAwesomeIcon icon={faTrash} color="black" />
            </TrashIcon>
          </ThumbInner>
        )}
        <div>
          {file.path} - <strong style={{ fontWeight: 600 }}>{formatBytes(file.size)}</strong>
        </div>
      </Thumbnail>
    )
  })

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  return <ThumbsContainer>{thumbs}</ThumbsContainer>
}

export const DragNDropThumbnail: FunctionComponent<DragNDropThumbnailProps> = ({
  file,
  setFile,
  fileTypes,
  image_only,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: createAcceptObject(image_only ? ['image/*'] : fileTypes),
    onDrop: (acceptedFiles) => {
      let new_file = acceptedFiles[0]
      setFile(
        Object.assign(new_file, {
          preview: URL.createObjectURL(new_file),
          is_image: ['jpg', 'jpeg', 'bmp', 'gif', 'png'].includes((new_file.name.split('.').pop() || '').toLowerCase()),
        })
      )
    },
  })

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(file.preview)
    }
  }, [file])

  return (
    <DragNDropStyled>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p style={{ margin: '1em' }}>Drag 'n' drop a file here, or click to select a file</p>
      </div>
      <ThumbsContainer>
        {file ? (
          <Thumbnail key={file.name}>
            {file.is_image ? (
              <ThumbInner>
                <img src={file.preview} style={img} />
              </ThumbInner>
            ) : (
              <ThumbInner style={{ alignItems: 'center' }}>
                <FontAwesomeIcon icon={faFile} />
              </ThumbInner>
            )}
            <div>{file.path}</div>
          </Thumbnail>
        ) : null}
      </ThumbsContainer>
    </DragNDropStyled>
  )
}

export const DragNDropMany: FunctionComponent<DragNDropManyProps> = ({ files, setFiles, fileTypes, image_only }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: createAcceptObject(image_only ? ['image/*'] : fileTypes),
    onDrop: (acceptedFiles) => {
      setFiles((files: File[]) => [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            is_image: ['jpg', 'jpeg', 'bmp', 'gif', 'png'].includes((file.name.split('.').pop() || '').toLowerCase()),
          })
        ),
      ])
    },
  })

  return (
    <DragNDropStyled>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p style={{ margin: '1em' }}>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <Thumbnails {...{ files, setFiles }} />
    </DragNDropStyled>
  )
}
