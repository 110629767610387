import React, { memo } from 'react'
import { geoCentroid } from 'd3-geo'
import { ComposableMap, Geographies, Geography, Marker, Annotation, Sphere, Graticule } from 'react-simple-maps'
import { scaleQuantize } from 'd3-scale'
import allStates from './allstates.json'

import { USCountyMapProps, USStateMapProps, WorldMapProps } from './types'

const geoCountyMapUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json'

function colorScale(color: number, range: any) {
  const scale = scaleQuantize()
    .domain(range)
    // @ts-ignore
    .range([
      'rgb(198,219,239)',
      'rgb(158,202,225)',
      'rgb(107,174,214)',
      'rgb(66,146,198)',
      'rgb(33,113,181)',
      'rgb(8,81,156)',
      'rgb(8,48,107)',
    ])
  return String(scale(color))
}

export const USCountyMap = memo(({ setTooltipContent, data, range }: USCountyMapProps) => {
  return (
    <>
      <ComposableMap projection="geoAlbersUsa">
        <Geographies geography={geoCountyMapUrl}>
          {({ geographies }: { geographies: any }) =>
            geographies.map((geo: any) => {
              const cur = data.find(
                (s: { fips: string; state: string; county: string; count: number }) => s.fips === geo.id
              )
              return (
                <Geography
                  data-tooltip-content=""
                  data-tooltip-id="map"
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setTooltipContent(cur ? `${cur.county}, ${cur.state} — ${cur.count}` : '')
                  }}
                  onMouseLeave={() => {
                    setTooltipContent('')
                  }}
                  fill={cur ? colorScale(cur.count, [0, range]) : 'rgb(247,251,255)'}
                  style={{
                    hover: {
                      fill: '#F53',
                      outline: 'none',
                    },
                    pressed: {
                      fill: '#E42',
                      outline: 'none',
                    },
                  }}
                />
              )
            })
          }
        </Geographies>
      </ComposableMap>
    </>
  )
})
USCountyMap.displayName = 'USCountyMap'

const geoUSStateUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json'

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
}

export const USStateMap = memo(
  ({ setTooltipContent, data, range }: { setTooltipContent: any; data: any; range: any }) => {
    return (
      <ComposableMap projection="geoAlbersUsa">
        <Geographies geography={geoUSStateUrl}>
          {({ geographies }: { geographies: any }) => (
            <>
              {geographies.map((geo: any) => {
                const cur = data[geo.properties.name]
                return (
                  <Geography
                    data-tooltip-content=""
                    data-tooltip-id="map"
                    key={geo.rsmKey}
                    stroke="#f0f3ff"
                    geography={geo}
                    fill={cur ? colorScale(cur, [0, range]) : 'rgb(247,251,255)'}
                    onMouseEnter={() => {
                      setTooltipContent(geo.properties.name + (cur ? ' — ' + cur : ''))
                    }}
                    onMouseLeave={() => {
                      setTooltipContent('')
                    }}
                    style={{
                      hover: {
                        fill: '#F53',
                        outline: 'none',
                      },
                      pressed: {
                        fill: '#E42',
                        outline: 'none',
                      },
                    }}
                  />
                )
              })}
              {geographies.map((geo: any) => {
                const centroid = geoCentroid(geo)
                const cur = allStates.find((s) => s.val === geo.id)
                return (
                  <g key={geo.rsmKey + '-name'}>
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                        <Marker coordinates={centroid}>
                          <text y="2" fontSize={14} textAnchor="middle">
                            {cur.id}
                          </text>
                        </Marker>
                      ) : (
                        <Annotation
                          subject={centroid}
                          dx={offsets[cur.id as keyof typeof offsets][0]}
                          dy={offsets[cur.id as keyof typeof offsets][1]}
                          connectorProps={{}}
                        >
                          <text x={4} fontSize={14} alignmentBaseline="middle">
                            {cur.id}
                          </text>
                        </Annotation>
                      ))}
                  </g>
                )
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>
    )
  }
)
USStateMap.displayName = 'USStateMap'

const geoWorldMapUrl = 'https://cdn.jsdelivr.net/npm/world-atlas@2.0.2/countries-50m.json'
export const WorldMap = memo(({ setTooltipContent, data, range }: WorldMapProps) => {
  return (
    <ComposableMap
      projectionConfig={{
        rotate: [-10, 0, 0],
        scale: 147,
      }}
      height={450}
    >
      <Sphere stroke="#F5F4F6" strokeWidth={0.5} id={'rsm-sphere'} fill="currentcolor" />
      <Graticule stroke="#F5F4F6" strokeWidth={0.5} />
      {data.length > 0 && (
        <Geographies geography={geoWorldMapUrl}>
          {({ geographies }: { geographies: any }) =>
            geographies.map((geo: any) => {
              const d = data.find((a: { country: string; count: number }) => a.country === geo.properties.name)
              return (
                <Geography
                  data-tooltip-content=""
                  data-tooltip-id="map"
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d.count, [0, range]) : 'rgb(247,251,255)'}
                  onMouseEnter={() => {
                    setTooltipContent(d ? `${geo.properties.name} — ${d['count']}` : geo.properties.name)
                  }}
                  onMouseLeave={() => {
                    setTooltipContent('')
                  }}
                  stroke="#f0f3ff"
                  style={{
                    hover: {
                      fill: '#F53',
                      outline: 'none',
                    },
                    pressed: {
                      fill: '#E42',
                      outline: 'none',
                    },
                  }}
                />
              )
            })
          }
        </Geographies>
      )}
    </ComposableMap>
  )
})
WorldMap.displayName = 'WorldMap'
