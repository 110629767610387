import React, { useState } from 'react'
import { usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { Col, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { DragNDrop } from '../../../components/component-items/dragndrop'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { StatusBarStyled } from '../../../components/component-items/status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'

export const UploadOrders = () => {
  const [file, setFile] = useState(null)
  const [response, setResponse] = useState<any>({ success: false, error: false })
  const {
    state: { csrf },
  } = globalState()

  const uploadFile = async () => {
    if (!file) {
      return
    }
    var formData = new window.FormData()
    formData.append('order_upload', file, 'order_upload.csv')
    const reload = false
    const stringifyBody = false
    const successMessage = 'Success: Orders have been uploaded and you will receive an email once this is complete'
    const errorMessage = (
      <>
        Something went wrong, please confirm the template used is correct, otherwise contact customer service:{' '}
        <a href="mailto:support@shippingtree.co">support@shippingtree.co</a>
      </>
    )
    const resp: any = await usePost('/api/order/upload_orders/', formData, csrf, reload, stringifyBody)
    setResponse(resp.success ? { success: successMessage, error: false } : { success: false, error: errorMessage })
  }
  return (
    <>
      <Row>
        <Col>
          <StatusBarStyled>
            <div className="header-item">
              <div className="status-icon">
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
              </div>
              <div className="status-text">
                <strong>Please Note</strong>: CSV Files must be formatted like the template, any errors will be reported
                below, and the upload will fail
              </div>
            </div>
          </StatusBarStyled>
        </Col>
      </Row>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Upload Orders</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '50%' }}>
          {response.success ? (
            <StatusBoxStyled className={'green'} style={{ marginBottom: '2em' }}>
              {response.success}
            </StatusBoxStyled>
          ) : response.error ? (
            <StatusBoxStyled className={'red'} style={{ marginBottom: '2em' }}>
              {response.error}
            </StatusBoxStyled>
          ) : null}
          <Form.Label style={{ marginTop: '1em' }}>
            CSV File Upload (<a href="/api/static/templates/order_upload_template.csv">Download Template</a>)
          </Form.Label>
          <DragNDrop {...{ file, setFile }} fileTypes={'.csv'} />
          <GlobalStyles.Button onClick={() => uploadFile()} style={{ minWidth: 150, margin: 0, float: 'right' }}>
            Submit
          </GlobalStyles.Button>
        </div>
      </GlobalStyles.FullPageCard>
    </>
  )
}
