import React, { useState, useEffect } from 'react'
import { useFetch, usePost, notify } from '../../../components/component-items/helpers'
import { Link } from 'react-router-dom'
import { globalState } from '../../../store'
import { toast } from 'react-toastify'

// Components
import Pager, { Pages } from '../../../components/component-items/pager'
import ButtonDropdown from '../../../components/component-items/button-dropdown'
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { TableCell, THSort } from '../../../components/component-items/datatable'
import { TableRow } from './styles'
import { SearchBox } from '../../../components/component-items/search'
import { Form } from 'react-bootstrap'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faFire } from '@fortawesome/pro-duotone-svg-icons'

export const BatchList = () => {
  const {
    state: { csrf },
  } = globalState()
  const [page, setPage] = useState(1)
  const tableRowOptions = [100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])

  // Assign sort based on url params first
  const filterOptions = ['Single Item', 'Pre-Pack', 'Non-Pre-Pack', 'Aged Orders', 'Not Started', 'Hot', 'All']
  const [filter, setFilter] = useState('All')
  const [sort, setSort] = useState({})
  const [search, setSearch] = useState('')

  const [loading, setLoading] = useState(false)
  const [batches, setBatches] = useState([])

  const url =
    '/api/inventory/batch/?range=' +
    rows +
    '&page=' +
    (page - 1) +
    '&order_by=' +
    Object.keys(sort) +
    '&sort=' +
    Object.values(sort) +
    '&q=' +
    search +
    '&filter=' +
    filter
  const res: any = useFetch(url, {
    redirect: 'follow',
  })
  const resp: any = res.response

  useEffect(() => {
    setLoading(!res.loaded)
    if (res.loaded) {
      setBatches(resp.batches)
    }
  }, [res.loaded])

  const total = res.loaded ? resp.total : 0
  const users = res.loaded ? resp.users : []
  const currentUser = res.loaded ? resp.currentUser : ''
  var totalPages = res.loaded && total ? Math.ceil(total / rows) : 1

  const setUser = async (batchId: any, userId: any) => {
    await usePost(`/api/inventory/batch/${batchId}/header/`, { change_user: userId }, csrf, false)
    notify({
      type: 'success',
      message: 'User successfully updated',
    })

    const newBatchList: any = [...batches]
    const option: any = newBatchList.find((a: any) => a.batchId === batchId)
    // @ts-ignore
    const index = newBatchList.indexOf(option)
    // @ts-ignore
    newBatchList[index] = { ...option, user: userId }
    setBatches(newBatchList)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Batch List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <ButtonDropdown title={'Filter'} sort={filter} setSort={setFilter} options={filterOptions} height={40} />
          <GlobalStyles.Button
            className="secondary"
            style={{ height: 40, width: 170 }}
            onClick={() => setSearch(currentUser)}
          >
            My Batches
          </GlobalStyles.Button>
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus updateURL />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <THSort center title={'Batch'} sortKey={'id'} sort={sort} setSort={setSort} />
              <th>
                <span className="text center">Companies</span>
              </th>
              <THSort center title={'Ship After'} sort={sort} setSort={setSort} />
              <THSort center title={'Ship Before'} sort={sort} setSort={setSort} />
              <th>
                <span className="text center">Tags</span>
              </th>
              <th>
                <span className="text center">Started</span>
              </th>
              <THSort center title={'Processing'} sort={sort} setSort={setSort} />
              <THSort center title={'Picked'} sort={sort} setSort={setSort} />
              <THSort center title={'Packed'} sort={sort} setSort={setSort} />
              <THSort center title={'Labelled'} sort={sort} setSort={setSort} />
              <THSort center title={'Stuck'} sort={sort} setSort={setSort} />
              <THSort center title={'Assigned'} sortKey={'assigned__last_name'} sort={sort} setSort={setSort} />
              <th>
                <span className="text center">Type</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {batches.map(
              ({
                batchId,
                companies,
                shipAfter,
                shipBefore,
                processing,
                picked,
                packed,
                labelled,
                stuck,
                started,
                type,
                typeName,
                hot,
                aged,
                user,
              }: any) => (
                <TableRow key={batchId}>
                  <TableCell center input={<Link to={`/batch/${batchId}/`}>{batchId}</Link>} />
                  <TableCell
                    center
                    input={companies.map((company: any, id: number) => (
                      <div key={id}>
                        <a href="#" onClick={() => setSearch(company)}>
                          {company}
                        </a>
                      </div>
                    ))}
                  />
                  <TableCell center input={shipAfter} />
                  <TableCell center input={shipBefore} />
                  <TableCell center>
                    {type === 'b2b' ? (
                      <StatusBoxStyled style={{ width: 75, margin: '0.5em auto' }} className={'purple bold'}>
                        B2B
                      </StatusBoxStyled>
                    ) : null}
                    {aged ? (
                      <StatusBoxStyled style={{ width: 75, margin: '0.5em auto' }} className={'yellow bold'}>
                        AGED
                      </StatusBoxStyled>
                    ) : null}
                    {hot ? (
                      <StatusBoxStyled style={{ width: 75, margin: '0.5em auto' }} className={'red bold'}>
                        <FontAwesomeIcon icon={faFire} /> {hot && hot > 1 ? `${hot} HOT` : 'HOT'}
                      </StatusBoxStyled>
                    ) : null}
                  </TableCell>
                  <TableCell center>
                    {started ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'purple' }} /> : null}
                  </TableCell>
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled style={{ width: 60, margin: 'auto' }} className={processing ? 'blue' : 'silver'}>
                        {processing}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled style={{ width: 60, margin: 'auto' }} className={picked ? 'blue' : 'silver'}>
                        {picked}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled style={{ width: 60, margin: 'auto' }} className={packed ? 'blue' : 'silver'}>
                        {packed}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled style={{ width: 60, margin: 'auto' }} className={labelled ? 'blue' : 'silver'}>
                        {labelled}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled style={{ width: 60, margin: 'auto' }} className={stuck ? 'yellow' : 'silver'}>
                        {stuck}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell>
                    <Form.Select value={user} onChange={(e) => setUser(batchId, e.target.value)}>
                      <option value={''} disabled hidden>
                        -User-
                      </option>
                      {users.map((u: any) => (
                        <option value={u.id} key={u.id}>
                          {u.first_name} {u.last_name}
                        </option>
                      ))}
                    </Form.Select>
                  </TableCell>
                  <TableCell center>
                    <StatusBoxStyled style={{ margin: 'auto' }} className="blue">
                      {typeName}
                    </StatusBoxStyled>
                  </TableCell>
                </TableRow>
              )
            )}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
