import React from 'react'
// Cards
import { ShippingMethodList } from './shipping-method-list'
import { ShippingBlacklist } from './shipping-blacklist'
import { ShippingAccounts } from './shipping-accounts'

export const ShippingMethods = () => (
  <>
    <ShippingMethodList />
    <ShippingBlacklist />
    <ShippingAccounts />
  </>
)
