import React from 'react'

// Components
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'

// Plugins
import ReactJson from 'react-json-view'

// TODO - move to folder
type EDITransactionModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  transaction: any
}

export const EDITransactionModal = ({ open, setOpen, transaction }: EDITransactionModalProps) => {
  return (
    <GenericModal
      heading={'940 Warehouse Shipping Order'}
      show={open}
      size={'xl'}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <div style={{ minHeight: 100, padding: '1em' }}>
        <h2 style={{ marginBottom: '1em' }}>EDI Message</h2>
        <div
          style={{
            maxHeight: '70vh',
            overflow: 'scroll',
            border: 'solid 1px lightgrey',
            borderRadius: 4,
            background: '#f3f3f3',
          }}
        >
          <ReactJson
            name={'EDI Messages'}
            src={transaction.message}
            theme="paraiso"
            displayDataTypes={true}
            iconStyle="square"
            displayObjectSize={true}
            indentWidth={4}
            collapsed={false}
            collapseStringsAfterLength={50}
            enableClipboard={true}
          />
        </div>
      </div>
    </GenericModal>
  )
}
