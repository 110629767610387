import React, { useState, useEffect } from 'react'

//Components
import GlobalStyles from '../component-items/styles'
import { Form, Col, Modal, Row } from 'react-bootstrap'

import { PrintLabelsModalProps } from './types'

export const PrintLabelsModal = ({ id, location_id, qty, scancode, open, setOpen }: PrintLabelsModalProps) => {
  const [validated, setValidated] = useState(false)

  const [quantity, setQuantity] = useState(qty ? qty : 1)
  const [labelFormat, setLabelFormat] = useState<any>(0)
  const [barcode, setBarcode] = useState('')

  useEffect(() => {
    setBarcode(scancode)
  }, [scancode])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement | HTMLButtonElement | HTMLAnchorElement>) => {
    const form: any = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }

    var params: any = { id, quantity, label_format: labelFormat, location_id: '', barcode: encodeURIComponent(barcode) }
    // This can be null
    if (location_id) {
      params['location_id'] = location_id
    }
    var queryString = Object.keys(params)
      ?.map((key) => key + '=' + params[key])
      .join('&')
    window.location.replace(`/api/warehouse/print_labels/?${queryString}`)
  }
  return (
    <Modal show={open} onHide={() => setOpen(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Print Labels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} md="4" controlId="validationCustom01" className="required">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                min={0}
                max={1000}
                placeholder="# of Labels"
                value={quantity}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
                  setQuantity(Number(e?.target?.value))
                }
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid quantity.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02" className="required">
              <Form.Label>Label Format</Form.Label>
              <Form.Select value={labelFormat} onChange={(e: any) => setLabelFormat(e?.target?.value)}>
                <option value="0">2.25" wide x 1.25" tall</option>
                <option value="1">1.5" wide x 0.5" tall</option>
                <option value="2">1" wide x 0.5" tall</option>
                <option value="3">UPC</option>
                <option value="4">4"x6" Pallet Labels</option>
                <option value="5">4"x2" Location Labels</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid format.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom03" className="required">
              <Form.Label>Scancode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Scancode"
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid scancode.</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <GlobalStyles.Button type="submit" style={{ minWidth: 125 }} onSubmit={() => handleSubmit}>
            Print
          </GlobalStyles.Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
