// React
import React, { FunctionComponent, useEffect, useState, useRef } from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { globalState } from '../../../../store'

// Helpers
import { StickyElement, useFetch, useClick, usePost, usePut } from '../../../../components/component-items/helpers'

// Components
import CardLoading from '../../../../components/component-items/loading-popover'
import GlobalStyles from '../../../../components/component-items/styles'
import { OrderOptions } from '../../../orders/order/styles'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'
import { AsnItem, AsnOption } from './item-helpers'
import { DropdownItem } from '../../../../components/component-items/export-tools'
import { Dropdown } from '../styles'

// Modals
import { EditASNModal } from '../edit-asn-modal'
import { ComplianceHistory } from '../compliance-history'

// Enums
import { ShipNoticeStatus } from '../../../../enums'

// Cards
import { ReceivingWorkflow } from './receiving-workflow'
import { NonComplianceWorkflow } from './non-compliance-workflow'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboardList,
  faClipboardListCheck,
  faDolly,
  faWarehouse,
  faEdit,
  faHeartRate,
  faHourglassHalf,
  faPlaneArrival,
  faBoxCheck,
  faFileInvoiceDollar,
} from '@fortawesome/pro-duotone-svg-icons'

import { StatusBarProps, ProductProps } from './types'

const StatusBar: FunctionComponent<StatusBarProps> = ({ asn, warehouse, company, status, loaded, error }) => {
  return (
    <Row className="hide-in-percy">
      <Col>
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              {status === 'Received' ? (
                <FontAwesomeIcon icon={faClipboardListCheck} style={{ color: '#5d78ff' }} />
              ) : (
                <FontAwesomeIcon icon={faDolly} style={{ color: '#5d78ff' }} />
              )}
            </div>
            {status === 'Received' ? (
              <div className="status-text">ASN Billing - {asn}</div>
            ) : (
              <div className="status-text">Receiving ASN - {asn}</div>
            )}
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            {status !== 'Completed' ? (
              <div className="status-icon">
                <FontAwesomeIcon style={{ color: '#FFB822' }} icon={faClipboardList} />
              </div>
            ) : (
              <div className="status-icon">
                <FontAwesomeIcon style={{ color: 'green' }} icon={faClipboardListCheck} />
              </div>
            )}

            <div className="status-text">
              {status}
              <div>
                Company: <strong>{company}</strong>
              </div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text" style={{ paddingRight: '1em' }}>
              Facility:
            </div>
            <div className="status-text">
              <strong>{warehouse}</strong>
            </div>
            <div className="status-icon" style={{ marginLeft: '2em' }}>
              <FontAwesomeIcon icon={faWarehouse} />
            </div>
          </div>
          {!loaded ? <CardLoading error={error} /> : null}
        </StatusBarStyled>
      </Col>
    </Row>
  )
}

export const SelectItem = () => {
  const {
    state: { csrf },
  } = globalState()

  const location = useLocation()
  const history = useHistory()
  const asn = location.pathname.split('/')[2]
  const { response: resp, error, loaded }: any = useFetch(`/api/inventory/asn/${asn}/details/`, {})
  const [current, setCurrent] = useState('scan_item')
  const [barcode, setBarcode] = useState('')
  const [openEditASNModal, setEditASNModal] = useState(false)
  const [openComplianceHistory, setComplianceHistory] = useState(false)

  const products = loaded ? resp.products : []
  const warehouse = loaded ? resp.warehouse : ''
  const company = loaded ? resp.company : ''
  const status = loaded ? resp.asnStatus : 'Processing'
  const compliance = loaded ? resp.compliance : null
  const asnComplianceCompleted = loaded ? resp.compliance_completed : false
  const asnCompliance = loaded ? resp.compliance : null
  const isProject = loaded ? resp.projectAsn : null

  // Edit ASN variables
  const tracking = loaded && resp.tracking ? resp.tracking : ''
  const delivery = loaded ? resp.delivery : ''
  const sender = loaded && resp.sender ? resp.sender : 'N/A'
  const integrationID = loaded ? resp.integrationID : null
  const po = loaded ? resp.po : null
  const bol = loaded ? resp.bol : null
  const pro = loaded ? resp.pro : null
  const serviceLevel = loaded ? resp.service_level : ''

  const [statusDropdown, setStatusDropdown] = useState(false)
  const node = useRef(null)
  useClick(node, setStatusDropdown)

  // Add cursor to input field on render
  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus()
      inputElement.current.select()
    }
  }, [])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    var item_id = null
    products?.map((product: ProductProps) => {
      if (product.barcode === barcode) {
        item_id = product.id
      }
    })

    if (item_id) {
      if (status === 'Received') {
        history.push(`/asn/${asn}/item/${item_id}/non-compliance/`)
      } else {
        history.push(`/asn/${asn}/item/${item_id}/confirm-details/`)
      }
    } else {
      toast(`No item found with barcode: ${barcode}`, { type: 'error' })
    }
  }

  const handleKeyPressed = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }

  return (
    <>
      <StatusBar asn={asn} warehouse={warehouse} company={company} status={status} loaded={loaded} error={error} />
      <Row>
        <Col xl={2}>
          <StickyElement>
            <OrderOptions>
              <ul>
                <GlobalStyles.Tooltip data-title="Update ASN contents (delivery date, sender, etc)">
                  <AsnOption onClick={() => setEditASNModal(true)} icon={faEdit} title={'Edit ASN'} />
                </GlobalStyles.Tooltip>
                {status == 'Received' && (
                  <>
                    <GlobalStyles.Tooltip data-title="Displays list of all ASN compliance fees">
                      <AsnOption
                        onClick={() => setComplianceHistory(true)}
                        icon={faFileInvoiceDollar}
                        title={'All Compliance'}
                      />
                    </GlobalStyles.Tooltip>
                    <ComplianceHistory
                      open={openComplianceHistory}
                      setOpen={setComplianceHistory}
                      compliance={compliance}
                    />
                  </>
                )}
                <AsnOption
                  onClick={() => setStatusDropdown(!statusDropdown)}
                  icon={faHeartRate}
                  title={'Change Status'}
                  arrow={true}
                  node={node}
                >
                  {statusDropdown ? (
                    <Dropdown>
                      <DropdownItem
                        onClick={() =>
                          usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.InTransit }, csrf)
                        }
                        icon={faHourglassHalf}
                        text={'Mark as In Transit'}
                      />
                      <DropdownItem
                        onClick={() =>
                          usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Arrived }, csrf)
                        }
                        icon={faPlaneArrival}
                        text={'Mark as Arrived'}
                      />
                      <DropdownItem
                        onClick={() =>
                          usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Processing }, csrf)
                        }
                        icon={faDolly}
                        text={'Mark as Processing'}
                      />
                      {!isProject && (
                        <DropdownItem
                          onClick={() =>
                            usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Received }, csrf)
                          }
                          icon={faBoxCheck}
                          text={'Mark as Received'}
                        />
                      )}
                    </Dropdown>
                  ) : null}
                </AsnOption>
              </ul>
            </OrderOptions>
          </StickyElement>
        </Col>
        <Col xl={7}>
          <GlobalStyles.FullPageCard>
            <GlobalStyles.CardHeader>
              <GlobalStyles.CardTitle>
                <h3>
                  ASN Item List<small>{products.length} Total</small>
                </h3>
              </GlobalStyles.CardTitle>
            </GlobalStyles.CardHeader>
            <div style={{ maxHeight: 320, overflow: 'scroll' }}>
              <GlobalStyles.DataTable>
                <thead>
                  <tr>
                    <th />
                    <th>
                      <span className="text">Product</span>
                    </th>
                    <th>
                      <span className="text center">SKU</span>
                    </th>
                    <th>
                      <span className="text center">Sent</span>
                    </th>
                    <th>
                      <span className="text center">Received</span>
                    </th>
                    <th>
                      <span className="text center">Defective</span>
                    </th>
                    {status !== 'Received' && (
                      <th>
                        <span className="text center">Over/Short</span>
                      </th>
                    )}

                    <th>
                      <span className="text center">Compliance</span>
                    </th>
                    <th>
                      <span className="text center">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((product: any, id: number) => (
                    <AsnItem {...product} asn={asn} status={status} key={id} />
                  ))}
                </tbody>
                {!loaded ? <CardLoading error={error} /> : null}
              </GlobalStyles.DataTable>
            </div>
          </GlobalStyles.FullPageCard>
          <GlobalStyles.FullPageCard>
            <GlobalStyles.CardHeader>
              <GlobalStyles.CardTitle>
                {status == 'Received' ? <h3>Add ASN Billing</h3> : <h3>Receive ASN Item</h3>}
              </GlobalStyles.CardTitle>
            </GlobalStyles.CardHeader>
            <div style={{ minHeight: 80, margin: '2em' }}>
              <Form noValidate onSubmit={handleSubmit}>
                <Row style={{ padding: '.5em 1em 0' }}>
                  <Form.Group as={Col} md="9" controlId="scan" className="required">
                    <Form.Control
                      type="text"
                      placeholder="Scan ASN Item"
                      onSubmit={handleSubmit}
                      onChange={(e) => setBarcode(e.target.value)}
                      onKeyDown={handleKeyPressed}
                      ref={inputElement}
                      required
                    />
                    <Form.Control.Feedback type="invalid">Please provide a valid barcode</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <GlobalStyles.Button type="submit" style={{ margin: 0, width: '100%', height: 40 }}>
                      Submit
                    </GlobalStyles.Button>
                  </Form.Group>
                </Row>
              </Form>
            </div>
          </GlobalStyles.FullPageCard>
        </Col>
        <Col xl={3}>
          {status == 'Received' ? (
            <NonComplianceWorkflow
              itemComplianceCompleted={false}
              asn={asn}
              current={current}
              asnComplianceCompleted={asnComplianceCompleted}
              asnCompliance={asnCompliance}
            />
          ) : (
            <ReceivingWorkflow asn={asn} current={current} isProject={isProject} />
          )}
          {!loaded ? <CardLoading error={error} /> : null}
        </Col>
      </Row>
      <EditASNModal
        open={openEditASNModal}
        setOpen={setEditASNModal}
        {...{ sender, tracking, serviceLevel, delivery, po, bol, pro, integrationID }}
      />
    </>
  )
}
