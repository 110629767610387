import React, { useState, useRef, FunctionComponent, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { usePost, notify } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { Button, Form } from 'react-bootstrap'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import GenericModal from '../../../../components/component-items/modal'

// Cards
import { Condition } from './condition'
import { Actions } from './action'
import { Event } from './event'

// Types
import { defaultFilters } from './types'
import { ButtonGroup } from '../../../../components/component-items/buttons'
import { AutomationPreview } from './preview'

const filterActions = (actions: any) => {
  var action = actions
  var logical = action.logical

  // TODO - update field_value to use field based on automationType

  // Update send_notification message logic to pull content from mentionRef
  if (action.type === 'send_notification') {
    const mentionRef = actions.result.field_value
    if (mentionRef?.current) {
      const message = mentionRef.current.getContent()
      if (!message) {
        notify({ title: 'Message Field Required', message: 'Missing notification body', type: 'error' })
        return
      } else {
        action = { ...action, result: { ...action.result, field_value: message } }
      }
    }
  } else if (action.type === 'logical_and') {
    for (let i = 0; i < logical.length; i++) {
      const mentionRef = logical[i].result.field_value
      if (mentionRef?.current) {
        const message = mentionRef.current.getContent()
        if (!message) {
          notify({ title: 'Message Field Required', message: 'Missing notification body', type: 'error' })
          return
        } else {
          logical[i].result.field_value = message
        }
      }
    }
    action = { ...action, logical: logical }
  }

  // Update edit_order_item logic to use value from field_value
  if (action.type === 'edit_order_item') {
    let field_type = action.result.field_type
    action = { ...action, result: { ...action.result, field_type: field_type.value } }
  } else if (action.type === 'logical_and') {
    for (let i = 0; i < logical.length; i++) {
      if (logical[i].type === 'edit_order_item') {
        let field_type = logical[i].result.field_type
        logical[i].result.field_type = field_type.value
      }
    }
    action = { ...action, logical: logical }
  }

  // Update notification_to
  if (action.type === 'send_notification') {
    let notification_to = action.result?.notification_to
    if (typeof notification_to === 'string') {
      notification_to = notification_to.split(',')
    } else {
      notification_to = notification_to?.map((user: any) => user.display_name)
    }
    action = { ...action, result: { ...action.result, notification_to: notification_to } }
  } else if (action.type === 'logical_and') {
    for (let i = 0; i < logical.length; i++) {
      if (logical[i].type === 'send_notification') {
        let notification_to = logical[i].result.notification_to
        if (typeof notification_to === 'string') {
          logical[i].result.notification_to = notification_to.split(',')
        } else {
          logical[i].result.notification_to = notification_to.map((user: any) => user.display_name)
        }
      }
    }
    action = { ...action, logical: logical }
  }
  return action
}

export const Automations = () => {
  const {
    state: { csrf },
  } = globalState()

  const node = useRef<HTMLFormElement | null>(null)
  const [validated, setValidated] = useState(false)
  const [nameModal, setNameModal] = useState(false)
  const [preview, setPreview] = useState(false)

  const actionTypes = [
    { label: 'Order', value: 'order' },
    { label: 'ASN', value: 'asn' },
    { label: 'Return', value: 'return' },
    { label: 'Inventory', value: 'inventory' },
    { label: 'Product Variant', value: 'product_variant' },
    { label: 'Billing', value: 'billing' },
  ]
  const [automationType, setAutomationType] = useState(actionTypes[0].value)
  const eventDefault = {
    category: 'now',
    conditions: '',
  }
  const [filters, setFilters] = useState<any>(defaultFilters)
  const [event, setEvent] = useState(eventDefault)
  const [actions, setActions] = useState<any>({ ...defaultFilters, type: 'edit_order_field' })

  const checkValidity = () => {
    setValidated(true)
    if (node?.current?.checkValidity() === false) {
      return false
    }
    return true
  }

  const handleSubmit = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    if (!checkValidity()) {
      return
    }

    if (!confirm('Are you sure you want to run this automation?')) {
      return
    }

    let action = filterActions(actions)

    usePost(
      '/api/core/automation/run/',
      { ...{ category: automationType, condition: filters, event, action } },
      csrf,
      false,
      true,
      true
    ).then((response) => {
      if (response?.error) {
        notify({ type: 'warning', message: response?.error })
      } else {
        notify({ type: 'success', message: 'Automation Ran Successfully' })
      }
    })
  }

  const handlePreview = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    if (!checkValidity()) {
      return
    }

    setPreview(true)
    setValidated(false)
  }

  // Use effect when type changes to set default on setFilters
  const updateType = (value: any) => {
    setAutomationType(value)
    let filters = defaultFilters
    let actions = defaultFilters
    if (value === 'order') {
      filters = { ...defaultFilters, type: 'all_orders' }
      actions = { ...defaultFilters, type: 'edit_order_field' }
    } else if (value === 'asn') {
      filters = { ...defaultFilters, type: 'all_asns' }
      actions = { ...defaultFilters, type: 'edit_asn_field' }
    } else if (value === 'return') {
      filters = { ...defaultFilters, type: 'all_returns' }
      actions = { ...defaultFilters, type: 'edit_return_field' }
    } else if (value === 'inventory') {
      filters = { ...defaultFilters, type: 'all_inventory' }
      actions = { ...defaultFilters, type: 'edit_inventory_field' }
    } else if (value === 'product_variant') {
      filters = { ...defaultFilters, type: 'all_product_variants' }
      actions = { ...defaultFilters, type: 'edit_product_variant_field' }
    } else if (value === 'billing') {
      filters = { ...defaultFilters, type: 'all_billing' }
      actions = { ...defaultFilters, type: 'edit_billing_field' }
    }
    setFilters(filters)
    setActions(actions)
  }

  return (
    <>
      <GlobalStyles.FullPageCard>
        <Form ref={node} validated={validated} onSubmit={handleSubmit} style={{ padding: '0 1em 2em' }}>
          <div style={{ padding: '2em 1em 0' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '2em' }}>
              <ButtonGroup aria-label="action-types">
                {actionTypes.map(({ label, value }, id: number) => (
                  <Button
                    key={id}
                    variant="secondary"
                    active={automationType == value}
                    onClick={() => updateType(value)}
                  >
                    {label}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            <h3 style={{ marginTop: '1em' }}>Condition</h3>
            <div style={{ display: 'flex' }}>
              <Condition {...{ automationType, filters, setFilters }} r={240} g={248} b={255} />
            </div>
            <h3 style={{ marginTop: '1em' }}>Trigger Event</h3>
            <div style={{ display: 'flex' }}>
              <Event {...{ automationType, event, setEvent }} />
            </div>
            <h3 style={{ marginTop: '1em' }}>Action</h3>
            {automationType === 'order' && (
              <StatusBoxStyled className={'purple'} style={{ margin: '0 0.9em', textAlign: 'center' }}>
                Please note: all modifications will only be performed on unfulfilled orders.
              </StatusBoxStyled>
            )}
            <Actions {...{ automationType, filters, event, actions, setActions }} r={240} g={248} b={255} />
            <div style={{ padding: '1em 0' }}>
              <GlobalStyles.Button
                type="button"
                className="royal"
                style={{ minWidth: 125, float: 'right' }}
                onClick={() => checkValidity() && setNameModal(true)}
              >
                Save
              </GlobalStyles.Button>
              {event.category === 'now' ? (
                <>
                  <GlobalStyles.Button style={{ minWidth: 125, float: 'right' }} onClick={handleSubmit}>
                    Run
                  </GlobalStyles.Button>
                  <GlobalStyles.Button
                    className="secondary"
                    style={{ minWidth: 125, float: 'right' }}
                    onClick={handlePreview}
                  >
                    Preview
                  </GlobalStyles.Button>
                </>
              ) : null}
            </div>
          </div>
        </Form>
      </GlobalStyles.FullPageCard>
      <AutomationCreate
        {...{ category: automationType, condition: filters, event: event, action: actions }}
        open={nameModal}
        setOpen={setNameModal}
      />
      <AutomationPreview
        {...{
          open: preview,
          setOpen: setPreview,
          category: automationType,
          condition: filters,
          event: event,
          action: actions,
        }}
      />
    </>
  )
}

type AutomationCreateProps = {
  open: boolean
  setOpen: (open: boolean) => void
  category: string
  condition: any
  event: any
  action: any
}
const AutomationCreate: FunctionComponent<AutomationCreateProps> = ({
  open,
  setOpen,
  category,
  condition,
  event,
  action,
}) => {
  const {
    state: { csrf },
  } = globalState()

  const node = useRef<HTMLFormElement | null>(null)
  const [validated, setValidated] = useState(false)
  const history = useHistory()

  const [name, setName] = useState('')

  const handleSubmit = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)
    if (node?.current?.checkValidity() === false) {
      notify({ type: 'error', message: 'Choose a name for this Automation.' })
      return
    }
    usePost(
      '/api/core/automation/',
      { ...{ category, name, condition, event, action: filterActions(action) } },
      csrf,
      false,
      true,
      true
    ).then((response) => {
      if (response?.error) {
        notify({ type: 'warning', message: response?.error })
      }
    })

    history.go(0)
  }

  return (
    <GenericModal
      heading={'Automation Name'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <>
          <GlobalStyles.Button onClick={() => setOpen(false)}>Close</GlobalStyles.Button>
          <GlobalStyles.Button onClick={handleSubmit}>Save</GlobalStyles.Button>
        </>
      }
    >
      <Form ref={node} validated={validated} onSubmit={handleSubmit} style={{ padding: '2em' }}>
        <Form.Control
          type="text"
          name="name"
          placeholder="Example: 'Pause new orders with tag 'hold'"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
        />
      </Form>
    </GenericModal>
  )
}
