import React, { useState, useEffect, useRef } from 'react'
import { usePost } from '../components/component-items/helpers'
import { globalState } from '../store'

// Components
import GlobalStyles from '../components/component-items/styles'
import { CenteredSpinner } from '../components/component-items/loading-popover'
import Form from 'react-bootstrap/Form'

export const ShipOrderPage = () => {
  const [loading, setLoading] = useState(false)
  const [barcode, setBarcode] = useState('')
  const {
    state: { csrf },
  } = globalState()

  const handleKeyPressed = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = async () => {
    if (loading) {
      return
    }
    setLoading(true)

    const reload = false
    const stringify = true
    const sendResponse = false
    await usePost('/api/warehouse/start_shipping/', { barcode }, csrf, reload, stringify, sendResponse)

    setLoading(false)
    setBarcode('')
  }

  // Add cursor to input field on render
  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputElement?.current?.focus()
    inputElement?.current?.select()
  }, [])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>Ship Order</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 110, margin: '2em' }}>
        <Form.Label>
          <strong>Pack</strong> (Order ID OR Rack Location Barcode)
        </Form.Label>
        <div style={{ display: 'flex' }}>
          <Form.Control
            type="text"
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            placeholder="Barcode"
            onKeyDown={handleKeyPressed}
            ref={inputElement}
          />
          {!loading ? (
            <GlobalStyles.Button className="royal" style={{ minWidth: 125 }} onClick={() => handleSubmit()}>
              Submit
            </GlobalStyles.Button>
          ) : (
            <GlobalStyles.Button className="royal" style={{ minWidth: 125, cursor: 'auto' }}>
              <CenteredSpinner />
              &nbsp;
            </GlobalStyles.Button>
          )}
        </div>
      </div>
    </GlobalStyles.FullPageCard>
  )
}
