// React
import React, { FunctionComponent, useState } from 'react'
// Components
import styled from 'styled-components'
import { TableCell } from '../../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import GlobalStyles from '../../../../components/component-items/styles'
// Modals
import GenericModal from '../../../../components/component-items/modal'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

import { ReceivingHistoryProps } from './types'

export const ReceivingHistory: FunctionComponent<ReceivingHistoryProps> = ({
  open,
  setOpen,
  inventoryTransactions,
}) => {
  const [sort, setSort] = useState({})
  return (
    <GenericModal
      heading={'Receiving History'}
      show={open}
      size="xl"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Inventory Transaction List</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ minHeight: 300, maxWidth: '100%' }}>
          <GlobalStyles.DataTable>
            <thead>
              <tr>
                <th>
                  <span className="text center">Location</span>
                </th>
                <th>
                  <span className="text center">Inventory Type</span>
                </th>
                <th>
                  <span className="text center">Quantity</span>
                </th>
                <th>
                  <span className="text center">User</span>
                </th>
                <th>
                  <span className="text center">Received Date</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {inventoryTransactions?.map((transaction: any, id: number) => (
                <TableRow key={id}>
                  <TableCell center input={transaction.inventory_item.location_barcode}></TableCell>
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled
                        style={{ width: 120, margin: 'auto' }}
                        className={
                          transaction.inventory_item.inventory_type == 'pickable' ||
                          transaction.inventory_item.inventory_type == 'backstock'
                            ? 'green'
                            : 'yellow'
                        }
                      >
                        {transaction.inventory_item.inventory_type}
                      </StatusBoxStyled>
                    }
                  ></TableCell>
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled
                        className={transaction.quantity < 0 ? 'red' : 'green'}
                        style={{ width: 75, margin: 'auto' }}
                      >
                        {transaction.quantity}
                      </StatusBoxStyled>
                    }
                  ></TableCell>
                  <TableCell center input={transaction.user}></TableCell>
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled style={{ width: 150, margin: 'auto' }} className="silver">
                        {new Date(transaction.created).toLocaleString()}
                      </StatusBoxStyled>
                    }
                  ></TableCell>
                </TableRow>
              ))}
            </tbody>
          </GlobalStyles.DataTable>
        </div>
      </GlobalStyles.FullPageCard>
    </GenericModal>
  )
}
