import React from 'react'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import { useFetch } from '../../components/component-items/helpers'
import { ReturnListStyled } from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'

export const ReturnsReceived = ({ url }: { url: string }) => {
  var resp: any = useFetch(url, {}, 30)
  var total = resp.loaded ? resp?.response.total : 0
  var defaultData = [
    { count: 0 },
    { count: 0 },
    { count: 0 },
    { count: 0 },
    { count: 0 },
    { count: 0 },
    { count: 0 },
    { count: 0 },
  ]
  var returns = resp.loaded && resp.response.returns.length ? resp.response.returns.slice(-8) : defaultData

  return (
    <ReturnListStyled>
      <div className="widget__head">
        <h3>Returns Received</h3>
        <div className="toolbar">
          <button
            onClick={() => {
              window.location.href = '/return/'
            }}
            type="button"
          >
            <i>
              <FontAwesomeIcon icon={faExternalLink} />
            </i>
          </button>
        </div>
      </div>
      <div className="widget__body">
        <div className="aside">
          <div className="label">{total}</div>
          <img src={'/api/static/assets/media/misc/iconbox_bg.png'} alt="bg" />
        </div>
        <div className="bar-graph">
          {returns.map((r: any, id: number) => (
            <div className="bar-total" key={id}>
              <div className="bar-current" style={{ height: `${(r.count * 100) / total}%` }}></div>
            </div>
          ))}
        </div>
      </div>
      {!resp.loaded ? <CardLoading text={resp.placeholder} error={resp.error} /> : null}
    </ReturnListStyled>
  )
}

export default ReturnsReceived
