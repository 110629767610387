import { CreateInvoice } from './create-invoice'
import React, { useState, useEffect } from 'react'
import { useFetch, notify } from '../../../components/component-items/helpers'
import { toast } from 'react-toastify'

export const QuickbooksIntegrationPage = () => {
  const { response, loaded, error, placeholder }: any = useFetch('/api/quickbooks/authenticate/', {})

  useEffect(() => {
    if (loaded) {
      if (!response.connected) {
        window.open(response.redirect_url, '_blank')
      }
    }
  }, [loaded])
  return (
    <>
      <CreateInvoice />
    </>
  )
}
