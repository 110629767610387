import React, { useRef } from 'react'
import { globalState } from '../../store'
import styled from 'styled-components'
import { device } from '../component-items/device'

//Components
import { Header, SubHeader } from './headers'
import { NavigationAside } from './aside'
import { NavigationFooter } from './footers'
import { OnboardingChecklist } from './onboarding'

//Styles - Note: order matters
import 'bootstrap/dist/css/bootstrap.min.css'
import '../chrome/global.css'

export const Background = styled.div`
  position: fixed;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  padding: 1em;
  background: white;
  color: black;
  box-shadow: rgba(228, 255, 248, 0.13) 5px 8px 20px 0px;
`
export const Border = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid #ececec;
  overflow: scroll;
`

const NavigationStyled = styled.div<{ navOpen?: boolean }>`
  display: flex;
  flex: 1;
  height: 100%;
  .main-nav__root {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .main-nav__page {
    display: flex;
    flex-direction: row;
    padding-top: 75px;
    height: 100%;
  }
  .main-nav__wrapper {
    flex: 1 1 auto;
    flex-direction: column;
    display: flex;
    padding-left: 0px;
    @media ${device.laptop} {
      padding-left: ${(props) => (props.navOpen ? '260px' : '80px')};
    }
  }
  .main-nav__content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    position: relative;
  }
  .page-content {
    width: 100%;
    margin: 0;
    padding: 0 25px;
  }
`

export const Chrome = ({ children }: { children: React.ReactNode }) => {
  const {
    state: { navOpen, companyId },
  } = globalState()
  const node = useRef<HTMLDivElement>(null)
  return (
    <NavigationStyled {...{ navOpen }}>
      <div className="main-nav__root">
        <div className="main-nav__page">
          <div className="main-nav__wrapper">
            <NavigationAside />
            <Header {...{ node }} />
            <div className="main-nav__content" ref={node}>
              <SubHeader />
              <div className="page-content">{children}</div>
              {companyId && <OnboardingChecklist />}
            </div>
            <NavigationFooter />
          </div>
        </div>
      </div>
    </NavigationStyled>
  )
}

export default Chrome
