import React, { useState } from 'react'
import { useTheme } from 'styled-components'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { Form } from 'react-bootstrap'
import { TableCell } from '../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faLayerPlus } from '@fortawesome/pro-duotone-svg-icons'

export const Storage = () => {
  const theme = useTheme()

  const defaultProduct = {
    id: 1,
    length: 12,
    width: 12,
    height: 12,
    quantity: 1000,
  }

  const [count, setCount] = useState(0)
  const [products, setProducts] = useState([defaultProduct])

  const unitCost = 0.85
  const costList = products.map(
    ({ length, width, height, quantity }) => (length / 12) * (width / 12) * (height / 12) * quantity * unitCost
  )
  const totalCost = costList.reduce((a, b) => a + b, 0)

  function handleChange(id: number | string, name: string, value: any) {
    const newList = products.map((item) => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          [name]: value,
        }
        return updatedItem
      }
      return item
    })
    setProducts(newList)
  }

  function addRow() {
    setCount(count + 1)
    // @ts-ignore
    setProducts(products.concat({ ...defaultProduct, id: count, sku: `sku-${count}` }))
  }

  return (
    <>
      <StatusBoxStyled className="silver" style={{ margin: '0 auto 2em', textAlign: 'center' }}>
        We measure down to the unit level and calculate the total cubic feet on the 1st of the month
      </StatusBoxStyled>
      <GlobalStyles.DataTable>
        <thead>
          <tr>
            <th>
              <span className="text">SKU</span>
            </th>
            <th>
              <span className="text">Length (in)</span>
            </th>
            <th>
              <span className="text">Width (in)</span>
            </th>
            <th>
              <span className="text">Height (in)</span>
            </th>
            <th>
              <span className="text">Quantity</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((c: any, id: number) => (
            <GlobalStyles.TableRow key={id}>
              <TableCell>
                <Form.Control
                  type="text"
                  value={c.sku}
                  onChange={(e) => handleChange(c.id, 'sku', e.target.value)}
                  placeholder="SKU"
                  required
                />
              </TableCell>
              <TableCell>
                <Form.Control
                  type="number"
                  min="1"
                  value={c.length}
                  onChange={(e) => handleChange(c.id, 'length', e.target.value)}
                  placeholder="Length"
                  required
                />
              </TableCell>
              <TableCell>
                <Form.Control
                  type="number"
                  min="1"
                  value={c.width}
                  onChange={(e) => handleChange(c.id, 'width', e.target.value)}
                  placeholder="Width"
                  required
                />
              </TableCell>
              <TableCell>
                <Form.Control
                  type="number"
                  min="1"
                  value={c.height}
                  onChange={(e) => handleChange(c.id, 'height', e.target.value)}
                  placeholder="Height"
                  required
                />
              </TableCell>
              <TableCell>
                <Form.Control
                  type="number"
                  min="1"
                  placeholder="Quantity"
                  required
                  value={c.quantity}
                  onChange={(e) => handleChange(c.id, 'quantity', e.target.value)}
                />
              </TableCell>
              <TableCell
                input={
                  <div className="dropdown__container" style={{ minWidth: 60 }}>
                    {products.length > 1 ? (
                      <button onClick={() => setProducts(products.filter((e) => e !== c))}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    ) : null}
                    <button onClick={() => addRow()}>
                      <FontAwesomeIcon icon={faLayerPlus} />
                    </button>
                  </div>
                }
                center
              />
            </GlobalStyles.TableRow>
          ))}
        </tbody>
      </GlobalStyles.DataTable>
      <GlobalStyles.DataTable style={{ margin: '3em auto 5em', width: 500 }}>
        <tbody>
          <GlobalStyles.TableRow>
            <TableCell style={{ fontSize: '1.1em', fontWeight: 500, color: theme.colors.comet }}>
              Storage Costs
            </TableCell>
            <TableCell
              center
              input={
                <StatusBoxStyled className="blue" style={{ width: 125, margin: 'auto' }}>
                  ${unitCost} / cubic foot
                </StatusBoxStyled>
              }
            />
          </GlobalStyles.TableRow>
          <GlobalStyles.TableRow>
            <TableCell style={{ fontSize: '1.1em', fontWeight: 500, color: theme.colors.comet }}>Total Cost</TableCell>
            <TableCell
              center
              input={
                <StatusBoxStyled className="purple" style={{ width: 125, margin: 'auto' }}>
                  ~${Math.round(totalCost)}
                </StatusBoxStyled>
              }
            />
          </GlobalStyles.TableRow>
        </tbody>
      </GlobalStyles.DataTable>
    </>
  )
}
