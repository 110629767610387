import React from 'react'
import { BackgroundLayer } from './styles'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'
import { OrderStatusBox } from '../../../components/component-items/status-box'

import { AvailabilityRowProps, AvailabilityCardProps } from './types'

const AvailabilityRow = ({
  bold,
  warehouse,
  onHand,
  onOrder,
  defective,
  production,
  available,
  inbound,
}: AvailabilityRowProps) => {
  return (
    <GlobalStyles.TableRow>
      <TableCell {...{ bold }}>{warehouse}</TableCell>
      <TableCell center {...{ bold }}>
        {onHand}
      </TableCell>
      <TableCell center {...{ bold }}>
        {onOrder}
      </TableCell>
      <TableCell center {...{ bold }}>
        {defective}
      </TableCell>
      <TableCell center {...{ bold }}>
        {production}
      </TableCell>
      <TableCell
        center
        input={
          <OrderStatusBox
            style={{ width: 100, margin: 'auto' }}
            status={available}
            status_id={available === '0' ? 70 : 200}
          />
        }
      />
      <TableCell center {...{ bold }}>
        {inbound}
      </TableCell>
    </GlobalStyles.TableRow>
  )
}

export const AvailabilityCard = ({ warehouses, availability }: AvailabilityCardProps) => {
  return (
    <BackgroundLayer style={{ display: 'block', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            Availability<small>{availability ? availability.available : 0} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {/* <GlobalStyles.Button style={{ height: 40, marginRight: '-0.5em' }} onClick={() => setOpen(true)}>Settings</GlobalStyles.Button> */}
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Warehouse</span>
              </th>
              <th>
                <span className="text center">On Hand</span>
              </th>
              <th>
                <span className="text center">On Order</span>
              </th>
              <th>
                <span className="text center">Defective</span>
              </th>
              <th>
                <span className="text center">Production</span>
              </th>
              <th>
                <span className="text center">Available</span>
              </th>
              <th>
                <span className="text center">Inbound</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {warehouses.map((w: any, id: number) => (
              <AvailabilityRow {...w} key={id} />
            ))}
            {availability ? <AvailabilityRow {...availability} bold /> : null}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
    </BackgroundLayer>
  )
}
