import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useFetch, usePost, isNormalInteger } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'
import { useParams } from 'react-router-dom'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import GenericModal from '../../../../components/component-items/modal'
import { TableCell } from '../../../../components/component-items/datatable'
import { Form, InputGroup } from 'react-bootstrap'
import { BackgroundLayer, ProductModalStyled } from './styles'
import CardLoading from '../../../../components/component-items/loading-popover'

import { BundleModalProps } from './types'

const BundleModal = ({ bundleItems, open, setOpen }: BundleModalProps) => {
  const productVariant = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()

  const node = useRef<HTMLInputElement>(null)
  const [productCount, setProductCount] = useState([])
  const [search, setSearch] = useState<string>('')
  const [added, setAdded] = useState<any>([])
  const resp: any = useFetch(`/api/product/variant/${productVariant}/bundle_list/?q=${encodeURIComponent(search)}`, {})

  const productSearch = resp.loaded ? resp.response.products : []

  useEffect(() => {
    const list: any = {}
    bundleItems.map((p: any) => (list[p.pId] = p))
    setAdded(list)
  }, [bundleItems])

  const buttons = (
    <>
      <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
        Close
      </GlobalStyles.Button>
      <GlobalStyles.Button
        style={{ minWidth: 125 }}
        onClick={() => usePost(`/api/product/variant/${productVariant}/bundles/`, { ...{ added, bundle: true } }, csrf)}
      >
        Update
      </GlobalStyles.Button>
    </>
  )

  return (
    <GenericModal heading={'Product Variant Settings'} show={open} onHide={() => setOpen(false)} buttons={buttons}>
      <ProductModalStyled
        onSubmit={(e) => {
          e.preventDefault()
          setSearch(String(node?.current?.value))
        }}
      >
        <div style={{ margin: '0 2em' }}>
          <h2 style={{ marginTop: '1em' }}>Bundled Items</h2>
          <GlobalStyles.DataTable style={{ boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px' }}>
            <thead>
              <tr>
                <th>
                  <span className="text"></span>
                </th>
                <th>
                  <span className="text">Product Variant</span>
                </th>
                <th>
                  <span className="text center">Quantity</span>
                </th>
                <th>
                  <span className="text center">Remove</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(added).map((a: any) => (
                <GlobalStyles.TableRow className={added[a].qty ? 'active' : ''} key={a}>
                  <TableCell
                    input={
                      <img
                        src={added[a].img}
                        alt="Product Variant"
                        className="product_image"
                        style={{ maxWidth: 50 }}
                      />
                    }
                  />
                  <TableCell
                    input={
                      <>
                        <Link to={`/product/variant/${added[a].pId}/`} rel="noreferrer" target="__none">
                          {added[a].sku}
                        </Link>
                        <div>{added[a].desc}</div>
                      </>
                    }
                  />
                  <TableCell center>
                    <Form.Control type="text" value={added[a].qty} style={{ width: 100, margin: 'auto' }} disabled />
                  </TableCell>
                  <TableCell
                    center
                    input={
                      <GlobalStyles.Button
                        className="secondary"
                        style={{ margin: 'auto' }}
                        onClick={() => {
                          const clone = added
                          delete clone[a]
                          setAdded({ ...clone })
                        }}
                      >
                        Remove
                      </GlobalStyles.Button>
                    }
                  />
                </GlobalStyles.TableRow>
              ))}
            </tbody>
          </GlobalStyles.DataTable>
          <h2 style={{ marginTop: '2em' }}>Add Items</h2>
          <div style={{ boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px' }}>
            <div className="search-bar" style={{ padding: 10 }}>
              <InputGroup>
                <Form.Control placeholder="Search" ref={node} />
                <GlobalStyles.Button type="submit" className="secondary" style={{ minWidth: 125, margin: 0 }}>
                  Search
                </GlobalStyles.Button>
              </InputGroup>
            </div>
            <div className="scrolltable">
              <GlobalStyles.DataTable>
                <thead>
                  <tr>
                    <th>
                      <span className="text"></span>
                    </th>
                    <th>
                      <span className="text">Product Variant</span>
                    </th>
                    <th>
                      <span className="text center">Quantity</span>
                    </th>
                    <th>
                      <span className="text center">Add</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productSearch.map((p: any) => {
                    const { img, pId, sku, desc } = p
                    const qty = productCount[p.pId] ? productCount[p.pId] : ''
                    return (
                      <GlobalStyles.TableRow className={qty ? 'active' : ''} key={pId}>
                        <TableCell
                          input={
                            <img src={img} alt="Product Variant" className="product_image" style={{ maxWidth: 50 }} />
                          }
                        />
                        <TableCell
                          input={
                            <>
                              <Link to={`/product/variant/${pId}/`} rel="noreferrer" target="__none">
                                {sku}
                              </Link>
                              <div>{desc}</div>
                            </>
                          }
                        />
                        <TableCell
                          input={
                            <Form.Control
                              type="text"
                              value={qty}
                              style={{ width: 100, margin: 'auto' }}
                              onChange={(e) => setProductCount({ ...productCount, [p.pId]: e.target.value } as any)}
                            />
                          }
                          center
                        />
                        <TableCell
                          input={
                            <GlobalStyles.Button
                              onClick={() => {
                                if (isNormalInteger(productCount[p.pId])) {
                                  setAdded({ ...added, [p.pId]: { ...p, qty: productCount[p.pId] } })
                                  const newCount = productCount
                                  delete newCount[p.pId]
                                  setProductCount({ ...newCount })
                                }
                              }}
                              style={{ color: 'white' }}
                            >
                              Add
                            </GlobalStyles.Button>
                          }
                        />
                      </GlobalStyles.TableRow>
                    )
                  })}
                </tbody>
              </GlobalStyles.DataTable>
            </div>
          </div>
        </div>
      </ProductModalStyled>
    </GenericModal>
  )
}

export const BundleCard = () => {
  const [open, setOpen] = useState(false)
  const productVariant = useParams<{ id: string }>().id

  const url = `/api/product/variant/${productVariant}/bundles/`
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})

  const bundleItems = loaded ? resp.bundleItems : []

  return (
    <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            Bundle Items<small>{bundleItems ? bundleItems.length : 0} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button style={{ height: 40, marginRight: '-0.5em' }} onClick={() => setOpen(true)}>
            Modify
          </GlobalStyles.Button>
          <BundleModal {...{ open, setOpen, bundleItems }} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text"></span>
              </th>
              <th>
                <span className="text">Product Variant</span>
              </th>
              <th>
                <span className="text center">Quantity</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {bundleItems.map(({ img, pId, sku, desc, qty }: any) => (
              <GlobalStyles.TableRow key={pId}>
                <TableCell
                  input={<img src={img} alt="Product Variant" className="product_image" style={{ maxWidth: 50 }} />}
                />
                <TableCell
                  input={
                    <>
                      <Link to={`/product/variant/${pId}/`} rel="noreferrer" target="__none">
                        {sku}
                      </Link>
                      <div>{desc}</div>
                    </>
                  }
                />
                <TableCell input={qty} center />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BackgroundLayer>
  )
}
