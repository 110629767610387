import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { globalState } from '../../../store'
import { useParams } from 'react-router-dom'
import { useFetch, usePost, isNormalInteger, useDelete, notify } from '../../../components/component-items/helpers'
import styled from 'styled-components'
import { toast } from 'react-toastify'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import CardLoading from '../../../components/component-items/loading-popover'
import GenericModal from '../../../components/component-items/modal'
import { TableCell } from '../../../components/component-items/datatable'
import { SearchBox } from '../../../components/component-items/search'
import { Form } from 'react-bootstrap'

// Enums
import { ShipNoticeStatus } from '../../../enums'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDolly, faTrashAlt, faEdit, faFileInvoiceDollar } from '@fortawesome/pro-duotone-svg-icons'

import { ProductListProps } from './types'

const ScrollTable = styled.div`
  height: 500px;
  overflow-y: auto;
  position: relative;
  padding: 0 1em;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bbc2e5;
    border-radius: 5px;
  }
`

export const AddAsnItem = ({
  products,
  open,
  setOpen,
}: {
  products: any[]
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const asn = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()

  const [loading, setLoading] = useState(false)
  const [productCount, setProductCount] = useState<any[]>([])
  const [search, setSearch] = useState<any>('')
  const [added, setAdded] = useState<any[]>([])
  const resp: any = useFetch(`/api/inventory/asn/${asn}/product_search/?q=${encodeURIComponent(search)}`, {})

  const productList = resp.loaded ? resp.response.products : []

  useEffect(() => {
    const added: any = {}
    const count: any = {}
    products.map((p) => {
      added[p.pId] = { ...p, qty: p.sent }
      count[p.pId] = p.sent
    })
    setAdded(added)
    setProductCount(count)
  }, [products])

  useEffect(() => {
    setLoading(!resp.loaded)
  }, [resp.loaded])

  const buttons = (
    <>
      <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
        Close
      </GlobalStyles.Button>
      <GlobalStyles.Button
        style={{ minWidth: 125 }}
        onClick={() => usePost(`/api/inventory/asn/${asn}/item/`, { ...{ added } }, csrf)}
      >
        Submit
      </GlobalStyles.Button>
    </>
  )

  const AddItem = (id: any, item: any) => {
    if (isNormalInteger(productCount[id])) {
      // @ts-ignore
      setAdded({ ...added, [id]: { ...item, qty: productCount[id] } })
    }
  }

  return (
    <GenericModal heading={'Add Products To ASN'} show={open} onHide={() => setOpen(false)} buttons={buttons}>
      <div style={{ margin: '0 2em' }}>
        {Object.keys(added).length ? (
          <>
            <h2 style={{ marginTop: '1em' }}>ASN Products</h2>
            <GlobalStyles.DataTable
              style={{ boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px', marginBottom: '2em', maxHeight: 500 }}
            >
              <thead>
                <tr>
                  <th>
                    <span className="text"></span>
                  </th>
                  <th>
                    <span className="text">Product Variant</span>
                  </th>
                  <th>
                    <span className="text center">Quantity</span>
                  </th>
                  <th>
                    <span className="text center">Remove</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(added).map((a: any, id: number) => (
                  <GlobalStyles.TableRow className="active" key={id}>
                    <TableCell
                      input={<img src={added[a].img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />}
                    />
                    <TableCell
                      input={
                        <Link to={`/product/variant/${added[a].pId}/`} rel="noreferrer" target="__none">
                          {added[a].sku}
                        </Link>
                      }
                    >
                      <div>{added[a].name}</div>
                    </TableCell>
                    <TableCell center>
                      <Form.Control
                        type="number"
                        min="1"
                        value={productCount[added[a].pId]}
                        style={{ width: 100, margin: 'auto' }}
                        onChange={(e) => {
                          setAdded({ ...added, [a]: { ...added[a], qty: e.target.value } })
                          setProductCount({ ...productCount, [a]: e.target.value })
                        }}
                      />
                    </TableCell>
                    <TableCell
                      center
                      input={
                        <GlobalStyles.Button
                          className="secondary"
                          style={{ margin: 'auto' }}
                          onClick={() => {
                            let clone = added
                            delete clone[a]
                            setAdded({ ...clone })
                            clone = productCount
                            delete clone[a]
                            setProductCount({ ...clone })
                          }}
                        >
                          Remove
                        </GlobalStyles.Button>
                      }
                    />
                  </GlobalStyles.TableRow>
                ))}
              </tbody>
            </GlobalStyles.DataTable>
            <h2>Add Products</h2>
          </>
        ) : null}
        <div style={{ boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px' }}>
          <div className="search-bar" style={{ padding: 10 }}>
            <SearchBox {...{ search, setSearch, loading, setLoading }} updateURL />
          </div>
          <ScrollTable>
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text"></span>
                  </th>
                  <th>
                    <span className="text">Product Variant</span>
                  </th>
                  <th>
                    <span className="text center">Quantity</span>
                  </th>
                  <th>
                    <span className="text center">Add</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {productList?.map((l: any, id: any) => (
                  <GlobalStyles.TableRow className={productCount[l.pId] ? 'active' : ''} key={id}>
                    <TableCell input={<img src={l.img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />} />
                    <TableCell
                      input={
                        <Link to={`/product/variant/${l.pId}/`} rel="noreferrer" target="__none">
                          {l.sku}
                        </Link>
                      }
                    >
                      <div>{l.name}</div>
                    </TableCell>
                    <TableCell
                      input={
                        <Form.Control
                          type="number"
                          value={productCount[l.pId] || ''}
                          style={{ width: 100, margin: 'auto' }}
                          onChange={(e) => setProductCount({ ...productCount, [l.pId]: e.target.value })}
                        />
                      }
                      center
                    />
                    <TableCell
                      center
                      input={
                        <GlobalStyles.Button
                          onClick={() => AddItem(l.pId, l)}
                          style={{ color: 'white', margin: 'auto' }}
                        >
                          Add
                        </GlobalStyles.Button>
                      }
                    />
                  </GlobalStyles.TableRow>
                ))}
              </tbody>
            </GlobalStyles.DataTable>
            {!resp.loaded ? <CardLoading text={resp.placeholder} error={resp.error} /> : null}
          </ScrollTable>
        </div>
      </div>
    </GenericModal>
  )
}

export const ProductList = ({ products, statusId, userIsWarehouse, warehouseId }: ProductListProps) => {
  const asn = useParams<{ id: string }>().id
  const total = products.length
  let history = useHistory()
  const [open, setOpen] = useState(false)
  const {
    state: { csrf, warehouseActive },
  } = globalState()

  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const inputElement = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus()
      inputElement.current.select()
    }
  }, [])

  useEffect(() => {
    const keyword = search.toLowerCase()
    const product = products.filter(
      (p) =>
        p.barcode?.toLowerCase() === keyword ||
        p.casepack_barcode?.map((barcode: any) => barcode.toLowerCase()).includes(keyword)
    )
    setLoading(false)
    if (inputElement.current) {
      inputElement.current.select()
    }
    if (product.length === 1) {
      if (statusId === ShipNoticeStatus.Received) {
        history.push(`item/${product[0].id}/non-compliance/`)
      } else {
        history.push(`item/${product[0].id}/confirm-details/`)
      }
    }
  }, [search])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Products<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {userIsWarehouse ? (
            <SearchBox {...{ search, setSearch, loading, setLoading, inputElement }} title={'Scan'} updateURL />
          ) : null}
          {statusId === ShipNoticeStatus.InTransit || (userIsWarehouse && statusId !== ShipNoticeStatus.Completed) ? (
            <GlobalStyles.Button onClick={() => setOpen(true)} style={{ width: 150, marginRight: 0 }}>
              Add Products
            </GlobalStyles.Button>
          ) : null}
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 100, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th></th>
              <th>
                <span className="text" style={{ textAlign: 'left' }}>
                  Product Variant
                </span>
              </th>
              <th>
                <span className="text center">Sent</span>
              </th>
              <th>
                <span className="text center">Received</span>
              </th>
              <th>
                <span className="text center">Bad</span>
              </th>
              <th>
                <span className="text center">Over/Short</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map(({ id, pId, img, name, sku, sent, received, defective, over_short }) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell center input={<img src={img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />} />
                <TableCell input={<Link to={`/product/variant/${pId}/`}>{sku}</Link>}>
                  <div>{name}</div>
                </TableCell>
                <TableCell input={sent} center />
                <TableCell input={received} center />
                <TableCell input={defective} center />
                <TableCell input={over_short > 0 ? `+${over_short}` : over_short} center />
                <TableCell center>
                  <div className="dropdown__container">
                    {statusId === ShipNoticeStatus.InTransit ? (
                      <>
                        <button onClick={() => setOpen(true)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button onClick={() => useDelete(`/api/inventory/asn/${asn}/item/${id}/`, csrf)}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </>
                    ) : userIsWarehouse && statusId < ShipNoticeStatus.Received ? (
                      <button onClick={() => history.push(`item/${id}/confirm-details/`)}>
                        <FontAwesomeIcon icon={faDolly} />
                      </button>
                    ) : userIsWarehouse && statusId === ShipNoticeStatus.Received ? (
                      <button onClick={() => history.push(`item/${id}/non-compliance/`)}>
                        <FontAwesomeIcon icon={faFileInvoiceDollar} />
                      </button>
                    ) : null}
                  </div>
                </TableCell>
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <AddAsnItem {...{ open, setOpen, products }} />
    </GlobalStyles.FullPageCard>
  )
}

export default ProductList
