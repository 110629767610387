import React, { useState } from 'react'
import { usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'
import InputGroup from 'react-bootstrap/InputGroup'

import { PauseOrderModalProps } from './types'

export const PauseOrderModal = ({ order, batched, pauseModal, setPauseModal }: PauseOrderModalProps) => {
  const [waiveFee, setWaiveFee] = useState(false)
  const {
    state: { csrf },
  } = globalState()
  return (
    <GenericModal
      heading={'Pause Order'}
      show={pauseModal}
      onHide={() => setPauseModal(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => usePut(`/api/order/${parseInt(order)}/mark_order_paused/`, { waive_fee: waiveFee }, csrf)}
        >
          Confirm
        </GlobalStyles.Button>
      }
    >
      {batched ? (
        <>
          <p style={{ fontSize: '1.25rem', textAlign: 'center' }}>
            Please confirm you wish to pause this order.
            <br />
            Due to the advanced status of this order there will be a $5.00 fee.
          </p>
          <div className="search-bar" style={{ padding: 10 }}>
            <InputGroup style={{ justifyContent: 'center' }}>
              <Checkbox selected={waiveFee} setSelected={setWaiveFee} />
              <p style={{ padding: '0 1em', lineHeight: '24px' }}>Waive $5.00 fee</p>
            </InputGroup>
          </div>
        </>
      ) : (
        <p style={{ fontSize: '1.25rem', textAlign: 'center' }}>Please confirm you wish to pause this order.</p>
      )}
    </GenericModal>
  )
}
