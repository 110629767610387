import { useParams } from 'react-router-dom'
import React from 'react'

// Components
import { useFetch } from '../../../components/component-items/helpers'
import CardLoading from '../../../components/component-items/loading-popover'
import { BillingStyled } from './styles'

export const Billing = () => {
  const params: any = useParams()
  const project = params?.id
  const { response, loaded, error, placeholder }: any = useFetch(
    `/api/inventory/project/${project}/transactions/`,
    {},
    60
  )
  const transactions = loaded ? response.transactions : null
  const total = loaded ? response.total : null
  return (
    <BillingStyled>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>Billing</h3>
        </div>
      </div>
      <div className="order-section">
        {transactions?.length ? (
          transactions.map((b: any, id: string | number) => (
            <div className="order-item" key={id}>
              <div>{b.description}</div>
              <div>${b.amount}</div>
            </div>
          ))
        ) : (
          <div className="order-item">
            <div>Minimum Project Fee</div>
            <div>$35.00</div>
          </div>
        )}
        <hr />
        <div className="order-item">
          <div>Total:</div>
          <div>${total}</div>
        </div>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BillingStyled>
  )
}
