import React from 'react'
import { BackgroundLayer } from './styles'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../../components/component-items/helpers'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import { OrderStatusBox, StatusBoxStyled } from '../../../../components/component-items/status-box'
import CardLoading from '../../../../components/component-items/loading-popover'

import { AvailabilityRowProps } from './types'

const AvailabilityRow = ({
  bold,
  warehouse,
  onHand,
  onOrder,
  defective,
  production,
  available,
  inbound,
}: AvailabilityRowProps) => {
  return (
    <GlobalStyles.TableRow>
      <TableCell {...{ bold }}>{warehouse}</TableCell>
      <TableCell center {...{ bold }}>
        <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={onHand === '0' ? 'red' : 'silver'}>
          {onHand}
        </StatusBoxStyled>
      </TableCell>
      <TableCell center {...{ bold }}>
        {onOrder}
      </TableCell>
      <TableCell center {...{ bold }}>
        {defective}
      </TableCell>
      <TableCell center {...{ bold }}>
        {production}
      </TableCell>
      <TableCell
        center
        input={
          <OrderStatusBox
            style={{ width: 75, margin: 'auto' }}
            status={available}
            status_id={available === '0' ? 14 : 200}
          />
        }
      />
      <TableCell center {...{ bold }}>
        {inbound}
      </TableCell>
      {/* <TableCell center children={projected} {...{ bold }} /> */}
    </GlobalStyles.TableRow>
  )
}

export const AvailabilityCard = () => {
  const productVariant = useParams<{ id: string }>().id
  const url = `/api/product/variant/${productVariant}/availability/`
  const { response: resp, error, loaded, placeholder }: any = useFetch(url, {})

  const warehouses = loaded ? resp.warehouses : []
  const availability = loaded ? resp.availability : []

  return (
    <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            Inventory Snapshot<small>{availability ? availability.available : 0} Available</small>
          </h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ width: '100%', overflowX: 'scroll' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Warehouse</span>
              </th>
              <th>
                <span className="text center">On Hand</span>
              </th>
              <th>
                <span className="text center">On Order</span>
              </th>
              <th>
                <span className="text center">Defective</span>
              </th>
              <th>
                <span className="text center">Production</span>
              </th>
              <th>
                <span className="text center">Available</span>
              </th>
              <th>
                <span className="text center">Inbound</span>
              </th>
              {/* <th><span className="text center">Projected</span></th> */}
            </tr>
          </thead>
          <tbody>
            {warehouses.map((w: any, id: number) => (
              <AvailabilityRow {...w} key={id} />
            ))}
            {availability ? <AvailabilityRow {...availability} bold /> : null}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BackgroundLayer>
  )
}
