import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-light-svg-icons'

import { AddressCardProps } from './types'
import CardLoading from '../component-items/loading-popover'

export const EditAddress = styled.span`
  position: absolute;
  top: 0.5em;
  right: 1em;
`

export const AddressCard: FunctionComponent<AddressCardProps> = ({
  address,
  setAddressModal,
  canEditAddress,
  loading,
  error,
  placeholder,
}) => {
  return (
    <div className="order-section">
      {loading ? (
        <CardLoading text={placeholder} error={error} />
      ) : (
        <>
          {address ? (
            <>
              <h3 style={{ paddingRight: 15 }}>
                {address.name}{' '}
                {canEditAddress ? (
                  <EditAddress>
                    <button onClick={() => setAddressModal(true)}>
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                  </EditAddress>
                ) : null}
              </h3>
              {address.business && <p>{address.business}</p>}
              <p>{address.line1 + ' ' + (address.line2 ? address.line2 : '')}</p>
              <p>{address.city + ', ' + address.state}</p>
              <p>{address.zip + ', ' + address.country}</p>
              <p>{address.email}</p>
            </>
          ) : (
            <>
              <h3>
                <EditAddress>
                  <button>
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                </EditAddress>
              </h3>
              <div className="return-empty">
                <div role="img" aria-label="celebrate">
                  📇{' '}
                </div>
                Empty Address
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
