import React, { useState, useEffect } from 'react'
import { useFetch, usePost } from '../../components/component-items/helpers'
import { globalState } from '../../store'

import { Form, Col, Row } from 'react-bootstrap'

// Components
import Pager, { Pages } from '../../components/component-items/pager'
import GlobalStyles from '../../components/component-items/styles'
import { StatusBoxStyled } from '../../components/component-items/status-box'
import CardLoading from '../../components/component-items/loading-popover'
import { TableCell, THSort } from '../../components/component-items/datatable'
import { SearchBox } from '../../components/component-items/search'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaste } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom'

import { StatsCardProps, ShipmentListProps } from './types'

export const ExitScanPage = () => (
  <>
    <ExitScan />
  </>
)

export const StatsCard = ({ exitScanned, remaining }: StatsCardProps) => {
  return (
    <div className="row">
      <div className="col-6">
        Labels Exited: <a href="/api/shipment/exit_scan_csv/?type=exited">{exitScanned}</a>
      </div>
      <div className="col-6">
        Labels Remaining: <a href="/api/shipment/exit_scan_csv/?type=remaining">{remaining}</a>
      </div>
    </div>
  )
}

export const ExitScan = () => {
  const [trackingNumbers, setTrackingNumbers] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [warnings, setWarnings] = useState([])
  const [errors, setErrors] = useState([])

  const {
    state: { csrf },
  } = globalState()

  const handleSubmit = async (event: any) => {
    setSubmitted(false)
    event.preventDefault()
    event.stopPropagation()

    await usePost('/api/shipment/exit_scan/', { ...{ trackingNumbers } }, csrf, false, true, true)
      .then((response) => {
        if (response) {
          setSubmitted(true)
          setWarnings(response.warnings)
          setErrors(response.errors)
        } else {
          throw Error('Process timed out - please re-submit!')
        }
      })
      .catch((err) => {
        // @ts-ignore
        setErrors((errors) => [...errors, err.message])
      })
  }

  const url = '/api/shipment/exit_scan/'
  const { response, loaded, error, placeholder }: any = useFetch(url, {})

  const exitScanned = response ? response.exit_scanned.toLocaleString() : '-'
  const remaining = response ? response.remaining.toLocaleString() : '-'

  return (
    <>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Exit Scan</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '50%' }}>
          {!errors.length && submitted ? (
            <StatusBoxStyled className={'green'} style={{ marginBottom: '2em' }}>
              {'Exit scan success!'}
            </StatusBoxStyled>
          ) : (
            <></>
          )}
          {warnings.map((message, index) => (
            <StatusBoxStyled key={index} className={'yellow'} style={{ marginBottom: '2em' }}>
              {message}
            </StatusBoxStyled>
          ))}
          {errors.map((message) => (
            <>
              <StatusBoxStyled className={'red'} style={{ marginBottom: '1em', textAlign: 'center' }}>
                {'NOTE: NOT ALL ORDERS HAVE BEEN EXIT SCANNED!'}
              </StatusBoxStyled>
              <StatusBoxStyled className={'red'} style={{ marginBottom: '2em', userSelect: 'text' }}>
                {message}
              </StatusBoxStyled>
            </>
          ))}
          <StatsCard {...{ exitScanned, remaining }} />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col} md="12" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Paste tracking numbers:</Form.Label>
                <Form.Control
                  value={trackingNumbers}
                  as="textarea"
                  rows={3}
                  onChange={(e) => setTrackingNumbers(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="2" className="mt-3">
                <GlobalStyles.Button type="submit" style={{ margin: 0, width: '100%', height: 40 }}>
                  Submit
                </GlobalStyles.Button>
              </Form.Group>
            </Row>
          </Form>
        </div>
        {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      </GlobalStyles.FullPageCard>
      <ShipmentList {...{ trackingNumbers, setTrackingNumbers }} />
    </>
  )
}

export const ShipmentList = ({ trackingNumbers, setTrackingNumbers }: ShipmentListProps) => {
  const {
    state: { csrf },
  } = globalState()

  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 50, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const [search, setSearch] = useState('')

  // Assign sort based on url params first
  const [sort, setSort] = useState({})

  const url =
    '/api/shipment/?range=' +
    rows +
    '&exited=true' +
    '&fulfillment=fulfilled' +
    '&page=' +
    (page - 1) +
    '&order_by=' +
    Object.keys(sort) +
    '&sort_row=' +
    Object.values(sort) +
    '&q=' +
    search
  const { response, error, loaded, placeholder }: any = useFetch(url, {})
  const shipments = loaded ? response.shipments : []
  const total = loaded ? response.total : 0
  var totalPages = loaded && total ? Math.ceil(total / rows) : 1

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(!loaded)
  }, [loaded])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Shipments <small>{total} Not Exit Scanned</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <SearchBox
            style={{ marginLeft: '1em' }}
            {...{ search, setSearch, loading, setLoading, page, setPage }}
            setFocus
            updateURL
          />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <GlobalStyles.DataTable>
        <thead>
          <tr>
            <THSort center title="Batch" sortKey={'order__batch__created'} {...{ sort, setSort }} />
            <THSort center title="Order" sortKey={'order__created'} {...{ sort, setSort }} />
            <THSort center title="Created" {...{ sort, setSort }} />
            <THSort center title="Carrier" sortKey={'label__service_level__carrier__name'} {...{ sort, setSort }} />
            <th>
              <span className="text">Tracking</span>
            </th>
            <th>
              <span className="text" style={{ textAlign: 'center' }}>
                Actions
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {shipments?.map(({ id, batch, order, created, carrier, tracking }: any) => (
            <GlobalStyles.TableRow key={id}>
              <TableCell center input={<Link to={`/batch/${batch}/`}>{batch}</Link>} />
              <TableCell center input={<Link to={`/order/${order}/`}>{order}</Link>} />
              <TableCell center input={created} />
              <TableCell
                center
                input={
                  <StatusBoxStyled style={{ width: 150, margin: 'auto' }} className="silver">
                    {carrier}
                  </StatusBoxStyled>
                }
              />
              <TableCell input={tracking} />
              <TableCell
                input={
                  <div className="dropdown__container">
                    <button onClick={() => setTrackingNumbers(trackingNumbers + (tracking + '\n'))}>
                      {' '}
                      <GlobalStyles.Tooltip data-title="Paste tracking number">
                        <FontAwesomeIcon icon={faPaste} />
                      </GlobalStyles.Tooltip>
                    </button>
                  </div>
                }
                center
              />
            </GlobalStyles.TableRow>
          ))}
        </tbody>
      </GlobalStyles.DataTable>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
