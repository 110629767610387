import React, { useState, useRef } from 'react'
import { useClick } from '../../component-items/helpers'

// Components
import { Styled } from './styles'
import { ToolbarQiuckActions } from '../panels'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/pro-duotone-svg-icons'

export const QuickActions = () => {
  const [isOpen, setOpen] = useState(false)
  const node = useRef()
  useClick(node, setOpen)
  return (
    <>
      <Styled.HeaderButton className={isOpen ? 'active' : ''} onClick={() => setOpen(!isOpen)} aria-haspopup="true">
        <span className={'header__topbar-icon'}>
          <i>
            <FontAwesomeIcon icon={faCog} />
          </i>
        </span>
      </Styled.HeaderButton>
      <ToolbarQiuckActions {...{ isOpen, node }} close={() => setOpen(false)} />
    </>
  )
}
