import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { HamburgerMenuProps } from './types'

const MenuStyled = styled.div<{
  active?: boolean
  theme: object
}>`
  display: flex;
  justify-content: flex-end;

  .aside__brand-aside-toggler {
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background: none;
    outline: none !important;
    width: 26px;
    height: 26px;
    transition: all 0.4s ease;

    span {
      display: block;
      position: absolute;
      top: 12px;
      height: 2px;
      min-height: 2px;
      width: 100%;
      border-radius: 2px;
      transition: all 0.4s ease;
      background: #4e4c5f;
    }

    span::before,
    span::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      min-height: 2px;
      content: '';
      border-radius: 2px;
      transition: all 0.4s ease;
      background: #4e4c5f;
    }

    span::before {
      top: -8px;
    }

    span::after {
      bottom: -8px;
    }

    &.aside__brand-aside-toggler--left {
      span:before {
        transition: all 0.4s ease;
        left: auto;
        right: 0px;
        width: ${(props) => (props.active ? '100%' : '50%')};
      }

      span:after {
        transition: all 0.4s ease;
        left: auto;
        right: 0px;
        width: ${(props) => (props.active ? '100%' : '75%')};
      }

      &:hover span:after {
        width: ${(props) => (props.active ? '75%' : '100%')};
        transition: all 0.4s ease;
      }
      &:hover span:before {
        width: ${(props) => (props.active ? '50%' : '100%')};
        transition: all 0.4s ease;
      }

      &.aside__brand-aside-toggler--active span:before {
        transition: all 0.4s ease;
        left: 0px;
        right: auto;
        width: 50%;
      }

      &.aside__brand-aside-toggler--active span:after {
        transition: all 0.4s ease;
        left: 0px;
        right: auto;
        width: 75%;
      }
    }

    &:hover {
      span,
      span::before,
      span::after {
        background: ${(props) => props.theme.colors.dodgerBlue};
      }
    }

    &.aside__brand-aside-toggler--active {
      span,
      span::before,
      span::after {
        background: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
`

export const HamburgerMenu: FunctionComponent<HamburgerMenuProps> = ({ active }) => (
  <MenuStyled {...{ active }}>
    <div className="aside__brand-aside-toggler aside__brand-aside-toggler--left">
      <span></span>
    </div>
  </MenuStyled>
)

export default HamburgerMenu
