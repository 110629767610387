import React from 'react'
import { toast } from 'react-toastify'

export * from './order'
export * from './asn'
export * from './return'
export * from './inventory'
export * from './product-variant'
export * from './billing'

export type FiltersProps = {
  id: number
  type: string
  result: {
    field_type: string
    field_operator: string
    field_value: any
    notification_to?: string
  }
  logical: (FiltersProps | any)[]
}

export const defaultFilters: FiltersProps = {
  id: 1,
  type: 'all_orders',
  result: {
    field_type: '',
    field_operator: '',
    field_value: '',
    notification_to: '',
  },
  logical: [{}, {}],
}

export type ConditionProps = {
  automationType: string
  filters: FiltersProps
  setFilters: (filters: FiltersProps) => void
  children?: React.ReactNode
  r: number
  g: number
  b: number
}

export type EventProps = {
  automationType: string
  event: any
  setEvent: (event: any) => void
}

export type AutomationPreviewProps = {
  open: boolean
  setOpen: (open: boolean) => void
  category: string
  condition: FiltersProps
  event: any
  action: any
}

export const AllFieldTypes = [
  { value: 'exact', label: 'is' },
  { value: 'exclude_exact', label: 'is not' },
  { value: 'istartswith', label: 'starts with' },
  { value: 'iendswith', label: 'ends with' },
  { value: 'lt', label: 'less than' },
  { value: 'lte', label: 'less than or equal' },
  { value: 'gt', label: 'greater than' },
  { value: 'gte', label: 'greater than or equal' },
  { value: 'icontains', label: 'contains' },
  { value: 'iregex', label: 'matches (regular exp)' },
  { value: 'isnull', label: 'is blank' },
  { value: 'exclude_isnull', label: 'is not blank' },
  { value: 'in', label: 'in (list)' },
]

export const StringFieldTypes = AllFieldTypes

export const NumberFieldTypes = AllFieldTypes

export const BoolFieldTypes = [{ value: 'exact', label: 'is' }]

export const DateFieldTypes = [
  { value: 'exact', label: 'is' },
  { value: 'exclude_exact', label: 'is not' },
  { value: 'lt', label: 'before' },
  { value: 'lte', label: 'before or on' },
  { value: 'gt', label: 'after' },
  { value: 'gte', label: 'after or on' },
  { value: 'isnull', label: 'is blank' },
  { value: 'exclude_isnull', label: 'is not blank' },
]

export const SendNotificationType = () => (
  <>
    <option value={''} disabled hidden>
      - Field Type -
    </option>
    <option value={'app'}>In App Order Note</option>
    <option value={'email'}>Email</option>
    {/* <option value={'slack'}>Slack</option> */}
    {/* <option value={'sms'}>SMS</option> */}
  </>
)

// TODO - refine - this is written in many areas with a different name
export const fetchResults = async (fetchURL: string) => {
  try {
    const response = await fetch(fetchURL)
    if (!response.ok) {
      toast('There was a problem fetching results', { type: 'error' })
      return []
    }
    const results = await response.json()
    return results
  } catch (error) {
    console.error('There was a problem fetching results:', error)
    throw error
  }
}

export const userList = async (inputValue: string) => {
  const URL = `/api/core/users/?search=${encodeURIComponent(inputValue)}`
  let result = await fetchResults(URL)
  console.log(result)
  return result['results'] ? result.results : result
}

export const productVariantListOptions = async (inputValue: string) => {
  const productURL = `/api/product/variant/?q=${encodeURIComponent(inputValue)}&deleted=false&duplicate=false`
  let result = await fetchResults(productURL)
  result = result['results'] ? result.results : result
  return result.map((option: any) => ({
    label: option.sku,
    value: option.id,
  }))
}