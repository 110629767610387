import styled, { keyframes } from 'styled-components'
import { ButtonGroup } from 'react-bootstrap'

import GlobalStyles from '../../../../components/component-items/styles'

export const BackgroundLayer = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
`

export const DateRangeStyled = styled.div`
  position: absolute;
  top: 22px;
  left: -12px;
  z-index: 10;
  overflow: scroll;
  background-color: white;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em;
  width: fit-content;
`

export const RecurringSchedule = styled(ButtonGroup)`
  button {
    padding: 0.5em 1.5em;
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 1rem;
    border-color: #001682;
    border-width: 2px;
    border-right: None;
    border-left: none;
    &:first-child {
      border-left: 2px solid #001682;
    }
    &:last-child {
      border-right: 2px solid #001682;
    }
    &:hover {
      border-color: #001682;
      background-color: #d9dffc;
      color: black;
    }
    &.active {
      border-color: #001682;
      background-color: #001682;
      color: white;
    }
  }
`

export const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
    min-width: 300px;
  }
`

// Keyframes for moving the gradient
const moveGradient = keyframes`
  0% {
    background-position: 0% 25%;
  }
  50% {
    background-position: 50% 25%;
  }
  100% {
    background-position: 0% 25%;
  }
`;


export const RoyalShineButton = styled(GlobalStyles.Button)`
  min-width: 125px;
  background: linear-gradient(45deg, #001682, #32a06c);
  background-size: 150% 150%;  // Important for allowing the gradient to move
  animation: ${moveGradient} 2s ease-in-out infinite;
`;

export const RecurringGroup = styled.div`
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SubHeader = styled.h4`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  padding-top: 1em;
`

export const RecurringSection = styled.div`
  border: 1px solid #c6c9e5;
  border-radius: 5px;
  padding: 25px 10px;
  background-color: #afb6da45;
`
