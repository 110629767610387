import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../../components/component-items/helpers'
import styled from 'styled-components'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import GenericModal from '../../../../components/component-items/modal'
import CardLoading from '../../../../components/component-items/loading-popover'
import ButtonDropdown from '../../../../components/component-items/button-dropdown'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { SearchBox } from '../../../../components/component-items/search'
import { TableCell, THSort } from '../../../../components/component-items/datatable'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

export const InventoryList = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  // Assign sort based on url params first
  const filterOptions = ['All', 'Picks', 'Puts', 'Packs']
  const [filter, setFilter] = useState('All')
  const [sort, setSort] = useState({})
  const [search, setSearch] = useState('')

  const batch = useParams<{ id: string }>().id
  const [loading, setLoading] = useState(true)

  const res: any = useFetch(
    `/api/inventory/batch/${batch}/inventory/` +
      `?filter=${filter}` +
      `&sort=${Object.values(sort)}` +
      `&q=${encodeURIComponent(search)}` +
      `&order_by=${Object.keys(sort)}`,
    {}
  )
  const resp = res.response
  const loaded = res.loaded

  useEffect(() => {
    setLoading(!res.loaded)
  }, [res.loaded])

  const inventoryTransactions = loaded ? resp.transactions : []

  return (
    <GenericModal
      heading={'Inventory Transactions'}
      show={open}
      size="xl"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Inventory Transactions List</h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar>
            <ButtonDropdown title={'Filter'} sort={filter} setSort={setFilter} options={filterOptions} />
            <SearchBox
              style={{ marginLeft: '1em' }}
              {...{ search, setSearch, loading, setLoading }}
              setFocus
              updateURL
            />
          </GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <div style={{ minHeight: 300, maxWidth: '100%' }}>
          <GlobalStyles.DataTable>
            <thead>
              <tr>
                <THSort center title={'ID'} {...{ sort, setSort }} />
                <THSort center title={'Order'} {...{ sort, setSort }} />
                <THSort center title={'Sku'} sortKey={'inventory_item__product_variant__sku'} {...{ sort, setSort }} />
                <THSort
                  center
                  title={'Barcode'}
                  sortKey={'inventory_item__product_variant__barcode'}
                  {...{ sort, setSort }}
                />
                <THSort
                  center
                  title={'Location'}
                  sortKey={'inventory_item__location__barcode'}
                  {...{ sort, setSort }}
                />
                <THSort center title={'Quantity'} {...{ sort, setSort }} />
                <THSort center title={'Pending'} {...{ sort, setSort }} />
                <THSort center title={'User'} sortKey={'user__first_name'} {...{ sort, setSort }} />
                <THSort center title={'Created'} {...{ sort, setSort }} />
                <THSort center title={'Updated'} {...{ sort, setSort }} />
              </tr>
            </thead>
            <tbody>
              {inventoryTransactions.map(
                ({ id, pending, order, sku, barcode, location, quantity, user, created, updated }: any) => (
                  <TableRow key={id}>
                    <TableCell center input={id}></TableCell>
                    <TableCell
                      center
                      input={
                        <Link to={`/order/${order}/`} style={{ maxWidth: 150 }}>
                          {order}
                        </Link>
                      }
                    ></TableCell>
                    <TableCell center input={sku}></TableCell>
                    <TableCell center input={barcode}></TableCell>
                    <TableCell center input={location}></TableCell>
                    <TableCell
                      center
                      input={
                        <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={pending ? 'purple' : 'green'}>
                          {quantity}
                        </StatusBoxStyled>
                      }
                    ></TableCell>
                    <TableCell
                      center
                      input={
                        pending ? (
                          <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={'purple'}>
                            Pending
                          </StatusBoxStyled>
                        ) : null
                      }
                    ></TableCell>
                    <TableCell center input={user}></TableCell>
                    <TableCell
                      center
                      input={
                        <StatusBoxStyled style={{ width: 150, margin: 'auto' }} className={'silver'}>
                          {new Date(created).toLocaleString()}
                        </StatusBoxStyled>
                      }
                    ></TableCell>
                    <TableCell
                      center
                      input={
                        <StatusBoxStyled style={{ width: 150, margin: 'auto' }} className={'silver'}>
                          {new Date(updated).toLocaleString()}
                        </StatusBoxStyled>
                      }
                    ></TableCell>
                  </TableRow>
                )
              )}
            </tbody>
          </GlobalStyles.DataTable>
        </div>
        {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
      </GlobalStyles.FullPageCard>
    </GenericModal>
  )
}
