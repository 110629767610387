import React, { useState } from 'react'
import { useFetch } from '../../../components/component-items/helpers'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Clipboard from 'react-clipboard-animation'

// Components
import { StatusBarStyled } from '../../../components/component-items/status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboardList,
  faShippingFast,
  faExclamationTriangle,
  faLayerGroup,
  faWarehouse,
} from '@fortawesome/pro-duotone-svg-icons'

export const StatusBar = () => {
  const order = useParams<{ id: string }>().id

  const res: any = useFetch(`/api/order/${order}/status/`, {}, 10)
  const loaded = res.loaded
  const resp = loaded ? res.response : {}
  const [copied, setCopied] = useState(false)

  const status = loaded ? resp.status : 'Processing'
  const statusId = loaded ? resp.status_id : 100
  const warehouse = loaded ? resp.warehouse : ''
  const freight = loaded ? resp.freight : false
  const customer = loaded ? resp.customer : ''
  const remoteNumber = loaded ? resp.remoteNumber : ''
  const batch = loaded ? resp.batch : null
  const slot = loaded ? resp.slot : null
  const company = loaded ? resp.company : ''
  const batchedBy = loaded ? resp.batched_by : ''
  return (
    <StatusBarStyled>
      <div className="header-item">
        <div className="status-icon">
          {statusId >= 100 && statusId < 200 ? (
            <FontAwesomeIcon icon={faClipboardList} style={{ color: '#5d78ff' }} />
          ) : statusId >= 200 ? (
            <FontAwesomeIcon icon={faShippingFast} style={{ color: '#00a58f' }} />
          ) : (
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
          )}
        </div>
        <div className="status-text">
          Packing Order {order}
          <br />
          {status}
          {freight && (
            <>
              <br />
              {customer}
              <br />
              {remoteNumber && (
                <div className="clipboard" style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '5px' }}>Remote ID: {remoteNumber}</span>
                  <Clipboard
                    copied={copied}
                    setCopied={setCopied}
                    text={remoteNumber.replaceAll('#', '')}
                    color="blue"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="border-line"></div>
      <div className="header-item center">
        <div className="status-icon">
          <FontAwesomeIcon icon={faLayerGroup} />
        </div>
        <div className="status-text">
          {batch ? (
            <>
              <div>
                Batched: <Link to={`/batch/${batch}/`}>{batch}</Link> - <strong>SLOT #{slot}</strong>
              </div>
              <div>{batchedBy ? ` ― by ${batchedBy}` : ''}</div>
            </>
          ) : (
            'Unbatched'
          )}
          <div>
            Company: <strong>{company}</strong>
          </div>
        </div>
      </div>
      <div className="border-line"></div>
      <div className="header-item right">
        <div className="status-text" style={{ paddingRight: '1em' }}>
          Facility:
        </div>
        <div className="status-text">
          <strong>{warehouse}</strong>
        </div>
        <div className="status-icon" style={{ marginLeft: '2em' }}>
          <FontAwesomeIcon icon={faWarehouse} />
        </div>
      </div>
    </StatusBarStyled>
  )
}
