import React from 'react'
import { Link } from 'react-router-dom'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { TableCell } from '../../../../components/component-items/datatable'
import { Form } from 'react-bootstrap'

import { ShipmentCardProps } from './types'

export const ShipmentCard = ({
  s_index,
  shipment_number,
  shipments,
  setShipments,
  total,
  order_items,
}: ShipmentCardProps) => {
  function updateOrderItem(index: number, item: any) {
    const new_shipments = [...shipments]
    const newItems = [...order_items]
    const new_item = newItems[index]
    new_item.serial_number = item
    newItems[index] = new_item
    new_shipments[s_index] = { ...new_shipments[s_index], order_items: newItems }
    setShipments(new_shipments)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Shipment #{shipment_number}
            <small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 200, padding: '1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text"></span>
              </th>
              <th>
                <span className="text">SKU &amp; Description</span>
              </th>
              <th>
                <span className="text center">Packed</span>
              </th>
              <th>
                <span className="text center">Serial Number</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {order_items.map((order_item: any, index: number) => {
              const { img, p_id, sku, description, packed, serial_number, has_serial_numbers } = order_item
              return has_serial_numbers ? (
                <GlobalStyles.TableRow key={index}>
                  <TableCell center>
                    <img src={img} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
                  </TableCell>
                  <TableCell input={<Link to={`/product/variant/${p_id}/`}>{sku}</Link>}>
                    <div>{description}</div>
                  </TableCell>
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={'silver'}>
                        {packed}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell>
                    <Form.Control
                      type="text"
                      placeholder="Serial Number"
                      name="sn"
                      value={serial_number}
                      onChange={(e) => updateOrderItem(index, e.target.value)}
                      required
                    />
                  </TableCell>
                </GlobalStyles.TableRow>
              ) : null
            })}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
    </GlobalStyles.FullPageCard>
  )
}
