import { LabelStyled } from './styles'

import React from 'react'
import { useFetch } from '../../../components/component-items/helpers'
import { useParams } from 'react-router-dom'

// Components
import ImageZoom from 'react-medium-image-zoom'
import CardLoading from '../../../components/component-items/loading-popover'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faPrint } from '@fortawesome/pro-duotone-svg-icons'

export const Slips = () => {
  const order = useParams<{ id: string }>().id

  const url = `/api/order/${order}/slips/`
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})

  const slips = loaded ? resp.slips : []
  const print = loaded ? resp.print : false

  const total = slips?.length || 0
  return (
    <LabelStyled>
      <CarouselProvider
        naturalSlideWidth={300}
        naturalSlideHeight={150}
        totalSlides={total}
        infinite={true}
        isPlaying={true}
      >
        <div className="datatable__header">
          <div className="datatable__title">
            <h3>Labels and Packslips</h3>
            <div>
              {print ? (
                <a href={print} style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={faPrint} />
                </a>
              ) : null}
              {slips?.length > 1 ? (
                <>
                  <ButtonBack>
                    <i>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </i>
                  </ButtonBack>
                  <ButtonNext>
                    <i>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </i>
                  </ButtonNext>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="order-section">
          {slips.length ? (
            <Slider>
              {slips.map((img: string, id: number) => (
                <Slide index={id} key={id}>
                  <ImageZoom>
                    <img src={img} alt="Slip" className="img" />
                  </ImageZoom>
                </Slide>
              ))}
            </Slider>
          ) : (
            <div className="no-label">
              <div role="img" aria-label="none">
                🏷
              </div>
              No Shipping Labels
            </div>
          )}
        </div>
      </CarouselProvider>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </LabelStyled>
  )
}
