import React, { useState, FunctionComponent } from 'react'
import { globalState } from '../../store'
import { usePost } from './helpers'
import styled from 'styled-components'
import GlobalStyled from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons'

import { ButtonLoadingProps, CardLoadingProps } from './types'

const CardLoadingStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .text {
    display: flex;
    position: relative;
    background: ${(props) => props.theme.colors.white};
    border-radius: 4px;
    padding: 8px 1em 8px 1em;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    &--error svg {
      color: ${(props) => props.theme.colors.mySin};
    }
  }
`

export const Spinner = styled.div`
  width: 20px;
  &::before {
    content: '';
    right: 15px;
    border: 2px solid #d0d0d0;
    border-top-color: #4e6bff;
    width: 20px;
    height: 20px;
    margin-top: -8px;
    animation: ${GlobalStyled.Spinner} 0.5s linear infinite;
    box-sizing: border-box;
    position: absolute;
    top: 15px;
    border-radius: 50%;
  }
`

export const CenteredSpinner = styled(Spinner)`
  &::before {
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export const ButtonLoading: FunctionComponent<ButtonLoadingProps> = ({
  url,
  data,
  title,
  setResponse,
  style,
  className,
  onSubmit,
  innerRef,
  dataPw,
}) => {
  const [loading, setLoading] = useState(false)
  const {
    state: { csrf },
  } = globalState()
  const handleSubmit = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    if (onSubmit) {
      await onSubmit()
    } else {
      const reload = setResponse ? false : true
      const resp = await usePost(url || '', data, csrf, reload, true, true)
      if (setResponse && resp.message) {
        setResponse(resp.message)
      } else if (setResponse && resp.error) {
        setResponse(resp.error)
      }
    }
    setLoading(false)
  }

  return !loading ? (
    <GlobalStyled.Button
      ref={innerRef}
      className={className || 'royal'}
      {...{ style }}
      onClick={() => handleSubmit()}
      {...(dataPw && { 'data-pw': dataPw })}
    >
      {title ? title : 'Submit'}
    </GlobalStyled.Button>
  ) : (
    <GlobalStyled.Button ref={innerRef} className={className || 'royal'} style={{ ...style, cursor: 'auto' }}>
      <CenteredSpinner />
      &nbsp;
    </GlobalStyled.Button>
  )
}

const CardLoading: FunctionComponent<CardLoadingProps> = ({ text, error }) => (
  <CardLoadingStyled>
    <div className={`text ${error ? 'text--error' : ''}`}>
      {error ? (
        <>
          <FontAwesomeIcon icon={faExclamationTriangle} /> Problem fetching data
        </>
      ) : (
        <>
          {text ? text : 'Processing...'}
          <Spinner />
        </>
      )}
    </div>
  </CardLoadingStyled>
)

export default CardLoading
