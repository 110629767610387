import React, { FunctionComponent } from 'react'
import { globalState } from '../../../store'
import { Link } from 'react-router-dom'

// Components
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { StatusBarStyled } from '../../../components/component-items/status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks, faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons'
import { faCircle } from '@fortawesome/pro-regular-svg-icons'

type StatusBarProps = {
  settings: any
}
export const StatusLoopHeader: FunctionComponent<StatusBarProps> = ({ settings }) => {
  const { api_key: apiKey, updated } = settings || {}
  const {
    state: { actAs, userCompany },
  } = globalState()
  return (
    <StatusBarStyled>
      <div className="header-item">
        <div className="status-icon">
          {apiKey ? (
            <FontAwesomeIcon icon={faCircle} style={{ color: 'rgb(43, 51, 255)' }} />
          ) : (
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
          )}
        </div>
        <div className="status-text">
          {apiKey ? (
            <>
              Added on{' '}
              <strong>
                {new Date(updated).toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: 'numeric' })}
              </strong>
            </>
          ) : (
            <>Please set up your API Key</>
          )}
        </div>
      </div>
      <div className="border-line"></div>
      <div className="header-item center">
        <div
          className="status-text"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
        >
          <strong>{actAs || userCompany}</strong>
          <strong>
            <StatusBoxStyled className={apiKey ? 'green' : 'red'} style={{ lineHeight: '.8em' }}>
              {apiKey ? 'Connected' : 'Disconnected'}
            </StatusBoxStyled>
          </strong>
        </div>
      </div>
      {apiKey && (
        <>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon icon={faTasks} />
            </div>
            <div
              className="status-text"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              <Link to={'/return/'}>
                <strong>{settings.number_of_returns || 0}</strong> Return Notices
              </Link>
            </div>
          </div>
        </>
      )}
    </StatusBarStyled>
  )
}
