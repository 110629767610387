import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useFetch, usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { StatusBarStyled } from '../../../components/component-items/status-bar'
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import Form from 'react-bootstrap/Form'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faClipboardList,
  faLayerGroup,
  faShippingFast,
  faWarehouse,
  faFire,
} from '@fortawesome/pro-duotone-svg-icons'

export const StatusBar = () => {
  const {
    state: { csrf },
  } = globalState()
  const order = useParams<{ id: string }>().id

  const url = `/api/order/${order}/status/`
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})

  const hot = loaded ? resp.hot : false
  const statusName = loaded ? resp.status : ''
  const statusId = loaded ? resp.status_id : ''
  const isMaturing = loaded ? resp.isMaturing : false
  const maturation = loaded ? resp.maturation : ''
  const lastUpdate = loaded ? resp.lastUpdate : ''
  const batch = loaded ? resp.batch : null
  const slot = loaded ? resp.slot : null
  const batchedBy = loaded ? resp.batched_by : ''
  const company = loaded ? resp.company : ''
  const alternateWarehouses = loaded ? resp.alternateWarehouses : []
  const warehouse = loaded ? resp.warehouse : ''
  const isWarehouse = loaded ? resp.isWarehouse : false

  const handleSkipMaturation = (event: React.FormEvent<HTMLFormElement | HTMLButtonElement | HTMLAnchorElement>) => {
    event.preventDefault()
    event.stopPropagation()
    usePut(`/api/order/${order}/skip_maturation/`, {}, csrf)
  }

  return (
    <Row className="hide-in-percy">
      <Col>
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              {hot ? (
                <FontAwesomeIcon icon={faFire} style={{ color: '#ff1900' }} />
              ) : statusId >= 90 && statusId < 200 ? (
                <FontAwesomeIcon icon={faClipboardList} style={{ color: '#5d78ff' }} />
              ) : statusId >= 200 ? (
                <FontAwesomeIcon icon={faShippingFast} style={{ color: '#00a58f' }} />
              ) : (
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
              )}
            </div>
            <div className="status-text">
              {statusName} <strong>{hot ? '- HOT' : ''}</strong>
              <br />
              {isMaturing ? (
                <span>
                  Matures in {maturation} -{' '}
                  <a onClick={(e) => handleSkipMaturation(e)} href="">
                    Skip
                  </a>
                </span>
              ) : (
                <span>
                  Last Update <strong>{lastUpdate}</strong>
                </span>
              )}
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon icon={faLayerGroup} />
            </div>
            <div className="status-text">
              {batch ? (
                isWarehouse ? (
                  <>
                    <div>
                      Batched: <Link to={`/batch/${batch}/`}>{batch}</Link> - <strong>SLOT #{slot}</strong>
                    </div>
                    <div>{batchedBy ? ` ― by ${batchedBy}` : ''}</div>
                  </>
                ) : (
                  'Batched'
                )
              ) : (
                'Unbatched'
              )}
              <div>
                Company: <strong>{company}</strong>
              </div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text" style={{ paddingRight: '1em' }}>
              Facility:
            </div>
            <div className="status-text">
              {alternateWarehouses?.length && !batch ? (
                <Form.Select
                  defaultValue={'default'}
                  onChange={(e) =>
                    usePut(`/api/order/${order}/change_warehouse/`, { warehouse_id: e.target.value }, csrf)
                  }
                >
                  <option value={'default'}>{warehouse}</option>
                  {alternateWarehouses.map((w: any, id: number) => (
                    <option value={w.id} key={id}>
                      {w.name}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <strong>{warehouse}</strong>
              )}
            </div>
            <div className="status-icon" style={{ marginLeft: '2em' }}>
              <FontAwesomeIcon icon={faWarehouse} />
            </div>
          </div>
          {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
        </StatusBarStyled>
      </Col>
    </Row>
  )
}
