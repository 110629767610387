import React from 'react'
import GlobalStyles from './styles'
import { useTheme } from 'styled-components'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons'

import { HoverNotesProps } from './types'

export const HoverNotes = ({ description, title }: HoverNotesProps) => {
  const theme = useTheme() as any
  return (
    <GlobalStyles.Tooltip className="subheader__button" data-title={description} style={{ lineHeight: '22px' }}>
      <span>
        {title}
        <FontAwesomeIcon style={{ color: theme.colors.freshEggplant, marginLeft: '5px' }} icon={faQuestionCircle} />
      </span>
    </GlobalStyles.Tooltip>
  )
}
