import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFetch, usePost, isNormalInteger, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'
import { TableCell } from '../../../components/component-items/datatable'
import { TableRow } from './styles'
import { Form, InputGroup, Col } from 'react-bootstrap'
import { SearchBox } from '../../../components/component-items/search'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/pro-duotone-svg-icons'

import { AddRuleProps } from './types'

export const AddRule = ({ editRule, packageList, open, setOpen, products }: AddRuleProps) => {
  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)
  const defaultRule = {
    name: '',
    rule_type: '1',
    packaging_id: '',
    blacklist: false,
    enabled: true,
    ignore_promo_items: false,
  }
  const [rule, setRule] = useState<any>(defaultRule)
  const {
    state: { csrf },
  } = globalState()

  useEffect(() => {
    if (Object.keys(editRule).length === 0 && editRule.constructor === Object) {
      setRule(defaultRule)
    } else {
      setRule(editRule)
    }
  }, [editRule])

  const [productCount, setProductCount] = useState<any>([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [added, setAdded] = useState<any>([])

  const resp: any = useFetch(open ? `/api/product/package-rule/search/?q=${encodeURIComponent(search)}` : '', {})
  const productList = resp.loaded ? resp.response.products : []
  const packaging_types = [...new Set(packageList.map(({ packaging_type }: any) => packaging_type))]
  const quantitiesEnabled = rule.rule_type === '3'

  useEffect(() => {
    setDefaults()
  }, [products])

  useEffect(() => {
    setLoading(!resp.loaded)
  }, [resp.loaded])

  useEffect(() => {
    setLoading(!resp.loaded)
  }, [rule])

  const AddItem = (id: string | number, item: any) => {
    if (!quantitiesEnabled || isNormalInteger(productCount[id])) {
      setAdded({ ...added, [id]: { ...item, qty: productCount[id] } })
    }
  }

  const setDefaults = () => {
    const added: any = {}
    const count: any = {}
    products.map((p: any, id: number) => {
      added[p.pId] = { ...p, qty: p.quantity }
      count[p.pId] = p.quantity
    })
    setAdded(added)
    setProductCount(count)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    if (rule.rule_type !== '1' && !Object.keys(added).length) {
      notify({ type: 'error', message: 'Items must be added for the seleted rule type' })
      return
    }
    usePost('/api/product/package-rule/details/', { ...rule, products: added }, csrf)
  }

  return (
    <GenericModal
      heading={rule.id ? 'Update Packaging Rule' : 'Create Packaging Rule'}
      show={open}
      size="lg"
      onHide={() => {
        setOpen(false)
        setDefaults()
      }}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>{rule.id ? 'Update' : 'Create'}</GlobalStyles.Button>}
    >
      <Form noValidate ref={node} validated={validated} style={{ margin: '2em' }}>
        <Form.Group as={Col} md="12" className="required">
          <InputGroup>
            <Checkbox selected={rule.enabled} setSelected={() => setRule({ ...rule, enabled: !rule.enabled })} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Enabled</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={rule.ignore_promo_items}
              setSelected={() => {
                setAdded({})
                setProductCount({})
                setRule({ ...rule, ignore_promo_items: !rule.ignore_promo_items })
              }}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Ignore Promo Items</p>
          </InputGroup>
        </Form.Group>
        {rule.id ? (
          <Form.Group as={Col} md="12">
            <Form.Label>Rule Name</Form.Label>
            <Form.Control
              type="text"
              value={rule.name}
              onChange={(e) => setRule({ ...rule, name: e.target.value })}
              disabled
            />
          </Form.Group>
        ) : null}
        <Form.Group as={Col} md="12" className="required">
          <Form.Label style={{ marginTop: rule.id ? '1em' : '0em' }}>Rule Type</Form.Label>
          <Form.Select
            value={rule.blacklist}
            onChange={(e) => {
              setAdded({})
              setProductCount({})
              setRule({ ...rule, blacklist: e.target.value === 'true' })
            }}
            required
          >
            <option value={'false'}>Suggest Packaging (Show as suggestion while packing order)</option>
            <option value={'true'}>Prevent Packaging (Error if rule applies while packing order)</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="12" className="required">
          <Form.Label style={{ marginTop: rule.id ? '1em' : '0em' }}>Rule Selection</Form.Label>
          <Form.Select
            value={rule.rule_type}
            onChange={(e) => {
              setAdded({})
              setProductCount({})
              setRule({ ...rule, rule_type: e.target.value })
            }}
            required
          >
            <option value="1">All Orders</option>
            <option value="2">Any SKU Match</option>
            <option value="3">Exact SKU &amp; Quantity Match</option>
            <option value="4">Not (SKU Match)</option>
          </Form.Select>
        </Form.Group>
        {rule.rule_type !== '1' ? (
          <div style={{ margin: '2em' }}>
            {Object.keys(added).length ? (
              <>
                <h2 style={{ marginTop: '1em' }}>Current Products</h2>
                <GlobalStyles.DataTable
                  style={{ boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px', marginBottom: '2em', maxHeight: 500 }}
                >
                  <thead>
                    <tr>
                      <th>
                        <span className="text"></span>
                      </th>
                      <th>
                        <span className="text">Product Variant</span>
                      </th>
                      {quantitiesEnabled ? (
                        <th>
                          <span className="text center">Quantity</span>
                        </th>
                      ) : null}
                      <th>
                        <span className="text center">Remove</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(added).map((a: any, id: number) => (
                      <GlobalStyles.TableRow className="active" key={id}>
                        <TableCell
                          input={<img src={added[a].img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />}
                        />
                        <TableCell
                          input={
                            <Link to={`/product/variant/${added[a].pId}/`} rel="noreferrer" target="__none">
                              {added[a].sku}
                            </Link>
                          }
                        >
                          <div>{added[a].name}</div>
                        </TableCell>
                        {quantitiesEnabled ? (
                          <TableCell center>
                            <Form.Control
                              type="number"
                              min="1"
                              value={productCount[added[a].pId]}
                              style={{ width: 100, margin: 'auto' }}
                              onChange={(e) => {
                                setAdded({ ...added, [a]: { ...added[a], qty: e.target.value } })
                                setProductCount({ ...productCount, [a]: e.target.value })
                              }}
                            />
                          </TableCell>
                        ) : null}
                        <TableCell
                          center
                          input={
                            <GlobalStyles.Button
                              type="button"
                              className="secondary"
                              style={{ margin: 'auto' }}
                              onClick={() => {
                                let clone = added
                                delete clone[a]
                                setAdded({ ...clone })
                                clone = productCount
                                delete clone[a]
                                setProductCount({ ...clone })
                              }}
                            >
                              Remove
                            </GlobalStyles.Button>
                          }
                        />
                      </GlobalStyles.TableRow>
                    ))}
                  </tbody>
                </GlobalStyles.DataTable>
                <h2>Add Products</h2>
              </>
            ) : null}
            <div style={{ boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px' }}>
              <div className="search-bar" style={{ padding: 10 }}>
                <SearchBox {...{ search, setSearch, loading, setLoading }} updateURL />
              </div>
              <GlobalStyles.ScrollTable>
                <GlobalStyles.DataTable>
                  <thead>
                    <tr>
                      <th>
                        <span className="text"></span>
                      </th>
                      <th>
                        <span className="text">Product Variant</span>
                      </th>
                      {quantitiesEnabled ? (
                        <th>
                          <span className="text center">Quantity</span>
                        </th>
                      ) : null}
                      <th>
                        <span className="text center">Add</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList
                      .filter((p: any) => !added[p.pId])
                      .filter((p: any) => !rule.ignore_promo_items || !p.promo)
                      .map((l: any, id: number) => (
                        <GlobalStyles.TableRow className={added[l.pId] ? 'active' : ''} key={id}>
                          <TableCell
                            input={<img src={l.img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />}
                          />
                          <TableCell
                            input={
                              <Link to={`/product/variant/${l.pId}/`} rel="noreferrer" target="__none">
                                {l.sku}
                              </Link>
                            }
                          >
                            <div>{l.name}</div>
                          </TableCell>
                          {quantitiesEnabled ? (
                            <TableCell
                              input={
                                <Form.Control
                                  type="number"
                                  value={productCount[l.pId] || ''}
                                  style={{ width: 100, margin: 'auto' }}
                                  onChange={(e) => setProductCount({ ...productCount, [l.pId]: e.target.value })}
                                />
                              }
                              center
                            />
                          ) : null}
                          <TableCell
                            center
                            input={
                              <GlobalStyles.Button
                                type="button"
                                onClick={() => AddItem(l.pId, l)}
                                style={{ color: 'white', margin: 'auto' }}
                              >
                                Add
                              </GlobalStyles.Button>
                            }
                          />
                        </GlobalStyles.TableRow>
                      ))}
                  </tbody>
                </GlobalStyles.DataTable>
                {!resp.loaded ? <CardLoading text={resp.placeholder} error={resp.error} /> : null}
              </GlobalStyles.ScrollTable>
            </div>
          </div>
        ) : null}
        <Form.Group as={Col} md="12" className="required">
          <Form.Label style={{ marginTop: '1em' }}>Package</Form.Label>
          <Form.Select
            value={rule.packaging_id}
            onChange={(e) => setRule({ ...rule, packaging_id: e.target.value })}
            required
          >
            {/* @ts-ignore */}
            <option value="" default hidden>
              -- SELECT --
            </option>
            {packaging_types.map((type) => (
              // @ts-ignore
              <optgroup label={type} key={type}>
                {packageList
                  .filter((p: any) => p.packaging_type === type)
                  .map((p: any, id: number) => (
                    <option value={p.id} key={id}>
                      {p.sku}
                    </option>
                  ))}
              </optgroup>
            ))}
          </Form.Select>
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

export const PackagingRules = () => {
  const {
    state: { csrf },
  } = globalState()
  const { response: resp, loaded, error, placeholder }: any = useFetch('/api/product/package-rule/', {})

  const rules = loaded ? resp.rules : []
  const packageList = loaded ? resp.package_list : []
  const total = loaded ? resp.total : 0

  const [open, setOpen] = useState(false)
  const [editRule, setRule] = useState({})
  const [products, setProducts] = useState([])

  const deleteVariant = (id: number | string) => {
    if (confirm('Are you sure you want to delete?')) {
      usePost('/api/product/package-rule/details/', { id: id, delete: true }, csrf)
    }
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Packaging Rule List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button
            onClick={() => {
              setRule({})
              setOpen(true)
            }}
          >
            Add Packaging Rule
          </GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text"></span>
              </th>
              <th>
                <span className="text">Description</span>
              </th>
              <th>
                <span className="text">Packaging</span>
              </th>
              <th>
                <span className="text center">Items</span>
              </th>
              <th>
                <span className="text center">Enabled</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {rules.map(({ id, name, image, packaging, packaging_id, items, rule_name, enabled, blacklist }: any) => (
              <TableRow key={id}>
                <TableCell input={<img src={image} style={{ maxWidth: 50 }} />} />
                <TableCell input={name} />
                <TableCell input={<Link to={`/product/variant/${packaging_id}/`}>{packaging}</Link>} />
                <TableCell>
                  {items.length ? (
                    <div
                      style={{
                        maxHeight: 200,
                        overflow: 'scroll',
                        boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px',
                      }}
                    >
                      <GlobalStyles.DataTable>
                        <tbody>
                          {items.map(({ pId, image, sku, name, quantity }: any) => (
                            <TableRow key={pId}>
                              <TableCell
                                center
                                input={<img src={image} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />}
                              />
                              <TableCell input={<Link to={`/product/variant/${pId}/`}>{sku}</Link>}>
                                <div>{name}</div>
                              </TableCell>
                              <TableCell input={quantity} center />
                            </TableRow>
                          ))}
                        </tbody>
                      </GlobalStyles.DataTable>
                    </div>
                  ) : null}
                </TableCell>
                <TableCell
                  center
                  input={
                    <Form.Check
                      type="switch"
                      id={id}
                      label={enabled ? 'Enabled' : 'Disabled'}
                      checked={enabled}
                      onChange={() =>
                        usePost('/api/product/package-rule/details/', { id: id, enable_disable: true }, csrf)
                      }
                    />
                  }
                />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container">
                      <button
                        onClick={() => {
                          const rule = rules.find((r: any) => r.id === id)
                          setRule(rule)
                          setProducts(rule.items)
                          setOpen(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={() => deleteVariant(id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  }
                />
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      <AddRule {...{ open, setOpen, rules, editRule, packageList, products }} />
    </GlobalStyles.FullPageCard>
  )
}
