import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

export const theme = {
  colors: {
    bbq: '#a05447',
    black: '#000',
    bloodOrange: '#EF7626',
    catskillWhite: '#f2f9f8',
    comet: '#5a5d6f',
    crimson: '#e31414',
    cornflowerBlue: '#5578eb',
    dodgerBlue: '#5D78FF',
    dodgerBlueLight: '#3758FF',
    darkPurple: '#4B0092',
    periwinkleGray: '#bbc2e5',
    doveGray: '#6B6B6B',
    emeraldGreen: 'rgb(29,201,183)',
    gallery: '#EEE',
    ghostWhite: '#eef1ff',
    gray: '#838383',
    greenishCyan: '#34BFA3',
    freshEggplant: '#800080',
    java: '#1DC9B7;',
    javaBlue: '#1DA9C9;',
    lightOrange: '#FFC20A',
    linkWater: '#F6F8FD',
    neonGreen: '#39ff14',
    midGray: '#595d6e',
    mulledWine: '#4a485d',
    mySin: '#FFB822',
    perwinkleBlue: '#567EFA',
    radicalRed: '#FD397A',
    raven: '#74788d',
    riverBed: '#48465b',
    tealishBlue: '#636B9E',
    smoky: '#5D5B6F',
    white: '#FFF',
    whiteLilac: '#f7f8fa',
    neonYellow: '#FEFE62',
    zircon: '#F0F3FF',
  },
}

const GlobalStyle = createGlobalStyle`
  :root {
    --toastify-icon-color-info: ${theme.colors.darkPurple};
  }

  // react-medium-image-zoom with slider
  [data-rmiz] {
    width: 100%;
    height: 100%;
  }
`

export const ShippingTreeTheme = ({ children }: any) => (
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </>
)
