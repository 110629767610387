import React, { useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useFetch, usePost, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { TableCell } from '../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import CardLoading from '../../../components/component-items/loading-popover'
import { Form } from 'react-bootstrap'
import { Tooltip } from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPrint, faBoxCheck, faExternalLink, faPlayCircle } from '@fortawesome/pro-duotone-svg-icons'

export const AddRun = ({ open, setOpen, users, remainingProdQty }: any) => {
  const node = useRef<HTMLFormElement | null>(null)
  const [validated, setValidated] = useState(false)
  const {
    state: { csrf },
  } = globalState()

  const params: any = useParams()
  const project = params.id
  const defaultRun = {
    user: '',
    qty: 0,
    area: '',
  }
  const [productionRun, setProductionRun] = useState(defaultRun)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      console.log('checkValidity')
    } else if (productionRun.qty > remainingProdQty) {
      notify({ type: 'error', message: "Trying to create more than what's available" })
    } else if (productionRun.qty < 1) {
      notify({ type: 'error', message: 'Quantity must be greater than 0' })
    } else {
      usePost(`/api/inventory/project/${project}/production-run/`, { ...productionRun }, csrf)
    }
  }

  return (
    <GenericModal
      heading={'Add Production Run'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>Add Production Run</GlobalStyles.Button>}
    >
      <Form ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '2em' }}>
          <Form.Label>User</Form.Label>
          <Form.Select
            required
            value={productionRun.user}
            onChange={(e) => setProductionRun({ ...(productionRun || []), user: e.target.value })}
          >
            <option value={''} disabled hidden>
              -User-
            </option>
            {users?.map((u: any) => (
              <option value={u.id} key={u.id}>
                {u.first_name} {u.last_name}
              </option>
            ))}
          </Form.Select>
          <Form.Label style={{ marginTop: '1em' }}>QTY (Max {remainingProdQty})</Form.Label>
          <Form.Control
            type="number"
            required
            value={productionRun.qty}
            onChange={(e) => setProductionRun({ ...productionRun, qty: Number(e.target.value) })}
          />
          <Form.Label style={{ marginTop: '1em' }}>Area</Form.Label>
          <Form.Control
            type="text"
            value={productionRun.area}
            onChange={(e) => setProductionRun({ ...productionRun, area: e.target.value })}
          />
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

export const ProductionRuns = ({ users }: any) => {
  const params: any = useParams()
  const project = params.id
  const { response, loaded, error, placeholder }: any = useFetch(
    `/api/inventory/project/${project}/production_runs/`,
    {},
    60
  )
  const productionRuns = loaded ? response.productionRuns : null
  const remainingProdQty = loaded ? response.remainingProdQty : null
  const statusId = loaded ? response.statusId : null
  const total = productionRuns?.length
  const [open, setOpen] = useState(false)
  const {
    state: { csrf },
  } = globalState()

  function handleDelete(url: string) {
    if (window.confirm('Are you sure you want to delete this production run?')) {
      usePost(url, { delete: true }, csrf)
    }
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Production Runs<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {statusId < 200 ? (
            <GlobalStyles.Button onClick={() => setOpen(true)}>Add More Runs</GlobalStyles.Button>
          ) : null}
          <AddRun {...{ open, setOpen, users, remainingProdQty }} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 100, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Project Details</span>
              </th>
              <th>
                <span className="text center">Order</span>
              </th>
              <th>
                <span className="text center">ASN</span>
              </th>
              <th>
                <span className="text center">Requested</span>
              </th>
              <th>
                <span className="text center">Completed</span>
              </th>
              <th>
                <span className="text center">Status</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {productionRuns?.map(({ id, area, started, completed, userId, orderId, asnId, qty, received }: any) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell>
                  <Form.Control type="text" value={area} disabled style={{ marginBottom: '1em' }} />
                  <Form.Select
                    value={userId}
                    onChange={(e) =>
                      usePost(
                        `/api/inventory/project/${project}/production-run/${id}/details/`,
                        { change_user: e.target.value },
                        csrf
                      )
                    }
                  >
                    <option value={''} disabled hidden>
                      -User-
                    </option>
                    {users?.map((u: any) => (
                      <option value={u.id} key={u.id}>
                        {u.first_name} {u.last_name}
                      </option>
                    ))}
                  </Form.Select>
                </TableCell>
                <TableCell input={<div className="dropdown__container">{orderId ? orderId : null}</div>} center />
                <TableCell
                  input={
                    <div className="dropdown__container">
                      {asnId ? (
                        <Link to={`/asn/${asnId}/receive/`} rel="noreferrer" target="__blank">
                          <button>
                            <FontAwesomeIcon icon={faExternalLink} />
                          </button>
                        </Link>
                      ) : null}
                    </div>
                  }
                  center
                />
                <TableCell input={<StatusBoxStyled className={'silver'}>{qty}</StatusBoxStyled>} center />
                <TableCell
                  input={
                    started ? (
                      <StatusBoxStyled className={received > 0 ? 'green' : 'blue'}>{received}</StatusBoxStyled>
                    ) : null
                  }
                  center
                />
                <TableCell
                  input={
                    !started ? (
                      <StatusBoxStyled className={'yellow'}>{'Pending'}</StatusBoxStyled>
                    ) : !completed ? (
                      <StatusBoxStyled className={'blue'}>In&nbsp;Progress</StatusBoxStyled>
                    ) : (
                      <StatusBoxStyled className={'green'}>{'Completed'}</StatusBoxStyled>
                    )
                  }
                  center
                />
                <TableCell center style={{ width: 75 }}>
                  <div className="dropdown__container">
                    {!started && statusId > 100 ? (
                      <Tooltip data-title="Start Production Run">
                        <button
                          onClick={() =>
                            usePost(
                              `/api/inventory/project/${project}/production-run/${id}/details/`,
                              { start: true },
                              csrf
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faPlayCircle} />
                        </button>
                      </Tooltip>
                    ) : started && !completed ? (
                      <>
                        <Tooltip data-title="Print Picklist">
                          <a href={`/api/order/${orderId}/picklist/`} rel="noreferrer" target="__blank">
                            <button>
                              <FontAwesomeIcon icon={faPrint} />
                            </button>
                          </a>
                        </Tooltip>
                        <Tooltip data-title="Complete Production Run">
                          <button
                            onClick={() =>
                              usePost(
                                `/api/inventory/project/${project}/production-run/${id}/details/`,
                                { complete: true },
                                csrf
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faBoxCheck} />
                          </button>
                        </Tooltip>
                        <Tooltip data-title="Delete Production Run">
                          <button
                            onClick={() =>
                              handleDelete(`/api/inventory/project/${project}/production-run/${id}/details/`)
                            }
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </Tooltip>
                      </>
                    ) : null}
                  </div>
                </TableCell>
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}

export default ProductionRuns
