// React
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
// Helpers
import { StickyElement, useFetch } from '../../../../components/component-items/helpers'
// Components
import CardLoading from '../../../../components/component-items/loading-popover'
import GlobalStyles from '../../../../components/component-items/styles'
import { OrderOptions } from '../../../orders/order/styles'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'
import { AsnOption } from './item-helpers'

// Modals
import { PrintLabelsModal } from '../../../../components/modals/print-labels'

// Cards
import { ReceivingWorkflow } from './receiving-workflow'
import { CasePackCard } from '../../../products/product/variant/case-pack-card'
import { ProductVariantSpecifications } from '../../../products/product/variant/specifications-card'
import { ReceivingHistory } from './receiving-history'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faDolly, faPrint, faWarehouse } from '@fortawesome/pro-duotone-svg-icons'

import { StatusBarProps } from './types'

const StatusBar: FunctionComponent<StatusBarProps> = ({
  asn,
  name,
  warehouse,
  sent,
  received,
  company,
  loaded,
  error,
}) => {
  return (
    <Row className="hide-in-percy">
      <Col>
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              <FontAwesomeIcon icon={faDolly} style={{ color: '#5d78ff' }} />
            </div>
            <div className="status-text">
              Receiving Inventory - {name}
              <br />
              {received} / {sent} received
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon style={{ color: '#FFB822' }} icon={faClipboardList} />
            </div>

            <div className="status-text">
              Processing ASN {asn}
              <div>
                Company: <strong>{company}</strong>
              </div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text" style={{ paddingRight: '1em' }}>
              Facility:
            </div>
            <div className="status-text">
              <strong>{warehouse}</strong>
            </div>
            <div className="status-icon" style={{ marginLeft: '2em' }}>
              <FontAwesomeIcon icon={faWarehouse} />
            </div>
          </div>
          {!loaded ? <CardLoading error={error} /> : null}
        </StatusBarStyled>
      </Col>
    </Row>
  )
}

export const ConfirmDetails = () => {
  const asn = location.pathname.split('/')[2]
  const { shipnotice_item_id: itemId } = useParams<{ shipnotice_item_id: string }>()
  const { response: item, error, loaded }: any = useFetch(`/api/inventory/asn/${asn}/item/${itemId}/`, {})
  const [printModal, setPrintModal] = useState(false)
  const [openReceivingHistoryModal, setReceivingHistoryModal] = useState(false)
  const [variantIsConfirmed, setVariantIsConfirmed] = useState(false)

  // Page variables
  const shipnotice = loaded ? item.shipnotice : null
  const isProject = loaded ? shipnotice.project_asn : false

  const variant = loaded ? item.product_variant : null
  const { company, name, barcode } = loaded ? variant : ''

  const variantId = loaded ? item.product_variant?.id : ''
  const sent = loaded ? parseInt(item.quantity) : 0
  const good = loaded ? item.good : 0
  const defective = loaded ? item.defective : 0
  const warehouse = loaded ? item.warehouse : ''
  const current = 'confirm_details'

  useEffect(() => {
    if (variant) {
      setVariantIsConfirmed(
        variant.sku &&
          variant.barcode &&
          variant.height > 0 &&
          variant.width > 0 &&
          variant.length > 0 &&
          variant.weight > 0 &&
          variant.price > 0
      )
    }
  }, [loaded])

  return (
    <>
      <StatusBar
        asn={asn}
        name={name}
        sent={sent}
        received={good + defective}
        warehouse={warehouse}
        company={company}
        loaded={loaded}
        error={error}
      />
      <Row>
        <Col lg={3} xl={2}>
          <StickyElement>
            <OrderOptions>
              <ul>
                <GlobalStyles.Tooltip data-title="Print formatted labels given scancode">
                  <AsnOption onClick={() => setPrintModal(true)} icon={faPrint} title={'Print Labels'} />
                </GlobalStyles.Tooltip>
                {/* @ts-ignore */}
                <PrintLabelsModal open={printModal} setOpen={setPrintModal} id={variantId} scancode={barcode} />
                <GlobalStyles.Tooltip data-title="Displays list of all inventory transactions">
                  <AsnOption
                    onClick={() => setReceivingHistoryModal(true)}
                    icon={faClipboardList}
                    title={'Receiving History'}
                  />
                </GlobalStyles.Tooltip>
                <ReceivingHistory
                  open={openReceivingHistoryModal}
                  setOpen={setReceivingHistoryModal}
                  inventoryTransactions={item?.inventory_transactions}
                />
              </ul>
            </OrderOptions>
          </StickyElement>
        </Col>
        <Col xl={7}>
          {loaded && <ProductVariantSpecifications id={variantId} />}
          {loaded && <CasePackCard id={variantId} />}
          {!loaded ? <CardLoading error={error} /> : null}
        </Col>
        <Col xl={3}>
          <ReceivingWorkflow
            asn={asn}
            current={current}
            variantIsConfirmed={variantIsConfirmed}
            itemId={itemId}
            isProject={isProject}
          />
          {!loaded ? <CardLoading error={error} /> : null}
        </Col>
      </Row>
    </>
  )
}
