// React
import React, { FunctionComponent, useState } from 'react'
// Components
import styled from 'styled-components'
import { TableCell } from '../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import GlobalStyles from '../../../components/component-items/styles'
// Modals
import GenericModal from '../../../components/component-items/modal'

import { ComplianceHistoryProps } from './types'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

export const ComplianceHistory: FunctionComponent<ComplianceHistoryProps> = ({ open, setOpen, compliance }) => {
  const [sort, setSort] = useState({})
  return (
    <GenericModal
      heading={'Compliance History'}
      show={open}
      size="xl"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Compliance Fees</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <GlobalStyles.DataTable style={{ minHeight: 140 }}>
          <thead>
            <tr>
              <th>
                <span className="text center">User</span>
              </th>
              <th>
                <span className="text center">Item</span>
              </th>
              <th>
                <span className="text center">Category</span>
              </th>
              <th>
                <span className="text center">Labor (min)</span>
              </th>
              <th>
                <span className="text center">Material ($)</span>
              </th>
              <th>
                <span className="text center">Notes</span>
              </th>
              <th>
                <span className="text center">Total Cost ($)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {compliance?.complianceList?.map((comp: any, id: number) => (
              <TableRow key={id}>
                <TableCell center input={comp.name}></TableCell>
                <TableCell center input={comp.variant ? comp.variant : 'ASN'}></TableCell>
                <TableCell center input={comp.category}></TableCell>
                <TableCell center input={comp.labor}></TableCell>
                <TableCell center input={comp.materials}></TableCell>
                <TableCell center input={comp.notes}></TableCell>
                <TableCell center input={comp.amount}></TableCell>
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </GlobalStyles.FullPageCard>
    </GenericModal>
  )
}
