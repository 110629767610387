import React from 'react'
import styled from 'styled-components'

// Styled container for positioning
const RadioContainer = styled.div`
  display: inline-block;
  margin: 10px;
  height: 18px;
  width: 18px;
`

// Hidden radio input
const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;

  &:checked + label::after {
    opacity: 1; // Show the inner circle only when checked
  }
`

// Styled label
const StyledLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px; // Hide the label text
  width: 18px;
  height: 18px;
  background-color: #f8f9fa;
  border: 2px solid #50566d;
  border-radius: 50%;
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: 2px;
    background-color: #50566d;
    border-radius: 50%;
    opacity: 0; // Initially hidden
    transition: opacity 0.2s;
  }

  &:hover::after {
    opacity: 0.5; // Half-visible on hover for an indication that it's selectable
  }
`

export const RadioButton = ({ className, checked, ...props }: any) => {
  return (
    <RadioContainer className={className}>
      <HiddenRadio checked={checked} {...props} />
      <StyledLabel htmlFor={props.id} />
    </RadioContainer>
  )
}
