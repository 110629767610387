import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { TableCell } from '../../../../components/component-items/datatable'
import { Form, Col, Row } from 'react-bootstrap'

import { ShipmentCardProps } from './types'

export const ShipmentCard = ({
  sIndex,
  shipment_number,
  shipments,
  setShipments,
  total,
  no_packaging,
  order_items,
  packaging_items,
  weight,
  length,
  width,
  height,
}: ShipmentCardProps) => {
  const [unit, setUnit] = useState('lb')

  function updateShipment(dimension: any, value: any) {
    const newShipments = [...shipments]
    newShipments[sIndex] = { ...newShipments[sIndex], [dimension]: value }
    setShipments(newShipments)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Shipment #{shipment_number}
            <small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <Form noValidate style={{ minHeight: 200, padding: '1em' }}>
        <Row style={{ padding: '.5em 1em 0' }}>
          <Form.Group as={Col} lg="12" xl="4">
            <Row>
              <Form.Group as={Col} md="8" controlId="scan" className="required">
                <Form.Label>Weight</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Weight"
                  name="scan"
                  value={unit === 'oz' ? weight : Math.round((weight / 16) * 100) / 100}
                  onChange={(e) =>
                    updateShipment('weight', unit === 'lb' ? Number(e.target.value) * 16 : e.target.value)
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">Please provide a valid weight</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Unit</Form.Label>
                <Form.Select value={unit} onChange={(e) => setUnit(e.target.value)} required>
                  <option value={'oz'}>OZ</option>
                  <option value={'lb'}>LB</option>
                </Form.Select>
              </Form.Group>
            </Row>
          </Form.Group>
          <Form.Group as={Col} lg="12" xl="8">
            <Row>
              <Form.Group as={Col} md="4" controlId="qty" className="required">
                <Form.Label>Length (in)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Length"
                  name="length"
                  value={length}
                  onChange={(e) => updateShipment('length', e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">Please provide a valid height</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="qty" className="required">
                <Form.Label>Width (in)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Width"
                  name="width"
                  value={width}
                  onChange={(e) => updateShipment('width', e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">Please provide a valid height</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="qty" className="required">
                <Form.Label>Height (in)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Height"
                  name="height"
                  value={height}
                  onChange={(e) => updateShipment('height', e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">Please provide a valid height</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="7">
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text"></span>
                  </th>
                  <th>
                    <span className="text">SKU &amp; Description</span>
                  </th>
                  <th>
                    <span className="text center">Ordered</span>
                  </th>
                  <th>
                    <span className="text center">Packed</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {order_items.map(({ img, p_id, sku, description, ordered, packed }) => (
                  <GlobalStyles.TableRow key={p_id}>
                    <TableCell center>
                      <img src={img} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
                    </TableCell>
                    <TableCell input={<Link to={`/product/variant/${p_id}/`}>{sku}</Link>}>
                      <div>{description}</div>
                    </TableCell>
                    <TableCell
                      center
                      input={
                        <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={'silver'}>
                          {ordered}
                        </StatusBoxStyled>
                      }
                    />
                    <TableCell
                      center
                      input={
                        <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={'silver'}>
                          {packed}
                        </StatusBoxStyled>
                      }
                    />
                  </GlobalStyles.TableRow>
                ))}
              </tbody>
            </GlobalStyles.DataTable>
          </Form.Group>
          <Form.Group as={Col} md="5">
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text"></span>
                  </th>
                  <th>
                    <span className="text">Packaging</span>
                  </th>
                  <th>
                    <span className="text center">Packed</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {packaging_items.map(({ img, p_id, sku, description, packed }) =>
                  packed > 0 ? (
                    <GlobalStyles.TableRow key={p_id}>
                      <TableCell center>
                        <img src={img} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
                      </TableCell>
                      <TableCell input={<Link to={`/product/variant/${p_id}/`}>{sku}</Link>}>
                        <div>{description}</div>
                      </TableCell>
                      <TableCell
                        center
                        input={
                          <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={'silver'}>
                            {packed}
                          </StatusBoxStyled>
                        }
                      />
                    </GlobalStyles.TableRow>
                  ) : null
                )}
                {no_packaging && !packaging_items.length ? (
                  <GlobalStyles.TableRow>
                    <TableCell />
                    <TableCell input={'No Packaging'} />
                    <TableCell />
                  </GlobalStyles.TableRow>
                ) : null}
              </tbody>
            </GlobalStyles.DataTable>
          </Form.Group>
        </Row>
      </Form>
    </GlobalStyles.FullPageCard>
  )
}
