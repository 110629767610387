import React, { useState } from 'react'
import { globalState } from '../../store'
import { useFetch, notify } from '../../components/component-items/helpers'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

// Components
import GlobalStyles from '../../components/component-items/styles'
import Checkbox from '../../components/component-items/checkbox'
import { DragNDrop } from '../../components/component-items/dragndrop'
import { StatusBoxStyled } from '../../components/component-items/status-box'
import { StatusBarStyled } from '../../components/component-items/status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'

export const RateCalculator = () => {
  const [file, setFile] = useState(null)
  const [multiWarehouse, setMultiWarehouse] = useState(false)
  const [warehouseOne, setWarehouseOne] = useState<any>(10)
  const [warehouseTwo, setWarehouseTwo] = useState<any>(5)
  const [showDeliveryEstimate, setShowDeliveryEstimate] = useState(false)
  const [deliveryEstimate, setDeliveryEstimate] = useState<any>(3)
  const [rate, setRate] = useState<any>('')

  const {
    state: { csrf },
  } = globalState()
  const url = '/api/shipment/service_levels/'
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})
  const serviceLevels = loaded ? resp.service_levels : []
  const warehouses = loaded ? resp.warehouses : []
  const [response, setResponse] = useState<any>({ success: false, error: false })
  const uploadCSV = () => {
    if (!file) {
      // @ts-ignore
      setResponse({ success: false, error: 'ERROR: No CSV File has been uploaded.' })
      return
    }

    // Append form data
    var formData = new window.FormData()
    formData.append('warehouse_one', warehouseOne)
    if (multiWarehouse) {
      formData.append('warehouse_two', warehouseTwo)
    }
    if (showDeliveryEstimate) {
      formData.append('delivery_estimate', deliveryEstimate)
    } else {
      formData.append('rate', rate)
    }
    formData.append('csv_file', file, 'rate_data.csv')

    // Submit CSV
    window
      .fetch('/api/shipment/csv_rates/', {
        method: 'POST',
        headers: {
          'X-CSRFToken': csrf,
        },
        body: formData,
      })
      .then((response) => {
        if (!response.ok) {
          notify({ type: 'error', message: response.statusText, title: String(response.status) })
          throw Error(response.statusText)
        } else {
          return response.json()
        }
      })
      .then((data) => {
        if (!data.success) {
          setResponse({ success: false, error: data.error })
        } else {
          setResponse({ success: 'SUCCESS - rate estimates will be sent to your account email.', error: false })
          setFile(null)
        }
      })
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <StatusBarStyled>
            <div className="header-item">
              <div className="status-icon">
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
              </div>
              <div className="status-text">
                <strong>Please Note</strong>: CSV Files must be formatted like the template (
                <em>with dimensions in inches and weight in ounces</em>), any errors will be reported below
              </div>
            </div>
          </StatusBarStyled>
        </div>
      </div>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Rate Calculator</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '50%' }}>
          {response.success ? (
            <StatusBoxStyled className={'green'} style={{ marginBottom: '2em' }}>
              {response.success}
            </StatusBoxStyled>
          ) : response.error ? (
            <StatusBoxStyled className={'red'} style={{ marginBottom: '2em' }}>
              {response.error}
            </StatusBoxStyled>
          ) : null}
          <InputGroup>
            <Checkbox selected={multiWarehouse} setSelected={() => setMultiWarehouse(!multiWarehouse)} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Multi-Warehouse Comparison</p>
          </InputGroup>
          <Form.Label>Choose a Warehouse:</Form.Label>
          <div style={{ display: 'flex' }}>
            <Form.Select value={warehouseOne} onChange={(e) => setWarehouseOne(e.target.value)}>
              {warehouses
                ?.filter((w: any) => !multiWarehouse || (multiWarehouse && w.id != warehouseTwo))
                .map((w: any, id: number) => (
                  <option value={w.id} key={id}>
                    {w.name}
                  </option>
                ))}
            </Form.Select>
            {multiWarehouse ? (
              <Form.Select
                style={{ marginLeft: '1em' }}
                value={warehouseTwo}
                onChange={(e) => setWarehouseTwo(e.target.value)}
              >
                {warehouses
                  ?.filter((w: any) => w.id != warehouseOne)
                  .map((w: any, id: number) => (
                    <option value={w.id} key={id}>
                      {w.name}
                    </option>
                  ))}
              </Form.Select>
            ) : null}
          </div>
          <InputGroup style={{ marginTop: '1em' }}>
            <Checkbox
              selected={showDeliveryEstimate}
              setSelected={() => setShowDeliveryEstimate(!showDeliveryEstimate)}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Cheapest Rate within given Delivery Days</p>
          </InputGroup>
          {showDeliveryEstimate ? (
            <Form.Control
              type="text"
              value={deliveryEstimate}
              onChange={(e) => setDeliveryEstimate(e.target.value)}
              placeholder="Delivery Days"
              required
            />
          ) : (
            <>
              <Form.Label>
                Shipping Rate
                <br />
                (Note: Cheapest rate is used when rate cannot be found)
              </Form.Label>
              <Form.Select value={rate} onChange={(e) => setRate(e.target.value)}>
                <option value={''}>Cheapest Rate</option>
                {serviceLevels.map((s: any, id: number) => (
                  <option value={s.id} key={id}>
                    {s.carrier__name}: {s.name}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
          <Form.Label style={{ marginTop: '1em' }}>
            CSV File Upload (<a href="/api/static/templates/rate_estimate_template.csv">Download Template</a>)
          </Form.Label>
          <DragNDrop {...{ file, setFile }} fileTypes={'.csv'} />
          <GlobalStyles.Button onClick={() => uploadCSV()} style={{ minWidth: 150, margin: 0, float: 'right' }}>
            Submit
          </GlobalStyles.Button>
        </div>
      </GlobalStyles.FullPageCard>
    </>
  )
}
