import React from 'react'
import styled from 'styled-components'
import { StatusBarStyled } from '../../components/component-items/status-bar'
import { useFetch, usePost } from '../../components/component-items/helpers'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faFileInvoiceDollar, faUsdSquare } from '@fortawesome/pro-light-svg-icons'
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons'
import { faBookSpells, faFileImport } from '@fortawesome/pro-duotone-svg-icons'
import { globalState } from '../../store'

export const StatusBar = () => {
  const {
    state: { userIsWarehouse, actAs },
  } = globalState()

  const company_details_url = userIsWarehouse && !actAs ? '' : `/api/edi/relationship/details/`
  const { response: resp, loaded } = useFetch(company_details_url, {})
  const summaryDetailsURL = userIsWarehouse && !actAs ? `/api/edi/relationship/summary/` : ''
  const { response: respSummary, loaded: loadedSummary } = useFetch(summaryDetailsURL, {})
  const company = loaded ? resp.companyName : ''
  const live = company ? (loaded ? resp.ediEnabled : false) : true
  const lastSuccessfulTransaction = (loaded ? resp.lastSuccessfulTransactionDate || '' : '')?.split('T')[0]
  const successfulTransactions = loaded ? resp.successfulTransactions : 0
  const summaryLastSuccessfulTransaction = (
    loadedSummary ? respSummary.lastSuccessfulTransactionDate || '' : ''
  )?.split('T')[0]
  const summarySuccessfulTransactions = loadedSummary ? respSummary.successfulTransactions : 0
  const color = company
    ? successfulTransactions > 0
      ? 'green'
      : 'blue'
    : summarySuccessfulTransactions > 0
      ? 'green'
      : 'blue'
  return (
    <div className="row">
      <div className="col">
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              {live ? (
                <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#51e2d3' }} />
              ) : (
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
              )}
            </div>
            <div className="status-text">
              <div>{company && <strong>{!live ? 'EDI Disabled' : ''}</strong>}</div>
              <div>
                Last Successful Transaction{' '}
                <strong>{company ? lastSuccessfulTransaction : summaryLastSuccessfulTransaction}</strong>
              </div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon icon={faBookSpells} />
            </div>
            <div className="status-text">EDI Setup {company ? `for ${company}` : ''}</div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text">
              Successful Transactions:{' '}
              <strong>{company ? successfulTransactions : summarySuccessfulTransactions}</strong>
            </div>
            <div className="status-icon" style={{ marginLeft: '1em' }}>
              <FontAwesomeIcon icon={faFileImport} style={{ color: color }} />
            </div>
          </div>
        </StatusBarStyled>
      </div>
    </div>
  )
}
