import React, { useState } from 'react'
import Select from 'react-select'

// Components
import { MultiSelect, HeaderHover } from './styles'

const unfulfilledGroup = [
  { value: 70, label: 'Paused' },
  { value: 80, label: 'Backordered' },
  { value: 90, label: 'Received' },
]

const inFulfillmentGroup = [
  { value: 100, label: 'Processing' },
  { value: 110, label: 'Picked' },
  { value: 120, label: 'Packed' },
  { value: 145, label: 'Label Purchase Failed' },
  { value: 150, label: 'Rate Limit Exceeded' },
]
const fulfilledGroup = [
  { value: 160, label: 'Label Purchased' },
  { value: 200, label: 'Shipped' },
  { value: 300, label: 'Delivered' },
]

const createGroup = (groupName: string, options: any[], setValue: (args: any) => void) => {
  return {
    label: (() => {
      return (
        <HeaderHover
          onClick={() => setValue((value: any) => value.concat(options.filter((grpOpt) => !value.includes(grpOpt))))}
        >
          {groupName}
        </HeaderHover>
      )
    })(),
    options: options,
  }
}

const statusOptionsList = ({ setStatuses }: any) => [
  { value: 10, label: 'Cancelled' },
  createGroup('--Unfulfilled--', unfulfilledGroup, setStatuses),
  createGroup('--In Fulfillment--', inFulfillmentGroup, setStatuses),
  createGroup('--Fulfilled--', fulfilledGroup, setStatuses),
]

export const OrderStatusMultiSelect = ({ statuses, setStatuses }: any) => {
  let statusOptions = statusOptionsList({ setStatuses })
  return (
    <MultiSelect>
      <Select
        options={statusOptions}
        value={statuses}
        onChange={(option: any) => {
          return setStatuses(option)
        }}
        closeMenuOnSelect={false}
        isMulti
        className="basic-multi-select"
      />
    </MultiSelect>
  )
}
