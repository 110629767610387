import React, { useEffect, useState, useRef } from 'react'
import { useClick, useFetch, usePost } from '../../component-items/helpers'
import { globalState } from '../../../store'
import { Styled } from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

// Components
import ProgressBar from 'react-bootstrap/ProgressBar'
import { DropDown } from '../../component-items/helpers'
import GlobalStyles from '../../component-items/styles'
import GenericModal from '../../component-items/modal'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardListCheck, faCheckCircle, faComment } from '@fortawesome/pro-duotone-svg-icons'
import { faCircle, faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons'

import { ChecklistItemProps, OnboardingStepProps } from './types'

const ChecklistItem = ({ url, title, completed, active, setActive, children }: ChecklistItemProps) => {
  let history = useHistory()
  const handleClick = () => {
    if (url) {
      history.push(url)
    } else if (title != active) {
      setActive(title)
    } else {
      setActive('')
    }
  }
  return (
    <>
      <div onClick={handleClick} className={`nav__item ${!completed ? 'active' : ''}`}>
        <div className="item-icon">
          <FontAwesomeIcon icon={completed ? faCheckCircle : faCircle} />
        </div>
        <div className="item-title" style={{ fontWeight: completed ? 400 : 500 }}>
          {title}
        </div>
        {children ? (
          <div className="item-dropdown">
            <FontAwesomeIcon icon={active === title ? faAngleUp : faAngleDown} />
          </div>
        ) : null}
      </div>
      <div style={{ width: '100%' }}>
        {/* @ts-ignore */}
        <DropDown open={children && active === title}>
          <div style={{ padding: '1em' }}>{children}</div>
        </DropDown>
      </div>
    </>
  )
}

export const OnboardingChecklist = () => {
  const location = useLocation()
  const {
    state: { csrf, showOnboardingChecklist, companyId },
    dispatch,
  } = globalState()
  const [open, setOpen] = useState(true)
  const [active, setActive] = useState('')
  const [integrationModal, setIntegrationModal] = useState(false)

  const node = useRef<HTMLDivElement>(null)
  useClick(node, setOpen)

  useEffect(() => {
    setOpen(false)
  }, [location])

  useEffect(() => {
    setOpen(true)
  }, [showOnboardingChecklist])

  const { response, loaded }: any = useFetch(`/api/company/${companyId}/onboarding_data/`, {})
  const { teamMembersAdded, integrationSetup, inventoryDelivered, billingDetailsAdded }: OnboardingStepProps = loaded
    ? response
    : {}

  const progress_list = [teamMembersAdded, integrationSetup, inventoryDelivered, billingDetailsAdded]
  const progress_percentage = ((progress_list.filter((p) => p).length + 1) * 100) / 5

  const closeOnboarding = () => {
    usePost(`/api/company/${companyId}/onboarding_data/`, { skip: true }, csrf, false)
    dispatch({ type: 'hideOnboarding' })
  }
  return (
    loaded &&
    showOnboardingChecklist && (
      <Styled.Onboarding ref={node}>
        <div className={'panel '}>
          <div className="panel_head" onClick={() => setOpen((open) => !open)}>
            <h3>
              <FontAwesomeIcon icon={faClipboardListCheck} />
              &nbsp; Onboarding Checklist
            </h3>
            <button>
              <i>
                <FontAwesomeIcon icon={open ? faAngleDown : faAngleUp} />
              </i>
            </button>
          </div>
          <DropDown {...{ open }}>
            <div className="panel_body">
              <p style={{ fontSize: '1.2rem' }}>
                Complete your onboarding checklist to ensure all your shipping needs are covered!
              </p>
              <ProgressBar now={progress_percentage} style={{ width: '100%' }} />
              <ChecklistItem {...{ active, setActive }} completed={true} title={'Create Account'} />
              {/* TODO (mike) - add support for tours using Shepherd react plugin */}
              {/* <ChecklistItem url={"/package/rule/"} {...{ active, setActive }} completed={false} title={"Take a Tour"} /> */}
              <ChecklistItem
                url={'/company/users/'}
                {...{ active, setActive }}
                completed={teamMembersAdded}
                title={'Add Team Members'}
              />
              <ChecklistItem {...{ active, setActive }} completed={integrationSetup} title={'Setup Your Integration'}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Link to={'/integration/shop/'}>
                    <GlobalStyles.Button style={{ minWidth: 125 }}>Shopify</GlobalStyles.Button>
                  </Link>
                  <GlobalStyles.Button
                    className="secondary"
                    style={{ minWidth: 125 }}
                    onClick={() => setIntegrationModal((integrationModal) => !integrationModal)}
                  >
                    Other
                  </GlobalStyles.Button>
                  <GenericModal
                    heading={'Add eCommerce Integration'}
                    show={integrationModal}
                    size="lg"
                    onHide={() => setIntegrationModal(false)}
                    buttons={
                      <GlobalStyles.Button
                        style={{ minWidth: 125 }}
                        className="secondary"
                        onClick={() => setIntegrationModal(false)}
                      >
                        Close
                      </GlobalStyles.Button>
                    }
                  >
                    <div style={{ padding: '1em' }}>
                      <p>
                        Please email <a href="mailto:support@shippingtree.co">support@shippingtree.co</a> to indicate
                        which eCommerce Integration you would like to setup and we will send you the details required to
                        enable the integration. Thank you!
                      </p>
                    </div>
                  </GenericModal>
                </div>
              </ChecklistItem>
              <ChecklistItem {...{ active, setActive }} completed={inventoryDelivered} title={'Send us Inventory'}>
                <p>
                  For help sending inventory to one of our warehouses please contact{' '}
                  <a href="mailto:support@shippingtree.co">support@shippingtree.co</a>
                </p>
              </ChecklistItem>
              <ChecklistItem
                url={'/billing/company/'}
                {...{ active, setActive }}
                completed={billingDetailsAdded}
                title={'Add Billing Details'}
              />
            </div>
            <hr style={{ marginTop: '1em' }} />
            <div
              style={{
                userSelect: 'none',
                padding: '0.5em 2em',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <a href="mailto:support@shippingtree.co">
                <p>
                  Have Feedback? <FontAwesomeIcon icon={faComment} />
                </p>
              </a>
              <GlobalStyles.Button
                className="secondary"
                style={{ minWidth: 125, height: 30, lineHeight: 0 }}
                onClick={closeOnboarding}
              >
                Hide
              </GlobalStyles.Button>
            </div>
          </DropDown>
        </div>
      </Styled.Onboarding>
    )
  )
}
