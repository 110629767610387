import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch, usePost } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'

// Components
import { OrderOptions } from '../../../orders/order/styles'
import { ShippingWorkflow } from '../shipping-workflow'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'

// Cards
import { StatusBar } from '../status-bar'
import { ShipmentCard } from './shipment-card'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faBarcodeRead } from '@fortawesome/pro-duotone-svg-icons'

import { OrderOptionsProps } from './types'

const OrderOption = ({
  url,
  onClick,
  icon,
  title,
  arrow,
  children,
  node,
  active,
  disabled,
  complete,
}: OrderOptionsProps) => {
  return (
    <li ref={node} onClick={onClick} className={active ? 'active' : disabled ? 'disabled' : complete ? 'complete' : ''}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const SerialNumbers = () => {
  const order = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()

  const res: any = useFetch(`/api/order/${order}/serial_numbers/`, {})
  const loaded = res.loaded
  const resp = loaded ? res.response : {}

  const [updated, setUpdated] = useState('')
  const [packStatus, setPackStatus] = useState({
    fully_packed: false,
    needs_serial_numbers: false,
    serial_number_enabled: false,
    needs_dimensions: false,
    customer_pickup: false,
  })
  const [shipments, setShipments] = useState([])

  useEffect(() => {
    if (loaded && resp.shipments.length) {
      setShipments(resp.shipments)
    }
    if (loaded) {
      setPackStatus(resp.pack_status)
    }
  }, [loaded])

  const handleSubmit = async () => {
    setUpdated('')
    const resp = await usePost(`/api/order/${order}/serial_numbers/`, { shipments }, csrf, false)
    if (!resp.error) {
      setPackStatus(resp.pack_status)
      setUpdated('Serial Numbers have been added successfully')
    } else {
      setUpdated('')
    }
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <StatusBar />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-xl-2">
          <OrderOptions>
            <ul>
              <OrderOption onClick={() => handleSubmit()} icon={faBarcodeRead} title={'Update Serial Numbers'} />
            </ul>
          </OrderOptions>
        </div>
        <div className="col-lg-7 col-xl-8">
          <div className="row">
            <div className="col-xl-12">
              {updated ? (
                <StatusBoxStyled className={'green'} style={{ marginBottom: '1em', textAlign: 'center' }}>
                  {updated}
                </StatusBoxStyled>
              ) : null}
              {shipments.map((s: any, id: number) => (
                <ShipmentCard {...s} {...{ shipments, setShipments }} s_index={id} total={shipments.length} key={id} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <ShippingWorkflow {...packStatus} current={'serial_numbers'} />
        </div>
      </div>
    </>
  )
}
