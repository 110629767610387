import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'

import { OrderStatusBoxProps } from './types'

// Keyframes for moving the gradient
const moveGradient = keyframes`
  0% {
    background-position: 0% 25%;
  }
  50% {
    background-position: 50% 25%;
  }
  100% {
    background-position: 0% 25%;
  }
`

export const StatusBoxStyled = styled.div<{
  theme: object
}>`
  cursor: default;
  user-select: none;
  font-size: 0.85rem;
  font-weight: 600;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  &.green {
    background-color: rgba(29, 201, 183, 0.1);
    color: ${(props) => props.theme.colors.java};
  }
  &.blue {
    background-color: rgba(93, 120, 255, 0.1);
    color: ${(props) => props.theme.colors.dodgerBlue};
  }
  &.red {
    background-color: rgba(253, 57, 122, 0.1);
    color: ${(props) => props.theme.colors.radicalRed};
    &.bold {
      background-color: #ff0000;
      color: ${(props) => props.theme.colors.black};
    }
  }
  &.yellow {
    background-color: rgba(255, 184, 34, 0.1);
    color: ${(props) => props.theme.colors.mySin};
    &.bold {
      background-color: ${(props) => props.theme.colors.mySin};
      color: ${(props) => props.theme.colors.white};
    }
  }
  &.silver {
    background-color: ${(props) => props.theme.colors.linkWater};
    color: ${(props) => props.theme.colors.doveGray};
  }
  &.purple {
    background-color: ${(props) => props.theme.colors.freshEggplant};
    color: ${(props) => props.theme.colors.white};
    &.bold {
      background-color: ${(props) => props.theme.colors.freshEggplant};
      color: ${(props) => props.theme.colors.white};
    }
  }
  &.grey {
    background-color: ${(props) => props.theme.colors.gray};
    color: ${(props) => props.theme.colors.white};
  }
  &.black {
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};
  }
  &.white {
    background-color: ${(props) => props.theme.colors.whiteLilac};
    color: ${(props) => props.theme.colors.black};
  }
  &.royalshine {
    color: white;
    background: linear-gradient(45deg, #001682, #32a06c);
    background-size: 125 % 125 %; // Important for allowing the gradient to move
    animation: ${moveGradient} 2s ease -in -out infinite;
  }
`

export const Disclaimer = styled(StatusBoxStyled)`
  margin-top: 1em;
  font-style: italic;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 2em;
`
export const OrderStatusBox: FunctionComponent<OrderStatusBoxProps> = ({ status, status_id, style }) => {
  var className = 'blue'
  if (['40', '70', '80', '155', '150'].includes(String(status_id))) {
    className = 'yellow'
  } else if (['10', '145'].includes(String(status_id))) {
    className = 'red'
  } else if (['200', '300'].includes(String(status_id))) {
    className = 'green'
  }
  return (
    <StatusBoxStyled style={style} className={className}>
      {status}
    </StatusBoxStyled>
  )
}
