import React from 'react'
import { Tooltip } from './styles'
import { StatusBarStyled } from '../../../components/component-items/status-bar'

// Components
import Form from 'react-bootstrap/Form'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCubes, faExclamationTriangle, faHourglassHalf } from '@fortawesome/pro-light-svg-icons'
import { faCheckDouble } from '@fortawesome/pro-duotone-svg-icons'

import { StatusBarProps } from './types'

export const StatusBar = ({ id, product, status, title, company, description, variants, updated }: StatusBarProps) => {
  return (
    <StatusBarStyled>
      <div className="header-item">
        <div className="status-icon">
          {status === 'Awaiting Inventory' ? (
            <FontAwesomeIcon icon={faHourglassHalf} style={{ color: '#5d78ff' }} />
          ) : status === 'Now Shipping' ? (
            <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#00a58f' }} />
          ) : (
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
          )}
        </div>
        <div className="status-text">
          {status || 'Loading'}
          <br />
          <span>
            Last Update <strong>{updated}</strong>
          </span>
        </div>
      </div>
      <div className="border-line"></div>
      <div className="header-item center">
        <div className="status-icon">
          <FontAwesomeIcon icon={faCubes} />
        </div>
        <Tooltip bottom data-title={description}>
          <div className="status-text">
            <div style={{ wordBreak: 'break-all' }}>
              <strong>{title}</strong>
            </div>
            <div>
              Company: <strong>{company}</strong>
            </div>
          </div>
        </Tooltip>
      </div>
      <div className="border-line"></div>
      <div className="header-item right">
        <div className="status-text" style={{ paddingRight: '1em' }}>
          Variants:
        </div>
        <div className="status-text">
          <Form.Select
            value={id}
            style={{ maxWidth: 200 }}
            onChange={(e) =>
              e.target.value ? window.open(`/product/variant/${e.target.value}/`) : window.open(`/product/${product}/`)
            }
          >
            <option value={''}>Parent Product</option>
            {(variants || []).map((v: any, id: number) => (
              <option value={v.id} key={id}>
                {v.title} - {v.sku}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="status-icon" style={{ marginLeft: '2em' }}>
          <FontAwesomeIcon icon={faCubes} />
        </div>
      </div>
    </StatusBarStyled>
  )
}
