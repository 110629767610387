import styled from 'styled-components'
import GlobalStyles from '../../components/component-items/styles'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

export const FilterListItem = styled.div`
  margin: 0 auto 1em;
  width: 50%;
`

export const DateRangeStyled = styled.div`
  overflow: scroll;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em auto;
  width: fit-content;
`

export const EditorContainer = styled.div`
  margin: 0 1em -1em;
  color: ${(props) => props.theme.colors.black};
  .editor-container {
    background: #f7f7f7;
    border-radius: 4px;
    padding: 0.5em 1em;
    margin: 1em 0;
    max-height: 300px;
    overflow: scroll;
  }
`
