import React, { useState } from 'react'
import { useFetch } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import Pager, { Pages } from '../../../components/component-items/pager'

// Fontawesome
import styled from 'styled-components'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

export const Reports = () => {
  const {
    state: { companyId },
  } = globalState()
  const [page, setPage] = useState(1)
  const tableRowOptions = [100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])

  const url = `/api/company/${companyId}/document_list/?range=` + rows + '&page=' + (page - 1)
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})

  const documents = loaded ? resp.documents : []
  const total = loaded ? resp.total : 0
  var totalPages = loaded && total ? Math.ceil(total / rows) : 1

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>Company Documents &amp; Reports</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Created</span>
              </th>
              <th>
                <span className="text">Title</span>
              </th>
              <th>
                <span className="text center">Download</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {documents?.map(({ id, title, description, url, created }: any) => (
              <TableRow key={id}>
                <TableCell>{created}</TableCell>
                {description ? (
                  <TableCell input={title}>
                    <div>{description}</div>
                  </TableCell>
                ) : (
                  <TableCell input={title} />
                )}
                <TableCell center>
                  {url ? (
                    <a href={url}>
                      <GlobalStyles.Button style={{ color: 'white' }}>Download</GlobalStyles.Button>
                    </a>
                  ) : (
                    <StatusBoxStyled className="purple" style={{ width: 100, margin: 'auto' }}>
                      Downloading
                    </StatusBoxStyled>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
