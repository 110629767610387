import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import Pager from '../../../components/component-items/pager'
import { useFetch, usePost, notify } from '../../../components/component-items/helpers'
import Select from 'react-select'
import { globalState } from '../../../store'
import styled from 'styled-components'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { SearchBox } from '../../../components/component-items/search'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { StatusBarStyled } from '../../../components/component-items/status-bar'
import { TableCell } from '../../../components/component-items/datatable'
import Form from 'react-bootstrap/Form'
import { TableRow } from '../../orders/styles'
import { toTitleCase } from '../../../components/component-items/utils'
import { FilterListItem, NavButton } from './styles'
import { Badge } from 'react-bootstrap'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/pro-light-svg-icons'
import { constants } from 'buffer'

const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
    div[id^='react-select-3-option'] {
      &:hover {
        background-color: #deebff;
      }
      :not(:hover) {
        transition: background-color 0.1s linear;
        -webkit-transition: background-color 0.1s linear;
      }
    }
  }
  div[id^='react-select-3']:not(:hover) {
    background-color: transparent;
  }
  div[id^='react-select-'][id$='-group-'][id*='-heading']:not(:hover) {
    background-color: transparent;
  }
  div[id^='react-select-3-group']:hover + div > div {
    background-color: #deebff !important;
  }
`

const HeaderHover = styled.div`
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
`

const createGroup = (groupName: string, options: any, setValue: (value: any) => void) => {
  return {
    label: (() => {
      return (
        <HeaderHover
          onClick={() =>
            setValue((value: any) => value.concat(options?.filter((grpOpt: any) => !value.includes(grpOpt))))
          }
        >
          {groupName}
        </HeaderHover>
      )
    })(),
    options: options,
  }
}

export const FilterStoreOrders = ({
  showFilterModal,
  setFilterModal,
  storeSettings,
  shop,
}: {
  showFilterModal: boolean
  setFilterModal: (value: any) => void
  storeSettings: any
  shop: string
}) => {
  let history = useHistory()
  const [statuses, setStatuses] = useState('')
  const [location, setLocation] = useState({ value: '', label: 'ShippingTree' })

  const importedGroup = [
    { value: 'CLOSED', label: 'Closed' },
    { value: 'IN_PROGRESS', label: 'In progress' },
  ]

  const nonImportedGroup = [
    { value: 'CANCELLED', label: 'Cancelled' },
    { value: 'INCOMPLETE', label: 'Incomplete' },
    { value: 'ON_HOLD', label: 'On hold' },
    { value: 'OPEN', label: 'Open' },
    { value: 'SCHEDULED', label: 'Scheduled' },
  ]
  const statusOptions = [
    createGroup('--Non imported--', nonImportedGroup, setStatuses),
    createGroup('--Imported--', importedGroup, setStatuses),
  ]

  return (
    <GenericModal
      heading={'Filter Orders'}
      show={showFilterModal}
      size="lg"
      onHide={() => setFilterModal(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => {
            setFilterModal(false)
            history.push(
              // @ts-ignore
              `?statuses=${statuses !== '' ? statuses?.map((status: any) => status.value).join(',') : ''}&location=${
                location.value
              }&shop=${shop}`
            )
          }}
        >
          Filter
        </GlobalStyles.Button>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Filter by Status</Form.Label>
          <MultiSelect>
            <Select
              options={statusOptions}
              value={statuses}
              onChange={(option: any) => {
                return setStatuses(option)
              }}
              closeMenuOnSelect={false}
              isMulti
              className="basic-multi-select"
            />
          </MultiSelect>
        </FilterListItem>
        <FilterListItem>
          <Form.Label>Filter by Location</Form.Label>
          <Select
            options={[
              { value: 'all', label: 'All' },
              ...(storeSettings?.shopLocations
                .filter(
                  (location: any) =>
                    !storeSettings?.fulfillmentLocations
                      .map(({ location }: any) => location.legacyResourceId)
                      .includes(location.legacyResourceId)
                )
                .map((location: any) => ({
                  value: location.legacyResourceId,
                  label: location.name,
                })) || []),
              ...(storeSettings?.fulfillmentLocations.map(({ location }: any) => ({
                value: location.legacyResourceId,
                label: location.name,
              })) || []),
            ]}
            value={location}
            onChange={(option: any) => {
              return setLocation(option)
            }}
          />
        </FilterListItem>
      </div>
    </GenericModal>
  )
}

export const StoreFulfillmentOrderList = ({ shop, storeSettings }: { shop: { shop: string }; storeSettings: any }) => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)

  // Pagination
  const tableRowOptions = [25, 50, 100, 250]
  const [rows, setRows] = useState(tableRowOptions[0])
  // After and before refers to the pointer of the pagination for graphql
  const [after, setAfter] = useState('')
  const [before, setBefore] = useState('')

  // Search
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  // Filters
  const [status, setStatus] = useState('')
  const [stLocation, setStLocation] = useState('')

  const url =
    '/api/shopify/fulfillment-orders/?' +
    'shop=' +
    shop.shop +
    '&range=' +
    rows +
    '&q=' +
    search +
    '&status=' +
    status +
    '&location=' +
    stLocation +
    '&after=' +
    after +
    '&before=' +
    before
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})
  const { fulfillmentOrders, shippingtreeOrders, pageInfo }: any = resp || {}
  const { hasNextPage, hasPreviousPage, startCursor, endCursor } = pageInfo || {}

  useEffect(() => {
    setStatus(urlParams.get('statuses') || '')
    setStLocation(urlParams.get('location') || '')
  }, [location])

  const onClickNextPage = () => {
    setBefore('')
    setAfter(endCursor)
  }

  const onClickPreviousPage = () => {
    setBefore(startCursor)
    setAfter('')
  }

  useEffect(() => {
    if (loaded) {
      setLoading(!loaded)
      if (resp.warning) {
        notify({ type: 'warning', message: resp.warning })
      }
    }
  }, [loaded])

  // Assign sort based on url params first
  const [showFilterModal, setFilterModal] = useState(false)
  const [filterCount, setFilterCount] = useState(0)

  useEffect(() => {
    let counter = 0
    if (status) counter++
    if (stLocation !== 'all') counter++
    setAfter('')
    setFilterCount(counter)
  }, [status, stLocation])

  const created_date_options = {
    year: 'numeric' as const,
    month: 'short' as const,
    day: 'numeric' as const,
    hour: 'numeric' as const,
    minute: '2-digit' as const,
    hour12: true, // Use AM/PM
  }

  return (
    <>
      <GlobalStyles.CardHeader style={{ padding: '1.5em', display: 'flex', alignContent: 'end' }}>
        <GlobalStyles.CardTitle>
          {hasPreviousPage && (
            <>
              <NavButton onClick={onClickPreviousPage}>
                <FontAwesomeIcon style={{ marginRight: '4px' }} icon={faArrowCircleLeft} /> Prev Page{' '}
              </NavButton>
              <span>|</span>
            </>
          )}
          {hasNextPage && (
            <NavButton onClick={onClickNextPage}>
              Next Page
              <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faArrowCircleRight} />
            </NavButton>
          )}
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <FilterStoreOrders
            showFilterModal={showFilterModal}
            setFilterModal={setFilterModal}
            storeSettings={storeSettings}
            shop={shop.shop}
          />
          <GlobalStyles.Button onClick={() => setFilterModal(true)}>
            Filter {filterCount > 0 && <Badge pill>{filterCount}</Badge>}
          </GlobalStyles.Button>
          <button
            onClick={() => {
              setBefore('')
              setAfter('')
              setSearch('')
            }}
          >
            <Link to={`/integration/shop/`} style={{ maxWidth: 150 }}>
              Reset All
            </Link>
          </button>
          <SearchBox {...{ search, setSearch, loading, setLoading }} setFocus updateURL />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, overflow: 'scroll' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Details</span>
              </th>
              <th>
                <span className="text">Address</span>
              </th>
              <th>
                <span className="text center">Items</span>
              </th>
              <th>
                <span className="text center">Fulfillment Status</span>
              </th>
              <th>
                <span className="text center">Request Status</span>
              </th>
              <th>
                <span className="text center">Imported</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {loaded
              ? fulfillmentOrders?.map(
                  ({ id, order, status, requestStatus, assignedLocation, lineItems, createdAt }: any, k_id: number) => {
                    const st_order = shippingtreeOrders.find(
                      ({ shopifyFulfillmentOrderId }: { shopifyFulfillmentOrderId: number | string }) =>
                        id.includes(shopifyFulfillmentOrderId)
                    )
                    return (
                      <TableRow key={k_id}>
                        <TableCell>
                          Shopify order ID:{' '}
                          <Link
                            to={'#'}
                            onClick={() =>
                              window.open(`https://${resp.shop}/admin/orders/${order.legacyResourceId}`, '_blank')
                            }
                            style={{ fontWeight: '500' }}
                          >
                            {order.legacyResourceId}
                          </Link>
                          {order.name ? (
                            <div>
                              Shopify order number: <span style={{ fontWeight: '500' }}>{order.name}</span>
                            </div>
                          ) : null}
                          {shippingtreeOrders.map(({ id: orderId, shopifyFulfillmentOrderId: fOrderId }: any) =>
                            id.includes(fOrderId) ? (
                              <>
                                ST Order ID:{' '}
                                <Link
                                  key={fOrderId}
                                  to={`/order/${orderId}/`}
                                  style={{ maxWidth: 150, fontWeight: '500' }}
                                >
                                  {orderId}
                                </Link>
                                <br />
                              </>
                            ) : null
                          )}
                          <div>
                            Created{' '}
                            <span style={{ fontWeight: '500' }}>
                              {new Intl.DateTimeFormat('en-US', created_date_options).format(new Date(createdAt))}
                            </span>
                          </div>
                          Location: <span style={{ fontWeight: '500' }}>{assignedLocation?.location?.name}</span>
                        </TableCell>
                        <TableCell input={order.shippingAddress?.name}>
                          {order.shippingAddress ? (
                            <>
                              <div>
                                {order.shippingAddress.address1}
                                {order.shippingAddress.address2
                                  ? ' - ' + order.shippingAddress.address2
                                  : ''} <br /> {order.shippingAddress.city}, {order.shippingAddress.province} <br />
                                {order.shippingAddress.country} <br /> {order.shippingAddress.zip}
                              </div>
                            </>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {lineItems?.length ? (
                            <div
                              style={{
                                maxHeight: 200,
                                overflow: 'scroll',
                                boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px',
                              }}
                            >
                              <GlobalStyles.DataTable>
                                <tbody>
                                  {lineItems.map((item: any) => (
                                    <TableRow key={item.id}>
                                      <TableCell input={item.sku} />
                                      <TableCell input={item.totalQuantity} center />
                                    </TableRow>
                                  ))}
                                </tbody>
                              </GlobalStyles.DataTable>
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell center>
                          <StatusBoxStyled className={status === 'FULFILLED' ? 'green' : 'blue'}>
                            {status ? toTitleCase(status.replace('_', ' ')) : '-'}
                          </StatusBoxStyled>
                        </TableCell>
                        <TableCell center>
                          <StatusBoxStyled className={requestStatus === 'ACCEPTED' ? 'green' : 'blue'}>
                            {requestStatus ? toTitleCase(requestStatus.replace('_', ' ')) : '-'}
                          </StatusBoxStyled>
                        </TableCell>
                        <TableCell center>
                          <StatusBoxStyled className={st_order ? 'green' : 'yellow'}>
                            {st_order ? 'Imported' : 'Not Imported'}
                          </StatusBoxStyled>
                        </TableCell>
                      </TableRow>
                    )
                  }
                )
              : null}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <div>
          <Pager options={tableRowOptions} rows={rows} setRows={setRows} />
        </div>
        <div style={{ float: 'right' }}>
          {hasPreviousPage && (
            <>
              <NavButton onClick={onClickPreviousPage}>
                <FontAwesomeIcon style={{ marginRight: '4px' }} icon={faArrowCircleLeft} /> Prev Page{' '}
              </NavButton>
              <span>|</span>
            </>
          )}
          {hasNextPage && (
            <NavButton onClick={onClickNextPage}>
              Next Page
              <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faArrowCircleRight} />
            </NavButton>
          )}
        </div>
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </>
  )
}
