import React, { useState } from 'react'
import styled from 'styled-components'
import { useFetch } from '../../../components/component-items/helpers'
import { Link } from 'react-router-dom'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import CardLoading from '../../../components/component-items/loading-popover'
import { TableCell, THSort } from '../../../components/component-items/datatable'
import { LotNumbersModalProps } from './types'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

export const LotNumbersModal = ({ order, lotNumbersModal, setLotNumbersModal }: LotNumbersModalProps) => {
  const url = `/api/order/${order}/lot_numbers/`
  const {
    response: resp,
    loaded,
    error,
    placeholder,
  }: { response: any; loaded: boolean; error?: any; placeholder?: string } = useFetch(url, {})

  const lotNumbers = loaded ? resp : []

  const exportCSV = () => {
    // @ts-ignore
    window.location = `${url}?export=true`
  }

  return (
    <GenericModal
      heading={'Lot Numbers'}
      show={lotNumbersModal}
      size="xl"
      onHide={() => setLotNumbersModal(false)}
      buttons={
        <>
          <GlobalStyles.Button style={{ minWidth: 125 }} className="secondary" onClick={() => exportCSV()}>
            Export
          </GlobalStyles.Button>
          <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setLotNumbersModal(false)}>
            Close
          </GlobalStyles.Button>
        </>
      }
    >
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Lot Numbers List</h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar></GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <div style={{ minHeight: 300, maxWidth: '100%' }}>
          <GlobalStyles.DataTable>
            <thead>
              <tr>
                <th>
                  <span className="text center">Variant Name</span>
                </th>
                <th>
                  <span className="text center">SKU</span>
                </th>
                <th>
                  <span className="text center">Lot Number</span>
                </th>
                <th>
                  <span className="text center">Received Date</span>
                </th>
                <th>
                  <span className="text center">Expiry Date</span>
                </th>
                <th>
                  <span className="text center">Manufacture Date</span>
                </th>
                <th>
                  <span className="text center">Best Before Date</span>
                </th>
                <th>
                  <span className="text center">Quantity</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {lotNumbers.map(
                ({
                  variant_id,
                  sku,
                  name,
                  lot_number,
                  received_date,
                  expiry_date,
                  manufacture_date,
                  best_before_date,
                  quantity,
                }: any) => (
                  <TableRow key={variant_id}>
                    <TableCell
                      center
                      input={
                        <Link to={`/product/variant/${variant_id}/`} target="_blank">
                          {name}
                        </Link>
                      }
                    ></TableCell>
                    <TableCell center input={sku}></TableCell>
                    <TableCell center input={lot_number ? lot_number : '--'}></TableCell>
                    <TableCell center input={received_date ? received_date : '--'}></TableCell>
                    <TableCell center input={expiry_date ? expiry_date : '--'}></TableCell>
                    <TableCell center input={manufacture_date ? manufacture_date : '--'}></TableCell>
                    <TableCell center input={best_before_date ? best_before_date : '--'}></TableCell>
                    <TableCell center input={quantity}></TableCell>
                  </TableRow>
                )
              )}
            </tbody>
          </GlobalStyles.DataTable>
        </div>
      </GlobalStyles.FullPageCard>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GenericModal>
  )
}
