import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { usePost, notify } from '../../../components/component-items/helpers'
import { useParams } from 'react-router-dom'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { Form } from 'react-bootstrap'

type StartProjectModalFunction = {
  canStart: boolean
  open: boolean
  setOpen: (open: boolean) => void
}

export const StartProjectModal = ({ canStart, open, setOpen }: StartProjectModalFunction) => {
  const params = useParams() as any
  const project = params?.id
  const [location, setLocation] = useState('')
  const {
    state: { csrf },
  } = globalState()

  function handleSubmit() {
    if (canStart) {
      usePost(`/api/inventory/project/${project}/details/`, { location, start: true }, csrf)
    } else {
      notify({ type: 'error', message: 'Cannot start project, not enough inventory is available.' })
    }
  }

  return (
    <GenericModal
      heading={'Start Project'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button
          className={!canStart ? 'secondary' : ''}
          disabled={!canStart}
          onClick={() => handleSubmit()}
        >
          Start Project
        </GlobalStyles.Button>
      }
    >
      <Form.Group style={{ margin: '2em' }}>
        {canStart ? (
          <>
            <Form.Label>Choose Production Location</Form.Label>
            <Form.Control type="text" value={location} onChange={(e) => setLocation(e.target.value)} />
          </>
        ) : (
          <p style={{ color: 'red', textAlign: 'center' }}>
            Unable to start project, one or more items have insufficient inventory. Refer to the{' '}
            <strong>Allocatable</strong> column located on this page for more information.
          </p>
        )}
      </Form.Group>
    </GenericModal>
  )
}
