import React from 'react'
import styled from 'styled-components'
import { ButtonGroup as BootstrapButtonGroup } from 'react-bootstrap'

export const LargeButton = styled.button`
  font-size: 1.5rem;
  height: 3rem !important;
  width: 3rem !important;
`

export const ButtonGroup = styled(BootstrapButtonGroup)`
  button {
    padding: 0.5em 1.5em;
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 1rem;
    border-color: #001682;
    border-width: 2px;
    border-right: None;
    border-left: none;
    &:first-child {
      border-left: 2px solid #001682;
    }
    &:last-child {
      border-right: 2px solid #001682;
    }
    &:hover {
      border-color: #001682;
      background-color: #d9dffc;
      color: black;
    }
    &.active {
      border-color: #001682;
      background-color: #001682;
      color: white;
    }
  }
`
