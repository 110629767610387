// React
import React, { FunctionComponent, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import { TableCell } from '../../../../components/component-items/datatable'
import { useFetch, usePut, StickyElement } from '../../../../components/component-items/helpers'
import CardLoading from '../../../../components/component-items/loading-popover'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'

// Modals
import { PrintLabelsModal } from '../../../../components/modals/print-labels'
import { ComplianceHistory } from '../compliance-history'
import { ComplianceModal } from '../compliance-modal'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { globalState } from '../../../../store'
import { BackgroundLayer } from '../styles'
import { OrderOptions } from '../../../orders/order/styles'
import { AsnItem, AsnOption } from './item-helpers'
import { NonComplianceWorkflow } from './non-compliance-workflow'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faFileInvoiceDollar, faPrint, faWarehouse } from '@fortawesome/pro-duotone-svg-icons'

import { StatusBarProps, InventoryItemProps } from './types'

const StatusBar: FunctionComponent<StatusBarProps> = ({
  asn,
  name,
  warehouse,
  company,
  good = 0,
  defective = 0,
  sent,
  loaded,
  error,
}) => {
  return (
    <Row className="hide-in-percy">
      <Col>
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ color: '#5d78ff' }} />
            </div>
            <div className="status-text">
              Compliance - {name}
              <br />
              {good + defective} / {sent} received
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon style={{ color: '#FFB822' }} icon={faClipboardList} />
            </div>

            <div className="status-text">
              Processing ASN {asn}
              <div>
                Company: <strong>{company}</strong>
              </div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text" style={{ paddingRight: '1em' }}>
              Facility:
            </div>
            <div className="status-text">
              <strong>{warehouse}</strong>
            </div>
            <div className="status-icon" style={{ marginLeft: '2em' }}>
              <FontAwesomeIcon icon={faWarehouse} />
            </div>
          </div>
          {!loaded ? <CardLoading error={error} /> : null}
        </StatusBarStyled>
      </Col>
    </Row>
  )
}

export const NonCompliance = () => {
  const asn = location.pathname.split('/')[2]
  const { shipnotice_item_id: itemId } = useParams<{ shipnotice_item_id: string }>()
  const { response: item, error, loaded }: any = useFetch(`/api/inventory/asn/${asn}/item/${itemId}/`, {})
  const { response: shipnotice }: any = useFetch(`/api/inventory/asn/${asn}/details/`, {})
  const asnCompliance = shipnotice ? shipnotice.compliance : null

  const {
    id,
    product_variant: variant,
    compliant,
    good,
    defective,
    sku,
    over_short,
    received,
    warehouse,
    img,
    compliance: itemCompliance,
  }: InventoryItemProps = loaded ? item : {}

  const variantId = variant?.id
  const variantName = loaded ? variant.name : ''
  const barcode = loaded ? variant.barcode : ''
  const itemComplianceList = loaded ? itemCompliance.complianceList : []
  const status = loaded && shipnotice ? shipnotice.asnStatus : 'Processing'
  const sent = loaded ? parseInt(item.quantity) : 0
  const company = loaded ? variant.company : ''
  const current = 'non_compliance'

  const [printModal, setPrintModal] = useState(false)
  const [openComplianceHistory, setComplianceHistory] = useState(false)
  const [openCompliance, setCompliance] = useState(false)
  const itemComplianceCompleted = loaded ? compliant || (!compliant && itemComplianceList.length > 0) : false
  const asnComplianceCompleted = loaded ? item?.shipnotice?.compliance_completed : false

  const {
    state: { csrf },
  } = globalState()

  return (
    <>
      <StatusBar
        asn={asn}
        name={variantName}
        warehouse={warehouse}
        company={company}
        good={good}
        defective={defective}
        sent={sent}
        loaded={loaded}
        error={error}
      />
      <Row>
        <Col lg={3} xl={2}>
          <StickyElement>
            <OrderOptions>
              <ul>
                <GlobalStyles.Tooltip data-title="Print formatted labels given scancode">
                  <AsnOption onClick={() => setPrintModal(true)} icon={faPrint} title={'Print Labels'} />
                </GlobalStyles.Tooltip>
                {/* @ts-ignore */}
                <PrintLabelsModal open={printModal} setOpen={setPrintModal} id={variantId} scancode={barcode} />
                <GlobalStyles.Tooltip data-title="Displays list of all ASN compliance fees">
                  <AsnOption
                    onClick={() => setComplianceHistory(true)}
                    icon={faFileInvoiceDollar}
                    title={'All Compliance'}
                  />
                </GlobalStyles.Tooltip>
                <ComplianceHistory
                  open={openComplianceHistory}
                  setOpen={setComplianceHistory}
                  compliance={asnCompliance}
                />
              </ul>
            </OrderOptions>
          </StickyElement>
        </Col>
        <Col xl={7}>
          <GlobalStyles.FullPageCard>
            <GlobalStyles.CardHeader>
              <GlobalStyles.CardTitle>
                <h3>Item Compliance</h3>
              </GlobalStyles.CardTitle>
              <GlobalStyles.CardToolbar style={{ float: 'right' }}>
                <GlobalStyles.Button
                  onClick={() =>
                    usePut(`/api/inventory/asn/${asn}/item/${id}/mark_compliance/`, { compliant: false }, csrf)
                  }
                  className="secondary"
                  style={{ height: 40, width: 200 }}
                >
                  Mark Non-Compliant
                </GlobalStyles.Button>
                <GlobalStyles.Button
                  onClick={() =>
                    usePut(`/api/inventory/asn/${asn}/item/${id}/mark_compliance/`, { compliant: true }, csrf)
                  }
                  style={{ height: 40, width: 200 }}
                >
                  Mark Compliant
                </GlobalStyles.Button>
              </GlobalStyles.CardToolbar>
            </GlobalStyles.CardHeader>
            <div style={{ minHeight: 130 }}>
              <GlobalStyles.DataTable>
                <thead>
                  <tr>
                    <th />
                    <th>
                      <span className="text">Product</span>
                    </th>
                    <th>
                      <span className="text center">SKU</span>
                    </th>
                    <th>
                      <span className="text center">Sent</span>
                    </th>
                    <th>
                      <span className="text center">Good</span>
                    </th>
                    <th>
                      <span className="text center">Defective</span>
                    </th>
                    <th>
                      <span className="text center">Compliance</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <AsnItem
                    pId={variantId}
                    barcode={barcode}
                    sku={sku}
                    img={img}
                    name={variantName}
                    over_short={over_short}
                    received={received}
                    good={good}
                    defective={defective}
                    sent={sent}
                    compliant={compliant}
                    status={status}
                    key={id}
                    single
                  />
                </tbody>
                {!loaded ? <CardLoading error={error} /> : null}
              </GlobalStyles.DataTable>
            </div>
          </GlobalStyles.FullPageCard>
          {loaded && compliant !== null && !compliant && (
            <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em' }}>
              <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
                <GlobalStyles.CardTitle>
                  <h3>Non-Compliance Fees</h3>
                </GlobalStyles.CardTitle>
                <GlobalStyles.CardToolbar>
                  <GlobalStyles.Button
                    onClick={() => setCompliance(true)}
                    style={{ height: 40, marginRight: '-0.5em' }}
                  >
                    Update Fees
                  </GlobalStyles.Button>
                  {loaded && (
                    <ComplianceModal {...itemCompliance} itemId={id} open={openCompliance} setOpen={setCompliance} />
                  )}
                </GlobalStyles.CardToolbar>
              </GlobalStyles.CardHeader>
              <GlobalStyles.DataTable style={{ minHeight: '130px' }}>
                <thead>
                  <tr>
                    <th>
                      <span className="center text">User</span>
                    </th>
                    <th>
                      <span className="center text">Category</span>
                    </th>
                    <th>
                      <span className="center text">Labor (min)</span>
                    </th>
                    <th>
                      <span className="center text">Material ($)</span>
                    </th>
                    <th>
                      <span className="center text">Notes</span>
                    </th>
                    <th>
                      <span className="center text">Total Cost ($)</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {itemComplianceList?.map((comp: any, id: number) => (
                    <GlobalStyles.TableRow key={id}>
                      <TableCell center input={comp.name} />
                      <TableCell center input={comp.category} />
                      <TableCell center input={comp.labor} />
                      <TableCell center input={comp.materials} />
                      <TableCell center input={comp.notes} />
                      <TableCell center input={comp.amount} />
                    </GlobalStyles.TableRow>
                  ))}
                </tbody>
                {!loaded ? <CardLoading error={error} /> : null}
              </GlobalStyles.DataTable>
              {!loaded ? <CardLoading error={error} /> : null}
            </BackgroundLayer>
          )}
        </Col>
        <Col xl={3}>
          <NonComplianceWorkflow
            itemComplianceCompleted={itemComplianceCompleted}
            asnComplianceCompleted={asnComplianceCompleted}
            asn={asn}
            current={current}
            itemId={itemId}
            asnCompliance={asnCompliance}
          />
        </Col>
      </Row>
    </>
  )
}
