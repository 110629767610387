import React from 'react'
import { getWeek } from 'date-fns'

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

// Return the ordinal of a number
export const getOrdinal = (n: number) => {
  var s = ['th', 'st', 'nd', 'rd']
  var v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const formatBytes = (bytes: any, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getMonthNames = (locale = 'en-US') => {
  const months: string[] = []
  for (let i = 0; i < 12; i++) {
    const month = new Date(0, i).toLocaleString(locale, { month: 'long' })
    months.push(month)
  }
  return months
}

export const getWeekdayNames = (locale = 'en-US') => {
  const weekdays: string[] = []
  for (let i = 0; i < 7; i++) {
    const weekday = new Date(0, 0, i).toLocaleString(locale, { weekday: 'long' })
    weekdays.push(weekday)
  }
  return weekdays
}

export const formatDate = (dateString: string) => {
  const dateOptions: any = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }
  const timeOptions: any = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  // Format date and time separately
  const date = new Date(dateString)
  const formattedDate = date.toLocaleDateString('en-US', dateOptions)
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions)

  // Combine date and time with a newline in between
  return (
    <div style={{ minWidth: 100, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>
        <strong style={{ fontWeight: 500 }}>{formattedDate}</strong>
      </div>
      <div>{formattedTime}</div>
    </div>
  )
}
