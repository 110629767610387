import React, { useState } from 'react'
import { usePost, notify } from '../../components/component-items/helpers'
import { globalState } from '../../store'
import { toast } from 'react-toastify'
import { useTheme } from 'styled-components'

// Components
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'
import { Form, InputGroup, Col, Row } from 'react-bootstrap'
import { DragNDrop } from '../../components/component-items/dragndrop'
import { StatusBarStyled } from '../../components/component-items/status-bar'
import Checkbox from '../../components/component-items/checkbox'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoSquare } from '@fortawesome/pro-duotone-svg-icons'

const uploadFile = async (file: any, url: string, name: string, csrf: string) => {
  // Check there is a file first
  if (!file) {
    notify({ type: 'error', message: 'No valid file found.' })
    return
  }
  const formData = new window.FormData()
  formData.append(name, file)
  const resp = await usePost(url, formData, csrf, false, false)
  if (resp.success) {
    notify({ type: 'success', message: resp.success })
  }
}

type UploadAdjustInventoryModalProps = {
  open: boolean
  setOpen: (isOpen?: boolean) => void
  url: string
}

export const UploadAdjustInventoryModal = ({ open, setOpen, url }: UploadAdjustInventoryModalProps) => {
  const [file, setFile] = useState(null)
  const {
    state: { csrf },
  } = globalState()
  const defaultColumns = {
    lot_number: false,
    expiry_date: false,
    best_before_date: false,
    manufacture_date: false,
    cycle_count: false,
    destination_location: false,
  }
  const [columns, setColumns] = useState(defaultColumns)

  const generateCSV = () => {
    const downloadURL =
      url.replace('inventory_list', 'inventory_csv_list') +
      '&no_page=true' +
      `&cycle_count=${columns.cycle_count}` +
      `&expiry_date=${columns.expiry_date}` +
      `&best_before_date=${columns.best_before_date}` +
      `&manufacture_date=${columns.manufacture_date}` +
      `&lot_number=${columns.lot_number}` +
      `&destination_location=${columns.destination_location}`
    window.location.href = downloadURL
    setOpen(false)
  }

  const theme = useTheme() as any
  return (
    <GenericModal
      heading={'Adjust Inventory'}
      show={open}
      size="xl"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => uploadFile(file, `/api/inventory/inventory-item/adjust_inventory/`, 'inventoryItems', csrf)}
        >
          Upload
        </GlobalStyles.Button>
      }
    >
      <div style={{ justifyContent: 'center', margin: 'auto', maxWidth: '85%', padding: '1em' }}>
        <StatusBarStyled style={{ backgroundColor: theme.colors.ghostWhite }}>
          <div className="header-item">
            <div className="status-icon">
              <FontAwesomeIcon icon={faInfoSquare} style={{ color: theme.colors.freshEggplant }} />
            </div>
            <div className="status-text">
              1. Select the Adjustment Options below that correspond to fields you wish to update. Once done, click{' '}
              <u>Generate CSV</u> and a CSV will be downloaded with neccessary columns. <br />
              2. Update the CSV generated with new values and then upload here to confirm changes. Make sure you
              filtered the correct inventory items before doing so.
            </div>
          </div>
        </StatusBarStyled>
        <Row style={{ margin: '0 2em 2em' }}>
          <Form.Group as={Col} lg="6" md="12">
            <Form.Label>Dates</Form.Label>
            <InputGroup>
              <Checkbox
                selected={columns.lot_number}
                setSelected={() => setColumns({ ...columns, lot_number: !columns.lot_number })}
              />
              <p style={{ padding: '0 1em', lineHeight: '24px' }}>Lot Number (lot_number)</p>
            </InputGroup>
            <InputGroup>
              <Checkbox
                selected={columns.expiry_date}
                setSelected={() => setColumns({ ...columns, expiry_date: !columns.expiry_date })}
              />
              <p style={{ padding: '0 1em', lineHeight: '24px' }}>Expiry Date (expiry_date)</p>
            </InputGroup>
            <InputGroup>
              <Checkbox
                selected={columns.best_before_date}
                setSelected={() => setColumns({ ...columns, best_before_date: !columns.best_before_date })}
              />
              <p style={{ padding: '0 1em', lineHeight: '24px' }}>Best Before Date (best_before_date)</p>
            </InputGroup>
            <InputGroup>
              <Checkbox
                selected={columns.manufacture_date}
                setSelected={() => setColumns({ ...columns, manufacture_date: !columns.manufacture_date })}
              />
              <p style={{ padding: '0 1em', lineHeight: '24px' }}>Manufacture Date (manufacture_date)</p>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="6" md="12">
            <Form.Label>Inventory Count Adjustment</Form.Label>
            <InputGroup>
              <Checkbox
                selected={columns.cycle_count}
                setSelected={() => setColumns({ ...columns, cycle_count: !columns.cycle_count })}
              />
              <p style={{ padding: '0 1em', lineHeight: '24px' }}>Cycle Count (variance + count)</p>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="8" md="12">
            <Form.Label>Movement</Form.Label>
            <InputGroup>
              <Checkbox
                selected={columns.destination_location}
                setSelected={() => setColumns({ ...columns, destination_location: !columns.destination_location })}
              />
              <p style={{ padding: '0 1em', lineHeight: '24px' }}>Location (destination_location_id)</p>
            </InputGroup>
          </Form.Group>
        </Row>

        <Form.Label style={{ marginTop: '.5em' }}>
          CSV File Upload (
          <a href="#" onClick={() => generateCSV()}>
            Generate CSV
          </a>
          )
        </Form.Label>
        <DragNDrop file={file} setFile={setFile} fileTypes={'.csv'} />
      </div>
    </GenericModal>
  )
}
