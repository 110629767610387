import React from 'react'

// Components
import MyResponsiveLine from '../../components/component-items/line-graph'
import CardLoading from '../../components/component-items/loading-popover'
import { GraphCardSmallStyled } from './styles'

import { GraphCardSmallProps } from './types'

export const GraphCardSmall = ({
  title,
  desc,
  total,
  color,
  loaded,
  data,
  pointName,
  yLabel,
  error,
}: GraphCardSmallProps) => {
  return (
    <GraphCardSmallStyled>
      <div className="widget-body">
        <div className="wrapper">
          <div className="details">
            <div className="title">{title}</div>
            <div className="desc">{desc}</div>
          </div>
          <div className="total">+{total}</div>
        </div>
        <div className="graph-container">
          <MyResponsiveLine data={data} color={color} pointName={pointName} yLabel={yLabel} />
        </div>
      </div>
      {!loaded ? <CardLoading error={error} /> : null}
    </GraphCardSmallStyled>
  )
}

export default GraphCardSmall
