import React, { useState, useRef, useEffect } from 'react'
import { showLoader, hideLoader } from '../../../components/component-items/helpers'
import styled from 'styled-components'
import { Form, Col, Row } from 'react-bootstrap'
import CsvDownload from 'react-json-to-csv'
import { useTheme } from 'styled-components'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import CardLoading from '../../../components/component-items/loading-popover'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { TableCell } from '../../../components/component-items/datatable'

const TableRow = styled(GlobalStyles.TableRow)`
  td {
    border-top: ${(props: any) => (props.id === 0 ? '1px solid darkgrey' : '')};
  }
`

export const PostageRates = () => {
  const theme = useTheme()

  const [details, setDetails] = useState({
    warehouse: '10', // Temecula
    type: 'zones',
    length: '',
    width: '',
    height: '',
    weight: '',
  })
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
  })

  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)
  const [resp, setResp] = useState<any>(false)
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    fetch('/api/postage/rating-tool/')
      .then((response) => response.json())
      .then((json) => {
        setResp(json)
        setLoaded(true)
      })
      .catch((error) => {
        setLoaded(true)
        setError('ERROR: ' + error)
      })
  }, [])
  const warehouses: any = loaded ? resp.warehouses : []
  const countries = loaded ? resp.countries : []

  const [results, setResults] = useState([])
  const zones = [...new Set(results.map((rate: any) => rate.zone))]

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    showLoader()

    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    await fetch('/api/postage/rating-tool/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...details, ...{ address } }),
    })
      .then((response) => response.json())
      .then((json) => {
        setResults(json.results)
        hideLoader()
      })
      .catch((error) => {
        setLoaded(true)
        setError('ERROR: ' + error)
      })
  }
  return (
    <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
      <Row>
        <Form.Group className="required">
          <Form.Label>Choose a Warehouse:</Form.Label>
          <Form.Select
            value={details.warehouse}
            onChange={(e) => setDetails({ ...details, warehouse: e.target.value })}
            required
          >
            {warehouses.map(({ id, location_name }: any) => (
              <option value={id} key={id}>
                {location_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="required">
          <Form.Label>Choose Rating Type</Form.Label>
          <Form.Select value={details.type} onChange={(e) => setDetails({ ...details, type: e.target.value })} required>
            <option value="zones">All Zones</option>
            <option value="address">Address</option>
          </Form.Select>
        </Form.Group>
      </Row>
      {details.type === 'address' ? (
        <Row>
          <Form.Group as={Col} md="6" className="required">
            <Form.Label>Address 1</Form.Label>
            <Form.Control
              type="text"
              placeholder="Address 1"
              value={address.line1}
              onChange={(e) => setAddress({ ...address, line1: e.target.value })}
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid Address.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Address 2</Form.Label>
            <Form.Control
              type="text"
              placeholder="Address 2"
              value={address.line2}
              onChange={(e) => setAddress({ ...address, line2: e.target.value })}
            />
            <Form.Control.Feedback type="invalid">Please provide a valid Address.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" className="required">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              value={address.city}
              onChange={(e) => setAddress({ ...address, city: e.target.value })}
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid city.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" className="required">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              placeholder="State"
              value={address.state}
              onChange={(e) => setAddress({ ...address, state: e.target.value })}
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid state.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" className="required">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              placeholder="Zip"
              value={address.zip}
              onChange={(e) => setAddress({ ...address, zip: e.target.value })}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid zip. If there is none, enter 000000
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" className="required">
            <Form.Label>Country</Form.Label>
            <Form.Select value={address.country} onChange={(e) => setAddress({ ...address, country: e.target.value })}>
              {Object.keys(countries).map((c, id) => (
                <option value={c} key={id}>
                  {countries[c]}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" className="required">
              Please provide a valid country.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      ) : null}
      <Row>
        <Form.Group as={Col} md="3" className="required">
          <Form.Label>Length (in)</Form.Label>
          <Form.Control
            type="text"
            value={details.length}
            onChange={(e) => setDetails({ ...details, length: e.target.value })}
            placeholder="Length"
            required
          />
        </Form.Group>
        <Form.Group as={Col} md="3" className="required">
          <Form.Label>Width (in)</Form.Label>
          <Form.Control
            type="text"
            value={details.width}
            onChange={(e) => setDetails({ ...details, width: e.target.value })}
            placeholder="Width"
            required
          />
        </Form.Group>
        <Form.Group as={Col} md="3" className="required">
          <Form.Label>Height (in)</Form.Label>
          <Form.Control
            type="text"
            value={details.height}
            onChange={(e) => setDetails({ ...details, height: e.target.value })}
            placeholder="Height"
            required
          />
        </Form.Group>
        <Form.Group as={Col} md="3" className="required">
          <Form.Label>Weight (oz)</Form.Label>
          <Form.Control
            type="text"
            value={details.weight}
            onChange={(e) => setDetails({ ...details, weight: e.target.value })}
            placeholder="Weight"
            required
          />
        </Form.Group>
      </Row>
      <GlobalStyles.Button style={{ minWidth: 150, margin: '1em 0 0 1em', float: 'right' }}>Submit</GlobalStyles.Button>
      {results.length ? (
        <>
          <GlobalStyles.Button
            as="div"
            style={{ minWidth: 150, margin: '1em 0 0 1em', float: 'right' }}
            className="secondary"
            type="button"
          >
            <CsvDownload type="button" delimiter="," data={results}>
              Export (CSV)
            </CsvDownload>
          </GlobalStyles.Button>
          <GlobalStyles.DataTable style={{ marginTop: '8em' }}>
            <thead>
              <tr>
                <th colSpan={5}>
                  <span className="text center" style={{ fontSize: '1.2em', textTransform: 'capitalize' }}>
                    Postage for {details.type === 'address' ? 'Single Address' : 'All Zones'}
                  </span>
                </th>
              </tr>
              <tr>
                <th>
                  <span className="text center">Zone</span>
                </th>
                <th>
                  <span className="text center">Carrier</span>
                </th>
                <th>
                  <span className="text center">Service Level</span>
                </th>
                <th>
                  <span className="text center">Delivery Days</span>
                </th>
                <th>
                  <span className="text center">Amount</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {zones.map((zone: any, index: number) => {
                const rates: any = results.filter((rate: any) => rate.zone === zone)
                return (
                  <React.Fragment key={index}>
                    <GlobalStyles.TableRow>
                      <TableCell
                        rowspan={rates.length + 1}
                        style={{
                          fontSize: '1.5em',
                          fontWeight: 500,
                          color: theme.colors.comet,
                          borderTop: '1px solid darkgrey',
                        }}
                        center
                      >
                        {rates[0].zone}
                      </TableCell>
                    </GlobalStyles.TableRow>
                    {rates
                      .sort((a: any, b: any) => a.amount - b.amount)
                      .map(({ carrier, service, delivery_days, amount }: any, id: number) => (
                        <TableRow id={String(id)} key={id}>
                          <TableCell center input={carrier} />
                          <TableCell center input={service} />
                          <TableCell center input={delivery_days} />
                          <TableCell center>
                            <StatusBoxStyled className={'blue'} style={{ width: 90, margin: 'auto' }}>
                              ${amount}
                            </StatusBoxStyled>
                          </TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                )
              })}
            </tbody>
          </GlobalStyles.DataTable>
        </>
      ) : null}
      {!loaded ? <CardLoading error={!!error} text={error} /> : null}
    </Form>
  )
}
