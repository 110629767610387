import React from 'react'

import ReactMarkdown from 'react-markdown'
import rehypeSanitize from 'rehype-sanitize'
import gfm from 'remark-gfm'

import { useTheme } from 'styled-components'
import { format, differenceInWeeks } from 'date-fns'
import { StatusBoxStyled } from '../component-items/status-box'
import { NotificationType } from '../../enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBolt,
  faBoxAlt,
  faCubes,
  faExclamationTriangle,
  faRampLoading,
  faSparkles,
  faTasks,
  faWrench,
} from '@fortawesome/pro-duotone-svg-icons'

export function formatDate(datetime: string | Date) {
  const now = new Date()
  const parsedDatetime = new Date(datetime)

  const weeksAgo = Math.abs(differenceInWeeks(now, parsedDatetime))
  if (weeksAgo > 0) {
    return format(parsedDatetime, 'MMMM d, yyyy, h:mm a')
  } else {
    return format(parsedDatetime, 'EEEE, h:mm a')
  }
}

function StatusBox({ status, children }: { status: string; children: any }) {
  return (
    <StatusBoxStyled
      className={`${status} bold`}
      style={{
        display: 'inline-block',
        padding: '0 .3em',
        fontWeight: '0.8em',
        lineHeight: '1.4em',
        opacity: 80,
        borderWidth: 2,
      }}
    >
      {children}
    </StatusBoxStyled>
  )
}

function parseMentions(text: string, recipients: string[]) {
  const parsedText = text.split(/(@\w+\b)/g)
  const length = parsedText.length - 1
  return parsedText.map((part, index) => {
    if (part.startsWith('@') && recipients.includes(part.slice(1))) {
      return (
        <React.Fragment key={index}>
          {index === 0 ? '' : ' '}
          <span key={index} style={{ color: 'darkmagenta' }}>
            {part}
          </span>
          {index === length ? '' : ' '}
        </React.Fragment>
      )
    } else {
      return part
    }
  })
}

function parseStatusTags(text: string, recipients: string[]) {
  let lastIndex = 0
  const parts = []
  const statusRegex = /<status:(\w+)>(.*?)<\/status>/g
  let match
  while ((match = statusRegex.exec(text)) !== null) {
    const beforeStatus = parseMentions(text.slice(lastIndex, match.index), recipients)
    parts.push(
      ...beforeStatus,
      <>
        {' '}
        <StatusBox key={lastIndex} status={match[1]}>
          {match[2]}
        </StatusBox>{' '}
      </>
    )
    lastIndex = match.index + match[0].length
  }
  parts.push(...parseMentions(text.slice(lastIndex), recipients))
  return parts
}

export function NotificationIcon({ type = '' }: { type: string }) {
  const theme: any = useTheme()
  let icon, color
  switch (type) {
    case NotificationType.TypeOrder:
      icon = faBoxAlt
      color = theme.colors.dodgerBlue
      break
    case NotificationType.TypeASN:
      icon = faRampLoading
      color = theme.colors.dodgerBlue
      break
    case NotificationType.TypeProject:
      icon = faTasks
      color = theme.colors.dodgerBlue
      break
    case NotificationType.TypeProductVariant:
      icon = faCubes
      color = theme.colors.dodgerBlue
      break
    case NotificationType.TypeSystemUpdate:
      icon = faBolt
      color = theme.colors.mySin
      break
    case NotificationType.TypeFeatureUpdate:
      icon = faSparkles
      color = theme.colors.freshEggplant
      break
    case NotificationType.TypeMaintenance:
      icon = faWrench
      color = theme.colors.mySin
      break
    case NotificationType.TypeSystemAlert:
      icon = faExclamationTriangle
      color = theme.colors.radicalRed
      break
    default:
      icon = faBoxAlt
      break
  }
  return <FontAwesomeIcon icon={icon} color={color} />
}

function filterMarkup(message: string) {
  return message.replaceAll('@', '&#64;')
}

export function NotificationMessage({ message = '', recipients = [] }: { message: string; recipients: string[] }) {
  // Convert to readable markup
  const parts = parseStatusTags(message, recipients)
  message = filterMarkup(message)
  return (
    <div>
      {parts.map((part, index) =>
        typeof part === 'string' ? (
          <ReactMarkdown
            key={index}
            // @ts-ignore
            rehypePlugins={[rehypeSanitize]}
            // @ts-ignore
            // remarkPlugins={[gfm]}
            components={{ p: ({ children }) => <>{children}</> }}
          >
            {part.trim()}
          </ReactMarkdown>
        ) : (
          part
        )
      )}
    </div>
  )
}
