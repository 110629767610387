import React, { useState } from 'react'
import { usePut, notify } from '../../components/component-items/helpers'
import { globalState } from '../../store'
import { Col, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { toast } from 'react-toastify'

// Components
import GlobalStyles from '../../components/component-items/styles'
import { DragNDrop } from '../../components/component-items/dragndrop'
import { StatusBarStyled } from '../../components/component-items/status-bar'
import { useTheme } from 'styled-components'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'

export const UploadPrices = () => {
  const theme: any = useTheme()
  const [file, setFile] = useState(null)
  const {
    state: { csrf },
  } = globalState()

  const uploadFile = async () => {
    if (!file) {
      return
    }
    const formData = new window.FormData()
    formData.append('prices_upload', file, 'prices_upload.csv')
    const resp = await usePut('/api/product/variant/upload_prices/', formData, csrf, false, false)
    if (resp.success) {
      notify({ type: 'success', message: resp.success })
    }
  }

  return (
    <>
      <Row>
        <Col>
          <StatusBarStyled>
            <div className="header-item">
              <div className="status-icon">
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: theme.colors.mySin }} />
              </div>
              <div className="status-text">
                <strong>Please Note</strong>: CSV Files must be formatted like the template, any errors will be
                reported, and the entire upload will fail
              </div>
            </div>
          </StatusBarStyled>
        </Col>
      </Row>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Update Prices</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '50%' }}>
          <Form.Label style={{ marginTop: '1em' }}>
            CSV File Upload (<a href="/api/static/templates/new-prices.csv">Download Template</a>)
          </Form.Label>
          <DragNDrop {...{ file, setFile }} fileTypes={'.csv'} />
          <GlobalStyles.Button onClick={() => uploadFile()} style={{ minWidth: 150, margin: 0, float: 'right' }}>
            Submit
          </GlobalStyles.Button>
        </div>
      </GlobalStyles.FullPageCard>
    </>
  )
}
