import React, { useState, FunctionComponent } from 'react'
import GlobalStyles from '../component-items/styles'
import { Form, Col, Modal, Row } from 'react-bootstrap'

import { PrintInventoryItemsModalProps, PrintItemType } from './types'

export const PrintInventoryItemsModal: FunctionComponent<PrintInventoryItemsModalProps> = ({
  id,
  onClose,
  open,
  itemType,
}) => {
  const [quantity, setQuantity] = useState(1)

  const handleSubmit = () => {
    const params = new URLSearchParams({
      print_type: 'not_printed',
      print_quantity: `${quantity}`,
    })
    let url
    switch (itemType) {
      case PrintItemType.InventoryItem:
        url = `/api/product/variant/${id}/print_inventory_items/?${params.toString()}`
        break
      case PrintItemType.ProductVariant:
        url = `/api/inventory/inventory-item/${id}/print_label/?${params.toString()}`
        break
      default:
        break
    }

    window.open(url, '_blank')
    onClose()
  }

  return (
    <Modal show={open} onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Print Inventory Item Barcode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ justifyContent: 'center' }}>
            <Form.Group as={Col} offset="2" md="8" controlId="validationCustom01" className="required">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                placeholder="# of Labels"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
                required
                min={1}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Quantity.</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <GlobalStyles.Button type="submit" style={{ minWidth: 125 }} onSubmit={handleSubmit}>
            Print
          </GlobalStyles.Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
