import React from 'react'

// Components
import { TableCell } from '../../../../components/component-items/datatable'
import { Form } from 'react-bootstrap'
import Select from 'react-select'

// Types
import { 
  ASNEventOptions, 
  ASNStatusTypes, 
  BillingEventOptions, 
  EventProps, 
  InventoryEventOptions, 
  OrderEventOptions, 
  OrderStatusTypes, 
  ProductVariantEventOptions, 
  ReturnEventOptions, 
  ReturnStatusTypes} from './types'

export const Event = ({ automationType, event, setEvent }: EventProps) => {
  let hasEventConditions = ["order_status", "asn_status", "return_status"].includes(event.category)
  let optionsList: any = []
  if (automationType === 'order') {
    optionsList = OrderStatusTypes
  } else if (automationType === 'asn') {
    optionsList = ASNStatusTypes
  } else if (automationType === 'return') {
    optionsList = ReturnStatusTypes
  }
  return (
    <>
      <TableCell>
        <Form.Select
          required
          value={event.category}
          onChange={(e) => setEvent((w: any) => ({ ...w, category: e.target.value }))}
        >
          <option value={''} disabled hidden>
            -Type-
          </option>
          <option value={'now'}>Now</option>
          {automationType === 'order' ? (
            <OrderEventOptions />
          ) : automationType === 'asn' ? (
            <ASNEventOptions />
          ) : automationType === 'return' ? (
            <ReturnEventOptions />
          ) : automationType === 'inventory' ? (
            <InventoryEventOptions />
          ) : automationType === 'product_variant' ? (
            <ProductVariantEventOptions />
          ) : automationType === 'billing' ? (
            <BillingEventOptions />
          ) : null}
        </Form.Select>
      </TableCell>
      {hasEventConditions && (
        <>
          <TableCell style={{ lineHeight: '40px' }}>To</TableCell>
          <TableCell style={{ background: 'rgb(0,0,0,0)', minWidth: 250 }}>
            <Select
              options={optionsList}
              value={optionsList.find((o: any) => o.value === event.conditions)}
              onChange={(e: any) => setEvent((w: any) => ({ ...w, conditions: e.value }))}
            />
          </TableCell>
        </>
      )}
    </>
  )
}
