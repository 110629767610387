import React, { useRef } from 'react'
import { usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { OrderStatusModalProps } from './types'

export const OrderStatusModal = ({
  order,
  statusList,
  manualStatusModal,
  setManualStatusModal,
}: OrderStatusModalProps) => {
  const changeStatusId = useRef(null)
  const {
    state: { csrf },
  } = globalState()
  return (
    <GenericModal
      heading={'Change Order Status'}
      show={manualStatusModal}
      onHide={() => setManualStatusModal(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() =>
            usePost(
              `/api/order/${order}/change_status/`,
              // @ts-ignore
              { pk: parseInt(order), status: changeStatusId?.current?.value },
              csrf
            )
          }
        >
          Save
        </GlobalStyles.Button>
      }
    >
      <InputGroup>
        <Form.Group controlId="exampleForm.ControlSelect1" style={{ width: '100%', padding: '2em 2em 3em' }}>
          <Form.Label>Please choose the order status you wish to move this to:</Form.Label>
          <Form.Select ref={changeStatusId}>
            {statusList?.map((st: any, id: number) => (
              <option value={st[0]} key={id}>
                {st[1]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </InputGroup>
    </GenericModal>
  )
}
