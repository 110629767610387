import React, { useState } from 'react'
import { usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import { DragNDrop } from '../../../components/component-items/dragndrop'

import { UploadProductModalProps } from './types'

export const UploadProductModal = ({ open, setOpen, asn }: UploadProductModalProps) => {
  const [file, setFile] = useState(null)
  const {
    state: { csrf },
  } = globalState()

  const uploadFile = () => {
    // Check there is a file first
    if (!file) {
      return
    }
    const name = 'asn_items'
    const extension = '.csv'
    var formData = new window.FormData()
    formData.append(name, file, name + extension)
    usePost(`/api/inventory/asn/${asn}/upload_products/`, formData, csrf, true, false)
  }

  return (
    <GenericModal
      heading={'Upload ASN Items'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={uploadFile}>
          Upload
        </GlobalStyles.Button>
      }
    >
      <div style={{ justifyContent: 'center', margin: 'auto', padding: '1em' }}>
        <Form.Label style={{ marginTop: '1em' }}>
          CSV File Upload (<a href="/api/static/templates/asn-product-upload-template.csv">Download Template</a>)
        </Form.Label>
        <DragNDrop {...{ file, setFile }} fileTypes={'.csv'} />
      </div>
    </GenericModal>
  )
}
