import React, { useState, useEffect } from 'react'
import { useFetch, usePut } from '../../../components/component-items/helpers'

// Components
import { TableCell } from '../../../components/component-items/datatable'
import { AddItemRow } from './tablelist'
import { ButtonLoading } from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { ExchangeListStyled } from './styles'
import { globalState } from '../../../store'

import { ReturnModalProps, ReturnItemProps } from './types'

const ReturnItem = ({ img, pId, desc, sku, qty, returnReasons, returns, setReturns }: ReturnItemProps) => {
  const [returnType, setReturnType] = useState('dnr')
  const [returnOption, setReturnOption] = useState(returnReasons[0].id)
  const [quantity, setQuantity] = useState(qty)

  useEffect(() => {
    setReturns({ ...returns, [pId]: { return_type: returnType, quantity: quantity, return_reason: returnOption } })
  }, [returnType, returnOption, quantity])

  return (
    <GlobalStyles.TableRow>
      <TableCell input={<img src={img} style={{ maxWidth: 50 }} alt="product" />} />
      <TableCell input={desc}>
        <div className="input">{sku}</div>
      </TableCell>
      <TableCell input={qty} />
      <TableCell
        input={
          <Form.Select value={returnType} onChange={(e) => setReturnType(e.target.value)}>
            <option value="dnr">Do not return</option>
            <option value="refund">Refund</option>
            <option value="exchange">Exchange</option>
            <option value="undeliverable">Returned Undeliverable</option>
          </Form.Select>
        }
      />
      {returnType !== 'dnr' ? (
        <>
          <TableCell
            input={
              <InputGroup>
                <Form.Control
                  min="0"
                  type="number"
                  placeholder="To Return"
                  value={quantity}
                  // @ts-ignore
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                />
              </InputGroup>
            }
          />
          <TableCell
            input={
              <Form.Select value={returnOption} onChange={(e) => setReturnOption(e.target.value)}>
                {returnReasons.map((r: any, id: number) => (
                  <option value={r.id} key={id}>
                    {r.reason}
                  </option>
                ))}
              </Form.Select>
            }
          />
        </>
      ) : null}
    </GlobalStyles.TableRow>
  )
}

export const ReturnModal = ({ returnModal, setReturnModal, order }: ReturnModalProps) => {
  const {
    state: { csrf },
  } = globalState()
  const url = `/api/order/${order}/returns/`
  const res: any = useFetch(url, {})
  const loaded = res.loaded
  const dataResp = res.response

  const orderItems = loaded ? dataResp.returnItems : null
  const returnReasons = loaded ? dataResp.returnReasons : []

  const [returns, setReturns] = useState({})
  const [createLabel, setLabel] = useState(false)
  const allOrderItems = orderItems ? orderItems.products.concat(orderItems.promoItems) : []

  useEffect(() => {
    const returnItems: any = {}
    allOrderItems.map((i: any) => {
      returnItems[i.pId] = {
        return_type: 'dnr',
        quantity: 0,
        return_reason: '',
      }
    })
    setReturns(returnItems)
  }, [orderItems])

  const [productCount, setProductCount] = useState<any>([])

  const [showExchange, setShowExchange] = useState(false)
  useEffect(() => {
    let showExchange = false
    for (const [_, value] of Object.entries(returns) as any) {
      if (value.return_type === 'exchange') {
        showExchange = true
      }
    }
    setShowExchange(showExchange)
  }, [returns])

  const [search, setSearch] = useState('')
  const resp: any = useFetch(
    showExchange ? `/api/order/${order}/product_search/?q=${encodeURIComponent(search)}&returns=1` : '',
    {}
  )
  const exchangeItems = resp.loaded ? resp.response.results : []

  return (
    <GenericModal
      heading={'Create Return'}
      show={returnModal}
      size={'xl'}
      onHide={() => setReturnModal(false)}
      buttons={
        <>
          <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setReturnModal(false)}>
            Close
          </GlobalStyles.Button>
          <ButtonLoading
            style={{ minWidth: 125 }}
            onSubmit={() =>
              usePut(
                `/api/order/${order}/create_return/`,
                { returns: returns, createLabel: createLabel, exchanges: productCount },
                csrf
              )
            }
          >
            Submit
          </ButtonLoading>
        </>
      }
    >
      <GlobalStyles.DataTable>
        <thead>
          <tr>
            <th>
              <span className="text"></span>
            </th>
            <th>
              <span className="text">Item</span>
            </th>
            <th>
              <span className="text">Sent</span>
            </th>
            <th>
              <span className="text">Return Type</span>
            </th>
            <th>
              <span className="text">Quantity</span>
            </th>
            <th>
              <span className="text">Reason</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {allOrderItems.map((i: any, id: number) => (
            <ReturnItem {...i} {...{ returnReasons, returns, setReturns }} key={id} />
          ))}
        </tbody>
      </GlobalStyles.DataTable>
      <InputGroup style={{ justifyContent: 'center', padding: '2em 2em 0em' }}>
        <Checkbox selected={createLabel} setSelected={() => setLabel(!createLabel)} />
        <p style={{ padding: '0 1em', lineHeight: '24px' }}>Create Label and Email Copy to Customer</p>
      </InputGroup>
      {/* This was pulled in from components/order-page/tablelist */}
      {showExchange ? (
        <ExchangeListStyled onSubmit={(e) => e.preventDefault()} style={{ padding: '0em 4%' }}>
          <h3>Exchange Items</h3>
          <div className="search-bar" style={{ padding: 10 }}>
            <InputGroup>
              <Form.Control placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
              <GlobalStyles.Button type="submit" className="secondary" style={{ minWidth: 125, margin: 0 }}>
                Search
              </GlobalStyles.Button>
            </InputGroup>
          </div>
          <div className="scrolltable">
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text">Image</span>
                  </th>
                  <th>
                    <span className="text">Product Variant</span>
                  </th>
                  <th>
                    <span className="text">In Stock</span>
                  </th>
                  <th>
                    <span className="text">Quantity</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {exchangeItems.map((p: any, id: number) => (
                  <AddItemRow
                    img={p.img}
                    sku={p.sku}
                    desc={p.desc}
                    available={p.available}
                    qty={productCount[p.pId] ? productCount[p.pId] : ''}
                    onChange={(e: any) => setProductCount({ ...productCount, [p.pId]: e.target.value })}
                    key={id}
                  />
                ))}
              </tbody>
            </GlobalStyles.DataTable>
          </div>
        </ExchangeListStyled>
      ) : null}
    </GenericModal>
  )
}
