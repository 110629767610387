import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { usePost, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { Form } from 'react-bootstrap'
import { FilterListItem } from '../styles'

// Types
import { ExportOrdersProps } from './types'

export const ExportOrders = ({ showExportModal, setExportModal, url, body }: ExportOrdersProps) => {
  let history = useHistory()
  const {
    state: { csrf },
  } = globalState()
  const [exportType, setExportType] = useState('order')

  const handleExportOrders = async () => {
    const reload = false
    const stringifyBody = true
    const return_response = true
    const resp = await usePost(
      url,
      Object.assign(body, { ...{ exportType } }),
      csrf,
      reload,
      stringifyBody,
      return_response
    )
    if (resp.success) {
      notify({
        type: 'success',
        message:
          'Orders have been requested, you will be redirect to the reports page and will receive an email once the report is generated.',
      })
      history.push('/company/reports/')
    }
  }

  return (
    <GenericModal
      heading={'Export Orders'}
      show={showExportModal}
      size="lg"
      onHide={() => setExportModal(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleExportOrders}>
          Export
        </GlobalStyles.Button>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Export Order List</Form.Label>
          <Form.Select value={exportType} onChange={(e) => setExportType(e.target.value)}>
            <optgroup label="Export Type">
              <option value={'order'}>Order Detail Export</option>
              <option value={'shipment'}>Shipment Detail Export</option>
              <option value={'lot_numbers'}>Lot Number Export</option>
            </optgroup>
          </Form.Select>
        </FilterListItem>
      </div>
    </GenericModal>
  )
}
