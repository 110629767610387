import React, { Link } from 'react-router-dom'

// Components
import GlobalStyles from '../../components/component-items/styles'

import { PostPage, Background, SignIn, Information, Logo } from './styles'

export const BadRequestPage = () => {
  return (
    <PostPage>
      <Background>
        <SignIn>
          <Information>
            <Logo src={'/api/static/assets/login/logo.png'} />
            <div style={{ textAlign: 'center', fontSize: '6em' }}>😢</div>
            <h1>500</h1>
            <p>
              We&#39;re sorry, there seems to be an issue on our end! Please contact support at{' '}
              <a href="mailto:support@shippingtree.co">support@shippingtree.co</a>.
            </p>
            <Link to="/">
              <GlobalStyles.Button style={{ minWidth: 125 }}>Home</GlobalStyles.Button>
            </Link>
          </Information>
        </SignIn>
      </Background>
    </PostPage>
  )
}
