import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { CheckboxProps } from './types'

const CheckboxStyled = styled.label`
  width: 18px;
  height: 18px;
  top: 2px;
  padding: 0;
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  span {
    background: ${(props) => props.theme.colors.zircon};
    border-radius: 3px;
    position: absolute;
    left: 0;
    height: 18px;
    width: 18px;
    &.active:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -2px;
      margin-top: -6px;
      width: 5px;
      height: 10px;
      border-width: 0 2px 2px 0 !important;
      border: solid #99a6bf;
      transform: rotate(45deg);
    }
  }
`
export const Checkbox: FunctionComponent<CheckboxProps> = ({ selected, setSelected, style }) => (
  <CheckboxStyled onClick={() => setSelected(!selected)} {...{ style }}>
    <span className={selected ? 'active' : ''}></span>
  </CheckboxStyled>
)

export default Checkbox
