import React from 'react'
import styled from 'styled-components'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { OrderInfo } from './styles'

import { TableRowProps } from './types'

const TableRow = ({ text, count, color }: TableRowProps) => (
  <GlobalStyles.TableRow>
    <TableCell input={text} />
    <TableCell
      center
      input={
        <StatusBoxStyled style={{ width: 100, margin: 'auto' }} className={count ? color : 'silver'}>
          {count}
        </StatusBoxStyled>
      }
    />
  </GlobalStyles.TableRow>
)

const SubHeader = styled(GlobalStyles.TableRow)`
  background: rgb(246, 249, 255);
  th {
    vertical-align: middle;
    padding: 10px;
  }
`

export const OrderStatusBar = ({ counts }: any) => {
  return (
    <OrderInfo style={{ height: 'auto', display: 'block' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>Batch Progress</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ width: '100%' }}>
        <GlobalStyles.DataTable>
          <tbody>
            <SubHeader>
              <th>
                <span className="text">Batch Summary</span>
              </th>
              <th></th>
            </SubHeader>
            <TableRow text={'All'} count={counts.all} color={'silver'} />
            <TableRow text={'Shipped'} count={counts.shipped} color={'green'} />
            <TableRow text={'Unshipped'} count={counts.unshipped} color={'blue'} />
            <SubHeader>
              <th>
                <span className="text">Batch Detail</span>
              </th>
              <th></th>
            </SubHeader>
            <TableRow text={'Processing'} count={counts.processing} color={'blue'} />
            <TableRow text={'Picked'} count={counts.picked} color={'blue'} />
            <TableRow text={'Packed'} count={counts.packed} color={'blue'} />
            <TableRow text={'Labeled'} count={counts.labeled} color={'blue'} />
            <TableRow text={'Pending'} count={counts.pending} color={'yellow'} />
          </tbody>
        </GlobalStyles.DataTable>
      </div>
    </OrderInfo>
  )
}
