import React, { useEffect, useState } from 'react'
import { notify, useFetch, usePost, usePut } from '../../components/component-items/helpers'
import { globalState } from '../../store'
import styled from 'styled-components'

// Components
import GlobalStyles from '../../components/component-items/styles'
import { SettingsContainer } from './styles'
import { Form } from 'react-bootstrap'
import CardLoading from '../../components/component-items/loading-popover'

const Label = styled(Form.Label)`
  margin-top: 1em;
`

export const EDISettings = () => {
  const url = '/api/edi/relationship/details/'
  const { response: resp, loaded, error, placeholder } = useFetch(url, {})

  const {
    state: { csrf },
  } = globalState()

  const [settings, setSettings] = useState({
    id: '',
    name: '',
    isaId: '',
    testIsaId: '',
    gs1CompanyPrefix: '',
    extensionDigit: '',
    ediEnabled: false,
  })

  useEffect(() => {
    if (loaded) {
      setSettings(resp || {})
    }
  }, [loaded])

  const handleSubmit = async () => {
    const url = `/api/edi/relationship/${settings.id}/`
    const { response, error } = await usePut(url, settings, csrf)
    if (response) {
      notify({ message: 'Settings updated', type: 'success' })
    } else {
      notify({ message: error, type: 'error' })
    }
  }

  return (
    <SettingsContainer style={{ display: 'block', position: 'relative', height: 'unset' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>EDI Settings</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar></GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ padding: '1em', marginBottom: '4em' }}>
        <Label>GS1 Company Prefix</Label>
        <Form.Control
          type="text"
          value={settings.gs1CompanyPrefix}
          onChange={(e: any) => setSettings((s) => ({ ...s, gs1CompanyPrefix: e.target.value }))}
        />
        <Label>Extension Digit</Label>
        <Form.Control
          type="text"
          value={settings.extensionDigit}
          onChange={(e: any) => setSettings((s) => ({ ...s, extensionDigit: e.target.value }))}
        />
        <Label>Name (In Orderful)</Label>
        <Form.Control
          type="text"
          value={settings.name}
          onChange={(e: any) => setSettings((s) => ({ ...s, name: e.target.value }))}
        />
        <Label>ISA ID</Label>
        <Form.Control
          type="text"
          value={settings.isaId}
          onChange={(e: any) => setSettings((s) => ({ ...s, isaId: e.target.value }))}
        />
        <Label>Test ISA ID</Label>
        <Form.Control
          type="text"
          value={settings.testIsaId}
          onChange={(e: any) => setSettings((s) => ({ ...s, testIsaId: e.target.value }))}
        />
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <Form.Check
            type="switch"
            checked={settings.ediEnabled}
            onChange={() => setSettings((s) => ({ ...s, ediEnabled: !s.ediEnabled }))}
          />
          <Label style={{ margin: '0 0 0 .5em' }}>EDI Enabled</Label>
        </div>
        <div style={{ float: 'right', marginTop: '1.5em' }}>
          <GlobalStyles.Button onClick={handleSubmit}>Update</GlobalStyles.Button>
        </div>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </SettingsContainer>
  )
}
