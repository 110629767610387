import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useFetch, useDelete, usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { Form, InputGroup } from 'react-bootstrap'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { DragNDrop } from '../../../components/component-items/dragndrop'
import Checkbox from '../../../components/component-items/checkbox'

const Container = styled.div`
  margin: 2em auto;
  width: 60%;
  color: ${(props) => props.theme.colors.black};
  .editor-container {
    background: #f7f7f7;
    border-radius: 4px;
    padding: 0.5em 1em;
    margin: 1em 0;
  }
`

const PackingSlipImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border: 1px solid grey;
    width: 100%;
    max-width: 400px;
    height: auto;
  }
`

export const PackingSlip = () => {
  const {
    state: { csrf, companyId },
  } = globalState()
  const { response: resp, loaded, error }: any = useFetch(`/api/company/${companyId}/packing-slip/`, {})
  const slipId = loaded ? resp.id : null

  const imagePresent = loaded ? resp.image : null
  const image = loaded ? <img src={resp.image} style={{ width: 200 }} /> : null

  const [shippingName, setShippingName] = useState('')
  const [QRDefault, setQRDefault] = useState('ecommerce_checkout_url')
  const [QRURL, setQRURL] = useState('')
  const [pricing, setPricing] = useState(true)

  const [file, setFile] = useState(null)

  useEffect(() => {
    if (loaded) {
      setShippingName(resp.shipping_name || '')
      setQRDefault(resp.qr_default || '')
      setQRURL(resp.qr_static_url || '')
      setPricing(resp.pricing)
    }
  }, [loaded])

  const uploadFile = async () => {
    var formData = new window.FormData()
    if (file) {
      formData.append('image', file, 'logo.png')
    }

    const reload = true
    const stringifyBody = false
    formData.append('shipping_name', shippingName)
    formData.append('qr_default', QRDefault)
    formData.append('qr_static_url', QRURL)
    // @ts-ignore
    formData.append('pricing', pricing)
    usePut(`/api/company/${companyId}/packing-slip/${slipId}/`, formData, csrf, reload, stringifyBody)
  }

  const deleteLogo = async () => {
    useDelete(`/api/company/${companyId}/packing-slip/${slipId}/delete_logo/`, csrf, true, false)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>Packing Slip</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <Container>
        <Form.Label>Company Name (As Shown on Packing Slip)</Form.Label>
        <Form.Control type="text" name="name" value={shippingName} onChange={(e) => setShippingName(e.target.value)} />
        <Form.Label style={{ marginTop: '2em' }}>Company Logo</Form.Label>
        {imagePresent ? (
          <GlobalStyles.Button
            onClick={() => deleteLogo()}
            style={{ minWidth: 150, marginTop: '2em', float: 'right', background: 'red' }}
          >
            Delete Logo
          </GlobalStyles.Button>
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'center' }}>{image}</div>
        <DragNDrop {...{ file, setFile }} fileTypes={'.jpg, .jpeg, .png'} />
        <StatusBoxStyled className={'purple'} style={{ margin: '2em 0' }}>
          QR codes are added to 4' x 6' thermal slips and allow the customer to view their virtual packing slip via your
          shopify checkout page, a static URL given to all merchants below, or optionally ShippingTree's packing-slip
          page if none of those are provided.
        </StatusBoxStyled>
        <Form.Label>Default QR Code URL</Form.Label>
        <Form.Select value={QRDefault} onChange={(e) => setQRDefault(e.target.value)}>
          <option value="ecommerce_checkout_url">Ecommerce Checkout URL</option>
          <option value="static_url">Static URL</option>
          <option value="internal_url">ShippingTree URL (Virtual Packslip)</option>
          <option value="blank">None</option>
        </Form.Select>
        <Form.Label style={{ marginTop: '2em' }}>Static QR Code URL (default for manual orders)</Form.Label>
        <Form.Control type="text" value={QRURL} maxLength={200} onChange={(e) => setQRURL(e.target.value)} />
        <InputGroup style={{ marginTop: '2em' }}>
          <Checkbox selected={pricing} setSelected={() => setPricing(!pricing)} />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>Include Pricing (Pulled from e-commerce checkout)</p>
        </InputGroup>
        <Form.Label style={{ marginTop: '2em' }}>Preview Packing Slip (Uses most recent fulfilled order)</Form.Label>
        <PackingSlipImage>
          {loaded && <img src={`/api/company/${companyId}/packing-slip/${slipId}/preview/`} />}
        </PackingSlipImage>
        <GlobalStyles.Button onClick={() => uploadFile()} style={{ minWidth: 150, marginTop: '2em', float: 'right' }}>
          Submit
        </GlobalStyles.Button>
      </Container>
    </GlobalStyles.FullPageCard>
  )
}
