import React from 'react'

export const subscribeToNotifications = (
  type: string,
  onNotificationReceived: { (notification: any): void },
  id?: any
) => {
  const ws = new WebSocket(
    `${window.location.protocol === 'https:' ? 'wss://' : 'ws://'}${window.location.host}/api/ws/notifications/${type}/`
  )

  ws.onopen = (event) => {
    console.log('WebSocket connection opened:', event)
    // Optionally, you might send a message to the server to subscribe to specific notifications for the given order
    let payload: any = {
      action: 'subscribe',
    }
    if (id) {
      payload[`${type}_id`] = id
    }
    ws.send(JSON.stringify(payload))
  }

  ws.onmessage = (event) => {
    const message = JSON.parse(event.data)
    if (message.type === 'notification') {
      onNotificationReceived(message.notification)
    }
  }

  ws.onerror = (error) => {
    console.error('WebSocket error:', error)
  }

  ws.onclose = (event) => {
    console.log('WebSocket connection closed:', event)
    // Optionally, you might want to handle reconnecting if the connection is closed unexpectedly
  }

  return () => {
    ws.close()
  }
}
