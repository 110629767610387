import React from 'react'
import ReactTimeAgo from 'react-time-ago'
import { Row } from 'react-bootstrap'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import { useFetch } from '../../components/component-items/helpers'
import MyResponsiveLine from '../../components/component-items/line-graph'
import { GraphCardStyled, GeneralStatisticsStyled } from './styles'

import { GraphCardInnerProps } from './types'

const GraphCardInner = ({ title, value, graph, color }: GraphCardInnerProps) => (
  <GraphCardStyled className="col-xl-4 col-lg-6 col-md-6 col-6">
    <div className="graph__item">
      <div className="title">{title}</div>
      <div className="stats">
        <div className="info">${value}</div>
        <div className="chart">
          <div className="canvas-container">
            <MyResponsiveLine
              data={graph}
              color={color}
              custom={{ enableArea: false, enablePoints: false, useMesh: false }}
            />
          </div>
        </div>
      </div>
    </div>
  </GraphCardStyled>
)

export const GeneralStatisticsMerchant = ({ url, color }: { url: string; color: string | string[] }) => {
  var resp: any = useFetch(url, {})

  var processing = resp.loaded ? resp.response.processingFees.toLocaleString() : 0
  var picking = resp.loaded ? resp.response.pickingFees.toLocaleString() : 0
  var postage = resp.loaded ? resp.response.postageFees.toLocaleString() : 0
  var fulfillment = resp.loaded ? resp.response.fulfillmentFees.toLocaleString() : 0
  var lastUpdated = resp.loaded ? resp.response.lastUpdated : 0

  const tempData = [
    { x: 1, y: 0 },
    { x: 2, y: 0 },
  ]

  var graph1 =
    resp.loaded && resp.response.processingFeesData.length > 1
      ? resp.response.processingFeesData.map((a: any) => ({ x: a.created_Hour, y: Math.abs(a.total) }))
      : tempData
  var processingGraph = [
    {
      id: 'Processing',
      data: graph1,
    },
  ]

  var graph2 =
    resp.loaded && resp.response.pickingFeesData.length > 1
      ? resp.response.pickingFeesData.map((a: any) => ({ x: a.created_Hour, y: Math.abs(a.total) }))
      : tempData
  var pickingGraph = [
    {
      id: 'Picking',
      data: graph2,
    },
  ]

  var graph4 =
    resp.loaded && resp.response.postageFeesData.length > 1
      ? resp.response.postageFeesData.map((a: any) => ({ x: a.created_Hour, y: Math.abs(a.total) }))
      : tempData
  var postageGraph = [
    {
      id: 'Postage',
      data: graph4,
    },
  ]

  var graph_fulfillment =
    resp.loaded && resp.response.fulfillmentData.length > 1
      ? resp.response.fulfillmentData.map((a: any) => ({ x: a.created_Hour, y: Math.abs(a.total) }))
      : tempData
  var graph_postage =
    resp.loaded && resp.response.postageFeesData.length > 1
      ? resp.response.postageFeesData.map((a: any) => ({ x: a.created_Hour, y: Math.abs(a.total) }))
      : tempData

  var mainGraph = [
    {
      id: 'Fulfillment',
      color: '#645ca1',
      data: graph_fulfillment,
    },
    {
      id: 'Postage',
      color: '#34BFA3',
      data: graph_postage,
    },
  ]

  return (
    <GeneralStatisticsStyled>
      <div className="head">
        <h3>
          General Statistics
          <small className="ps-3">
            Last Updated: {lastUpdated ? <ReactTimeAgo date={Date.parse(lastUpdated)} locale="en-US" /> : 'NA'}
          </small>
        </h3>
        <div className="toolbar">
          {/* TODO (Mike) - Add support for these export types
            <button>XML</button>
            <button>CSV</button>
            */}
        </div>
      </div>
      <div className="widget__body">
        <div className="widget__wrapper">
          <div className="widget__content">
            <Row>
              <GraphCardInner title={'Processing Fees'} value={processing} graph={processingGraph} color={'#FFB822'} />
              <GraphCardInner title={'Picking Fees'} value={picking} graph={pickingGraph} color={'#5974FF'} />
              <GraphCardInner title={'Postage'} value={postage} graph={postageGraph} color={'#34BFA3'} />
            </Row>
            <div className="main-stats">
              <div className="legend-item">
                <div className="legend-color" style={{ backgroundColor: color[0] }}></div>
                <span className="legend-name">Fulfillment (${fulfillment})</span>
              </div>
              <div className="legend-item">
                <div className="legend-color" style={{ backgroundColor: color[1] }}></div>
                <span className="legend-name">Postage (${postage})</span>
              </div>
            </div>
          </div>
          <div className="main-chart">
            <div className="main-chart__container">
              <MyResponsiveLine
                data={mainGraph}
                color={color}
                yLabel={'$'}
                custom={{ areaBaselineValue: -1000, yScale: { type: 'linear', min: -1000, max: 'auto' } }}
              />
            </div>
          </div>
        </div>
      </div>
      {!resp.loaded ? <CardLoading text={resp.placeholder} error={resp.error} /> : null}
    </GeneralStatisticsStyled>
  )
}

export default GeneralStatisticsMerchant
