// React
import React, { FunctionComponent, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'

import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTheme } from 'styled-components'

// Components
import { ProgressList } from '../../../warehouse/packing/styles'
import GlobalStyles from '../../../../components/component-items/styles'
import { notify } from '../../../../components/component-items/helpers'

// Modals
import { ComplianceModal } from '../compliance-modal'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcodeRead, faFileInvoiceDollar, faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons'

import { MenuOptionProps, NonComplianceWorkflowProps } from './types'

const MenuOption: FunctionComponent<MenuOptionProps> = ({ url, icon, title, active, enabled, onClick, options }) => {
  return (
    <li onClick={onClick} className={active ? 'active' : !enabled ? 'disabled' : ''}>
      {options ? (
        <Row style={{ padding: '.6em' }}>
          {Object.keys(options).map((key, index) => (
            <Col key={index} lg={6}>
              <GlobalStyles.Button
                className="secondary"
                onClick={options[key]}
                style={{ width: '100%', height: 35, margin: '0' }}
                disabled={!active}
              >
                {key}
              </GlobalStyles.Button>
            </Col>
          ))}
        </Row>
      ) : (
        <Link to={url && enabled ? url : '#'}>
          <span className="icon">
            <FontAwesomeIcon icon={icon} />
          </span>
          <span className="text">{title}</span>
        </Link>
      )}
    </li>
  )
}

export const NonComplianceWorkflow: FunctionComponent<NonComplianceWorkflowProps> = ({
  asn,
  current,
  itemId,
  itemComplianceCompleted,
  asnComplianceCompleted,
  asnCompliance,
}) => {
  const theme: any = useTheme()
  const url = `/asn/${asn}/`
  const history = useHistory()
  const [openCompliance, setCompliance] = useState(false)

  const handleNextItem = () => {
    history.push(url + 'receive/')
  }

  const handleComplete = async () => {
    if (asnComplianceCompleted) {
      history.push('#review')
      setCompliance(true)
    } else {
      notify({
        type: 'warning',
        message: 'All items must have compliance designation. If non-compliant, billing must be attached.',
      })
    }
  }

  const options: any = {
    'Next Item': handleNextItem,
    Review: handleComplete,
  }

  return (
    <>
      <ProgressList>
        <ul>
          <MenuOption
            url={url + 'receive/'}
            active={current === 'scan_item'}
            enabled
            icon={faBarcodeRead}
            title={'Select Product'}
          />

          <MenuOption
            url={url + `item/${itemId}/non-compliance/`}
            active={current === 'non_compliance'}
            icon={faFileInvoiceDollar}
            enabled={current === 'non_compliance'}
            title={'Non-Compliance Fees'}
          />

          {/* Can't show complete until all items are either marked compliant or non-compliant and has billing for non-compliant items + on non-compliance page */}
          <MenuOption
            enabled={itemComplianceCompleted || asnComplianceCompleted}
            active={itemComplianceCompleted || asnComplianceCompleted}
            options={options}
          />
        </ul>
      </ProgressList>

      {current === 'non_compliance' && !itemComplianceCompleted && (
        <StatusBarStyled style={{ backgroundColor: 'white' }}>
          <div className="header-item">
            <div className="status-icon">
              <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: theme.colors.mySin }} />
            </div>
            <div className="status-text">Compliance is incomplete for this item</div>
          </div>
        </StatusBarStyled>
      )}
      <ComplianceModal {...asnCompliance} open={openCompliance} setOpen={setCompliance} />
    </>
  )
}
