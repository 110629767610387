import React, { useState, useEffect } from 'react'
import { globalState } from '../../../store'
import { useParams } from 'react-router-dom'
import { usePost, usePut } from '../../../components/component-items/helpers'

// Components
import styled from 'styled-components'
import { Form, Col, Row } from 'react-bootstrap'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar } from 'react-date-range'

import { EditASNModalProps } from './types'

export const DateRangeStyled = styled.div`
  overflow: scroll;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em auto;
  width: fit-content;
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0;
  }
`

export const EditASNModal = ({
  open,
  setOpen,
  sender,
  tracking,
  serviceLevel,
  delivery,
  po,
  bol,
  pro,
  integrationID,
}: EditASNModalProps) => {
  const [validated, setValidated] = useState(false)
  const asn = useParams<{ id: string }>()?.id
  const {
    state: { csrf },
  } = globalState()

  var currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)
  const [asnData, setAsnData] = useState({
    sender: '',
    tracking: '',
    tracking_number: '',
    service_level: '',
    po_number: '',
    bol_number: '',
    pro_number: '',
    integration_id: '',
    delivery_date: currentDate,
  })

  useEffect(() => {
    setAsnData((asnData) => ({
      ...asnData,
      sender: sender || '',
      tracking_number: tracking || '',
      service_level: serviceLevel || '',
      po_number: po || '',
      bol_number: bol || '',
      pro_number: pro || '',
      integration_id: integrationID || '',
      delivery_date: delivery ? new Date(delivery) : currentDate,
    }))
  }, [sender, tracking, serviceLevel, delivery])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget

    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }
    usePut(
      `/api/inventory/asn/${asn}/`,
      { ...asnData, delivery_date: asnData.delivery_date.toLocaleDateString('en-US').replace(/\//g, '-') },
      csrf
    )
  }

  return (
    <GenericModal
      heading={'Edit ASN'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSubmit(event)}
        >
          Update
        </GlobalStyles.Button>
      }
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div style={{ margin: '2em auto', width: '90%' }}>
          <Row>
            <Form.Group as={Col} xl="4" controlId="validationCustom01" className="required">
              <Form.Label>Sender</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Senders Name"
                name="name"
                value={asnData.sender}
                onChange={(e) => setAsnData({ ...asnData, sender: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter the senders name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xl="4" controlId="validationCustom03">
              <Form.Label>Tracking Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Tracking #"
                value={asnData?.tracking_number}
                onChange={(e) => setAsnData({ ...asnData, tracking_number: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} xl="4" controlId="validationCustom04">
              <Form.Label>Shipped By (Carrier)</Form.Label>
              <Form.Control
                type="text"
                name="serviceLevel"
                placeholder="Carrier Name"
                value={asnData.service_level}
                onChange={(e) => setAsnData({ ...asnData, service_level: e.target.value })}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} xl="4">
              <Form.Label>PO #</Form.Label>
              <Form.Control
                type="text"
                placeholder="PO #"
                name="po_number"
                value={asnData.po_number}
                onChange={(e) => setAsnData({ ...asnData, po_number: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} xl="4">
              <Form.Label>BOL #</Form.Label>
              <Form.Control
                type="text"
                placeholder="BOL #"
                name="bol_number"
                value={asnData.bol_number}
                onChange={(e) => setAsnData({ ...asnData, bol_number: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} xl="4">
              <Form.Label>PRO #</Form.Label>
              <Form.Control
                type="text"
                name="pro_number"
                placeholder="PRO #"
                value={asnData.pro_number}
                onChange={(e) => setAsnData({ ...asnData, pro_number: e.target.value })}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} xl="4">
              <Form.Label>Integration ID</Form.Label>
              <Form.Control
                type="text"
                name="integration_id"
                placeholder="Integration ID"
                value={asnData.integration_id}
                onChange={(e) => setAsnData({ ...asnData, integration_id: e.target.value })}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} xl="12" controlId="validationCustom03">
              <Form.Label>Expected Delivery Date</Form.Label>
              <DateRangeStyled>
                <Calendar
                  onChange={(e) => setAsnData({ ...asnData, delivery_date: e })}
                  date={asnData.delivery_date}
                  minDate={currentDate}
                  showMonthAndYearPickers={false}
                  className="hide-in-percy"
                />
              </DateRangeStyled>
            </Form.Group>
          </Row>
        </div>
      </Form>
    </GenericModal>
  )
}
