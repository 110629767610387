import React from 'react'
import type { PointerEvent } from 'react'

// Components
import GlobalStyles from './styles'

import { PointerSensor as LibPointerSensor, PointerSensorOptions } from '@dnd-kit/core'
import { useSortable, arrayMove as dndKitArrayMove } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export class PointerSensor extends LibPointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: ({ nativeEvent: event }: PointerEvent<Element>, { onActivation }: PointerSensorOptions): boolean => {
        if (!event.isPrimary || event.button !== 0 || isInteractiveElement(event.target as HTMLElement)) {
          return false
        }
        onActivation?.({ event })

        return true
      },
    },
  ]
}

function isInteractiveElement(element: HTMLElement) {
  // The following ensures that if any element or parent is of type interactiveElements -
  // then we will consider that inside the draggable container instead of considering it
  // a drag event.
  const interactiveElements = ['button', 'input', 'textarea', 'select', 'option']
  const interactiveClasses = ['modal']

  let cur: HTMLElement | null = element

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return true
    } else if (interactiveElements.includes(cur?.tagName.toLowerCase())) {
      return true
    } else if (interactiveClasses.some((c) => cur?.classList.contains(c))) {
      console.log(cur?.classList)
      return true
    }
    cur = cur.parentElement
  }

  return false
}

export const removeAtIndex = (array: any[], index: number) => {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export const insertAtIndex = (array: any[], index: number, item: any) => {
  return [...array.slice(0, index), item, ...array.slice(index)]
}

export const arrayMove = (array: any[], oldIndex: number, newIndex: number) => {
  return dndKitArrayMove(array, oldIndex, newIndex)
}

export const SortableItem = ({ id, children }: { id: number; children?: any }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  }

  return (
    <GlobalStyles.TableRow style={style} ref={setNodeRef} {...attributes} {...listeners}>
      {children}
    </GlobalStyles.TableRow>
  )
}
