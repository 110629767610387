import React from 'react'
import { useFetch, usePost } from '../../../components/component-items/helpers'
import { useParams } from 'react-router-dom'
import { globalState } from '../../../store'

// Components
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { StatusBarStyled } from '../../../components/component-items/status-bar'
import CardLoading from '../../../components/component-items/loading-popover'
import Form from 'react-bootstrap/Form'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboardList,
  faBoxOpen,
  faConveyorBelt,
  faBoxCheck,
  faTasks,
  faWarehouse,
  faExclamationTriangle,
} from '@fortawesome/pro-duotone-svg-icons'

export const StatusBar = () => {
  const params: any = useParams()
  const project = params.id
  const {
    state: { csrf },
  } = globalState()
  const { response, loaded, error, placeholder }: any = useFetch(`/api/inventory/project/${project}/status/`, {}, 60)
  const statusId = loaded ? response.statusId : null
  const status = loaded ? response.status : null
  const deadline = loaded ? response.deadline : null
  const overdue = loaded ? response.overdue : null
  const lastUpdate = loaded ? response.lastUpdate : null
  const company = loaded ? response.company : null
  const alternateWarehouses = loaded ? response.alternateWarehouses : []
  const warehouse = loaded ? response.warehouse : null

  return (
    <div className="row">
      <div className="col">
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              {statusId === 10 ? (
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
              ) : statusId === 100 ? (
                <FontAwesomeIcon icon={faClipboardList} style={{ color: '#5d78ff' }} />
              ) : statusId === 120 ? (
                <FontAwesomeIcon icon={faBoxOpen} style={{ color: '#5d78ff' }} />
              ) : statusId === 140 ? (
                <FontAwesomeIcon icon={faConveyorBelt} style={{ color: '#5d78ff' }} />
              ) : statusId === 200 ? (
                <FontAwesomeIcon icon={faBoxCheck} style={{ color: '#00a58f' }} />
              ) : (
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
              )}
            </div>
            <div className="status-text">
              <div>
                {status || 'Scheduled'} - Project {project}
              </div>
              Last Update <strong>{lastUpdate || 'Never'}</strong>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon icon={faTasks} />
            </div>
            <div
              className="status-text"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              <strong>{company || 'N/A'}</strong>
              <strong>
                <StatusBoxStyled className={overdue ? 'red' : 'blue'} style={{ lineHeight: '.8em' }}>
                  {'Due ' + deadline || 'N/A'}
                </StatusBoxStyled>
              </strong>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text" style={{ paddingRight: '1em' }}>
              Facility:
            </div>
            <div className="status-text">
              {statusId <= 100 && alternateWarehouses.length ? (
                <Form.Select
                  defaultValue={'default'}
                  onChange={(e) =>
                    usePost(`/api/inventory/project/${project}/details/`, { change_warehouse: e.target.value }, csrf)
                  }
                >
                  <option value={'default'}>{warehouse}</option>
                  {alternateWarehouses.map((w: any, id: string | number) => (
                    <option value={w.id} key={id}>
                      {w.name}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <strong>{warehouse || 'N/A'}</strong>
              )}
            </div>
            <div className="status-icon" style={{ marginLeft: '2em' }}>
              <FontAwesomeIcon icon={faWarehouse} />
            </div>
          </div>
          {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
        </StatusBarStyled>
      </div>
    </div>
  )
}
