import styled from 'styled-components'
import { device } from './device'

export const BackgroundLayer = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
`

export const StatusBarStyled = styled(BackgroundLayer)`
  color: #282a3c;
  align-items: stretch;
  justify-content: space-between;
  padding: 1rem 2rem;
  display: block;
  position: relative;
  @media ${device.laptop} {
    display: flex;
  }
  .header-item {
    display: flex;
    justify-content: center;
    padding: 1em 0;
    @media ${device.laptop} {
      padding: 0;
      &.center {
        justify-content: center;
      }
      &.right {
        justify-content: flex-end;
      }
    }
  }
  .border-line {
    border-bottom: 1px solid #dddddd;
    @media ${device.laptop} {
      border-right: 1px solid #dddddd;
    }
  }
  .status-icon {
    display: flex;
    align-items: center;
    padding: 0 1.25rem 0 0;
    svg {
      font-size: 2.4rem;
      color: #bbbed2;
    }
  }
  .status-text {
    color: #282a3c;
    align-self: center;
  }
  a {
    font-weight: 500;
    color: #536be2;
  }
`
