import React from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../components/component-items/helpers'

// Components
import CardLoading from '../../../components/component-items/loading-popover'

// Cards
import { StatusBar } from './status-card'
import { VariantCard } from './variant-card'
import { AvailabilityCard } from './availability-card'
import { ProductDetails } from './product-details'
import { ProductAllocation } from './allocation-card'

export const ProductPage = () => {
  const productVariant = useParams<{ id: string }>().id
  const productVariantUrl = `/api/product/${productVariant}/details/`
  const { response: resp, loaded, error, placeholder }: any = useFetch(productVariantUrl, {})

  const warehouses = loaded ? resp.warehouses : []
  const availability = loaded ? resp.availability : null
  const allocation = loaded ? resp.allocation : {}
  const variants = loaded ? resp.variants : []
  const status = loaded ? resp.product : {}
  const productDetails = loaded ? resp.product : {}
  const is_packaging = loaded ? resp.product.is_packaging : false

  return (
    <>
      <div className="row">
        <div className="col">
          <StatusBar {...status} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-xl-7">
          <div className="row">
            <div className="col-xl-12">
              <ProductAllocation img={loaded ? resp.img : '/api/static/img/placeholder-product.png'} {...allocation} />
            </div>
            <div className="col-xl-12">
              <VariantCard {...{ variants, is_packaging }} />
              <AvailabilityCard {...{ warehouses, availability }} />
            </div>
          </div>
        </div>
        <div className="col-xl-5" style={{ marginBottom: 20 }}>
          <ProductDetails {...{ productDetails }} />
        </div>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </>
  )
}
