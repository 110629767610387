import React, { useState, useEffect, useRef, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { usePost } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { TableCell } from '../../../../components/component-items/datatable'
import { Form, Col, Row } from 'react-bootstrap'

function upsert(array: any[], item: any) {
  const i = array.findIndex((_item) => _item.p_id === item.p_id)
  if (i > -1) array[i] = item
  else array.push(item)
  return array
}

type ShipmentCardProps = {
  shipment_number: number
  total: number
  id: number | string
  no_packaging?: boolean
  order_items: any[]
  packaging_items: any[]
  deleteShipment: (shipment_number: number, id: number) => void
  updateList: boolean
  setUpdateList: (updateList: boolean) => void
  setPackStatus: (pack_status: string) => void
  shipments: any[]
  setShipments: (shipments: any[]) => void
}

export const ShipmentCard: FunctionComponent<ShipmentCardProps> = ({
  shipment_number,
  total,
  id,
  no_packaging,
  order_items,
  packaging_items,
  deleteShipment,
  updateList,
  setUpdateList,
  setPackStatus,
  shipments,
  setShipments,
}) => {
  const { id: order } = useParams<{ id: string }>()

  const {
    state: { csrf },
  } = globalState()

  const [validated, setValidated] = useState(false)
  const packDefault = {
    barcode: '',
    qty: '1',
  }
  const [packItem, setPackItem] = useState(packDefault)

  const [noPackaging, setNoPackaging] = useState(false)
  const [orderItems, setOrderItems] = useState<typeof order_items>([])
  const [packagingItems, setPackagingItems] = useState<typeof packaging_items>([])

  useEffect(() => {
    setOrderItems(order_items)
    setPackagingItems(packaging_items)
    setNoPackaging(!!no_packaging)
  }, [order_items, packaging_items, no_packaging])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget

    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }
    const resp = await usePost(
      '/api/shipment/pack_item/',
      { pk: order, ...packItem, shipment: shipment_number },
      csrf,
      false
    )

    // If there is no ID for this shipment yet, set it
    if (!resp.error && !id) {
      const updatedShipments = shipments.map((shipment) => {
        if (shipment.shipment_number === shipment_number) {
          return { ...shipment, id: resp.shipment_id }
        }
        return shipment
      })
      setShipments(updatedShipments)
    }

    if (resp.type === 'product') {
      setOrderItems(upsert(orderItems, resp))
    } else {
      setPackagingItems([resp])
    }

    setValidated(false)
    setPackItem(packDefault)
    setPackStatus(resp.pack_status)
    setUpdateList(!updateList)
  }

  const handleNoPackaging = async () => {
    const resp = await usePost(
      '/api/shipment/pack_item/',
      { pk: order, barcode: 'no_packaging', shipment: shipment_number },
      csrf,
      false
    )
    setPackStatus(resp.pack_status)
    setPackagingItems([])
    setNoPackaging(true)
  }

  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputElement?.current) {
      inputElement.current.focus()
      inputElement.current.select()
    }
  }, [])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Shipment #{shipment_number}
            <small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button
            id={'delete-shipment-' + shipment_number}
            className="secondary"
            onClick={() => deleteShipment(shipment_number, Number(id))}
          >
            Delete Shipment
          </GlobalStyles.Button>
          <GlobalStyles.Button
            id={'no-packaging-' + shipment_number}
            className="secondary"
            onClick={() => handleNoPackaging()}
          >
            No Packaging
          </GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 200, padding: '1em' }}>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Row style={{ padding: '.5em 1em 0' }}>
            <Form.Group as={Col} md="7" className="required">
              <Form.Control
                id={'shipment-' + shipment_number}
                type="text"
                placeholder="Scan Items"
                name="scan"
                value={packItem.barcode}
                onChange={(e) => setPackItem({ ...packItem, barcode: e.target.value })}
                ref={inputElement}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid barcode</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="qty" className="required">
              <Form.Control
                type="number"
                placeholder="Quantity"
                name="qty"
                value={packItem.qty}
                onChange={(e) => setPackItem({ ...packItem, qty: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a quantity</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="2">
              <GlobalStyles.Button type="submit" style={{ margin: 0, width: '100%', height: 40 }}>
                Add
              </GlobalStyles.Button>
            </Form.Group>
          </Row>
        </Form>
        <Row>
          <Form.Group as={Col} md="7">
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text"></span>
                  </th>
                  <th>
                    <span className="text">SKU &amp; Description</span>
                  </th>
                  <th>
                    <span className="text center">Ordered</span>
                  </th>
                  <th>
                    <span className="text center">Packed</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderItems.map(({ img, p_id, sku, description, ordered, packed }) =>
                  packed > 0 ? (
                    <GlobalStyles.TableRow key={p_id}>
                      <TableCell center>
                        <img src={img} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
                      </TableCell>
                      <TableCell input={<Link to={`/product/variant/${p_id}/`}>{sku}</Link>}>
                        <div>{description}</div>
                      </TableCell>
                      <TableCell
                        center
                        input={
                          <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={'silver'}>
                            {ordered}
                          </StatusBoxStyled>
                        }
                      />
                      <TableCell
                        center
                        input={
                          <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={'silver'}>
                            {packed}
                          </StatusBoxStyled>
                        }
                      />
                    </GlobalStyles.TableRow>
                  ) : null
                )}
              </tbody>
            </GlobalStyles.DataTable>
          </Form.Group>
          <Form.Group as={Col} md="5">
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text"></span>
                  </th>
                  <th>
                    <span className="text">Packaging</span>
                  </th>
                  <th>
                    <span className="text center">Packed</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {packagingItems.map(({ img, p_id, sku, description, packed }) =>
                  packed > 0 ? (
                    <GlobalStyles.TableRow key={p_id}>
                      <TableCell center>
                        <img src={img} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
                      </TableCell>
                      <TableCell input={<Link to={`/product/variant/${p_id}/`}>{sku}</Link>}>
                        <div>{description}</div>
                      </TableCell>
                      <TableCell
                        center
                        input={
                          <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={'silver'}>
                            {packed}
                          </StatusBoxStyled>
                        }
                      />
                    </GlobalStyles.TableRow>
                  ) : null
                )}
                {noPackaging && !packagingItems.length ? (
                  <GlobalStyles.TableRow>
                    <TableCell />
                    <TableCell input={'No Packaging'} />
                    <TableCell />
                  </GlobalStyles.TableRow>
                ) : null}
              </tbody>
            </GlobalStyles.DataTable>
          </Form.Group>
        </Row>
      </div>
    </GlobalStyles.FullPageCard>
  )
}
