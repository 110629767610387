import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { globalState } from '../../../store'

import { usePost, notify } from '../../../components/component-items/helpers'
import { useParams } from 'react-router-dom'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { Form } from 'react-bootstrap'

export const ReduceQuantity = ({ remainingProdQty, open, setOpen }: any) => {
  const params: any = useParams()
  const project = params.id
  const {
    state: { csrf },
  } = globalState()
  const [qty, setQty] = useState(0)

  function handleSubmit() {
    if (qty > remainingProdQty) {
      notify({ type: 'error', message: 'Cannot reduce quantity by more than ' + remainingProdQty })
    } else if (qty < 1) {
      notify({ type: 'error', message: 'Qty cannot be less than 1' })
    } else {
      usePost(`/api/inventory/project/${project}/details/`, { reduce_quantity: qty }, csrf)
    }
  }

  return (
    <GenericModal
      heading={'Reduce Quantity'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={() => handleSubmit()}>Submit</GlobalStyles.Button>}
    >
      <Form.Group style={{ margin: '2em' }}>
        <Form.Label>Reduce Quantity By (Max {remainingProdQty})</Form.Label>
        <Form.Control type="number" min="1" value={qty} onChange={(e) => setQty(Number(e.target.value))} />
      </Form.Group>
    </GenericModal>
  )
}
