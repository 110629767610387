import '@draft-js-plugins/mention/lib/plugin.css'

import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'
import { createInlineStyleButton } from '@draft-js-plugins/buttons'
import createLinkPlugin from '@draft-js-plugins/anchor'
import createMentionPlugin from '@draft-js-plugins/mention'
// @ts-ignore
import { stateToMarkdown } from 'draft-js-export-markdown'

import styled from 'styled-components'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBold, faCode, faItalic, faUnderline } from '@fortawesome/pro-regular-svg-icons'
import { convertToRaw } from 'draft-js'

import { MentionComponentProps } from './types'

const EditorWrapper = styled.div`
  padding: 1em 2em;
  background-color: whitesmoke;
  border-radius: 10px;
  .editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ababab;
    background: #fefefe;
  }

  .editor :global(.public-DraftEditor-content) {
    min-height: 140px;
  }
  .buttonWrapper {
    display: inline-block;
  }

  .button {
    background: #333;
    color: #ddd;
    font-size: 18px;
    border: 0;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
    border-radius: 4px;
  }

  .button svg {
    fill: #ddd;
  }

  .button:hover,
  .button:focus {
    background: #444;
    outline: 0; /* reset for :focus */
  }

  .active {
    color: #6a9cc9;
  }

  .active svg {
    fill: #6a9cc9;
  }
  .toolbar {
    display: flex;
    width: fit-content;
    border: 1px solid #111;
    background: #333;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
    z-index: 2;
    box-sizing: border-box;
  }

  .toolbar:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #333;
    border-width: 4px;
    margin-left: -4px;
  }

  .toolbar:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #111;
    border-width: 6px;
    margin-left: -6px;
  }
  .input {
    height: 34px;
    width: 220px;
    padding: 0 12px;
    font-size: 15px;
    font-family: inherit;
    background-color: transparent;
    border: none;
    color: #ddd;
  }

  .input:focus {
    outline: none;
  }

  .input::placeholder {
    color: #aaa;
  }

  .inputInvalid {
    color: #e65757;
  }

  .link {
    color: #2996da;
    text-decoration: underline;
  }
`

export const MentionComponent = forwardRef((props: MentionComponentProps, ref) => {
  const editorRef = useRef<Editor>(null)
  const [editorState, setEditorState] = useState(() => createEditorStateWithText(''))

  // User mentions state
  const [userOpen, setUserOpen] = useState(false)
  const [userSuggestions, setUserSuggestions] = useState([])

  // Tag mentions state
  const [tagOpen, setTagOpen] = useState(false)
  const [tagSuggestions, setTagSuggestions] = useState([])

  const { InlineToolbar, linkPlugin, UserMentionSuggestions, TagMentionSuggestions, plugins } = useMemo(() => {
    const linkPlugin = createLinkPlugin({
      placeholder: 'http://…',
      theme: {
        input: 'input',
        inputInvalid: 'inputInvalid',
        link: 'link',
      },
    })
    const inlineToolbarPlugin = createInlineToolbarPlugin({
      theme: {
        buttonStyles: {
          button: 'button',
          buttonWrapper: 'buttonWrapper',
          active: 'active',
        },
        toolbarStyles: {
          toolbar: 'toolbar',
        },
      },
    })
    const userMentionPlugin = createMentionPlugin({ mentionTrigger: '@', mentionPrefix: '@' })
    const tagMentionPlugin = createMentionPlugin({ mentionTrigger: '#', mentionPrefix: '#' })
    return {
      plugins: [inlineToolbarPlugin, linkPlugin, userMentionPlugin, tagMentionPlugin],
      InlineToolbar: inlineToolbarPlugin.InlineToolbar,
      linkPlugin,
      UserMentionSuggestions: userMentionPlugin.MentionSuggestions,
      TagMentionSuggestions: tagMentionPlugin.MentionSuggestions,
    }
  }, [])

  const onUserOpenChange = useCallback((_open: boolean) => {
    setUserOpen(_open)
  }, [])

  const onUserSearchChange = useCallback(({ value }: { value: string }) => {
    try {
      fetch(`/api/core/users/?search=${value}`)
        .then((response) => response.json())
        .then((data) => {
          setUserSuggestions(
            data['results'].map((user: any) => ({ ...user, name: user.display_name, link: '', avatar: user.image }))
          )
        })
    } catch (error) {
      console.error('Error fetching user mentions:', error)
    }
  }, [])

  const onTagOpenChange = useCallback((_open: boolean) => {
    setTagOpen(_open)
  }, [])

  const onTagSearchChange = useCallback(({ value }: { value: string }) => {
    try {
      fetch(`/api/core/notification-tags/?search=${value}`)
        .then((response) => response.json())
        .then((data) => {
          setTagSuggestions(data)
        })
    } catch (error) {
      console.error('Error fetching user mentions:', error)
    }
  }, [])

  const customEntityRenderMap = {
    mention: (entity: any) => `@${entity.data.mention.name.replace}`,
    tag: (entity: any) => `#${entity.data.tag.name}`,
  }

  // Function to convert editor state to markdown with custom rendering
  const convertToMarkdownWithCustomEntities = (editorState: any) => {
    return stateToMarkdown(editorState.getCurrentContent(), {
      entityRenderers: customEntityRenderMap,
    })
  }

  useImperativeHandle(ref, () => ({
    getContent: () => {
      var cleanedContent = convertToMarkdownWithCustomEntities(editorState)
      // Unescape underscores
      return cleanedContent.replace(/\\_/g, '_')
    },
  }))

  const BoldButton = createInlineStyleButton({
    style: 'BOLD',
    children: <FontAwesomeIcon icon={faBold} />,
  })

  const ItalicButton = createInlineStyleButton({
    style: 'ITALIC',
    children: <FontAwesomeIcon icon={faItalic} />,
  })

  const UnderlineButton = createInlineStyleButton({
    style: 'UNDERLINE',
    children: <FontAwesomeIcon icon={faUnderline} />,
  })

  const CodeButton = createInlineStyleButton({
    style: 'CODE',
    children: <FontAwesomeIcon icon={faCode} />,
  })

  return (
    <EditorWrapper className="editor" onClick={() => editorRef.current!.focus()}>
      <Editor
        editorKey={'editor'}
        editorState={editorState}
        onChange={setEditorState}
        plugins={plugins}
        ref={editorRef}
        placeholder="Add text..."
      />
      <InlineToolbar>
        {
          // may be use React.Fragment instead of div to improve perfomance after React 16
          (externalProps) => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <CodeButton {...externalProps} />
              {/* @ts-ignore */}
              <linkPlugin.LinkButton {...externalProps} />
            </>
          )
        }
      </InlineToolbar>
      <UserMentionSuggestions
        open={userOpen}
        onOpenChange={onUserOpenChange}
        suggestions={userSuggestions}
        onSearchChange={onUserSearchChange}
      />
      <TagMentionSuggestions
        open={tagOpen}
        onOpenChange={onTagOpenChange}
        suggestions={tagSuggestions}
        onSearchChange={onTagSearchChange}
      />
    </EditorWrapper>
  )
})

MentionComponent.displayName = 'MentionComponent'

export default MentionComponent
