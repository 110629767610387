import React from 'react'
import styled from 'styled-components'

import { BillingProps } from './types'

const BillingStyled = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 0 25px 25px 25px;
  height: 100%;
  .datatable__header {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    min-height: 60px;
    .datatable__title {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #48465b;
        small {
          font-weight: 300;
          padding-left: 1rem;
          font-size: 1rem;
          color: #74788d;
        }
      }
    }
    .datatable__toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    .order-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &:last-child {
        color: ${(props) => props.theme.colors.black};
      }
    }
    & :nth-last-child(3) {
      flex: 1;
    }
    span:last-child {
      margin-left: 1rem;
    }
    p {
      margin-bottom: 0;
    }
    hr {
      width: 100%;
    }
  }
  button {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: center;
    border-radius: 0.2rem;
    color: #595d6e;
    font-size: 1.25rem;
    &:hover {
      background: ${(props) => props.theme.colors.zircon};
      svg {
        color: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
`

export const Billing = ({ classType, transactions, total }: BillingProps) => {
  return (
    <BillingStyled>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>Billing</h3>
        </div>
      </div>
      <div className="order-section">
        {transactions.length ? (
          transactions.map((b, id) => (
            <div className="order-item" key={id}>
              <div>{b.description}</div>
              <div>${b.amount}</div>
            </div>
          ))
        ) : classType === 'ShipNotice' ? (
          <div className="order-item">
            <div>Free Compliant Receiving</div>
            <div>$0.00</div>
          </div>
        ) : null}
        <hr />
        <div className="order-item">
          <div>Total:</div>
          <div>${total}</div>
        </div>
      </div>
    </BillingStyled>
  )
}
