import React from 'react'

export const ProductVariantConditionOptions = () => (
  <>
    <option value={'all_product_variants'}>All Product Variants</option>
    <optgroup label="Product Options">
      <option value={'product_variant_field'}>Product Variant Field</option>
    </optgroup>
    <optgroup label="Logical Type">
      <option value={'logical_and'}>AND</option>
      <option value={'logical_or'}>OR</option>
      <option value={'logical_not'}>NOT</option>
    </optgroup>
  </>
)

export const ProductVariantEventOptions = () => (
  <>
    <option value={'product_variant_created'}>Product Variant Created</option>
  </>
)

export const ProductVariantActionOptions = ({ event }: any) => {
  return (
    <>
      <optgroup label="Notification">
        <option value={'send_notification'}>Send Notification</option>
      </optgroup>
      <optgroup label="Logical Type">
        <option value={'logical_and'}>AND</option>
      </optgroup>
    </>
  )
}

export const ProductVariantFieldTypes = () => {
  return [
    { value: 'id', label: 'Product Variant ID', type: 'number', options: [], async: false, actionable: false },
    { value: 'product', label: 'Product', type: 'string', options: [], async: false, actionable: false },
    { value: 'title', label: 'Title', type: 'string', options: [], async: false, actionable: false },
    { value: 'price', label: 'Price', type: 'number', options: [], async: false, actionable: false },
    {
      value: 'custom_processing_fee',
      label: 'Custom Processing Fee',
      type: 'number',
      options: [],
      async: false,
      actionable: false,
    },
    {
      value: 'wholesale_price',
      label: 'Wholesale Price',
      type: 'number',
      options: [],
      async: false,
      actionable: false,
    },
    { value: 'sku', label: 'SKU', type: 'string', options: [], async: false, actionable: false },
    { value: 'barcode', label: 'Barcode', type: 'string', options: [], async: false, actionable: false },
    { value: 'hs_code', label: 'HS Code', type: 'string', options: [], async: false, actionable: false },
    { value: 'is_bundle', label: 'Is Bundle', type: 'boolean', options: [], async: false, actionable: false },
    { value: 'deleted', label: 'Deleted', type: 'boolean', options: [], async: false, actionable: false },
    { value: 'weight', label: 'Weight', type: 'number', options: [], async: false, actionable: false },
    { value: 'length', label: 'Length', type: 'number', options: [], async: false, actionable: false },
    { value: 'width', label: 'Width', type: 'number', options: [], async: false, actionable: false },
    { value: 'height', label: 'Height', type: 'number', options: [], async: false, actionable: false },
    { value: 'sync_inventory', label: 'Sync Inventory', type: 'boolean', options: [], async: false, actionable: false },
    { value: 'originator_id', label: 'Originator ID', type: 'string', options: [], async: false, actionable: false },
    {
      value: 'signature_required',
      label: 'Signature Required',
      type: 'boolean',
      options: [],
      async: false,
      actionable: false,
    },
    {
      value: 'adult_signature_required',
      label: 'Adult Signature Required',
      type: 'boolean',
      options: [],
      async: false,
      actionable: false,
    },
    { value: 'created', label: 'Created', type: 'date', options: [], async: false, actionable: false },
    { value: 'updated', label: 'Updated', type: 'date', options: [], async: false, actionable: false },
  ]
}

