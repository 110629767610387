import React, { useState, useEffect } from 'react'
import { globalState } from '../../../store'

import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { usePost } from '../../../components/component-items/helpers'
import { OrderInfo } from './styles'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import Checkbox from '../../../components/component-items/checkbox'
import { Form, InputGroup } from 'react-bootstrap'

import { ProductDetailsProps } from './types'

const Label = styled(Form.Label)`
  margin-top: 1em;
`

export const ProductDetails = ({ productDetails }: ProductDetailsProps) => {
  const productVariant = useParams<{ id: string }>().id
  const url = `/api/product/${productVariant}/details/`
  const {
    state: { csrf },
  } = globalState()

  const [product, setProduct] = useState<any>({})

  useEffect(() => {
    setProduct(productDetails)
  }, [productDetails])

  return (
    <OrderInfo style={{ display: 'block', height: 'unset' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>{product.is_packaging ? 'Packaging' : 'Product'} Details</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button onClick={() => usePost(url, { ...product }, csrf)}>Update</GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, width: '100%' }}>
        <Form.Group style={{ margin: '1em' }}>
          <Label>Title</Label>
          <Form.Control
            type="text"
            value={product.title || ''}
            onChange={(e: any) => setProduct({ ...product, title: e.target.value })}
          />
          <Label>Description</Label>
          <Form.Control
            as="textarea"
            aria-label="Product Description"
            value={product.description}
            onChange={(e: any) => setProduct({ ...product, description: e.target.value })}
          />
          <Label>Inventory Control</Label>
          <Form.Select
            value={product.inventoryControl || 'fifo'}
            onChange={(e: any) => setProduct({ ...product, inventoryControl: e.target.value })}
          >
            <option value={'fifo'}>Lot Control - FIFO</option>
            <option value={'lifo'}>Lot Control - LIFO</option>
          </Form.Select>
          <Label>
            <GlobalStyles.Tooltip
              data-title="Inventory will be ordered by this field"
              style={{ borderBottom: 'dashed 1px' }}
            >
              Inventory Control Field
            </GlobalStyles.Tooltip>
          </Label>
          <Form.Select
            value={product.inventory_control_category || 'received_date'}
            onChange={(e: any) => setProduct({ ...product, inventory_control_category: e.target.value })}
          >
            <option value={'lot_number'}>Lot Number</option>
            <option value={'received_date'}>Received Date</option>
            <option value={'manufacture_date'}>Manufacture Date</option>
            <option value={'best_before_date'}>Best Before Date</option>
            <option value={'expiry_date'}>Expiry Date</option>
          </Form.Select>
          <InputGroup style={{ marginTop: '2em' }}>
            <Checkbox selected={product.promo} setSelected={() => setProduct({ ...product, promo: !product.promo })} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Promotional Item</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={product.media_mail}
              setSelected={() => setProduct({ ...product, media_mail: !product.media_mail })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Eligible for Media Mail</p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={product.scan_serial_number}
              setSelected={() => setProduct({ ...product, scan_serial_number: !product.scan_serial_number })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Product Requires Scanning Serial Numbers</p>
          </InputGroup>
        </Form.Group>
      </div>
    </OrderInfo>
  )
}
