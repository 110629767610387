// React
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Link, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { GlobalStyled } from '../../../../components/component-items/styles'

import { TableCell, THSort } from '../../../../components/component-items/datatable'
import { useFetch, usePut, StickyElement } from '../../../../components/component-items/helpers'
import CardLoading from '../../../../components/component-items/loading-popover'
import { Pages } from '../../../../components/component-items/pager'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'

// Modals
import { PrintLabelsModal } from '../../../../components/modals/print-labels'
import { ComplianceModal } from '../compliance-modal'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { globalState } from '../../../../store'
import { BackgroundLayer } from '../styles'
import { DateRangeStyled } from '../edit-asn-modal'
import ButtonDropdown from '../../../../components/component-items/button-dropdown'
import { OrderOptions } from '../../../orders/order/styles'
import { ReceivingHistory } from './receiving-history'
import { ReceivingWorkflow } from './receiving-workflow'
import { AsnItem, AsnOption } from './item-helpers'

// Enums
import { LotControlField } from '../../../../enums'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarWeek,
  faClipboardList,
  faDolly,
  faPrint,
  faWarehouse,
  faPrintSearch,
  faFileInvoiceDollar,
} from '@fortawesome/pro-duotone-svg-icons'

import { AvailableLocationsProps, LotFieldProps, StatusBarProps } from './types'

const AvailableLocations: FunctionComponent<AvailableLocationsProps> = ({
  sku,
  inventoryFields,
  setInventoryFields,
  setShowLotFields,
}) => {
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState({})
  const [inventoryType, setInventoryType] = useState('All')

  const inventoryTypesOptions = [
    { value: 'pickable', label: 'Pickable' },
    { value: 'backstock', label: 'Backstock' },
    { value: 'defective', label: 'Defective' },
  ]

  // Set default filter options
  const [filterOptions, setFilterOptions] = useState([
    {
      label: 'Type',
      selected: true,
      type: 'multi-select',
      value: [
        { value: 'pickable', label: 'Pickable' },
        { value: 'backstock', label: 'Backstock' },
        { value: 'defective', label: 'Defective' },
      ],
      options: [],
    },
  ])

  const sortOptions = {
    All: '',
    Pickable: 'pickable',
    Backstock: 'backstock',
    Defective: 'defective',
  }

  const url = sku
    ? `/api/inventory/inventory-item/inventory_list?` +
      `page=${page}` +
      `&q=${encodeURIComponent(sku)}` +
      `&order_by=${Object.keys(sort).map((k: string) => k.toLowerCase().replace(' ', '_'))}` +
      `&sort_by=${Object.values(sort).map((k: any) => k.toLowerCase().replace(' ', '_'))}` +
      `&${filterOptions
        .filter(({ selected }) => selected)
        .map(({ label, value, type }) =>
          type === 'boolean'
            ? label.toLowerCase().replace(' ', '_') + '=' + String(value)
            : type === 'multi-select'
              ? label.toLowerCase().replace(' ', '_') + '=' + value.map((v) => v.value).toString()
              : null
        )
        .join('&')}`
    : ''

  const { response: resp, loaded: loaded, error: error }: any = useFetch(url, {})
  const inventoryItems = loaded ? resp.results : []
  const count = loaded ? resp.count : 0
  const totalPages = loaded && inventoryItems.length ? Math.round(count / inventoryItems.length) : 1

  const customSetInventoryType = (e: string) => {
    let newSelection: any = [...filterOptions]
    if (e === 'Pickable')
      newSelection[0] = { ...newSelection[0], selected: true, value: [{ value: 'pickable', label: 'Pickable' }] }
    else if (e === 'Backstock')
      newSelection[0] = { ...newSelection[0], selected: true, value: [{ value: 'backstock', label: 'Backstock' }] }
    else if (e === 'Defective')
      newSelection[0] = { ...newSelection[0], selected: true, value: [{ value: 'defective', label: 'Defective' }] }
    else
      newSelection[0] = {
        ...newSelection[0],
        selected: true,
        value: [
          { value: 'pickable', label: 'Pickable' },
          { value: 'backstock', label: 'Backstock' },
          { value: 'defective', label: 'Defective' },
        ],
      }
    setFilterOptions(newSelection)
    setInventoryType(e)
  }

  return (
    <GlobalStyles.FullPageCard style={{ width: '100%', overflow: 'scroll', padding: '2em 1em' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            Available Locations<small>{count.toLocaleString()} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <ButtonDropdown
            title={'Inventory Type'}
            sort={inventoryType}
            setSort={customSetInventoryType}
            options={Object.keys(sortOptions)}
            width={'120px'}
            height={'40px'}
            hidden={false}
          />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <GlobalStyles.DataTable style={{ minHeight: '150px' }}>
        <thead>
          <tr>
            <THSort title={'Location'} {...{ sort, setSort }} />
            <THSort title={'Type'} center {...{ sort, setSort }} />
            <THSort title={'Received'} center {...{ sort, setSort }} />
            <THSort title={'Lot Number'} center {...{ sort, setSort }} />
            <THSort title={'On Hand'} center {...{ sort, setSort }} />
            <THSort title={'Reserved'} center {...{ sort, setSort }} />
            <THSort title={'Available'} center {...{ sort, setSort }} />
            <th>
              <span className="text center">
                Capacity (ft<sup>3</sup>)
              </span>
            </th>
            <th>
              <span className="text center">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {inventoryItems?.map((i: any, id: number) => (
            <GlobalStyles.TableRow key={id}>
              <TableCell
                input={
                  <Link to={`/location/${i.location}/`} target="_blank">
                    {i.location_barcode}
                  </Link>
                }
              />
              <TableCell center style={{ textTransform: 'capitalize' }}>
                <StatusBoxStyled
                  className={['pickable', 'backstock'].includes(i.inventory_type) ? 'blue' : 'yellow'}
                  style={{ width: 100, margin: 'auto' }}
                >
                  {i.inventory_type}
                </StatusBoxStyled>
              </TableCell>
              <TableCell center>{i.received_date}</TableCell>
              <TableCell center>{i.lot_number}</TableCell>
              <TableCell center>
                <StatusBoxStyled className={i.on_hand ? 'blue' : 'silver'} style={{ minWidth: 75, margin: 'auto' }}>
                  {i.on_hand}
                </StatusBoxStyled>
              </TableCell>
              <TableCell center>
                <StatusBoxStyled className={i.reserved ? 'blue' : 'silver'} style={{ minWidth: 75, margin: 'auto' }}>
                  {i.reserved}
                </StatusBoxStyled>
              </TableCell>
              <TableCell
                center
                input={
                  <StatusBoxStyled className={i.available ? 'blue' : 'silver'} style={{ minWidth: 75, margin: 'auto' }}>
                    {i.available}
                  </StatusBoxStyled>
                }
              />
              <TableCell
                center
                input={
                  <StatusBoxStyled
                    className={i.capacity_available > 0 ? 'green' : 'yellow'}
                    style={{ minWidth: 75, margin: 'auto' }}
                  >
                    {i.capacity_available}
                  </StatusBoxStyled>
                }
              />
              <TableCell center>
                <button
                  onClick={() => {
                    setInventoryFields({
                      ...inventoryFields,
                      location: i.location_barcode,
                      inventory_type: i.inventory_type,
                      lot_number: i.lot_number,
                      expiry_date: i.expiry_date ? new Date(i.expiry_date) : '',
                      best_before_date: i.best_before_date ? new Date(i.best_before_date) : '',
                      manufacture_date: i.manufacture_date ? new Date(i.manufacture_date) : '',
                    })
                    setShowLotFields(true)
                  }}
                >
                  Select
                </button>
              </TableCell>
            </GlobalStyles.TableRow>
          ))}
        </tbody>
        {!loaded ? <CardLoading error={error} /> : null}
      </GlobalStyles.DataTable>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} show={5} />
      </GlobalStyles.CardFooter>
    </GlobalStyles.FullPageCard>
  )
}

const LotField: FunctionComponent<LotFieldProps> = ({
  field,
  inventoryFields,
  setInventoryFields,
  focused,
  setFocused,
  inventoryControlCategory,
}) => {
  const field_normal = field
    .split('_')
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')

  const [currentDate, setCurrentDate]: any = useState('')
  useEffect(() => {
    setCurrentDate(inventoryFields[field])
  }, [field, inventoryFields])

  return (
    <Col md={'6'} className={field === inventoryControlCategory || field === 'received_date' ? 'required' : ''}>
      <Form.Label>{field_normal}</Form.Label>
      <InputGroup>
        <Form.Control
          type="text"
          value={currentDate ? currentDate.toLocaleDateString('en-US').replace(/\//g, '-') : ''}
          onChange={(e) => null}
          onFocus={() => setFocused(field)}
          required={field === inventoryControlCategory || field === 'received_date' ? true : false}
        />
        <GlobalStyles.ClearInputButton
          type="button"
          onClick={() => {
            setFocused('')
            inventoryFields[field] = ''
            setInventoryFields(inventoryFields)
            setCurrentDate(null)
          }}
        >
          Clear
        </GlobalStyles.ClearInputButton>
        <InputGroup.Text id="inputGroupAppend">
          <FontAwesomeIcon icon={faCalendarWeek} />
        </InputGroup.Text>
        <Form.Control.Feedback type="invalid">{field_normal} is required</Form.Control.Feedback>
      </InputGroup>
      {focused === field && (
        <DateRangeStyled>
          {/* @ts-ignore */}
          <Calendar
            onChange={(item) => {
              setCurrentDate(item)
              inventoryFields[field] = item
              setInventoryFields(inventoryFields)
              setFocused('')
            }}
            date={inventoryFields[field]}
            className="hide-in-percy"
          />
        </DateRangeStyled>
      )}
    </Col>
  )
}

const StatusBar: FunctionComponent<StatusBarProps> = ({
  asn,
  name,
  warehouse,
  company,
  good = 0,
  defective = 0,
  sent,
  loaded,
  error,
}) => {
  return (
    <Row className="hide-in-percy">
      <Col>
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              <FontAwesomeIcon icon={faDolly} style={{ color: '#5d78ff' }} />
            </div>
            <div className="status-text">
              Receiving Inventory - {name}
              <br />
              {good + defective} / {sent} received
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon style={{ color: '#FFB822' }} icon={faClipboardList} />
            </div>

            <div className="status-text">
              Processing ASN {asn}
              <div>
                Company: <strong>{company}</strong>
              </div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text" style={{ paddingRight: '1em' }}>
              Facility:
            </div>
            <div className="status-text">
              <strong>{warehouse}</strong>
            </div>
            <div className="status-icon" style={{ marginLeft: '2em' }}>
              <FontAwesomeIcon icon={faWarehouse} />
            </div>
          </div>
          {!loaded ? <CardLoading error={error} /> : null}
        </StatusBarStyled>
      </Col>
    </Row>
  )
}

export const ReceiveInventory = () => {
  const asn = location.pathname.split('/')[2]
  const { shipnotice_item_id: itemId } = useParams<{ shipnotice_item_id: string }>()
  const { response: item, error, loaded }: any = useFetch(`/api/inventory/asn/${asn}/item/${itemId}/`, {})
  const id = loaded ? item.id : null
  const compliant = loaded ? item.compliant : undefined
  const variant = loaded ? item.product_variant : null
  const variantId = loaded ? variant.id : ''
  const variantName = loaded ? variant.name : ''
  const barcode = loaded ? variant.barcode : ''
  const received = loaded ? item.received : 0
  const sku = loaded ? variant.sku : ''
  const good = loaded ? item.good : 0
  const defective = loaded ? item.defective : 0
  const over_short = loaded ? item.over_short : 0
  const img = loaded ? item.img : ''
  const sent = loaded ? parseInt(item.quantity) : 0
  const warehouse = loaded ? item.warehouse : ''
  const company = loaded ? variant.company : ''
  const lotControl = loaded ? item.lot_control : {}
  const inventoryControlCategory = loaded ? variant.inventory_control_category : 'received_date'
  const current = 'receive_inventory'
  const shipnotice = loaded ? item.shipnotice : null
  const isProject = loaded ? shipnotice.project_asn : false

  const node: any = useRef()
  const theme: object = useTheme()
  const [validated, setValidated] = useState(false)
  const [printModal, setPrintModal] = useState(false)
  const [openReceivingHistoryModal, setReceivingHistoryModal] = useState(false)
  const [variantIsConfirmed, setVariantIsConfirmed] = useState(false)

  // Compliance
  const [openCompliance, setCompliance] = useState(false)
  const itemCompliance = loaded ? item.compliance : null

  const {
    state: { csrf },
  } = globalState()

  const [showLotFields, setShowLotFields] = useState(false)
  const [focused, setFocused] = useState('')
  const [lotNumber, setLotNumber] = useState('')
  const [suggestedInventoryItems, setSuggestedInventoryItems] = useState([])
  const [inventoryFields, setInventoryFields]: any = useState({
    quantity: 0,
    location: '',
    inventory_type: 'pickable',
    lot_number: '',
    expiry_date: null,
    best_before_date: null,
    manufacture_date: null,
    received_date: new Date(),
  })

  const inventoryTypesOptions = [
    { value: 'pickable', label: 'Pickable' },
    { value: 'backstock', label: 'Backstock' },
    { value: 'defective', label: 'Defective' },
  ]

  // For Returns, set lot control on pre-existing item
  useEffect(() => {
    if (Object.keys(lotControl).length > 0) {
      setInventoryFields({
        ...inventoryFields,
        best_before_date: lotControl.best_before_date ? new Date(lotControl.best_before_date) : '',
        expiry_date: lotControl.expiry_date ? new Date(lotControl.expiry_date) : '',
        manufacture_date: lotControl.manufacture_date ? new Date(lotControl.manufacture_date) : '',
        received_date: new Date(lotControl.received_date),
        lot_number: lotControl.lot_number,
      })
      setLotNumber(lotControl.lot_number)
    }
  }, [lotControl])

  // Show lot fields if inventory control category is set
  useEffect(() => {
    if (inventoryControlCategory !== 'received_date') setShowLotFields(true)
  }, [inventoryControlCategory])

  useEffect(() => {
    if (variant) {
      setVariantIsConfirmed(
        variant.sku &&
          variant.barcode &&
          variant.height > 0 &&
          variant.width > 0 &&
          variant.length > 0 &&
          variant.weight > 0 &&
          variant.price > 0
      )
    }
  }, [loaded])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    const expiry_date = inventoryFields.expiry_date
      ? inventoryFields.expiry_date.toLocaleDateString('en-US').replace(/\//g, '-')
      : null
    const best_before_date = inventoryFields.best_before_date
      ? inventoryFields.best_before_date.toLocaleDateString('en-US').replace(/\//g, '-')
      : null
    const manufacture_date = inventoryFields.manufacture_date
      ? inventoryFields.manufacture_date.toLocaleDateString('en-US').replace(/\//g, '-')
      : null
    const received_date = inventoryFields.received_date
      ? inventoryFields.received_date.toLocaleDateString('en-US').replace(/\//g, '-')
      : null

    usePut(
      `/api/inventory/asn/${asn}/item/${id}/receive_inventory/`,
      { ...inventoryFields, ...{ expiry_date, best_before_date, manufacture_date, received_date } },
      csrf
    )
  }

  const handleKeyPressed = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }

  const toggleLotFields = () => {
    setShowLotFields(!showLotFields)
  }

  // Add cursor to input field on render
  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputElement?.current?.focus()
    inputElement?.current?.select()
  }, [])

  return (
    <>
      <StatusBar
        asn={asn}
        name={variantName}
        warehouse={warehouse}
        company={company}
        good={good}
        defective={defective}
        sent={sent}
        loaded={loaded}
        error={error}
      />
      <Row>
        <Col lg={3} xl={2}>
          <StickyElement>
            <OrderOptions>
              <ul>
                <GlobalStyles.Tooltip data-title="Print formatted labels given scancode">
                  <AsnOption onClick={() => setPrintModal(true)} icon={faPrint} title={'Print Labels'} />
                </GlobalStyles.Tooltip>
                {/* @ts-ignore */}
                <PrintLabelsModal open={printModal} setOpen={setPrintModal} id={variantId} scancode={barcode} />
                <GlobalStyles.Tooltip data-title="Print inventory item barcodes for this shipnotice item">
                  <AsnOption
                    url={`/api/inventory/asn/${asn}/item/${id}/print_inventory_items/`}
                    icon={faPrintSearch}
                    title={'Print Inventory Item QR Codes'}
                  />
                </GlobalStyles.Tooltip>
                {!isProject && (
                  <GlobalStyles.Tooltip data-title="Add non-compliance billing for this specific ASN item">
                    <AsnOption
                      onClick={() => setCompliance(true)}
                      icon={faFileInvoiceDollar}
                      title={'Add Non-Compliance'}
                    />
                  </GlobalStyles.Tooltip>
                )}
                {loaded && (
                  <ComplianceModal
                    {...itemCompliance}
                    variant={variantName}
                    itemId={id}
                    open={openCompliance}
                    setOpen={setCompliance}
                  />
                )}

                <GlobalStyles.Tooltip data-title="Displays list of all inventory transactions">
                  <AsnOption
                    onClick={() => setReceivingHistoryModal(true)}
                    icon={faClipboardList}
                    title={'Receiving History'}
                  />
                </GlobalStyles.Tooltip>
                <ReceivingHistory
                  open={openReceivingHistoryModal}
                  setOpen={setReceivingHistoryModal}
                  inventoryTransactions={item?.inventory_transactions}
                />
              </ul>
            </OrderOptions>
          </StickyElement>
        </Col>
        <Col xl={7}>
          <GlobalStyles.FullPageCard>
            <GlobalStyles.CardHeader>
              <GlobalStyles.CardTitle>
                <h3>Item Progress</h3>
              </GlobalStyles.CardTitle>
            </GlobalStyles.CardHeader>
            <div style={{ minHeight: 130 }}>
              <GlobalStyles.DataTable>
                <thead>
                  <tr>
                    <th />
                    <th>
                      <span className="text">Product</span>
                    </th>
                    <th>
                      <span className="text center">SKU</span>
                    </th>
                    <th>
                      <span className="text center">Sent</span>
                    </th>
                    <th>
                      <span className="text center">Received</span>
                    </th>
                    <th>
                      <span className="text center">Defective</span>
                    </th>
                    <th>
                      <span className="text center">Over/Short</span>
                    </th>
                    <th>
                      <span className="text center">Compliance</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <AsnItem
                    pId={variantId}
                    barcode={barcode}
                    sku={sku}
                    img={img}
                    name={variantName}
                    received={received}
                    over_short={over_short}
                    good={good}
                    defective={defective}
                    sent={sent}
                    key={id}
                    compliant={compliant}
                    single
                  />
                </tbody>
                {!loaded ? <CardLoading error={error} /> : null}
              </GlobalStyles.DataTable>
            </div>
          </GlobalStyles.FullPageCard>
          <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em' }}>
            <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
              <GlobalStyles.CardTitle>
                <h3>Receive Item</h3>
              </GlobalStyles.CardTitle>
              <GlobalStyles.CardToolbar>
                <GlobalStyles.Button onClick={handleSubmit} style={{ height: 40, marginRight: '-0.5em' }}>
                  Receive
                </GlobalStyles.Button>
              </GlobalStyles.CardToolbar>
            </GlobalStyles.CardHeader>
            <Form
              onKeyDown={handleKeyPressed}
              noValidate
              ref={node}
              validated={validated}
              style={{ marginTop: '1em', padding: '0 1em' }}
            >
              <Row>
                <Form.Group as={Col} md={'4'} className="required">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    name="quantity"
                    placeholder={''}
                    onChange={(e) => setInventoryFields({ ...inventoryFields, quantity: e.target.value })}
                    required
                    ref={inputElement}
                  />

                  <Form.Control.Feedback type="invalid">Please enter a valid quantity</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={'4'} className="required">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="X-1-1-20"
                    required
                    value={inventoryFields.location}
                    onChange={(e) => setInventoryFields({ ...inventoryFields, location: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a valid location</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={'4'} className="required">
                  <Form.Label>Inventory Type</Form.Label>
                  <Form.Select
                    required
                    value={inventoryFields.inventory_type}
                    onChange={(e) => setInventoryFields({ ...inventoryFields, inventory_type: e.target.value })}
                  >
                    {inventoryTypesOptions.map(({ value, label }, id) => (
                      <option value={value} key={id}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">Please provide a valid inventory type</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div style={{ margin: '1em 0', cursor: 'pointer' }} onClick={toggleLotFields}>
                <h6>
                  <span style={{ marginRight: '8px' }}>Lot Control Fields</span>
                  <GlobalStyled.Arrow className={`aside-menu_item ${showLotFields ? 'active' : ''}`} />
                </h6>
              </div>

              <Row
                style={{
                  opacity: showLotFields ? '1' : '0',
                  transition: 'all .5s',
                }}
              >
                {showLotFields && (
                  <>
                    <Col md={'6'}>
                      <Form.Label>Lot Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={inventoryFields.lot_number}
                        onChange={(e) => {
                          setInventoryFields({ ...inventoryFields, lot_number: e.target.value })
                          setLotNumber(e.target.value)
                        }}
                      />
                    </Col>

                    {Object.values(LotControlField).map((value, index) => {
                      return (
                        <LotField
                          key={index}
                          field={value}
                          focused={focused}
                          setFocused={setFocused}
                          inventoryFields={inventoryFields}
                          setInventoryFields={setInventoryFields}
                          inventoryControlCategory={inventoryControlCategory}
                        />
                      )
                    })}
                  </>
                )}
              </Row>
            </Form>
            {!loaded ? <CardLoading error={error} /> : null}
          </BackgroundLayer>
          <AvailableLocations
            setShowLotFields={setShowLotFields}
            sku={sku}
            inventoryFields={inventoryFields}
            setInventoryFields={setInventoryFields}
          />
        </Col>
        <Col xl={3}>
          <ReceivingWorkflow
            asn={asn}
            current={current}
            itemId={itemId}
            variantIsConfirmed={variantIsConfirmed}
            isProject={isProject}
          />
          {!loaded ? <CardLoading error={error} /> : null}
        </Col>
      </Row>
    </>
  )
}
