import React from 'react'
import { useHistory } from 'react-router-dom'
import { Link, useParams } from 'react-router-dom'
import { useFetch, usePut } from '../../../components/component-items/helpers'
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import { globalState } from '../../../store'

import { ShipmentListStyled, ShipmentListCellStyled, ShipmentListRowStyled, DataTable } from './styles'

import { BatchListCellProps, BatchListRowProps, ProductRowProps } from './types'

const BatchListCell = ({ style, input, children, center, rowspan }: BatchListCellProps) => {
  return (
    <ShipmentListCellStyled style={style} rowSpan={rowspan}>
      <div style={{ textAlign: center ? 'center' : 'left', minWidth: '20px' }}>
        <span className="input">{input}</span>
        {children}
      </div>
    </ShipmentListCellStyled>
  )
}

const ProductRow = ({ id, sku, desc, qty, serialNumber, hasSerialNumbers }: ProductRowProps) => (
  <>
    <BatchListCell
      input={
        <>
          <Link to={`/product/variant/${id}/`} rel="noreferrer" target="__none">
            {sku}
          </Link>
          <div>{desc}</div>
        </>
      }
    />
    {hasSerialNumbers ? <BatchListCell input={serialNumber} /> : null}
    <BatchListCell input={qty} center />
  </>
)

function addItems(item: any[], toAdd: any, itemType: any, hasSerialNumbers: boolean) {
  const lineItems = toAdd.map((v: any, id: number) => (
    <ProductRow id={v.pId} {...v} {...{ hasSerialNumbers }} key={id} />
  ))
  var items = []
  items.push(
    <>
      <BatchListCell input={itemType} rowspan={toAdd.length} />
      {lineItems[0]}
    </>
  )
  items = items.concat(lineItems.slice(1))
  return items
}

const BatchListRow = ({ orderItems, dims, shipmentNumber, hasSerialNumbers }: BatchListRowProps) => {
  const productCount = orderItems.products.length
  const promoCount = orderItems.promoItems.length
  const packageCount = orderItems.package ? 1 : 0
  const totalCount = productCount + promoCount + packageCount

  let items: any[] = []
  if (productCount) {
    items = items.concat(addItems([], orderItems.products, 'Order Item', hasSerialNumbers))
  }
  if (promoCount) {
    items = items.concat(addItems([], orderItems.promoItems, 'Promo Item', hasSerialNumbers))
  }
  if (packageCount) {
    items = items.concat(addItems([], [orderItems.package], 'Packaging', hasSerialNumbers))
  }

  return (
    <>
      <ShipmentListRowStyled>
        <BatchListCell input={shipmentNumber} rowspan={totalCount} />
        <BatchListCell
          input={
            <span>
              {dims.length} x {dims.width} x<br />
              {dims.height} x ({dims.weight} oz)
            </span>
          }
          rowspan={totalCount}
        />
        {items[0]}
      </ShipmentListRowStyled>
      {items.slice(1).map((i, id) => (
        <ShipmentListRowStyled key={id}>{i}</ShipmentListRowStyled>
      ))}
    </>
  )
}

export const ShipmentList = () => {
  const {
    state: { csrf },
  } = globalState()
  let history = useHistory()
  const order = useParams<{ id: string }>().id

  const url = `/api/order/${order}/shipments/`
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})

  const shipmentItems = loaded ? resp.shipments : []
  const hasSerialNumbers = loaded ? resp.hasSerialNumbers : false

  const handleExportShipments = async () => {
    const url = `/api/order/${order}/download_shipments/`
    const resp = await usePut(url, {}, csrf, false)
    if (resp === undefined) {
      history.push('/company/reports/')
    }
  }

  return (
    <ShipmentListStyled>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>
            Shipments<small>{shipmentItems.length} Total</small>
          </h3>
        </div>
        <div className="datatable__title">
          <GlobalStyles.Button className="secondary" onClick={handleExportShipments}>
            Export Shipment Details
          </GlobalStyles.Button>
        </div>
      </div>
      <div className="card-body">
        <DataTable style={{ maxHeight: 'unset' }}>
          <thead>
            <tr>
              <th>
                <span className="text">#</span>
              </th>
              <th>
                <span className="text">L x W x H (OZ)</span>
              </th>
              <th>
                <span className="text">Item Type</span>
              </th>
              <th>
                <span className="text">Product Variant</span>
              </th>
              {hasSerialNumbers ? (
                <th>
                  <span className="text">Serial Number</span>
                </th>
              ) : null}
              <th>
                <span className="text" style={{ textAlign: 'center' }}>
                  QTY
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {shipmentItems.map((s: any, id: number) => (
              <BatchListRow {...s} {...{ hasSerialNumbers }} key={id} />
            ))}
          </tbody>
        </DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </ShipmentListStyled>
  )
}
