import React, { useState, useEffect, useRef } from 'react'
import { globalState } from '../../../../store'
import { usePost, useFetch, notify } from '../../../../components/component-items/helpers'
import { useParams } from 'react-router-dom'
import { BackgroundLayer } from './styles'
import { toast } from 'react-toastify'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { Form, Col, Row, InputGroup } from 'react-bootstrap'
import CardLoading from '../../../../components/component-items/loading-popover'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBarcodeRead,
  faUsdCircle,
  faBoxUsd,
  faFileInvoiceDollar,
  faWeightHanging,
  faTags,
  faRuler,
} from '@fortawesome/pro-duotone-svg-icons'

// USPS Retail Ground pieces may measure up to 130 inches in combined length and girth
// https://www.ups.com/pt/en/support/shipping-support/shipping-dimensions-weight.page
const MAX_DIMENSIONS_WARNING = 60
// USPS and UPS Max is around 70 pounds https://www.ups.com/pt/en/support/shipping-support/shipping-dimensions-weight.page
const MAX_WEIGHT_WARNING = 1120

export const ProductVariantSpecifications = ({ id }: { id?: string | number }) => {
  const productVariant = id || useParams<{ id: string }>().id
  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)
  const [state, setState] = useState<any>({})

  const {
    state: { csrf },
  } = globalState()

  const url = `/api/product/variant/${productVariant}/specifications/`
  const { response: resp, error, loaded, placeholder }: any = useFetch(url, {})

  const specs = loaded ? resp.specifications : {}
  const isBundle = loaded ? resp.isBundle : false
  const isPackaging = loaded ? resp.isPackaging : false
  const isWarehouse = loaded ? resp.isWarehouse : false
  useEffect(() => {
    if (loaded) {
      if (resp.error) {
        notify({ type: 'error', message: resp.error })
      } else {
        setState(specs)
      }
    }
  }, [loaded])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    const showDimensionsWarning =
      Number(state.length || 0) >= MAX_DIMENSIONS_WARNING ||
      Number(state.width || 0) >= MAX_DIMENSIONS_WARNING ||
      Number(state.height || 0) >= MAX_DIMENSIONS_WARNING
    const showWeightWarning = Number(state.weight || 0) >= MAX_WEIGHT_WARNING

    // Make sure dimensions and price are set
    if (
      !isBundle &&
      (!Math.ceil(state.weight) ||
        !Math.ceil(state.width) ||
        !Math.ceil(state.height) ||
        !Math.ceil(state.price) ||
        !Math.ceil(state.length))
    ) {
      toast('Dimensions and price must have a value other than 0', { type: 'error' })
      return
    }

    if (node?.current?.checkValidity() === false || !isWarehouse) {
      return
    }

    if (showDimensionsWarning || showWeightWarning) {
      notify({
        title: 'Dimensions or weight are bigger than expected',
        message: 'Are you sure that you want to ship this order?',
        type: 'warning',
        onSubmit: () => usePost(`/api/product/variant/${productVariant}/specifications/`, { ...state }, csrf),
        // @ts-ignore
        onDismiss: () => {
          setState({
            ...state,
            ...(Number(state.weight || 0) >= MAX_WEIGHT_WARNING ? { weight: 0 } : { weight: state.weight }),
            ...(Number(state.length || 0) >= MAX_DIMENSIONS_WARNING ? { length: 0 } : { length: state.length }),
            ...(Number(state.width || 0) >= MAX_DIMENSIONS_WARNING ? { width: 0 } : { width: state.width }),
            ...(Number(state.height || 0) >= MAX_DIMENSIONS_WARNING ? { height: 0 } : { height: state.height }),
          })
        },
        autoClose: 0,
        requestConfirmation: true,
      })
    } else {
      usePost(`/api/product/variant/${productVariant}/specifications/`, { ...state }, csrf)
    }
  }

  return (
    <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>Specifications</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {isWarehouse ? (
            <GlobalStyles.Button onClick={handleSubmit} style={{ height: 40, marginRight: '-0.5em' }}>
              Update
            </GlobalStyles.Button>
          ) : null}
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <Form noValidate ref={node} validated={validated} style={{ marginTop: '1em' }}>
        <Row>
          <Form.Group as={Col} md={'6'} className="required">
            <Form.Label>SKU</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faTags} />
              </InputGroup.Text>
              <Form.Control
                required
                type="text"
                placeholder="SKU"
                value={state.sku || ''}
                disabled={!isWarehouse}
                onChange={(e) => setState({ ...state, sku: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a SKU</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md={'6'} className="required">
            <Form.Label>Scancode</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faBarcodeRead} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Scancode"
                aria-describedby="inputGroupPrepend"
                required
                disabled={!isWarehouse}
                value={state.scancode || ''}
                onChange={(e) => setState({ ...state, scancode: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a scancode.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        {!isBundle ? (
          <Row>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Weight (oz)</Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faWeightHanging} />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Weight (oz)"
                  value={state.weight || ''}
                  onChange={(e) => setState({ ...state, weight: e.target.value })}
                  required
                  disabled={!isWarehouse}
                />
                <Form.Control.Feedback type="invalid">Please provide a valid Weight.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Length (in)</Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faRuler} />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Length (in)"
                  value={state.length || ''}
                  onChange={(e) => setState({ ...state, length: e.target.value })}
                  required
                  disabled={!isWarehouse}
                />
                <Form.Control.Feedback type="invalid">Please provide a valid Length.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Width (in)</Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faRuler} />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Width (in)"
                  value={state.width || ''}
                  onChange={(e) => setState({ ...state, width: e.target.value })}
                  required
                  disabled={!isWarehouse}
                />
                <Form.Control.Feedback type="invalid">Please provide a valid Width.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Height (in)</Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faRuler} />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Height (in)"
                  value={state.height || ''}
                  onChange={(e) => setState({ ...state, height: e.target.value })}
                  required
                  disabled={!isWarehouse}
                />
                <Form.Control.Feedback type="invalid">Please provide a valid Height.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
        ) : null}
        {isPackaging && (
          <Row>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Stored Length (in)</Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faRuler} />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Stored Length (in)"
                  value={state.storedLength || ''}
                  onChange={(e) => setState({ ...state, storedLength: e.target.value })}
                  required
                  disabled={!isWarehouse}
                />
                <Form.Control.Feedback type="invalid">Please provide a valid Stored Length.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Stored Width (in)</Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faRuler} />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Width (in)"
                  value={state.storedWidth || ''}
                  onChange={(e) => setState({ ...state, storedWidth: e.target.value })}
                  required
                  disabled={!isWarehouse}
                />
                <Form.Control.Feedback type="invalid">Please provide a valid Stored Width.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Stored Height (in)</Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faRuler} />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Height (in)"
                  value={state.storedHeight || ''}
                  onChange={(e) => setState({ ...state, storedHeight: e.target.value })}
                  required
                  disabled={!isWarehouse}
                />
                <Form.Control.Feedback type="invalid">Please provide a valid Stored Height.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
        )}
        <Row>
          <Form.Group as={Col} md="4" className="required">
            <Form.Label>Price</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faUsdCircle} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Price"
                value={state.price || ''}
                onChange={(e) => setState({ ...state, price: e.target.value })}
                required
                disabled={!isWarehouse}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid price.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Wholesale Price</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faBoxUsd} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Wholesale Price"
                value={state.wholesalePrice || ''}
                onChange={(e) => setState({ ...state, wholesalePrice: e.target.value })}
                disabled={!isWarehouse}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid wholesale price.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom05">
            <Form.Label>Custom Processing Fee</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faFileInvoiceDollar} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Custom Fee"
                value={state.customFee || ''}
                onChange={(e) => setState({ ...state, customFee: e.target.value })}
                disabled={!isWarehouse}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Processing Fee.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
      </Form>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BackgroundLayer>
  )
}
