import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch, usePost, useDelete } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GenericModal from '../../../components/component-items/modal'
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'

import { DragNDropMany } from '../../../components/component-items/dragndrop'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {
  thumbsContainer,
  thumb,
  thumbInner,
  img,
  ProductImage,
  NotesContainer,
  LargeNotesContainer,
  H3,
} from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faTrashAlt, faPrint } from '@fortawesome/pro-duotone-svg-icons'

function updateEditorState(state: any, setEditorState: any) {
  const blocksFromHtml = htmlToDraft(state)
  const { contentBlocks, entityMap } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
  const editorState = EditorState.createWithContent(contentState)
  setEditorState(editorState)
}

export const EditProjectNotes = ({ notes, open, setOpen }: any) => {
  const params: any = useParams()
  const project = params.id
  const [files, setFiles] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const {
    state: { csrf },
  } = globalState()

  useEffect(() => {
    updateEditorState(notes || '', setEditorState)
  }, [notes])

  const updateNotes = () => {
    const formData = new window.FormData()
    files.map((file: any) => formData.append(file.name, file))

    const notes = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    formData.append('notes', notes)

    const reload = true
    const stringifyBody = false
    usePost(`/api/inventory/project/${project}/details/`, formData, csrf, reload, stringifyBody)
  }

  return (
    <GenericModal
      heading={'Edit Project Instructions'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={() => updateNotes()}>Update</GlobalStyles.Button>}
    >
      <LargeNotesContainer>
        <H3>Project Instructions</H3>
        {/* @ts-ignore */}
        <Editor
          editorState={editorState}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-container"
          onEditorStateChange={setEditorState}
        />
        <H3 style={{ marginTop: '2em' }}>Project Files</H3>
        {/* @ts-ignore */}
        <DragNDropMany {...{ files, setFiles }} />
      </LargeNotesContainer>
    </GenericModal>
  )
}

export const ProjectNotes = () => {
  const [open, setOpen] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const params: any = useParams()
  const project = params.id
  const { response, loaded, error, placeholder }: any = useFetch(`/api/inventory/project/${project}/notes/`, {}, 60)

  const {
    state: { csrf },
  } = globalState()
  const notes = loaded ? response.notes : null
  const files = loaded ? response.files : null

  useEffect(() => {
    updateEditorState(notes || '', setEditorState)
  }, [notes])

  function handleDelete(id: number | string) {
    useDelete(`/api/inventory/project/${project}/file/${id}/`, csrf)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>Project Instructions</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button onClick={() => setOpen(true)}>Edit</GlobalStyles.Button>
          <EditProjectNotes {...{ notes, open, setOpen }} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <NotesContainer>
        {/* @ts-ignore */}
        <Editor
          editorState={editorState}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-container"
          toolbarHidden
          readOnly
        />
        {/* @ts-ignore */}
        <aside style={thumbsContainer}>
          {files?.map((file: any) => (
            <div style={thumb} key={file.id}>
              <ProductImage>
                {file.isImage ? (
                  // @ts-ignore
                  <div style={thumbInner}>
                    <img src={file.url} style={img} />
                  </div>
                ) : (
                  // @ts-ignore
                  <div style={{ ...thumbInner, alignItems: 'center' }} key={file.name}>
                    <FontAwesomeIcon icon={faFile} />
                  </div>
                )}
                <div className="shade" />
                <div className="buttons">
                  <a href={file.url} download>
                    <button>
                      <FontAwesomeIcon icon={faPrint} />
                    </button>
                  </a>
                  <button onClick={() => handleDelete(file.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </ProductImage>
              <div style={{ lineBreak: 'anywhere', textAlign: 'center' }}>{file.name}</div>
            </div>
          ))}
        </aside>
        {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      </NotesContainer>
    </GlobalStyles.FullPageCard>
  )
}
