import React, { useState, useEffect } from 'react'
import { useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import styled from 'styled-components'
import { Form, Col, Row } from 'react-bootstrap'
import { format } from 'date-fns'

// Components
import GlobalStyles from '../../../components/component-items/styles'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar } from 'react-date-range'

export const DateRangeStyled = styled.div`
  overflow: scroll;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em auto;
  width: fit-content;
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0;
  }
`

export const FilterListItem = styled.div`
  margin: 0 auto 1em;
  width: fit-content;
`

export const CreateAsn = () => {
  const [validated, setValidated] = useState(false)
  const [asnData, setAsnData] = useState({
    sender: '',
    warehouse: '',
    tracking_number: '',
    service_level: '',
    integration_id: '',
    po_number: '',
    bol_number: '',
    pro_number: '',
  })

  const {
    state: { csrf, companyId },
  } = globalState()
  const res: any = useFetch(`/api/company/${companyId}/warehouse_list/`, {})
  const loaded = res.loaded
  const resp = res.response

  const multiWarehouse = loaded ? resp.multiWarehouse : false
  const warehouses = loaded ? resp.warehouses : []
  const warehouse = loaded ? resp.warehouse : null
  const warehouseId = loaded ? resp.warehouseId : null

  useEffect(() => {
    if (loaded) {
      setAsnData({ ...asnData, warehouse: warehouseId })
    }
  }, [loaded])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget

    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }

    usePost('/api/inventory/asn/', { ...asnData, delivery_date: format(date, 'yyyy-MM-dd') }, csrf, false)
  }

  var currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)
  const [date, setDate] = useState(currentDate)

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Create ASN</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '65%' }}>
          <Row>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Sender</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Senders Name"
                name="name"
                value={asnData.sender}
                onChange={(e) => setAsnData({ ...asnData, sender: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter the senders name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Warehouse</Form.Label>
              <Form.Select
                value={asnData.warehouse}
                onChange={(e) => setAsnData({ ...asnData, warehouse: e.target.value })}
              >
                {!multiWarehouse ? (
                  <option value={warehouseId}>{warehouse}</option>
                ) : (
                  warehouses.map((c: { id: number | string; name: string }, id: number) => (
                    <option value={c.id} key={id}>
                      {c.name}
                    </option>
                  ))
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please select a warehouse.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6">
              <Form.Label>Tracking Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Tracking #"
                value={asnData.tracking_number}
                onChange={(e) => setAsnData({ ...asnData, tracking_number: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Shipped By (Carrier)</Form.Label>
              <Form.Control
                type="text"
                name="shipped_by"
                placeholder="Carrier Name"
                value={asnData.service_level}
                onChange={(e) => setAsnData({ ...asnData, service_level: e.target.value })}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="3">
              <Form.Label>Integration ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Integration ID"
                value={asnData.integration_id}
                onChange={(e) => setAsnData({ ...asnData, integration_id: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>PO #</Form.Label>
              <Form.Control
                type="text"
                placeholder="PO #"
                value={asnData.po_number}
                onChange={(e) => setAsnData({ ...asnData, po_number: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>BOL #</Form.Label>
              <Form.Control
                type="text"
                placeholder="BOL #"
                value={asnData.bol_number}
                onChange={(e) => setAsnData({ ...asnData, bol_number: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>PRO #</Form.Label>
              <Form.Control
                type="text"
                placeholder="PRO #"
                value={asnData.pro_number}
                onChange={(e) => setAsnData({ ...asnData, pro_number: e.target.value })}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Expected Delivery Date</Form.Label>
              <DateRangeStyled>
                <Calendar
                  onChange={(e) => setDate(e)}
                  date={date}
                  minDate={currentDate}
                  showMonthAndYearPickers={false}
                  className="hide-in-percy"
                />
              </DateRangeStyled>
            </Form.Group>
          </Row>
          <GlobalStyles.Button onClick={() => handleSubmit} style={{ minWidth: 150, margin: 0, float: 'right' }}>
            Next
          </GlobalStyles.Button>
        </div>
      </GlobalStyles.FullPageCard>
    </Form>
  )
}
