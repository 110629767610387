import React, { useState, useEffect, useRef } from 'react'
import { useFetch, usePost, useDelete, usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { TableCell } from '../../../components/component-items/datatable'
import { TableRow } from './styles'
import { Form } from 'react-bootstrap'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/pro-duotone-svg-icons'

import { AddAccountProps } from './types'

export const AddAccount = ({ currentAccount, open, setOpen, countries }: AddAccountProps) => {
  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)
  const defaultRule = {
    id: null,
    carrier: 'FedEx',
    billing_account: '',
    billing_zip: '',
    billing_country: 'US',
  }
  const [account, setAccount] = useState(defaultRule)
  const {
    state: { csrf, companyId },
  } = globalState()

  useEffect(() => {
    if (Object.keys(currentAccount).length === 0 && currentAccount.constructor === Object) {
      setAccount(defaultRule)
    } else {
      setAccount({ ...currentAccount, carrier: currentAccount.carrier_name })
    }
  }, [currentAccount])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    if (account.id) {
      usePut(`/api/company/${companyId}/shipping-account/${account.id}/`, { ...account }, csrf)
    } else {
      usePost(`/api/company/${companyId}/shipping-account/`, { ...account }, csrf)
    }
  }

  return (
    <GenericModal
      heading={account.id ? 'Update Shipping Account' : 'Create Shipping Account'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>{account.id ? 'Update' : 'Create'}</GlobalStyles.Button>}
    >
      <Form ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '2em' }}>
          <Form.Label>Carrier</Form.Label>
          <Form.Select
            required
            value={account.carrier}
            onChange={(e) => setAccount({ ...account, carrier: e.target.value })}
          >
            <option value="FedEx">FedEx</option>
            <option value="UPS">UPS</option>
          </Form.Select>
          <Form.Label style={{ marginTop: '1em' }}>Account Number</Form.Label>
          <Form.Control
            name="remote_method"
            required
            value={account.billing_account}
            onChange={(e) => setAccount({ ...account, billing_account: e.target.value })}
          />
          <Form.Label style={{ marginTop: '1em' }}>Billing Zip</Form.Label>
          <Form.Control
            name="remote_method"
            required
            value={account.billing_zip}
            onChange={(e) => setAccount({ ...account, billing_zip: e.target.value })}
          />
          <Form.Label style={{ marginTop: '1em' }}>Billing Country</Form.Label>
          <Form.Select
            required
            value={account.billing_country}
            onChange={(e) => setAccount({ ...account, billing_country: e.target.value })}
          >
            {Object.keys(countries).map((c, id) => (
              <option value={c} key={id}>
                {countries[c]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

export const ShippingAccounts = () => {
  const {
    state: { csrf, companyId },
  } = globalState()
  const {
    response: resp,
    loaded,
    error,
    placeholder,
  }: any = useFetch(`/api/company/${companyId}/shipping-account/`, {})
  const shippingAccounts = loaded ? resp.accounts : []

  const countries = loaded ? resp.countries : []
  const total = loaded ? shippingAccounts.length : 0

  const [open, setOpen] = useState(false)
  const [currentAccount, setAccount] = useState({})

  function deleteAccount(id: string) {
    useDelete(`/api/company/${companyId}/shipping-account/${id}/`, csrf)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Shipping Accounts<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button
            onClick={() => {
              setAccount({})
              setOpen(true)
            }}
          >
            Add Shipping Account
          </GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 300, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text center">Carrier</span>
              </th>
              <th>
                <span className="text center">Account Number</span>
              </th>
              <th>
                <span className="text center">Billing Zip</span>
              </th>
              <th>
                <span className="text center">Created</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {shippingAccounts?.map((r: any, id: number) => (
              <TableRow key={id}>
                <TableCell center input={r.carrier_name} />
                <TableCell center input={r.billing_account} />
                <TableCell center input={r.billing_zip} />
                <TableCell center input={new Date(r.created).toLocaleDateString('en-US')} />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container">
                      <button
                        onClick={() => {
                          setAccount(r)
                          setOpen(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={() => deleteAccount(r.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  }
                />
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      <AddAccount {...{ open, setOpen, currentAccount, countries }} />
    </GlobalStyles.FullPageCard>
  )
}
