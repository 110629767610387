import React, { useState, useEffect, useContext } from 'react'
import { useFetch } from '../../components/component-items/helpers'
import { Link } from 'react-router-dom'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import { OrderStatusBox } from '../../components/component-items/status-box'
import { Pages } from '../../components/component-items/pager'
import Checkbox from '../../components/component-items/checkbox'
import { BatchListCellCustom, BatchListRowStyled, BatchListStyled, DataTable } from './styles'
import { store } from '../../store'

import { BatchListRowActionsProps, BatchListCellProps } from './types'

const BatchListCell = ({ width, input, children, center }: BatchListCellProps) => (
  <BatchListCellCustom>
    <div style={{ width: width, textAlign: center ? 'center' : 'left' }}>
      <span className="input">{input}</span>
      {children}
    </div>
  </BatchListCellCustom>
)

const BatchListRow = ({ id, company, status, selectAll, setActive }: BatchListRowActionsProps) => {
  const [selected, setSelected] = useState(selectAll)
  const statusName =
    status === 70
      ? 'Paused'
      : status === 105
        ? 'Label Failed'
        : status === 106
          ? 'Buying Label'
          : status === 109
            ? 'Needs Input'
            : status === 110
              ? 'Label Purchased'
              : 'Processing'

  useEffect(() => {
    setSelected(selectAll)
  }, [selectAll])

  useEffect(() => {
    setActive(id, selected)
  }, [selected])

  return (
    <BatchListRowStyled className={selected ? 'active' : ''}>
      <BatchListCell input={<Checkbox {...{ selected, setSelected }} />} />
      <BatchListCell
        width={'100px'}
        input={
          <>
            <Link to={`/order/${id}/`} target="__none">
              {id}
            </Link>
            <div className="company-name">{company}</div>
          </>
        }
      />
      <BatchListCell width={'100px'} input={<OrderStatusBox status={statusName} status_id={status} />} center />
    </BatchListRowStyled>
  )
}

export const OrderListSmall = () => {
  const [selected, setSelected] = useState(false)
  const [page, setPage] = useState(1)
  const globalState = useContext(store)
  const {
    state: { companyId },
  } = globalState

  var resp: any = useFetch(`/api/company/${companyId}/dashboard/order_list?count=10&page=` + (page - 1), {})
  var orders = resp.loaded ? resp.response.problemorders : []
  var total = resp.loaded ? resp.response.total : 0
  var totalPages = resp.loaded && resp.response.total ? Math.ceil(total / 10) : 1

  const [active, setActive] = useState({})
  useEffect(() => {
    const list: any = {}
    orders.map((o: any) => (list[o.id] = selected))
    setActive(list)
  }, [selected])

  const setActiveOrder = (id: number | string, val: any) => {
    setActive({ ...active, [id]: val })
  }

  const [view, setView] = useState('View All')
  useEffect(() => {
    let allInactive = true
    Object.entries(active).forEach(([, val]) => {
      if (val) {
        allInactive = false
      }
    })
    allInactive ? setView('View All') : setView('View Selected')
  }, [active])

  const openOrder = () => {
    if (view === 'View All') {
      window.open('/order/', '_blank')
    } else {
      Object.entries(active).forEach(([key, val]) => (val ? window.open(`/order/${key}/`, '_blank') : null))
    }
  }

  return (
    <BatchListStyled style={{ position: 'relative' }}>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>
            Orders<small>{total} Total</small>
          </h3>
        </div>
        <div className="datatable__toolbar">
          <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => openOrder()}>
            {view}
          </GlobalStyles.Button>
        </div>
      </div>
      {orders && orders.length ? (
        <div className="card-body">
          <DataTable>
            <thead>
              <tr>
                <th>
                  <span>
                    <Checkbox {...{ selected, setSelected }} />
                  </span>
                </th>
                <th>
                  <span className="text" style={{ width: '100px' }}>
                    Order ID
                  </span>
                </th>
                <th>
                  <span className="text" style={{ width: '100px', textAlign: 'center' }}>
                    Status
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((o: any, id: number) => (
                <BatchListRow
                  selectAll={selected}
                  id={o.id}
                  company={o.company__name}
                  status={o.status}
                  setActive={setActiveOrder}
                  key={id}
                />
              ))}
            </tbody>
          </DataTable>
          <div className="datatable__foooter" style={{ justifyContent: 'center' }}>
            <Pages page={page} setPage={setPage} total={totalPages} />
          </div>
        </div>
      ) : resp.loaded ? (
        <div className="card-body">
          <div className="empty-card">
            <div role="img" aria-label="celebrate">
              🛒
            </div>
            No Orders Processing.
          </div>
        </div>
      ) : null}
      {!resp.loaded ? <CardLoading text={resp.placeholder} error={resp.error} /> : null}
    </BatchListStyled>
  )
}

export default OrderListSmall
