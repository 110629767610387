import React, { useState, useRef } from 'react'
import styled from 'styled-components'

// Components
import { useClick } from './helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

import { ButtonDropdownProps } from './types'

const ButtonDropdownStyled = styled.div`
  display: inline-block;
  .button-container {
    position: relative;
    display: inline-block;
  }
  ul.dropdown__selection {
    overflow: hidden;
    width: 100%;
    position: absolute;
    z-index: 10;
    background: ${(props) => props.theme.colors.white};
    list-style: none;
    margin: 0;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    padding: 1rem 0;
    border-radius: 4px;
    li {
      cursor: pointer;
      padding: 10px 15px;
      font-weight: 400;
      color: #595d6e;
      &:hover,
      &.active {
        background: ${(props) => props.theme.colors.gallery};
      }
    }
  }
  .button-label {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding-right: 1rem;
  }
  button {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.white};
    color: #595d6e;
    position: relative;
    outline: 0 !important;
    padding: 0.65rem 1rem;
    border: 1px solid #e2e5ec;
    border-radius: 4px !important;
    &:focus,
    &:active {
      color: #212529;
      background-color: #e6e6e6;
      border-color: #dfdfdf;
      box-shadow: none !important;
      border: 1px solid transparent;
    }
  }
`

const ButtonDropdown = ({ sort, setSort, options, width, height, title, hidden }: ButtonDropdownProps) => {
  const [isOpen, setOpen] = useState(false)
  const node = useRef<HTMLDivElement>(null)
  useClick(node, setOpen)
  const sorted = (sort: string) => {
    setSort(sort)
    setOpen(!isOpen)
  }
  return (
    <ButtonDropdownStyled>
      {!hidden ? (
        <div className="button-label">
          {title} {title ? ':' : null}
        </div>
      ) : null}
      <div className="button-container" ref={node}>
        <button
          onClick={() => setOpen(!isOpen)}
          style={{ width: width ? width : '160px', height: height ? height : 'auto' }}
          type="button"
          title="Status"
        >
          {sort}
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
        {isOpen ? (
          <ul className="dropdown__selection">
            {options.map((title, id) => (
              <li
                onClick={() => {
                  sorted(title)
                }}
                className={sort === title ? 'active' : ''}
                key={id}
              >
                {title}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </ButtonDropdownStyled>
  )
}

export default ButtonDropdown
