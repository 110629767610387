import styled from 'styled-components'

export const DateRangeStyled = styled.div`
  display: flex;
  overflow: scroll;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em auto;
  width: fit-content;
  h5 {
    font-size: 1.2em;
    text-align: center;
    margin-top: 1em;
    width: 100%;
  }
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0;
  }
`

export const FilterListItem = styled.div`
  margin: 0 auto 1em;
  width: fit-content;
`

export const DragNDrop = styled.section`
  margin: 1em 0;
  .dropzone {
    border: dashed 2px grey;
    padding: 1em;
    justify-content: center;
    display: flex;
  }
  h4 {
    margin-top: 1em;
  }
`
