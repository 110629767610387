import React from 'react'
import { globalState } from '../../../../../store'

export const ASNConditionOptions = () => (
  <>
    <option value={'all_asns'}>All ASNs</option>
    <optgroup label="ASN Options">
      <option value={'asn_field'}>ASN Field</option>
    </optgroup>
    <optgroup label="Logical Type">
      <option value={'logical_and'}>AND</option>
      <option value={'logical_or'}>OR</option>
      <option value={'logical_not'}>NOT</option>
    </optgroup>
    <optgroup label="ASN Item Options">
      <option value={'asn_item_any'}>Match ANY ASN Items</option>
      <option value={'asn_item_all'}>Match ALL ASN Items</option>
      <option value={'asn_item_none'}>Match No ASN Items</option>
    </optgroup>
  </>
)

export const ASNEventOptions = () => (
  <>
    <option value={'asn_created'}>ASN Created</option>
    <option value={'asn_status'}>ASN Status Changes</option>
  </>
)

export const ASNActionOptions = ({ event }: any) => {
  const isRestricted =
    event.category === 'asn_status' &&
    RestrictedASNStatusTypes.filter((s) => s.value === event.conditions).length > 0
  return (
    <>
      {!isRestricted && (
        <>
          <optgroup label="ASN Action">
            <option value={'edit_asn_field'}>Edit ASN</option>
            <option value={'edit_asn_item'}>Edit ASN Item</option>
          </optgroup>
        </>
      )}
      <optgroup label="Notification">
        <option value={'send_notification'}>Send Notification</option>
      </optgroup>
      <optgroup label="Logical Type">
        <option value={'logical_and'}>AND</option>
      </optgroup>
    </>
  )
}

export const ASNStatusTypes = [
  { value: 80, label: 'In Transit', actionable: false, color: 'blue' },
  { value: 90, label: 'Arrived', actionable: false, color: 'blue' },
  { value: 100, label: 'Processing', actionable: false, color: 'blue' },
  { value: 110, label: 'Received', actionable: false, color: 'blue' },
  { value: 200, label: 'Completed', actionable: false, color: 'green' },
]

const RestrictedASNStatusTypes = ASNStatusTypes.filter((option: any) => option.number >= 90)

export const ASNFieldTypes = () => {
  const {
    state: { activeWarehouses: activeWarehousesWithID },
  } = globalState()

  const activeWarehouses = activeWarehousesWithID.map((option: any) => ({ label: option.name, value: option.id }))
  return [
    { value: 'id', label: 'ASN ID', type: 'number', options: [], async: false, actionable: false },
    { value: 'status', label: 'Status', type: 'number', options: ASNStatusTypes, async: false, actionable: false },
    {
      value: 'warehouse',
      label: 'Warehouse',
      type: 'number',
      options: activeWarehouses,
      async: false,
      actionable: true,
    },
    { value: 'created', label: 'Created', type: 'date', options: [], async: false, actionable: false },
    { value: 'updated', label: 'Updated', type: 'date', options: [], async: false, actionable: false },
    { value: 'sender', label: 'Sender', type: 'string', options: [], async: false, actionable: true },
    { value: 'delivery_date', label: 'Delivery Date', type: 'date', options: [], async: false, actionable: true },
    { value: 'is_overdue', label: 'Is Overdue', type: 'boolean', options: [], async: false, actionable: false },
    { value: 'date_received', label: 'Date Received', type: 'date', options: [], async: false, actionable: false },
    { value: 'tracking_number', label: 'Tracking Number', type: 'string', options: [], async: false, actionable: true },
    {
      value: 'integration_id',
      label: 'Integration ID',
      type: 'string',
      options: [],
      async: false,
      actionable: true,
    },
    { value: 'po_number', label: 'PO Number', type: 'string', options: [], async: false, actionable: true },
    { value: 'bol_number', label: 'BOL Number', type: 'string', options: [], async: false, actionable: true },
    { value: 'pro_number', label: 'PRO Number', type: 'string', options: [], async: false, actionable: true },
  ]
}

