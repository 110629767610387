import React, { useState } from 'react'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { TabMenu } from '../../../components/component-items/tab-menu'
import { PostageRates } from './rating'
import { Processing } from './processing'
import { Receiving } from './receiving'
import { Storage } from './storage'
import { Packaging } from './packaging'

export const PricingTool = () => {
  const tabMapping = {
    'Postage Rates': <PostageRates />,
    Processing: <Processing />,
    'Receiving / Returns': <Receiving />,
    Storage: <Storage />,
    Packaging: <Packaging />,
    // "Other Services": <OtherServices />,
  }
  const tabOptions = Object.keys(tabMapping)
  const [activeTab, setActiveTab] = useState(tabOptions[0])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>ShippingTree Pricing</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <GlobalStyles.CardHeader style={{ justifyContent: 'center' }}>
        <TabMenu {...{ tabOptions, activeTab, setActiveTab }} />
      </GlobalStyles.CardHeader>
      <div style={{ margin: '2em auto', padding: '0 2em', maxWidth: '1000px' }}>
        {tabMapping[activeTab as keyof typeof tabMapping]}
      </div>
    </GlobalStyles.FullPageCard>
  )
}
