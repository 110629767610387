import React from 'react'
import 'react-step-progress-bar/styles.css'
import styled from 'styled-components'

// Components
import { ProgressBar, Step } from 'react-step-progress-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboardList,
  faBoxOpen,
  faConveyorBelt,
  faBoxCheck,
  faShippingFast,
} from '@fortawesome/pro-duotone-svg-icons'

const Container = styled.div`
  margin: 1em 1em 2.5em;
`

export const StepProgressBar = ({ progress }: any) => {
  return (
    <Container>
      <ProgressBar percent={progress} filledBackground="linear-gradient(to right, #fefb72, #f0bb31)">
        <Step transition="scale">
          {({ accomplished }: any) => (
            <FontAwesomeIcon
              icon={faClipboardList}
              style={{
                filter: `grayscale(${accomplished ? 0 : 80}%)`,
                fontSize: '2em',
                color: '#00377d',
                background: '#f2f3f8',
              }}
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }: any) => (
            <FontAwesomeIcon
              icon={faBoxOpen}
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`, fontSize: '2em', color: '#00377d' }}
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }: any) => (
            <FontAwesomeIcon
              icon={faConveyorBelt}
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`, fontSize: '2em', color: '#00377d' }}
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }: any) => (
            <FontAwesomeIcon
              icon={faBoxCheck}
              style={{
                filter: `grayscale(${accomplished ? 0 : 80}%)`,
                fontSize: '2em',
                color: '#00377d',
                background: '#f2f3f8',
              }}
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }: any) => (
            <FontAwesomeIcon
              icon={faShippingFast}
              style={{
                filter: `grayscale(${accomplished ? 0 : 80}%)`,
                fontSize: '2em',
                color: '#00377d',
                background: '#f2f3f8',
              }}
            />
          )}
        </Step>
      </ProgressBar>
    </Container>
  )
}
