import React from 'react'
import { Link } from 'react-router-dom'

// Components
import { OrderStatusBox } from '../../../components/component-items/status-box'

import { DetailCardProps } from './types'

export const DetailCard = ({
  asn,
  projectAsn,
  projectID,
  returnOrder,
  classType,
  tracking,
  totalOrders,
  delivery,
  serviceLevel,
  onTime,
  sender,
  integrationID,
  po,
  bol,
  pro,
  transferOrder,
}: DetailCardProps) => {
  return (
    <div className="order-section">
      <h3>
        {classType === 'ReturnNotice' ? 'Return' : projectAsn ? 'Project' : ''} ASN:<span>{asn}</span>
      </h3>
      <div>
        Shipping From:
        <span>{sender}</span>
      </div>
      <div>
        Shipped By:
        <span>{serviceLevel}</span>
      </div>
      {projectID ? (
        <div>
          Project:
          <span>
            <Link to={`/project/${projectID}/`}>{projectID}</Link>
          </span>
        </div>
      ) : null}
      {classType === 'ShipNotice' || classType === 'ReturnNotice' ? (
        <>
          <div>
            Estimated Delivery:
            <span>
              <OrderStatusBox
                style={{ padding: 2 }}
                status={delivery ? delivery : 'DATE REQUIRED'}
                status_id={!delivery ? 10 : onTime === 'arrived' ? 200 : onTime === 'delayed' ? 10 : 100}
              />
            </span>
          </div>
          <div>
            Tracking:
            <span>{tracking ? tracking : 'N/A'}</span>
          </div>
        </>
      ) : null}
      {classType === 'ShipNotice' ? (
        <div>
          Orders Awaiting Stock:
          <span>{totalOrders}</span>
        </div>
      ) : null}
      {returnOrder ? (
        <div>
          Return Order:
          <span>
            <Link to={`/order/${returnOrder}/`}>{returnOrder}</Link>
          </span>
        </div>
      ) : null}
      {transferOrder ? (
        <div>
          Transfer Order:
          <span>
            <Link to={`/order/${transferOrder}/`}>{transferOrder}</Link>
          </span>
        </div>
      ) : null}
      {integrationID ? (
        <div>
          Integration ID:
          <span>{integrationID}</span>
        </div>
      ) : null}
      {po ? (
        <div>
          PO #:
          <span>{po}</span>
        </div>
      ) : null}
      {bol ? (
        <div>
          BOL #:
          <span>{bol}</span>
        </div>
      ) : null}
      {pro ? (
        <div>
          PRO #:
          <span>{pro}</span>
        </div>
      ) : null}
    </div>
  )
}
