import React, { useState } from 'react'
import { globalState } from '../../../store'
import { useParams } from 'react-router-dom'
import { useFetch, usePost, StickyElement } from '../../../components/component-items/helpers'
import { BackgroundLayer, OrderOptions } from './styles'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'

// Cards
import { StepProgressBar } from './progress-bar'
import { ProjectItems } from './project-items'
import { ProjectNotes } from './project-notes'
import { ProductionRuns } from './production-runs'
import { StatusBar } from './status-bar'
import { Billing } from './billing'
import { GoldenSample, GoldenSampleUpload } from './golden-sample'

// Modals
import { StartProjectModal } from './start-project-modal'
import { BillingModal } from './billing-modal'
import { ReduceQuantity } from './update_quantity_modal'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import {
  faClipboardListCheck,
  faTrashAlt,
  faFileInvoiceDollar,
  faBoxCheck,
  faGem,
  faArrowSquareDown,
  faBoxFull,
} from '@fortawesome/pro-duotone-svg-icons'
import { NotificationList } from '../../../components/notifications'

const MenuOption = ({ url, onClick, icon, title, arrow, children, node }: any) => {
  return (
    <li ref={node} onClick={onClick}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const Project = () => {
  const [openGoldenSample, setGoldenSample] = useState(false)
  const [openStartProjectModal, setStartProjectModal] = useState(false)
  const [openBilling, setBilling] = useState(false)
  const [openQtyModal, setQtyModal] = useState(false)

  const params: any = useParams()
  const project = params.id
  const {
    state: { csrf, userIsWarehouse },
  } = globalState()

  const {
    response: resp,
    loaded,
    error,
    placeholder,
  }: any = useFetch(`/api/inventory/project/${project}/details/`, {}, 60)

  const users = loaded ? resp.users : []

  const canStart = loaded ? resp.canStart : false
  const remainingProdQty = loaded ? resp.remainingProdQty : 0

  const statusId = loaded ? resp.statusId : 100
  const progress = loaded ? resp.progress : 1

  return (
    <>
      <StatusBar />
      <div className="row">
        <div className="col-lg-3 col-xl-2">
          <StickyElement>
            <OrderOptions>
              <ul>
                {userIsWarehouse ? (
                  <>
                    <GlobalStyles.Tooltip data-title="Add additional labor costs">
                      <MenuOption onClick={() => setBilling(true)} icon={faFileInvoiceDollar} title={'Billing'} />
                    </GlobalStyles.Tooltip>
                    <BillingModal users={users} open={openBilling} setOpen={setBilling} />
                    {userIsWarehouse && (
                      <GlobalStyles.Tooltip data-title="Add additional documents/images">
                        <MenuOption onClick={() => setGoldenSample(true)} icon={faGem} title={'Add Golden Sample'} />
                      </GlobalStyles.Tooltip>
                    )}
                    <GoldenSampleUpload open={openGoldenSample} setOpen={setGoldenSample} />
                    {statusId === 100 ? (
                      <GlobalStyles.Tooltip data-title="Select production location to start project">
                        <MenuOption
                          onClick={() => setStartProjectModal(true)}
                          icon={faClipboardListCheck}
                          title={'Start Project'}
                        />
                      </GlobalStyles.Tooltip>
                    ) : null}
                    <StartProjectModal {...{ canStart }} open={openStartProjectModal} setOpen={setStartProjectModal} />
                    {statusId > 100 ? (
                      <GlobalStyles.Tooltip data-title="Displays project barcode with picking locations">
                        <MenuOption
                          url={`/api/inventory/project/${project}/picklist/`}
                          icon={faBoxFull}
                          title={'Project Picklist'}
                        />
                      </GlobalStyles.Tooltip>
                    ) : null}
                    {statusId > 100 && statusId < 200 ? (
                      <GlobalStyles.Tooltip data-title="Completes project and sets status to Closed">
                        <MenuOption
                          onClick={() =>
                            usePost(`/api/inventory/project/${project}/details/`, { complete: true }, csrf)
                          }
                          icon={faBoxCheck}
                          title={'Complete Project'}
                        />
                      </GlobalStyles.Tooltip>
                    ) : null}
                  </>
                ) : null}
                {statusId < 200 ? (
                  <GlobalStyles.Tooltip data-title="Reduce amount of items in project">
                    <MenuOption onClick={() => setQtyModal(true)} icon={faArrowSquareDown} title={'Reduce Quantity'} />
                  </GlobalStyles.Tooltip>
                ) : null}
                <ReduceQuantity {...{ remainingProdQty }} open={openQtyModal} setOpen={setQtyModal} />
                {statusId <= 100 ? (
                  <GlobalStyles.Tooltip data-title="Removes Project and all associated transactions">
                    <MenuOption
                      onClick={() => usePost(`/api/inventory/project/${project}/details/`, { deleted: true }, csrf)}
                      icon={faTrashAlt}
                      title={'Delete Project'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
              </ul>
            </OrderOptions>
          </StickyElement>
        </div>
        <div className="col-lg-6 col-xl-7">
          <div className="row">
            <div className="col-xl-12">
              <StepProgressBar {...{ progress }} />
              <BackgroundLayer>
                <ProjectNotes />
              </BackgroundLayer>
              <BackgroundLayer>
                <ProjectItems />
              </BackgroundLayer>
              {userIsWarehouse ? (
                <BackgroundLayer>
                  <ProductionRuns users={users} />
                </BackgroundLayer>
              ) : null}
            </div>
            <div className="col-xl-12">
              <div className="row" style={{ marginBottom: '20px' }}>
                <div className="col-xl-6">
                  <Billing />
                </div>
                <div className="col-xl-6">
                  <GoldenSample />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <StickyElement>
            <NotificationList id={project} type="project" />
          </StickyElement>
        </div>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </>
  )
}
