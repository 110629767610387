import React, { useState, useRef, FunctionComponent } from 'react'
import { globalState } from '../../../store'
import { toast } from 'react-toastify'

import styled from 'styled-components'
import { usePost, notify } from '../../../components/component-items/helpers'

// Components
import GenericModal from '../../../components/component-items/modal'
import GlobalStyles from '../../../components/component-items/styles'
import { Form } from 'react-bootstrap'
import { Calendar } from 'primereact/calendar'

import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

type StorageChargesModalTypes = {
  open: boolean
  setOpen: (open: boolean) => void
}

export const StorageChargesModal: FunctionComponent<StorageChargesModalTypes> = ({ open, setOpen }) => {
  const node: any = useRef()
  const [validated, setValidated] = useState(false)
  const {
    state: { csrf },
  } = globalState()

  const [date, setDate] = useState<Date>(new Date())

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }
    const resp = await usePost(
      '/api/company/run_storage_charges/',
      { month: date?.getMonth() + 1, year: date?.getFullYear() },
      csrf,
      false
    )
    if (!resp.error) {
      notify({
        type: 'success',
        title: 'Successfully Ran Storage Charges',
        message: 'This process can take up to an hour to complete.',
      })
      setOpen(false)
    }
  }

  return (
    <GenericModal
      heading={'Run Storage Charges'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleSubmit}>
          Run Storage Charges
        </GlobalStyles.Button>
      }
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit} style={{ margin: '0 2em 1em' }}>
        <div style={{ margin: '2em auto', width: '80%', justifyContent: 'center', display: 'flex' }}>
          <Form.Group>
            <Form.Label>Month & Year</Form.Label>
            <div>
              {/* @ts-ignore */}
              <Calendar
                value={date}
                onChange={(e: any) => setDate(e.value)}
                view="month"
                dateFormat="mm/yy"
                maxDate={new Date()}
              />
            </div>
          </Form.Group>
        </div>
      </Form>
    </GenericModal>
  )
}
