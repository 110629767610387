import React, { useState } from 'react'
import { useFetch, usePost, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { Form, Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import { Calendar } from 'primereact/calendar'
import { toast } from 'react-toastify'
import CardLoading from '../../../components/component-items/loading-popover'

// Components
import GlobalStyles from '../../../components/component-items/styles'

import { MultiSelect, CardHolder } from './styles'

export const CreateInvoice = () => {
  const {
    state: { csrf },
  } = globalState()

  const [validated, setValidated] = useState(false)
  const currentDate = new Date()
  const [date, setDate] = useState<Date>(new Date(currentDate.setMonth(currentDate.getMonth() - 1)))

  const [companies, setCompanies] = useState([])
  const url = '/api/quickbooks/'
  const { response, loaded, error, placeholder } = useFetch(url, {})
  const company_list: any = loaded
    ? (response! as any[]).map((company: { name: string; id: any }) => {
        return {
          label: company.name,
          value: company.id,
        }
      })
    : []

  const handleSubmit = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    let company_ids: any = []
    companies.map((company) => {
      company_ids.push(company['value'])
    })
    usePost(
      '/api/quickbooks/invoices/',
      { month: date?.getMonth() + 1, year: date?.getFullYear(), companies: company_ids },
      csrf,
      false
    ).then((response) => {
      if (response?.success) {
        notify({ type: 'success', message: 'Invoices were successfully submitted' })
      } else {
        notify({ type: 'error', message: 'Invoices were unsuccessfully submitted' })
      }
    })
  }

  return (
    <GlobalStyles.FullPageCard>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Create invoices</h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar>
            <GlobalStyles.Button type="submit">Submit</GlobalStyles.Button>
          </GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <CardHolder>
          <Row style={{ justifyContent: 'center' }}>
            <Form.Group as={Col} md="6">
              <Form.Label>Companies</Form.Label>
              <MultiSelect>
                <Select
                  options={company_list}
                  value={companies}
                  onChange={(e: any) => setCompanies(e)}
                  closeMenuOnSelect={false}
                  isMulti
                  placeholder="All"
                  className="basic-multi-select"
                />
              </MultiSelect>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Month & Year</Form.Label>
              <div>
                {/* @ts-ignore */}
                <Calendar
                  value={date}
                  onChange={(e: any) => setDate(e.value)}
                  view="month"
                  dateFormat="mm/yy"
                  maxDate={new Date()}
                />
              </div>
            </Form.Group>
          </Row>
        </CardHolder>
      </Form>
      {!loaded && <CardLoading text={placeholder} error={error} />}
    </GlobalStyles.FullPageCard>
  )
}
