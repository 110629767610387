import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faPrint,
  faCopy,
  faFilePdf,
  faFileExcel,
  faFileCsv,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'

import { ExportToolsProps } from './types'

export const DropdownStyled = styled.ul`
  position: absolute;
  z-index: 10;
  will-change: transform;
  min-width: 14rem;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 1.5rem 0 1rem;
  margin: 0;
  list-style: none;
  border: 0;
  font-size: 13px;
  li {
    user-select: none;
    a,
    i,
    span,
    .button {
      transition: all 0.3s ease !important;
    }
    a,
    .button {
      display: flex;
      align-items: center;
      padding: 0.55rem 1.75rem;
      cursor: pointer;
      &:hover {
        background: ${(props) => props.theme.colors.gallery};
        i,
        span {
          color: ${(props) => props.theme.colors.dodgerBlue};
        }
      }
    }
    i {
      flex: 0 0 30px;
      font-size: 1.25rem;
      line-height: 0;
      color: #a2a5b9;
    }
    span {
      color: #595d6e;
      font-weight: 400;
    }
  }
  .section {
    padding: 0 1.75rem;
    margin-top: 0;
    span {
      color: ${(props) => props.theme.colors.dodgerBlue};
      font-weight: 600;
      font-size: 0.8rem;
      text-transform: uppercase;
    }
  }
`

type ItemStyledProps = {
  hover?: string
}

const ItemStyled = styled.li<ItemStyledProps>`
  svg {
    transition: all 0.3s ease;
  }
  &:hover svg {
    color: ${(props) => (props.hover ? props.hover : '')};
  }
`

type DropdownItemProps = {
  url?: string
  icon: any
  text: string
  onClick?: any
  target?: string
  hover?: string
}

export const DropdownItem: FunctionComponent<DropdownItemProps> = ({ url, icon, text, onClick, target, hover }) => (
  <ItemStyled onClick={onClick} {...{ hover }}>
    <a href={url ? url : '#'} target={target}>
      <i>
        <FontAwesomeIcon icon={icon} />
      </i>
      <span>{text}</span>
    </a>
  </ItemStyled>
)

export const ExportTools: FunctionComponent<ExportToolsProps> = ({
  title,
  style,
  print,
  copy,
  excel,
  csv,
  pdf,
  view,
  releaseUnshipped,
}) => (
  <DropdownStyled style={style ? style : { bottom: '45px', left: '0px' }}>
    {title ? (
      <li className="section">
        <span>{title}</span>
      </li>
    ) : null}
    {copy ? <DropdownItem url={copy} text={'Copy'} icon={faCopy} /> : null}
    {excel ? <DropdownItem url={excel} text={'Excel'} icon={faFileExcel} /> : null}
    {csv ? <DropdownItem url={csv} text={'CSV'} icon={faFileCsv} /> : null}
    {pdf ? <DropdownItem url={pdf} text={'PDF'} icon={faFilePdf} /> : null}

    {view ? <DropdownItem url={view} text={'View'} icon={faEye} target={'__blank'} /> : null}
    {print ? <DropdownItem onClick={() => window.print()} text={'Print'} icon={faPrint} /> : null}
    {releaseUnshipped ? <DropdownItem onClick={() => releaseUnshipped()} text={'Release'} icon={faTrashAlt} /> : null}
  </DropdownStyled>
)

export default ExportTools
