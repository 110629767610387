import React, { createContext, useContext, useReducer, FunctionComponent } from 'react'
import { usePost } from './components/component-items/helpers'

const initialState = {
  csrf: null,
  isAuthenticated: false,
  actAs: null,
  userIsWarehouse: false,
  userIsManager: false,
  userIsExecutive: false,
  multiWarehouse: false,
  warehouseActive: '',
  defaultWarehouse: {},
  warehousesInactive: [],
  warehousesRemaining: [],
  routingWarehouses: [], // TODO - Add support for this in Backend
  activeWarehouses: [],
  userFullName: '',
  userEmail: '',
  navOpen: true,
  navStayOpen: true,
  asyncTaskFilterOptions: [],
  productFilterOptions: [],
  inventoryFilterOptions: [],
  userActivityOptions: [],
  showOnboardingChecklist: true,
}

type StoreContextType = any
const store = createContext<StoreContextType | null>(initialState)
const { Provider } = store

type StateProviderProps = {
  children: React.ReactNode
}
const StateProvider: React.FC<StateProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer((state: any, action: any) => {
    switch (action.type) {
      // Session Data
      case 'login':
        return { ...state, isAuthenticated: true }
      case 'logout':
        return { ...state, isAuthenticated: false }
      case 'csrf':
        return { ...state, csrf: action.csrf }
      case 'session':
        return { ...state, ...action.payload }
      // Onboarding
      case 'showOnboarding':
        return { ...state, showOnboardingChecklist: true }
      // Navigation
      case 'hideOnboarding':
        return { ...state, showOnboardingChecklist: false }
      // Navigation
      case 'navOpen':
        return { ...state, navOpen: true }
      case 'navOpenSave':
        // Update user's profile with saved state
        usePost('/api/navigation/', { navStayOpen: true }, state.csrf, false, true, true, false)
        return { ...state, navOpen: true, navStayOpen: true }
      case 'navClose':
        return { ...state, navOpen: false }
      case 'navCloseSave':
        // Update user's profile with saved state
        usePost('/api/navigation/', { navStayOpen: false }, state.csrf, false, true, true, false)
        return { ...state, navOpen: false, navStayOpen: false }
      case 'asyncTaskFilterOptions':
        usePost(
          '/api/core/tasks/async_task_options/',
          { filterOptions: action.asyncTaskFilterOptions },
          state.csrf,
          false,
          true,
          true,
          false
        )
        return { ...state, asyncTaskFilterOptions: action.asyncTaskFilterOptions }
      case 'productFilterOptions':
        usePost(
          '/api/product/variant/filter/',
          { filterOptions: action.productFilterOptions },
          state.csrf,
          false,
          true,
          true,
          false
        )
        return { ...state, productFilterOptions: action.productFilterOptions }
      case 'inventoryFilterOptions':
        usePost(
          '/api/inventory/inventory-item/inventory_list_options/',
          { filterOptions: action.inventoryFilterOptions },
          state.csrf,
          false,
          true,
          true,
          false
        )
        return { ...state, inventoryFilterOptions: action.inventoryFilterOptions }
      case 'userActivityOptions':
        usePost(
          '/api/core/notifications/user_activity_options/',
          { filterOptions: action.userActivityOptions },
          state.csrf,
          false,
          true,
          true,
          false
        )
        return { ...state, userActivityOptions: action.userActivityOptions }
      default:
        throw new Error('State update called without action type')
    }
  }, initialState)
  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, StateProvider }

export const globalState = () => useContext(store)
