import React from 'react'
import { useParams } from 'react-router-dom'

// Components
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faTasks, faWarehouse, faShippingFast } from '@fortawesome/pro-duotone-svg-icons'

import { StatusBarProps } from './types'

export const StatusBar = ({ overdue, companies, completed, warehouse, rack, deadline }: StatusBarProps) => {
  const batch = useParams<{ id: string }>().id
  return (
    <div className="row">
      <div className="col">
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              {!completed ? (
                <FontAwesomeIcon icon={faClipboardList} style={{ color: '#5d78ff' }} />
              ) : (
                <FontAwesomeIcon icon={faShippingFast} style={{ color: '#00a58f' }} />
              )}
            </div>
            <div className="status-text">
              {completed ? 'Completed' : 'In Progress'}:
              {companies?.map((company: any, id: number) => (
                <div key={id}>
                  <strong>{company}</strong>
                </div>
              ))}
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon icon={faTasks} />
            </div>
            <div
              className="status-text"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              <strong>Batch: {batch}</strong>
              <strong>RACK: {rack}</strong>
              <strong>
                <StatusBoxStyled className={overdue && !completed ? 'red' : 'blue'} style={{ lineHeight: '.8em' }}>
                  {'Due ' + deadline}
                </StatusBoxStyled>
              </strong>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text" style={{ paddingRight: '1em' }}>
              Facility:
            </div>
            <div className="status-text">
              <strong>{warehouse}</strong>
            </div>
            <div className="status-icon" style={{ marginLeft: '2em' }}>
              <FontAwesomeIcon icon={faWarehouse} />
            </div>
          </div>
        </StatusBarStyled>
      </div>
    </div>
  )
}
