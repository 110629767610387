import React from 'react'
import { useFetch } from '../../components/component-items/helpers'
import styled from 'styled-components'
import { globalState } from '../../store'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { OrderListStyled } from './styles'

import { OrderItemProps } from './types'

const OrderItemStyled = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  .order-item__color {
    width: 1rem;
    height: 1rem;
    border-radius: 3px;
    margin-right: 1.25rem;
    background-color: #343a40;
  }
  .order-item__descriptor {
    margin-right: 1.25rem;
  }
  .order-item__title {
    font-size: 1.1rem;
    font-weight: 500;
    color: #48465b;
    margin-bottom: 0.2rem;
    &:hover {
      color: #2739c1;
    }
  }
  .order-item__desc {
    font-size: 0.9rem;
    font-weight: 500;
    color: #74788d;
  }
  .order-item__orders {
    font-weight: 400;
    color: #74788d;
    font-size: 0.9rem;
    margin: auto 0 auto auto;
    span {
      font-weight: 500;
      color: #595d6e;
      font-size: 1rem;
    }
  }
`

const OrderItem = ({ url, color, company, unshipped, total }: OrderItemProps) => (
  <OrderItemStyled>
    <div className="order-item__color" style={{ backgroundColor: color }}></div>
    <div className="order-item__descriptor">
      <a href={url}>
        <div className="order-item__title">{company}</div>
      </a>
      <div className="order-item__desc">{total} Total</div>
    </div>
    <div className="order-item__orders">
      <span>{unshipped}</span> Orders Remain
    </div>
  </OrderItemStyled>
)

export const OrdersByMerchant = () => {
  const {
    state: { warehouseActive },
  } = globalState()
  const bootstrapColors = ['success', 'danger', 'brand', 'warning', 'info']
  const colors = ['#1dc9b7', '#fd397a', '#5867dd', '#ffb822', '#666666']
  var resp: any = useFetch(`/api/warehouse/${warehouseActive.id}/dashboard/order_stats/`, {}, 30)
  var total = resp.loaded ? resp.response.total : 0
  var companies = resp.loaded ? resp.response.companies : null
  var remainder = companies
    ? ((total - companies.slice(0, 5).reduce((a: any, b: any) => a + (b.orders || 0), 0)) / total) * 100
    : 100

  return (
    <OrderListStyled>
      <div className="widget__head">
        <h3>Order Statistics</h3>
        {/* <div className="toolbar" ref={node}>
                <button onClick={() => setOpen(!isOpen)} type="button">
                    <FontAwesomeIcon icon={faCloudDownload} />
                </button>
                {isOpen ? <ExportTools style={{ top: '50px', right: '0' }} title="Export Tools" view={'/order/'} /> : null}
            </div>
        */}
      </div>
      <div className="widget__body">
        <div className="summary">
          <div className="summary__total">{total}</div>
          <div className="summary__label">Total Orders</div>
        </div>
        <ProgressBar>
          {companies
            ? companies
                .slice(0, 5)
                .map((c: any, id: number) => (
                  <ProgressBar striped animated variant={bootstrapColors[id]} now={(c.orders / total) * 100} key={id} />
                ))
            : null}
          <ProgressBar striped animated variant="secondary" now={remainder} key={5} />
        </ProgressBar>
        <div className="results">
          {companies
            ? companies.map((c: any, id: number) => (
                <OrderItem
                  url={'/api/act-as?company=' + c.id}
                  color={id < colors.length ? colors[id] : '#666666'}
                  company={c.name}
                  unshipped={c.to_ship}
                  total={c.orders}
                  key={id}
                />
              ))
            : null}
        </div>
      </div>
      {!resp.loaded ? <CardLoading text={resp.placeholder} error={resp.error} /> : null}
    </OrderListStyled>
  )
}

export default OrdersByMerchant
