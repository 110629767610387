import styled from 'styled-components'

import { DropdownStyled } from '../../../components/component-items/export-tools'
import { device } from '../../../components/component-items/device'

export const BackgroundLayer = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
`

export const OrderOptions = styled(BackgroundLayer)`
  flex-direction: column;
  position: relative;
  ul {
    display: block;
    padding: 0.5rem 0;
    margin: 10px 0;
    list-style: none;
    border: 0;
  }
  li {
    position: relative;
    a {
      transition: all 0.3s;
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 0.75rem 1rem 0.75rem 1.5rem;
      &:hover {
        background-color: #f7f8fa;
      }
      .icon {
        flex: 0 0 30px;
        font-size: 1.25rem;
        color: #595d6e;
      }
      .text {
        font-weight: 500;
        flex-grow: 1;
        font-size: 1rem;
        color: #595d6e;
      }
    }
  }
`

export const OrderInfo = styled(BackgroundLayer)`
  position: relative;
  justify-content: space-between;
  padding: 1em;
  @media ${device.laptop} {
    margin-bottom: 0;
    height: 100%;
  }
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 1em;
    color: #4a485d;
    div {
      width: 100%;
    }
    span {
      margin-left: 1rem;
      float: right;
      font-weight: 500;
      max-width: 60%;
      text-align: right;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    p {
      margin-bottom: 0;
    }
  }
  button {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: center;
    border-radius: 0.2rem;
    color: #595d6e;
    font-size: 1.25rem;
    &:hover {
      background: ${(props) => props.theme.colors.zircon};
      svg {
        color: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
  .return-empty {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5em;
    div {
      font-size: 1em;
    }
  }
`

export const Dropdown = styled(DropdownStyled)`
  margin: 0 !important;
  padding: 15px 0 10px !important;
  top: 0;
  left: 105%;
`

export const BillingCellStyled = styled.td`
  background: ${(props) => props.theme.colors.white};
  vertical-align: middle;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.3s ease !important;
  &:last-child {
    cursor: pointer;
  }
  button {
    color: #595d6e;
  }
  .input {
    min-width: 20px;
    font-weight: 500 !important;
  }
  label {
    top: 4px;
  }
  .product_image {
    max-width: 60px;
    max-height: 60px;
  }
  .dropdown__container {
    position: relative;
    > button {
      cursor: pointer;
      height: 2rem;
      width: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      text-align: center;
      border-radius: 0.2rem;
      &:hover {
        background: ${(props) => props.theme.colors.zircon};
        svg {
          color: ${(props) => props.theme.colors.dodgerBlue};
        }
      }
    }
  }
`

export const DataTable = styled.table`
  width: 100%;
  max-height: 500px;
  thead {
    th {
      padding: 16px 10px;
      vertical-align: middle;
      padding: 10px;
      span.text {
        cursor: pointer;
        display: block;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #595d6e;
        min-width: 20px;
      }
      &:first-child {
        > span {
          position: relative;
          top: 3px;
        }
      }
    }
    /*Account for scroll bar on table*/
    padding-right: 3px;
  }
  tbody {
    max-height: 200px;
    overflow: scroll;
    position: relative;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
  tr {
    width: 100%;
    border-bottom: ${(props) => `1px solid ${props.theme.colors.zircon}`};
  }
`

export const BillingStyled = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 0 25px 25px 25px;
  position: relative;
  height: 100%;
  .datatable__header {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    min-height: 60px;
    .datatable__title {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #48465b;
        small {
          font-weight: 300;
          padding-left: 1rem;
          font-size: 1rem;
          color: #74788d;
        }
      }
    }
    .datatable__toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    .order-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &:last-child {
        color: ${(props) => props.theme.colors.black};
      }
    }
    & :nth-last-child(3) {
      flex: 1;
    }
    span:last-child {
      margin-left: 1rem;
    }
    p {
      margin-bottom: 0;
    }
    hr {
      width: 100%;
    }
  }
  button {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: center;
    border-radius: 0.2rem;
    color: #595d6e;
    font-size: 1.25rem;
    &:hover {
      background: ${(props) => props.theme.colors.zircon};
      svg {
        color: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
`

export const BillingRowStyled = styled.tr`
  left: 0px;
  &.active td {
    transition: all 0.3s ease !important;
    background: #f7f8fa;
  }
`

export const LabelStyled = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  display: block;
  padding: 0 25px 25px 25px;
  height: 100%;
  min-height: 220px;
  position: relative;
  .datatable__header {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    min-height: 60px;
    .datatable__title {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #48465b;
        small {
          font-weight: 300;
          padding-left: 1rem;
          font-size: 1rem;
          color: #74788d;
        }
      }
    }
    .datatable__toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .order-section {
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;
    img {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
  button {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: center;
    border-radius: 0.2rem;
    color: #595d6e;
    font-size: 1.25rem;
    &:hover {
      background: ${(props) => props.theme.colors.zircon};
      svg {
        color: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
  .no-label {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 1em 0;
    div {
      font-size: 2em;
    }
  }
`

export const NoteContainer = styled.div`
  margin: 2em 2em 0;
  color: ${(props) => props.theme.colors.black};
  .editor-container {
    background: #f7f7f7;
    border-radius: 4px;
    padding: 0.5em 1em;
    margin: 1em 0;
    max-height: 300px;
    overflow: scroll;
  }
  .styled-note {
    background-color: #f5f5f5; /* Background color similar to disabled input */
    border: 1px solid #ccc; /* Border similar to disabled input */
    padding: 5px; /* Padding for spacing inside the container */
    color: #555; /* Text color */
    cursor: not-allowed; /* Display a "not-allowed" cursor */
    max-height: 300px;
    overflow-y: auto;
  }
`

export const LargeNoteContainer = styled(NoteContainer)`
  margin: 2em;
  .editor-container {
    min-height: 200px;
    max-height: unset;
  }
`

export const ExchangeListStyled = styled.form`
  .text {
    position: absolute !important;
    top: unset !important;
    margin-top: -40px;
  }
  .scrolltable {
    margin-top: 40px;
    max-height: 500px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
`

export const ShipmentListStyled = styled.div`
  position: relative;
  width: 100%;
  .datatable__header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    min-height: 60px;
    .datatable__title {
      display: flex;
      align-items: center;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #48465b;
        small {
          font-weight: 300;
          padding-left: 1rem;
          font-size: 1rem;
          color: #74788d;
        }
      }
    }
    ul.dropdown__selection {
      overflow: hidden;
      width: 100%;
      position: absolute;
      z-index: 10;
      background: ${(props) => props.theme.colors.white};
      list-style: none;
      margin: 0;
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      padding: 1rem 0;
      border-radius: 4px;
      li {
        cursor: pointer;
        padding: 10px 15px;
        font-weight: 400;
        color: #595d6e;
        &:hover,
        &.active {
          background: ${(props) => props.theme.colors.gallery};
        }
      }
    }
    .datatable__toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
    }
  }
  button:last-child {
    margin-right: 0;
  }
  .card-body {
    flex-grow: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 0 18px 20px 18px;
  }
  .datatable__foooter {
    margin: 0;
    padding: 25px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

export const ShipmentListCellStyled = styled.td`
  background: ${(props) => props.theme.colors.white};
  vertical-align: middle;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.3s ease !important;
  &:last-child {
    cursor: pointer;
  }
  button {
    color: #595d6e;
  }
  .input {
    min-width: 20px;
    font-weight: 500 !important;
  }
  label {
    top: 4px;
  }
  .dropdown__container {
    position: relative;
    > button {
      cursor: pointer;
      height: 2rem;
      width: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      text-align: center;
      border-radius: 0.2rem;
      &:hover {
        background: ${(props) => props.theme.colors.zircon};
        svg {
          color: ${(props) => props.theme.colors.dodgerBlue};
        }
      }
    }
  }
`

export const ShipmentListRowStyled = styled.tr`
  left: 0px;
  &.active td {
    transition: all 0.3s ease !important;
    background: #f7f8fa;
  }
`

export const BatchListStyled = styled.div`
  position: relative;
  width: 100%;
  .datatable__header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    min-height: 60px;
    .datatable__title {
      display: flex;
      align-items: center;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #48465b;
        small {
          font-weight: 300;
          padding-left: 1rem;
          font-size: 1rem;
          color: #74788d;
        }
      }
    }
    ul.dropdown__selection {
      overflow: hidden;
      width: 100%;
      position: absolute;
      z-index: 10;
      background: ${(props) => props.theme.colors.white};
      list-style: none;
      margin: 0;
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      padding: 1rem 0;
      border-radius: 4px;
      li {
        cursor: pointer;
        padding: 10px 15px;
        font-weight: 400;
        color: #595d6e;
        &:hover,
        &.active {
          background: ${(props) => props.theme.colors.gallery};
        }
      }
    }
    .datatable__toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
    }
  }
  button:last-child {
    margin-right: 0;
  }
  .card-body {
    flex-grow: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 0 18px 20px 18px;
  }
  .datatable__foooter {
    margin: 0;
    padding: 25px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

export const BatchListRowStyled = styled.tr`
  left: 0px;
  &.active td {
    transition: all 0.3s ease !important;
    background: #f7f8fa;
  }
`

export const BatchListCellStyled = styled.td`
  background: ${(props) => props.theme.colors.white};
  vertical-align: middle;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.3s ease !important;
  &:last-child {
    cursor: pointer;
  }
  button {
    color: #595d6e;
  }
  .input {
    min-width: 20px;
    font-weight: 500 !important;
  }
  label {
    top: 4px;
  }
  .product_image {
    max-width: 60px;
    max-height: 60px;
  }
  .dropdown__container {
    position: relative;
    button {
      cursor: pointer;
      height: 2rem;
      width: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      text-align: center;
      border-radius: 0.2rem;
      &:hover {
        background: ${(props) => props.theme.colors.zircon};
        svg {
          color: ${(props) => props.theme.colors.dodgerBlue};
        }
      }
    }
  }
`

export const ProductModalStyled = styled.form`
  .text {
    position: absolute !important;
    top: unset !important;
    margin-top: -40px;
  }
  .scrolltable {
    margin-top: 40px;
    max-height: 500px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
`
