import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { globalState } from '../../../../store'
import { useFetch, usePost, notify } from '../../../../components/component-items/helpers'
import { Form } from 'react-bootstrap'
import CardLoading from '../../../../components/component-items/loading-popover'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'
import { useTheme } from 'styled-components'

// Components
import { OrderOptions } from '../../../orders/order/styles'
import { ShippingWorkflow } from '../shipping-workflow'
import GlobalStyles from '../../../../components/component-items/styles'
import GenericModal from '../../../../components/component-items/modal'
import { TableCell } from '../../../../components/component-items/datatable'
import { TableRow } from '../styles'

// Cards
import { StatusBar } from '../status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruckContainer, faChevronRight, faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons'

import { OrderOptionProps, FreightLabelsProps, FreightModalProps } from './types'

const OrderOption = ({
  url,
  onClick,
  icon,
  title,
  arrow,
  children,
  node,
  active,
  disabled,
  complete,
}: OrderOptionProps) => {
  return (
    <li ref={node} onClick={onClick} className={active ? 'active' : disabled ? 'disabled' : complete ? 'complete' : ''}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const FreightLabels = ({ shipments, setShipments, serviceLevels }: FreightLabelsProps) => {
  const carrierGrouping = [...new Set(serviceLevels?.map((sl: any) => sl.carrier__name))]
  const freightMarkup = 0.3

  const handleChange = (id: number, name: string, value: string) => {
    const newShipments = shipments.map((label: any) => {
      if (label.shipmentId === id) {
        // Calculate total cost for each row
        const updatedLabel = {
          ...label,
          [name]: value,
        }
        return updatedLabel
      }
      return label
    })
    setShipments(newShipments)
  }

  return (
    <GlobalStyles.DataTable>
      <thead>
        <tr>
          <th>
            <span className="text">Shipment</span>
          </th>
          <th>
            <span className="text center">Order Items</span>
          </th>
          <th>
            <span className="text center">Service Level</span>
          </th>
          <th>
            <span className="text center">Tracking</span>
          </th>
          <th>
            <span className="text center">Amount</span>
          </th>
          <th>
            <span className="text center">Markup</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {shipments?.map((shipment: any, id: number) => (
          <GlobalStyles.TableRow key={id}>
            <TableCell input={shipment.shipmentId}></TableCell>
            <TableCell>
              {shipment.items.length && (
                <div
                  style={{
                    maxHeight: 200,
                    overflow: 'scroll',
                    boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px',
                  }}
                >
                  <GlobalStyles.DataTable>
                    <tbody>
                      {shipment.items?.map((shipment: any) => (
                        <TableRow key={id}>
                          <TableCell
                            center
                            input={<img src={shipment.img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />}
                          />
                          <TableCell input={shipment.sku}>
                            <div>{shipment.name}</div>
                          </TableCell>
                          <TableCell input={shipment.qty} center />
                        </TableRow>
                      ))}
                    </tbody>
                  </GlobalStyles.DataTable>
                </div>
              )}
            </TableCell>
            <TableCell>
              <Form.Select
                value={shipment.serviceLevel}
                onChange={(e) => handleChange(shipment.shipmentId, 'serviceLevel', e.target.value)}
              >
                <option value="">---SELECT---</option>
                {carrierGrouping?.map((c, id) => (
                  // @ts-ignore
                  <optgroup label={c} key={id}>
                    {serviceLevels
                      .filter((sl: any) => sl.carrier__name == c)
                      .map((s: any, id: number) => (
                        <option value={s.id} key={id}>
                          {s.name}
                        </option>
                      ))}
                  </optgroup>
                ))}
              </Form.Select>
            </TableCell>
            <TableCell>
              <Form.Control
                type="text"
                placeholder="Tracking #"
                required
                value={shipment.tracking}
                onChange={(e) => handleChange(shipment.shipmentId, 'tracking', e.target.value)}
              />
            </TableCell>
            <TableCell>
              <Form.Control
                type="text"
                placeholder="Amount ($)"
                required
                value={shipment.amount}
                onChange={(e) => handleChange(shipment.shipmentId, 'amount', e.target.value)}
              />
            </TableCell>
            <TableCell>
              <Form.Control
                type="text"
                placeholder="Markup"
                required
                disabled
                value={shipment.markup ? shipment.markup : (shipment.amount * freightMarkup).toFixed(2)}
              />
            </TableCell>
          </GlobalStyles.TableRow>
        ))}
      </tbody>
    </GlobalStyles.DataTable>
  )
}

export const FreightModal = ({ open, setOpen, freightDetails, serviceLevels }: FreightModalProps) => {
  const order = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()

  const [validated, setValidated] = useState(false)
  const node = useRef<HTMLFormElement>(null)
  const [shipments, setShipments] = useState<any>([])
  const [missingServiceLevel, setMissingServiceLevel] = useState(true)
  const theme: any = useTheme()

  useEffect(() => {
    var missing = false
    shipments.map((label: any) => {
      if (!label.serviceLevel) {
        missing = true
      }
    })
    setMissingServiceLevel(missing)
  }, [shipments])

  useEffect(() => {
    if (freightDetails && freightDetails.length) {
      setShipments(freightDetails)
      // Check if service level is unselected
    }
  }, [freightDetails])
  const handleSubmit = (event: any) => {
    setValidated(true)

    event.preventDefault()
    event.stopPropagation()

    if (node?.current?.checkValidity() === false) {
      return
    }
    usePost(`/api/order/${order}/freight_details/`, { shipments: shipments }, csrf)
  }
  return (
    <GenericModal
      heading={'Freight Details'}
      show={open}
      size="xl"
      onHide={() => setOpen(false)}
      buttons={
        <>
          {missingServiceLevel && (
            <StatusBarStyled style={{ backgroundColor: 'white', margin: 0 }}>
              <div className="header-item">
                <div className="status-icon">
                  <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: theme.colors.mySin }} />
                </div>
                <div className="status-text">One or more labels do not have a Service Level selected</div>
              </div>
            </StatusBarStyled>
          )}
          <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleSubmit}>
            Submit
          </GlobalStyles.Button>
        </>
      }
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
        <FreightLabels shipments={shipments} setShipments={setShipments} serviceLevels={serviceLevels} />
      </Form>
    </GenericModal>
  )
}

export const Freight = () => {
  const order = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()

  const url = `/api/order/${order}/status/`
  const { response: resp, loaded: loaded, error: error }: any = useFetch(url, {})
  const freightDetails = loaded ? resp.freightDetails : {}
  const serviceLevels = loaded ? resp.serviceLevels : []
  const [shipments, setShipments] = useState<any>([])

  const [packStatus, setPackStatus] = useState({
    fully_packed: false,
    needs_serial_numbers: false,
    serial_number_enabled: false,
    needs_dimensions: false,
    freight: true,
    customer_pickup: false,
  })
  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    if (loaded) {
      setPackStatus(resp.packStatus)
      setShipments(freightDetails)
    }
  }, [loaded])

  const handleSubmit = async () => {
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }
    const resp = await usePost(`/api/order/${order}/freight_details/`, { shipments: shipments }, csrf, false)
    if (resp.success) {
      notify({ type: 'success', message: 'Successfully updated freight postage' })
    } else {
      notify({ type: 'error', message: 'There was an issue updating freight postage' })
    }
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <StatusBar />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-xl-2">
          <OrderOptions>
            <ul>
              <GlobalStyles.Tooltip data-title="Add freight postage">
                <OrderOption onClick={() => handleSubmit()} icon={faTruckContainer} title={'Update Freight'} />
              </GlobalStyles.Tooltip>
            </ul>
          </OrderOptions>
        </div>
        <div className="col-lg-7 col-xl-8">
          <div className="row">
            <div className="col-xl-12">
              <GlobalStyles.FullPageCard style={{ padding: 10, minHeight: 300 }}>
                <GlobalStyles.CardHeader>
                  <GlobalStyles.CardTitle>
                    <h3>
                      Freight Details<small>{shipments.length} Total</small>
                    </h3>
                  </GlobalStyles.CardTitle>
                </GlobalStyles.CardHeader>
                <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
                  <FreightLabels {...{ shipments, setShipments, serviceLevels }} />
                </Form>
                {!loaded && <CardLoading error={error} />}
              </GlobalStyles.FullPageCard>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <ShippingWorkflow {...packStatus} current={'freight'} />
        </div>
      </div>
    </>
  )
}
