import ReactMarkdown from 'react-markdown'
import React, { useState, FunctionComponent } from 'react'
import { globalState } from '../../../store'
import rehypeSanitize from 'rehype-sanitize'

import styled from 'styled-components'
import { usePost } from '../../../components/component-items/helpers'
import { NotificationIcon } from '../../../components/notifications/utils'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'
import { Form, InputGroup } from 'react-bootstrap'
import { TabMenu } from '../../../components/component-items/tab-menu'
import { HoverNotes } from '../../../components/component-items/hover-notes'
import Select from 'react-select'
import { CardHeader } from '../styles'

// Types
import { EntryModalProps, tabMappingProps } from '../types'

export const EntryModal: FunctionComponent<EntryModalProps> = ({ entryModal, setEntryModal }) => {
  const {
    state: { csrf },
  } = globalState()
  const [internal, setInternal] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const Label = ({ value, label }: { value: string; label: string }) => (
    <>
      <span style={{ width: 18, display: 'inline-flex', justifyContent: 'center' }}>
        {NotificationIcon({ type: value })}
      </span>
      &nbsp;{label}
    </>
  )

  const options = [
    { value: 'feature_update', label: <Label value={'feature_update'} label={'Feature Update'} /> },
    { value: 'system_update', label: <Label value={'system_update'} label={'System Update'} /> },
    { value: 'maintenance', label: <Label value={'maintenance'} label={'Maintenance'} /> },
    { value: 'system_alert', label: <Label value={'system_alert'} label={'System Alert'} /> },
  ]
  const [type, setType] = useState(options[0])

  const handleParentSubmit = () => {
    usePost(`/api/core/changelog/`, { ...{ title, description, type: type['value'], internal } }, csrf)
  }

  const tabMapping: any = {
    Write: (
      <>
        <InputGroup style={{ marginBottom: '1em' }}>
          <Checkbox selected={internal} setSelected={() => setInternal((i) => !i)} />
          <p style={{ margin: '0px 1em 1em' }} onClick={() => setInternal((i) => !i)}>
            <HoverNotes description="Internal events are not visible to merchants." title="Internal Event" />
          </p>
        </InputGroup>
        <Form.Label>Entry Type</Form.Label>
        <Select options={options} value={type} onChange={(e: any) => setType(e)} />
        <Form.Group>
          <Form.Label style={{ marginTop: '1em' }}>Title</Form.Label>
          <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} />
        </Form.Group>
        <Form.Label style={{ marginTop: '1em' }}>Body</Form.Label>
        <Form.Group>
          <Form.Control as="textarea" rows={5} value={description} onChange={(e) => setDescription(e.target.value)} />
        </Form.Group>
      </>
    ),
    Preview: (
      <>
        <h1 style={{ textTransform: 'capitalize', marginTop: '1em' }}>
          {type['value'].replace('_', ' ')} - {title}
        </h1>
        <ReactMarkdown
          // @ts-ignore
          rehypePlugins={[rehypeSanitize]}
          components={{
            h1: 'h4',
            h2: 'h4',
            h3: 'h4',
            h4: 'h4',
            h5: 'h4',
            h6: 'h4',
          }}
        >
          {description}
        </ReactMarkdown>
      </>
    ),
  }
  const tabOptions: any[] = Object.keys(tabMapping)
  const [activeTab, setActiveTab] = useState(tabOptions[0])

  return (
    <GenericModal
      heading={'Add Entry'}
      show={entryModal}
      size="lg"
      onHide={() => setEntryModal(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => handleParentSubmit()}>
          Submit
        </GlobalStyles.Button>
      }
    >
      {
        <>
          <CardHeader>
            <TabMenu tabOptions={tabOptions} {...{ activeTab, setActiveTab }} />
          </CardHeader>
          <div style={{ margin: '2em auto', padding: '0 2em', maxWidth: '1000px', minHeight: 250 }}>
            {tabMapping[activeTab]}
          </div>
        </>
      }
    </GenericModal>
  )
}
