import React, { useState, useRef } from 'react'
import { usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { Form, InputGroup, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

// Components
import GlobalStyles from '../../../components/component-items/styles'

export const CreatePackaging = () => {
  const {
    state: { csrf },
  } = globalState()
  const node: any = useRef()
  const history = useHistory()

  const [validated, setValidated] = useState(false)

  const defaultPackaging = {
    name: '',
    barcode: '',
    sku: '',
    packaging_type: 'B',
    price: 0.1,
    length: 0.0,
    width: 0.0,
    height: 0.0,
    weight: 0.0,
    stored_length: 0.0,
    stored_width: 0.0,
    stored_height: 0.0,
    inventory_control: 'fifo',
    inventory_control_category: 'received_date',
  }
  const [packaging, setPackaging] = useState(defaultPackaging)

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }
    const resp = await usePost('/api/product/packaging/', { ...packaging }, csrf, false)
    if (resp.id) {
      history.push(`/product/variant/${resp.id}/`)
    }
  }

  return (
    <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Create Packaging</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '55%' }}>
          <Row>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Ex: Large Box"
                name="name"
                value={packaging.name}
                onChange={(e) => setPackaging({ ...packaging, name: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please enter a valid package name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Price</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="price"
                  required
                  value={packaging.price}
                  onChange={(e) => setPackaging({ ...packaging, price: Number(e.target.value) })}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid sku.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Barcode</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="barcode"
                  required
                  value={packaging.barcode}
                  onChange={(e) => setPackaging({ ...packaging, barcode: e.target.value })}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid barcode.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>SKU</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="sku"
                  required
                  value={packaging.sku}
                  onChange={(e) => setPackaging({ ...packaging, sku: e.target.value })}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid sku.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Type</Form.Label>
              <Form.Select
                value={packaging.packaging_type}
                onChange={(e) => setPackaging({ ...packaging, packaging_type: e.target.value })}
              >
                <option value={'B'}>Box</option>
                <option value={'T'}>Poster Tube</option>
                <option value={'G'}>Poly/Bubble Bag</option>
                <option value={'F'}>Flat Envelope</option>
                <option value={'P'}>Pallet</option>
                <option value={'A'}>Package Accessory</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <h5 style={{ margin: '1.2em 0' }}>Shipping Dimensions</h5>
          <Row>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Length (in)</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="length"
                  required
                  value={packaging.length}
                  onChange={(e) => setPackaging({ ...packaging, length: Number(e.target.value) })}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid shipping length.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Width (in)</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="width"
                  required
                  value={packaging.width}
                  onChange={(e) => setPackaging({ ...packaging, width: Number(e.target.value) })}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid shipping width.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Height (in)</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="height"
                  required
                  value={packaging.height}
                  onChange={(e) => setPackaging({ ...packaging, height: Number(e.target.value) })}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid shipping height.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Weight (oz)</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="shipping_weight"
                  required
                  value={packaging.weight}
                  onChange={(e) => setPackaging({ ...packaging, weight: Number(e.target.value) })}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid shipping weight.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <h5 style={{ margin: '1.2em 0' }}>Stored Dimensions</h5>
          <Row>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Length (in)</Form.Label>
              <InputGroup>
                <Form.Control
                  step={0.1}
                  type="number"
                  name="stored_length"
                  required
                  onChange={(e) => setPackaging({ ...packaging, stored_length: Number(e.target.value) })}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid shipping length.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Width (in)</Form.Label>
              <InputGroup>
                <Form.Control
                  step={0.1}
                  type="number"
                  name="stored_width"
                  required
                  onChange={(e) => setPackaging({ ...packaging, stored_width: Number(e.target.value) })}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid shipping width.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Height (in)</Form.Label>
              <InputGroup>
                <Form.Control
                  step={0.1}
                  type="number"
                  name="stored_height"
                  required
                  onChange={(e) => setPackaging({ ...packaging, stored_height: Number(e.target.value) })}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid shipping height.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <GlobalStyles.Button style={{ minWidth: 150, margin: 0, float: 'right', cursor: 'auto' }}>
            Create Packaging
          </GlobalStyles.Button>
        </div>
      </GlobalStyles.FullPageCard>
    </Form>
  )
}
