import React, { useContext, useState, useEffect, FunctionComponent } from 'react'
import { store } from '../../store'
import { Link } from 'react-router-dom'
import { usePost, notify } from '../../components/component-items/helpers'
import { useQuery } from '../../hooks'
import { toast } from 'react-toastify'

// Components
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import BootstrapCarousel from 'react-bootstrap/Carousel'
import { OrderStatusBox } from '../../components/component-items/status-box'
import { StatusBoxStyled } from '../../components/component-items/status-box'
import { ButtonLoading } from '../../components/component-items/loading-popover'
import {
  PostPage,
  Background,
  SignIn,
  Information,
  InfoPanel,
  Input,
  Logo,
  H2,
  H3,
  P,
  ButtonWrapper,
  Carousel,
} from './styles'

import { NewCompany } from '../../types/company'

type NoteModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
}

export const NoteModal: FunctionComponent<NoteModalProps> = ({ open, setOpen }) => {
  return (
    <GenericModal
      heading={'Create a User Account'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <div style={{ padding: '2em' }}>
        <p>
          To create a user account, you must request your admin to <Link to="/company/users/">create</Link> one for you
          from within your merchant account.
        </p>
        <p>
          If you&#39;re having troubles, please contact customer service at{' '}
          <a href="mailto:support@shippingtree.co">support@shippingtree.co</a>.
        </p>
      </div>
    </GenericModal>
  )
}

export const LoginPage = () => {
  const querystring = window.location.search
  const query = useQuery()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const startIndex = window.location.hash === '#create-account' ? 1 : 0
  const [index, setIndex] = useState(startIndex)
  const [company, setCompany] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [volume, setVolume] = useState('')
  const [email, setEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [validated, setValidated] = useState(false)

  const [open, setOpen] = useState(false)

  const [response, setResponse] = useState('')
  const [error, setError] = useState('')

  const globalState = useContext(store)
  const {
    state: { csrf },
    dispatch,
  } = globalState

  useEffect(() => {
    getCSRF()
  }, [])

  useEffect(() => {
    if (query.get('mode') === 'reset') {
      setIndex(3)
    }

    // Check if we are confirming company
    const token = query.get('token')
    if (token) {
      confirmCompany(token)
    }
  }, [query])

  const confirmCompany = async (token: string) => {
    const url = `/api/company/confirm/`
    const body = { token }
    const resp = await usePost(url, body, csrf, false)
    if (resp && !resp.errors) {
      notify({
        type: 'success',
        message: 'Company has been confirmed. Login to continue with the registration process!',
      })
    }
  }

  const getCSRF = () => {
    fetch('/api/csrf/', {
      credentials: 'include',
    })
      .then((res) => {
        const csrfToken = res.headers.get('X-CSRFToken')
        dispatch({ type: 'csrf', csrf: csrfToken })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSession = () => {
    fetch('/api/session/', {
      credentials: 'include',
      headers: {
        'X-CSRFToken': csrf,
      },
    })
      .then(isResponseOk)
      .then((payload) => {
        if (payload.isAuthenticated) {
          setResponse('')
          getCSRF()
          dispatch({ type: 'session', payload: payload })
        } else {
          dispatch({ type: 'logout' })
          getCSRF()
        }
      })
      .catch((err) => {
        getCSRF()
      })
  }

  const isResponseOk = async (response: any) => {
    if (response.ok) {
      return response.json()
    } else {
      let json_response: any = {}
      try {
        json_response = await response.json()
      } catch (error) {
        setError('Trouble connecting... If this error persists, please reach out to support@shippingtree.co')
        throw new Error('Trouble connecting... If this error persists, please reach out to support@shippingtree.co')
      }
      throw new Error(json_response.message)
    }
  }

  const login = (e: any) => {
    // Verify field entry
    e.preventDefault()
    setValidated(true)
    if (e.currentTarget.checkValidity() === false) {
      return
    }

    // Submit credentials for login
    const url = `/api/login/`
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ ...{ username, password } }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrf,
      },
    })
      .then(isResponseOk)
      .then((payload) => {
        getSession()
      })
      .catch((err) => {
        getSession()
        setResponse(err.message)
      })
  }
  const resetPassword = async (e: { preventDefault: () => void; currentTarget: any }) => {
    // Verify field entry
    setResponse('')
    setError('')
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match')
      return
    }
    setValidated(true)
    if (e.currentTarget.checkValidity() === false || newPassword !== confirmPassword) {
      return
    }

    // Submit credentials for login
    const url = `/api/reset-password/`
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        password: newPassword,
        uid: query.get('uid'),
        token: query.get('token'),
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (resp.ok) {
      notify({ type: 'success', message: 'Password successfully updated. Please login.' })
      setResponse('')
      setIndex(0)
    } else {
      const json_response = await resp.json()
      setError(
        Object.entries(json_response.errors)
          .map(([key, value]) => `${key}: ${value}`)
          .join(', ')
      )
    }
    setValidated(false)
  }

  const signup = async (e: any) => {
    e.preventDefault()

    setValidated(true)
    if (e.currentTarget.checkValidity() === false || newPassword !== confirmPassword) {
      return
    }

    const url = `/api/company/new/${querystring}`
    const body: NewCompany = { company, firstName, lastName, volume, email, newPassword }
    const resp = await usePost(url, body, csrf, false)
    if (resp.success) {
      notify({
        title: 'Success!',
        message: 'You have registered your company. Please check your email to verify your email address.',
        type: 'success',
        autoClose: 0,
      })
    }
  }

  const forgotPassword = async (e: any) => {
    e.preventDefault()
    setValidated(true)
    if (e.currentTarget.checkValidity() === false) {
      return
    }
    const resp = await usePost('/api/password_reset/', { ...{ email } }, csrf, false, true, true)
    if (resp.success) {
      setResponse(resp.message ? resp.message : 'Success! Please check your email for a link to reset your password.')
    } else {
      setError(resp.error || 'There was an error. Please try again.')
    }
  }

  return (
    <PostPage>
      <Background>
        <SignIn>
          <Information>
            <Logo src={'/api/static/assets/login/logo.png'} />
            <BootstrapCarousel
              activeIndex={index}
              onSelect={() => null}
              controls={false}
              indicators={false}
              interval={null}
              touch={false}
            >
              <Carousel.Item>
                <H3>Welcome To ShippingTree</H3>
                {response ? (
                  <>
                    <StatusBoxStyled className="red" style={{ margin: '-1em 0 2em' }}>
                      {response}
                    </StatusBoxStyled>
                    {error ? (
                      <StatusBoxStyled className="yellow" style={{ margin: '-1em 0 2em' }}>
                        Visit our status page at <a href="https://status.shippingtree.co">status.shippingtree.co</a>
                      </StatusBoxStyled>
                    ) : null}
                  </>
                ) : null}
                <Form noValidate validated={validated} onSubmit={login}>
                  <Form.Group controlId="formUsername">
                    <Form.Text className="text-muted" style={{ paddingBottom: 2, fontSize: '80%' }}>
                      Username or Email
                    </Form.Text>
                    <Input
                      type="text"
                      name="username"
                      required
                      placeholder=""
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      data-pw="login-username"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email or username.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formPassword" className="py-2">
                    <Form.Text className="text-muted py-2" style={{ fontSize: '80%' }}>
                      Password
                    </Form.Text>
                    <Input
                      type="password"
                      name="password"
                      required
                      placeholder=""
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      data-pw="login-pass"
                    />
                    <Form.Control.Feedback type="invalid">Please enter your password.</Form.Control.Feedback>
                    <a href="#password_reset" onClick={() => setIndex(2)}>
                      <Form.Text style={{ textAlign: 'right', fontSize: '80%', display: 'grid' }}>
                        Forgot Password?
                      </Form.Text>
                    </a>
                  </Form.Group>
                  <ButtonWrapper className="pt-4" style={{ flexDirection: 'column' }}>
                    <ButtonLoading title={'Sign In'} dataPw="login-submit" />
                    <Form.Text className="text-muted" style={{ textAlign: 'center' }}>
                      New To ShippingTree?{' '}
                      <a href="#create-account" onClick={() => setIndex(1)}>
                        Create Merchant Account
                      </a>
                    </Form.Text>
                  </ButtonWrapper>
                </Form>
              </Carousel.Item>
              <Carousel.Item>
                <Form noValidate validated={validated} onSubmit={signup}>
                  <H3>
                    Create A Merchant Account
                    <br />
                    <a href="#" onClick={() => setOpen(true)}>
                      <Form.Text>Create User Account?</Form.Text>
                    </a>
                  </H3>
                  <Form.Group controlId="formCompanyName">
                    <Form.Text className="text-muted" style={{ paddingBottom: 2, fontSize: '80%' }}>
                      Company Name
                    </Form.Text>
                    <Input
                      type="text"
                      name="username"
                      required
                      placeholder=""
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Please enter your company name.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formFirstName">
                    <Form.Text className="text-muted" style={{ paddingBottom: 2, fontSize: '80%' }}>
                      First Name
                    </Form.Text>
                    <Input
                      type="name"
                      name="first_name"
                      required
                      placeholder=""
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Please enter your First Name.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formLastName">
                    <Form.Text className="text-muted" style={{ paddingBottom: 2, fontSize: '80%' }}>
                      Last Name
                    </Form.Text>
                    <Input
                      type="name"
                      name="last_name"
                      required
                      placeholder=""
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Please enter your Last Name.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formVolume">
                    <Form.Text className="text-muted" style={{ paddingBottom: 5 }}>
                      Volume
                    </Form.Text>
                    <Form.Select
                      value={volume}
                      onChange={(e) => setVolume(e.target.value)}
                      required
                      style={{ marginBottom: '2em' }}
                    >
                      <option value="">Average Monthly Volume</option>
                      <option value="1">less than 500 orders</option>
                      <option value="2">500-1,000 orders</option>
                      <option value="3">1,000-5,000 orders</option>
                      <option value="4">more than 5,000 orders</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select your estimated order volume.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formEmail">
                    <Form.Text className="text-muted" style={{ paddingBottom: 2, fontSize: '80%' }}>
                      Email
                    </Form.Text>
                    <Input
                      type="email"
                      name="email"
                      required
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Please enter your email.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formPassword">
                    <Form.Text className="text-muted" style={{ paddingBottom: 2, fontSize: '80%' }}>
                      Password
                    </Form.Text>
                    <Input
                      type="password"
                      name="password"
                      required
                      placeholder=""
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Please enter a valid password.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formPasswordConfirm">
                    <Form.Text className="text-muted" style={{ paddingBottom: 2, fontSize: '80%' }}>
                      Confirm Password
                    </Form.Text>
                    <Input
                      type="password"
                      name="password2"
                      required
                      placeholder=""
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {validated && newPassword !== confirmPassword && (
                      <Form.Control.Feedback style={{ display: 'inline' }} type="invalid">
                        Passwords do not match.
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <ButtonWrapper>
                    <ButtonLoading title={'Sign Up'} />
                  </ButtonWrapper>
                  <Form.Text className="text-muted" style={{ textAlign: 'center' }}>
                    Already have an account?{' '}
                    <a href="#" onClick={() => setIndex(0)}>
                      Sign In
                    </a>
                  </Form.Text>
                </Form>
              </Carousel.Item>
              <Carousel.Item>
                <Form noValidate validated={validated} onSubmit={forgotPassword}>
                  <H3>Forgot My Password</H3>
                  {response ? (
                    <OrderStatusBox style={{ margin: '-1em 0 2em' }} status={response} status_id={200} />
                  ) : null}
                  {error ? <OrderStatusBox style={{ margin: '-1em 0 2em' }} status={error} status_id={10} /> : null}
                  <Form.Group controlId="formUsername">
                    <Form.Text className="text-muted" style={{ paddingBottom: 2, fontSize: '80%' }}>
                      Email
                    </Form.Text>
                    <Input
                      type="text"
                      name="username"
                      required
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Please enter your email.</Form.Control.Feedback>
                  </Form.Group>
                  <ButtonWrapper>
                    <ButtonLoading />
                  </ButtonWrapper>
                  <Form.Text className="text-muted" style={{ textAlign: 'center', fontSize: '80%', display: 'grid' }}>
                    <a
                      href="#create-account"
                      onClick={() => {
                        setIndex(0)
                        setResponse('')
                      }}
                    >
                      Return To Sign In
                    </a>
                  </Form.Text>
                </Form>
              </Carousel.Item>
              <Carousel.Item>
                <H3>Reset your ShippingTree password</H3>
                {response ? (
                  <StatusBoxStyled className="green" style={{ margin: '-1em 0 2em' }}>
                    {response}
                  </StatusBoxStyled>
                ) : null}
                {error ? (
                  <StatusBoxStyled className="yellow" style={{ margin: '-1em 0 2em' }}>
                    {error}
                  </StatusBoxStyled>
                ) : null}
                <Form noValidate validated={validated} onSubmit={resetPassword}>
                  <Form.Group controlId="formPassword">
                    <Form.Text className="text-muted" style={{ paddingBottom: 2 }}>
                      Password
                    </Form.Text>
                    <Input
                      type="password"
                      name="password"
                      required
                      placeholder=""
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      data-pw="reset-pass"
                    />
                    <Form.Control.Feedback type="invalid">Please enter your password.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formPassword2">
                    <Form.Text className="text-muted" style={{ paddingBottom: 2 }}>
                      Repeat password
                    </Form.Text>
                    <Input
                      type="password"
                      name="confirm-password"
                      required
                      placeholder=""
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      data-pw="login-pass-2"
                    />
                    <Form.Control.Feedback type="invalid">Both passwords should match.</Form.Control.Feedback>
                  </Form.Group>
                  <ButtonWrapper>
                    <ButtonLoading title={'Change'} dataPw="reset-submit" />
                  </ButtonWrapper>
                  <Form.Text className="text-muted" style={{ textAlign: 'center' }}>
                    Login To ShippingTree?{' '}
                    <a href="#login" onClick={() => setIndex(0)}>
                      Login
                    </a>
                  </Form.Text>
                </Form>
              </Carousel.Item>
            </BootstrapCarousel>
          </Information>
          <InfoPanel>
            <Carousel>
              <Carousel.Item>
                <H2>Cross Border Ecommerce Simplified</H2>
                <P>Send or direct bulk inventory to a ShippingTree fulfillment center in the U.S.</P>
              </Carousel.Item>
              <Carousel.Item>
                <H2>Order Fulfillment Made Easy</H2>
                <P>Instantly connect ShippingTree software to your online store or marketplace.</P>
              </Carousel.Item>
              <Carousel.Item>
                <H2>Transparent Pricing.</H2>
                <P>Pay for what you use. No long-term commitments or surprise charges!</P>
              </Carousel.Item>
            </Carousel>
          </InfoPanel>
        </SignIn>
      </Background>
      <NoteModal {...{ open, setOpen }} />
    </PostPage>
  )
}

export default LoginPage
