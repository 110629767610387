import React, { useState, useRef, FunctionComponent } from 'react'
import { useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

import Form from 'react-bootstrap/Form'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import CardLoading from '../../../components/component-items/loading-popover'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { TableCell } from '../../../components/component-items/datatable'
import GenericModal from '../../../components/component-items/modal'
import Pager, { Pages } from '../../../components/component-items/pager'
import { Col, Row } from 'react-bootstrap'

type CarrierProps = {
  id: number
  carrier: string
  count: number
}

type ManifestsProps = {
  id: number
  date: string
  carrier: string
  title: string
  url: string
  last_downloaded: string
}

type CreateManifestProps = {
  carriers: CarrierProps[]
  open: boolean
  setOpen: (open: boolean) => void
}
export const CreateManifest: FunctionComponent<CreateManifestProps> = ({ carriers, open, setOpen }) => {
  const node: any = useRef()
  const [validated, setValidated] = useState(false)
  const [carrier, setCarrier] = useState('DHL eCommerce')
  const {
    state: { csrf },
  } = globalState()

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    usePost('/api/postage/manifests/', { ...{ carrier } }, csrf)
  }

  return (
    <GenericModal
      heading={'Create Manifest'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>{'Submit'}</GlobalStyles.Button>}
    >
      <Form ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '2em' }}>
          <Form.Label>Carrier</Form.Label>
          <Form.Select required value={carrier} onChange={(e) => setCarrier(e.target.value)}>
            {carriers.map(({ carrier }) => (
              <option key={carrier}>{carrier}</option>
            ))}
          </Form.Select>
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

export const PostageManifests = () => {
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const tableRowOptions = [100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const [download, setDownload] = useState(false)
  const {
    state: { csrf },
  } = globalState()

  const url = '/api/postage/manifests/?range=' + rows + '&page=' + (page - 1) + '&download=' + download
  const { response, loaded, error, placeholder } = useFetch(url, {})
  const manifests: ManifestsProps[] = loaded ? (response as any).manifests : []

  const total = loaded ? (response as any).total : 0
  const carriers: CarrierProps[] = loaded ? (response as any).carriers : []
  var totalPages = loaded && total ? Math.ceil(total / rows) : 1

  const handleDownload = (url: string, id: any) => {
    window.open(url, '__blank')
    usePost('/api/postage/manifests/', { id: id }, csrf)
    setDownload(true)
  }

  return (
    <Row>
      <Col xl={9}>
        <GlobalStyles.FullPageCard>
          <GlobalStyles.CardHeader>
            <GlobalStyles.CardTitle>
              <h3>Carrier Manifests</h3>
            </GlobalStyles.CardTitle>
            <GlobalStyles.CardToolbar>
              <GlobalStyles.Button style={{ height: 40, width: 170 }} onClick={() => setOpen(true)}>
                Create Manifest
              </GlobalStyles.Button>
              <CreateManifest {...{ carriers, open, setOpen }} />
            </GlobalStyles.CardToolbar>
          </GlobalStyles.CardHeader>
          <div style={{ minHeight: 500 }}>
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text center">Created</span>
                  </th>
                  <th>
                    <span className="text center">Carrier</span>
                  </th>
                  <th>
                    <span className="text">Title</span>
                  </th>
                  <th>
                    <span className="text center">Last Downloaded</span>
                  </th>
                  <th>
                    <span className="text center">Download</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {manifests.map(({ id, date, carrier, title, url, last_downloaded }) => (
                  <GlobalStyles.TableRow key={id}>
                    <TableCell center input={date} />
                    <TableCell
                      center
                      input={
                        <StatusBoxStyled style={{ width: 150, margin: 'auto' }} className="silver">
                          {carrier}
                        </StatusBoxStyled>
                      }
                    />
                    <TableCell input={title} />
                    <TableCell center input={last_downloaded} />
                    <TableCell center>
                      {url ? (
                        <GlobalStyles.Button style={{ color: 'white' }} onClick={() => handleDownload(url, id)}>
                          Download
                        </GlobalStyles.Button>
                      ) : (
                        <StatusBoxStyled className="purple" style={{ width: 100, margin: 'auto' }}>
                          Pending
                        </StatusBoxStyled>
                      )}
                    </TableCell>
                  </GlobalStyles.TableRow>
                ))}
              </tbody>
            </GlobalStyles.DataTable>
          </div>
          <GlobalStyles.CardFooter>
            <Pages page={page} setPage={setPage} total={totalPages} show={5} />
            <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
          </GlobalStyles.CardFooter>
          {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
        </GlobalStyles.FullPageCard>
      </Col>
      <Col xl={3}>
        <GlobalStyles.FullPageCard>
          <GlobalStyles.CardHeader>
            <GlobalStyles.CardTitle>
              <h3>
                Carriers<small>{carriers?.length || 0} Total</small>
              </h3>
            </GlobalStyles.CardTitle>
          </GlobalStyles.CardHeader>
          <div className="card-body" style={{ minHeight: 500 }}>
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text center">Carrier</span>
                  </th>
                  <th>
                    <span className="text center">Remaining</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {carriers?.map(({ id, carrier, count }) => (
                  <GlobalStyles.TableRow key={id}>
                    <TableCell input={carrier} center />
                    <TableCell input={count} center />
                  </GlobalStyles.TableRow>
                ))}
              </tbody>
            </GlobalStyles.DataTable>
          </div>
          {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
        </GlobalStyles.FullPageCard>
      </Col>
    </Row>
  )
}
