import React, { useState, useEffect } from 'react'
import { useFetch } from '../../components/component-items/helpers'
import { Link } from 'react-router-dom'

// Components
import Pager, { Pages } from '../../components/component-items/pager'
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import { StatusBoxStyled } from '../../components/component-items/status-box'
import { TableCell, THSort } from '../../components/component-items/datatable'
import { TableRow } from './styles'
import { SearchBox } from '../../components/component-items/search'

export const LocationList = () => {
  const [page, setPage] = useState(1)
  const tableRowOptions = [100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])

  // Assign sort based on url params first
  const [sort, setSort] = useState({})
  const [search, setSearch] = useState('')

  const [loading, setLoading] = useState(false)

  const url =
    '/api/inventory/location/?range=' +
    rows +
    '&page=' +
    (page - 1) +
    '&order_by=' +
    Object.keys(sort) +
    '&sort=' +
    Object.values(sort) +
    '&q=' +
    search
  const res: any = useFetch(url, {})
  const locations = res.loaded ? res?.response.locations : []

  useEffect(() => {
    setLoading(!res.loaded)
  }, [res.loaded])

  const total = res.loaded ? res?.response.total : 0
  var totalPages = res.loaded && total ? Math.ceil(total / rows) : 1

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Location List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <a href="/location/edit/">
            <GlobalStyles.Button className="secondary">Batch Edit Locations</GlobalStyles.Button>
          </a>
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus updateURL />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <THSort title={'Barcode'} center {...{ sort, setSort }} />
              <THSort title={'Aisle'} center {...{ sort, setSort }} />
              <THSort title={'Bay'} center {...{ sort, setSort }} />
              <THSort title={'Level'} center {...{ sort, setSort }} />
              <THSort title={'Slot'} center {...{ sort, setSort }} />
              <THSort title={'Type'} sortKey={'get_location_type'} center {...{ sort, setSort }} />
              <THSort title={'Velocity'} center {...{ sort, setSort }} />
              <THSort title={'Inventory Items'} sortKey={'item_count'} center {...{ sort, setSort }} />
              <THSort title={'Total Capacity'} sortKey={'get_capacity'} center {...{ sort, setSort }} />
              <THSort title={'Capacity Remaining'} sortKey={'get_capacity_remaining'} center {...{ sort, setSort }} />
              <THSort title={'Current Weight'} sortKey={'get_weight_used'} center {...{ sort, setSort }} />
              <THSort title={'Weight Remaining'} sortKey={'get_weight_remaining'} center {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>
            {locations.map(
              ({
                id,
                barcode,
                aisle,
                bay,
                level,
                slot,
                velocity,
                itemCount,
                capacity,
                capacity_remaining,
                weight_used,
                weight_remaining,
                location_type,
              }: any) => {
                const capacityDisplay = capacity ? (
                  <span>
                    {capacity} ft<sup>3</sup>
                  </span>
                ) : (
                  'N/A'
                )
                const capacityRemainingDisplay = capacity_remaining ? (
                  <span>
                    {capacity_remaining} ft<sup>3</sup>
                  </span>
                ) : (
                  'N/A'
                )
                return (
                  <TableRow key={id}>
                    <TableCell center input={<Link to={`/location/${id}/`}>{barcode}</Link>} />
                    <TableCell center input={aisle} />
                    <TableCell center input={bay} />
                    <TableCell center input={level} />
                    <TableCell center input={slot} />
                    <TableCell center input={location_type} style={{ textTransform: 'capitalize' }} />
                    <TableCell center input={velocity} />
                    <TableCell center>
                      <StatusBoxStyled
                        className={itemCount > 0 ? 'green' : itemCount < 0 ? 'red' : 'blue'}
                        style={{ width: 100, margin: '2em auto' }}
                      >
                        {itemCount}
                      </StatusBoxStyled>
                    </TableCell>
                    <TableCell center>
                      <StatusBoxStyled
                        className={capacity > 0 ? 'green' : capacity < 0 ? 'red' : 'silver'}
                        style={{ width: 100, margin: '2em auto' }}
                      >
                        {capacityDisplay}
                      </StatusBoxStyled>
                    </TableCell>
                    <TableCell center>
                      <StatusBoxStyled
                        className={capacity_remaining > 0 ? 'blue' : 'red'}
                        style={{ width: 100, margin: '2em auto' }}
                      >
                        {capacityRemainingDisplay}
                      </StatusBoxStyled>
                    </TableCell>
                    <TableCell center>
                      <StatusBoxStyled
                        className={weight_used > 0 ? 'green' : 'silver'}
                        style={{ width: 100, margin: '2em auto' }}
                      >{`${weight_used} lbs`}</StatusBoxStyled>
                    </TableCell>
                    <TableCell center>
                      <StatusBoxStyled
                        className={weight_remaining > 0 ? 'blue' : 'red'}
                        style={{ width: 100, margin: '2em auto' }}
                      >{`${weight_remaining} lbs`}</StatusBoxStyled>
                    </TableCell>
                  </TableRow>
                )
              }
            )}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
