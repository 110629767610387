import React, { useState, useEffect } from 'react'
import { usePost, useFetch, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Select from 'react-select'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import Checkbox from '../../../components/component-items/checkbox'
import { OrderInfo } from './styles'
import { Form } from 'react-bootstrap'
import CardLoading from '../../../components/component-items/loading-popover'
import { HoverNotes } from '../../../components/component-items/hover-notes'

const Label = styled(Form.Label)`
  margin-top: 1em;
`

const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
  }
`

export const ShopifySettings = ({ shop }: { shop: { shop: string } }) => {
  const defaultSettings = {
    storeUrl: '',
    integrationEnabled: false,
    manageTheirFulfillmentRequests: false,
    fulfillmentLocations: [],
    shopLocations: [],
    allowSplitFulfillmentOrders: false,
    giftNoteEnabled: false,
  }
  const [settings, setSettings] = useState(defaultSettings)
  const {
    state: { csrf },
  } = globalState()

  const {
    response: resp,
    loaded,
    error,
    placeholder,
  }: any = useFetch(`/api/shopify/store-settings?shop=${shop.shop}`, {})

  useEffect(() => {
    if (loaded) {
      // Transform multi-select content into an acceptable form
      var storeSettings = resp
      setSettings(storeSettings || defaultSettings)
    }
  }, [loaded])

  const handleSettingsSubmit = async (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    await usePost(`/api/shopify/store-settings?shop=${shop.shop}`, { ...settings }, csrf, false).then((response) => {
      if (!response.error) {
        notify({ type: 'success', message: 'Shopify settings have been updated.' })
      }
    })
  }

  return (
    <OrderInfo style={{ display: 'block', height: 'unset' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>Shopify Fulfillment Order Settings</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar></GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      {loaded && (
        <div style={{ width: '100%', paddingBottom: '4em' }}>
          <Form.Group style={{ margin: '1em', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'inherit' }}>
              <Checkbox
                selected={settings.manageTheirFulfillmentRequests}
                setSelected={() =>
                  setSettings({
                    ...settings,
                    manageTheirFulfillmentRequests: !settings.manageTheirFulfillmentRequests,
                  })
                }
                style={{ top: '3px', margin: '0 1em 0 0' }}
              />
              <HoverNotes
                // @ts-ignore
                style={{ padding: '0 1em', lineHeight: '24px' }}
                title="Automatically accept shopify fulfillment orders"
                description="If this is enabled, ShippingTree will automatically accept all fulfillment requests from Shopify. If this is disabled, Fulfillment Requests must either be manually requested or requested using Shopify Flow."
              />
            </div>
            <div>
              <Checkbox
                selected={settings.allowSplitFulfillmentOrders}
                setSelected={() =>
                  setSettings({ ...settings, allowSplitFulfillmentOrders: !settings.allowSplitFulfillmentOrders })
                }
                style={{ top: '3px', margin: '0 1em 0 0' }}
              />
              <Label>Allow split fulfillment orders</Label>
            </div>
            <div>
              <Checkbox
                selected={!settings.giftNoteEnabled}
                setSelected={() => setSettings({ ...settings, giftNoteEnabled: !settings.giftNoteEnabled })}
                style={{ top: '3px', margin: '0 1em 0 0' }}
              />
              <Label>Use order notes as Gift notes</Label>
            </div>
            <div>
              <Label>Shop Locations</Label>
              <ul>{settings.shopLocations?.map((location: any, id: number) => <li key={id}>{location?.name}</li>)}</ul>
            </div>
            <div>
              <Label>Fulfillment Locations</Label>
              <ul>
                {settings.fulfillmentLocations?.map((fulfillmentLocation: any, id: number) => (
                  <li key={id}>{fulfillmentLocation?.location?.name}</li>
                ))}
              </ul>
            </div>
          </Form.Group>
          <GlobalStyles.Button style={{ minWidth: '125px', float: 'right' }} onClick={handleSettingsSubmit}>
            Update
          </GlobalStyles.Button>
        </div>
      )}
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </OrderInfo>
  )
}
