import React from 'react'
import { useFetch } from '../../components/component-items/helpers'

// Components
import GlobalStyles from '../../components/component-items/styles'
import { SettingsContainer, Label } from './styles'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

export const ActionActivity = () => {
  return (
    <SettingsContainer style={{ display: 'block', height: 'unset' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>Actions Activity</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar></GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ padding: '1em', marginBottom: '4em' }}>
        <div>
          <Label>Activity here</Label>
        </div>
      </div>
    </SettingsContainer>
  )
}
