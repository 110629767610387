import React, { useEffect, useState } from 'react'
import { notify, useFetch, usePost, usePut } from '../../components/component-items/helpers'
import { globalState } from '../../store'
import styled from 'styled-components'

// Components
import GlobalStyles from '../../components/component-items/styles'
import { SettingsContainer } from './styles'
import { Form } from 'react-bootstrap'

const Label = styled(Form.Label)`
  margin-top: 1em;
`

export const EDISummary = () => {
  const url = '/api/edi/relationship/summary/'
  const { response: resp, loaded, error } = useFetch(url, {})
  const retailers = loaded ? resp?.retailersSupported : []
  const merchants = loaded ? resp?.merchantsSupported : []

  return (
    <SettingsContainer style={{ display: 'block', height: 'unset' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>EDI Summary</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar></GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ padding: '1em', marginBottom: '4em' }}>
        <div>
          <Label>
            <strong style={{ fontWeight: 500, textDecoration: 'underline' }}>{retailers.length}</strong> Retailers
            Supported
          </Label>
          <ul>
            {retailers.map((retailer: string) => (
              <li key={retailer}>{retailer}</li>
            ))}
          </ul>
        </div>
        <div>
          <Label>
            <strong style={{ fontWeight: 500, textDecoration: 'underline' }}>{merchants.length}</strong> Merchants
            Supported
          </Label>
          <ul>
            {merchants.map((merchant: string) => (
              <li key={merchant}>{merchant}</li>
            ))}
          </ul>
        </div>
      </div>
    </SettingsContainer>
  )
}
