import React, { useState } from 'react'
import { globalState } from '../../../store'
import Styled from 'styled-components'

// Components
import { Form, Col, Row } from 'react-bootstrap'
import { notify } from '../../../components/component-items/helpers'
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell as Cell } from '../../../components/component-items/datatable'

export const TableCell = Styled(Cell)`
  background-color: aliceblue;
`

export const LocationEdit = () => {
  const {
    state: { csrf },
  } = globalState()
  const [validated, setValidated] = useState(false)
  const [locations, setLocations] = useState({
    aisle: '',
    zone: '',
    description: '',
    label_size: '2.25x1.25',
    location_type: '',
    velocity: 'C',
    start_bay: '',
    start_level: '',
    start_slot: '',
    end_bay: '',
    end_level: '',
    end_slot: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    edit: 'create',
  })
  const locationTypesOptions = [
    { value: 'bin', label: 'Bin = 18x12x12 20lbs' },
    { value: 'pallet', label: 'Pallet = 48x40x72 1000lbs' },
    { value: 'shelf', label: 'Shelf = 48x24x24 80lbs' },
  ]

  const velocityOptions = [
    { value: 'A', label: 'A = 20% of inventory - 80% of movement' },
    { value: 'B', label: 'B = 30% of inventory - 15% of movement' },
    { value: 'C', label: 'C = 50% of inventory - 5% of movement' },
  ]
  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }

    fetch(`/api/inventory/location/mass_edit/`, {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'X-CSRFToken': csrf,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...locations }),
    })
      .then((response) => {
        if (locations.label_size == 'none') {
          return response.json()
        } else {
          return response.blob()
        }
      })
      .then((response) => {
        if (locations.label_size == 'none' && response.success) {
          notify({ type: 'error', message: response.message })
        } else {
          const blob = new Blob([response], { type: 'application/octet-stream' })
          const downloadUrl = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = downloadUrl
          a.download = 'labels.zpl'
          document.body.appendChild(a)
          a.click()
          // @ts-ignore
          URL.revokeObjectURL(blob)
        }
      })
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Batch Edit Locations</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '65%' }}>
          <Row>
            <Form.Group as={Col} md="12" className="required">
              <Form.Label>Batch Editing Scenario</Form.Label>
              <Form.Select
                name="type"
                required
                value={locations.edit}
                onChange={(e) => setLocations({ ...locations, edit: e.target.value })}
              >
                <option value="create">Create only new locations</option>
                <option value="edit">Create and edit existing locations</option>
                <option value="delete">Delete Locations without inventory</option>
                <option value="print">Print only existing locations</option>
              </Form.Select>
            </Form.Group>
            {!['delete'].includes(locations.edit) && (
              <>
                <Form.Group as={Col} md="4" className="required">
                  <Form.Label>Label Size</Form.Label>
                  <Form.Select
                    name="type"
                    required
                    value={locations.label_size}
                    onChange={(e) => setLocations({ ...locations, label_size: e.target.value })}
                  >
                    <option value="none">Don't print Labels</option>
                    <option value="2.25x1.25">2.25"x1.25" Product Labels</option>
                    <option value="4x6">4"x6" Pallet Labels</option>
                    <option value="4x2">4"x2" Location Labels</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="4" className="required">
                  <Form.Label>Location Type</Form.Label>
                  <Form.Select
                    name="location_type"
                    value={locations.location_type}
                    required
                    onChange={(e) => setLocations({ ...locations, location_type: e.target.value })}
                  >
                    {locationTypesOptions.map(({ value, label }, id) => (
                      <option value={value} key={id}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">Please provide a valid location type.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" className="required">
                  <Form.Label>Velocity</Form.Label>
                  <Form.Select
                    name="velocity"
                    value={locations.velocity}
                    required
                    onChange={(e) => setLocations({ ...locations, velocity: e.target.value })}
                  >
                    {velocityOptions.map(({ value, label }, id) => (
                      <option value={value} key={id}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">Please provide a valid velocity.</Form.Control.Feedback>
                </Form.Group>
              </>
            )}
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Aisle</Form.Label>
              <Form.Control
                type="text"
                name="aisle"
                value={locations.aisle}
                onChange={(e) => setLocations({ ...locations, aisle: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid aisle.</Form.Control.Feedback>
            </Form.Group>
            {!['delete', 'print'].includes(locations.edit) && (
              <>
                <Form.Group as={Col} md="4">
                  <Form.Label>Zone</Form.Label>
                  <Form.Control
                    type="number"
                    name="zone"
                    value={locations.zone}
                    onChange={(e) => setLocations({ ...locations, zone: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">Please provide a valid zone.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={locations.description}
                    onChange={(e) => setLocations({ ...locations, description: e.target.value })}
                  />
                </Form.Group>
              </>
            )}
          </Row>
          <Row>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Start Bay</Form.Label>
              <Form.Control
                type="number"
                name="start_bay"
                value={locations.start_bay}
                onChange={(e) => setLocations({ ...locations, start_bay: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid starting bay.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Start Level</Form.Label>
              <Form.Control
                type="number"
                name="start_level"
                value={locations.start_level}
                onChange={(e) => setLocations({ ...locations, start_level: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid starting level.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>Start Slot</Form.Label>
              <Form.Control
                type="number"
                name="start_slot"
                value={locations.start_slot}
                onChange={(e) => setLocations({ ...locations, start_slot: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid starting slot.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>End Bay</Form.Label>
              <Form.Control
                type="number"
                name="end_bay"
                value={locations.end_bay}
                onChange={(e) => setLocations({ ...locations, end_bay: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid ending bay.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>End Level</Form.Label>
              <Form.Control
                type="number"
                name="end_level"
                value={locations.end_level}
                onChange={(e) => setLocations({ ...locations, end_level: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid ending level.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="required">
              <Form.Label>End Slot</Form.Label>
              <Form.Control
                type="number"
                name="end_slot"
                value={locations.end_slot}
                onChange={(e) => setLocations({ ...locations, end_slot: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid ending slot.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          {!['delete', 'print'].includes(locations.edit) && (
            <Row>
              <Form.Group as={Col} md="3" className="required">
                <Form.Label>Length (in)</Form.Label>
                <Form.Control
                  type="number"
                  name="length"
                  value={locations.length}
                  onChange={(e) => setLocations({ ...locations, length: e.target.value })}
                  required
                />
                <Form.Control.Feedback type="invalid">Please provide a valid length.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" className="required">
                <Form.Label>Width (in)</Form.Label>
                <Form.Control
                  type="number"
                  name="width"
                  value={locations.width}
                  onChange={(e) => setLocations({ ...locations, width: e.target.value })}
                  required
                />
                <Form.Control.Feedback type="invalid">Please provide a valid width.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" className="required">
                <Form.Label>Height (in)</Form.Label>
                <Form.Control
                  type="number"
                  name="height"
                  value={locations.height}
                  onChange={(e) => setLocations({ ...locations, height: e.target.value })}
                  required
                />
                <Form.Control.Feedback type="invalid">Please provide a valid height.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" className="required">
                <Form.Label>Weight (lbs)</Form.Label>
                <Form.Control
                  type="number"
                  name="weight"
                  value={locations.weight}
                  onChange={(e) => setLocations({ ...locations, weight: e.target.value })}
                  required
                />
                <Form.Control.Feedback type="invalid">Please provide a valid weight.</Form.Control.Feedback>
              </Form.Group>
            </Row>
          )}
          <GlobalStyles.Button
            type="submit"
            style={{ minWidth: 125, margin: '1em 0 0', float: 'right' }}
            onSubmit={handleSubmit}
          >
            Submit
          </GlobalStyles.Button>
        </div>
      </GlobalStyles.FullPageCard>
    </Form>
  )
}
