import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { globalState } from '../../../../store'
import { useFetch, usePost } from '../../../../components/component-items/helpers'
import { Form, Col, Row, InputGroup } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { ButtonLoading } from '../../../../components/component-items/loading-popover'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode } from '@fortawesome/pro-regular-svg-icons'

export const BulkSlotting = () => {
  const {
    state: { csrf },
  } = globalState()
  const { id: batch, inventory_item } = useParams<{ id: string; inventory_item: string }>()

  const {
    response: resp,
    loaded,
    error,
    placeholder,
  }: any = useFetch(`/api/inventory/batch/${batch}/slot/?inventory_item=${inventory_item}`, {
    redirect: 'follow',
  })

  // Update pick details after loading response
  useEffect(() => {
    if (loaded) {
      setPickDetails(resp)
      setScanQty(resp.quantity)
      if (resp.pick_confirmation) {
        // Only confirm location if pick_confirmation is not required to use QR code
        // setConfirmed(true)
      }
    }
  }, [loaded])

  const company = loaded ? resp.company : 'N/A'

  const [loading, setLoading] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [scanLocation, setScanLocation] = useState('')
  const [scanBarcode, setScanBarcode] = useState('')
  const [scanQty, setScanQty] = useState(1)
  const [pickDetails, setPickDetails] = useState({
    img: '',
    name: '',
    location: '',
    qr_code: '',
    barcode: '',
    quantity: '',
    pick_confirmation: false,
  })
  const [validated, setValidated] = useState(false)

  const inputLocation = useRef<HTMLInputElement>(null)
  const inputBarcode = useRef<HTMLInputElement>(null)
  // Set input field box to location on load
  useEffect(() => {
    if (inputLocation.current && !confirmed) {
      inputLocation.current.focus()
      inputLocation.current.select()
    }
  }, [])

  // Set input field box to bacode after confirming location
  useEffect(() => {
    if (confirmed && inputBarcode.current) {
      inputBarcode.current.focus()
      inputBarcode.current.select()
    }
  }, [confirmed])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget

    if (!confirmed) {
      setConfirmed(true)
      return
    }

    // Prevent multiple submissions
    if (loading) {
      return
    }

    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }

    setLoading(true)
    const resp = await usePost(
      `/api/inventory/batch/${batch}/slot/?inventory_item=${inventory_item}`,
      { location: scanLocation, barcode: scanBarcode, quantity: scanQty },
      csrf,
      false
    )
    setLoading(false)
    if (resp && resp.quantity) {
      setPickDetails(resp)
      setScanBarcode('')
      if (inputBarcode.current) {
        inputBarcode.current.focus()
        inputBarcode.current.select()
      }
    }
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, maximum-scale=0.8" />
      </Helmet>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <GlobalStyles.FullPageCard>
          <GlobalStyles.CardHeader>
            <GlobalStyles.CardTitle>
              <h3>PUT ITEM</h3>
            </GlobalStyles.CardTitle>
            <GlobalStyles.CardTitle>
              {/* @ts-ignore */}
              <div style={{ textAlign: 'right', paddingleft: '1em' }}>
                Batch Picking for <u>{company}</u>
              </div>
            </GlobalStyles.CardTitle>
          </GlobalStyles.CardHeader>
          <div style={{ margin: '2em auto', width: '90%' }}>
            <Row>
              <Form.Group as={Col} md="12" style={{ display: 'flex' }}>
                <img style={{ width: 'auto', height: 75, margin: 'auto' }} src={pickDetails.img} />
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>Product Variant</Form.Label>
                <Form.Control type="text" name="location" value={pickDetails.name} disabled />
              </Form.Group>
              <Form.Group as={Col} md="12" className="required">
                <Form.Label>
                  Location: <strong>{pickDetails.location}</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  ref={inputLocation}
                  disabled={confirmed}
                  placeholder={pickDetails.location}
                  value={scanLocation}
                  onChange={(e) => setScanLocation(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">Please input a valid location.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>
                  Product Variant Barcode: <strong>{pickDetails.barcode}</strong>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faBarcode} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="barcode"
                    ref={inputBarcode}
                    disabled={!confirmed}
                    placeholder={pickDetails.barcode}
                    value={scanBarcode}
                    onChange={(e) => setScanBarcode(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">Please input a valid barcode.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>Qty ({pickDetails.quantity} Remaining)</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="quantity"
                    disabled={!confirmed}
                    placeholder={pickDetails.quantity}
                    value={scanQty}
                    onChange={(e: any) => setScanQty(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">Please input a valid quantity.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <ButtonLoading style={{ minWidth: '100%', margin: '1em 0 0', float: 'right' }}>Submit</ButtonLoading>
          </div>
        </GlobalStyles.FullPageCard>
      </Form>
    </>
  )
}
