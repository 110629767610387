import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'

// Components
import { useFetch } from '../../../components/component-items/helpers'
import GlobalStyles from '../../../components/component-items/styles'
import CardLoading from '../../../components/component-items/loading-popover'
import { TableCell } from '../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

const SubHeader = styled(GlobalStyles.TableRow)`
  th {
    vertical-align: middle;
    padding: 10px;
  }
`

export const ProjectItems = () => {
  const params: any = useParams()
  const project = params.id
  const { response, loaded, error, placeholder }: any = useFetch(`/api/inventory/project/${project}/items/`, {}, 60)
  const inputItems = loaded ? response.inputItems : null
  const outputItems = loaded ? response.outputItems : null
  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>BOM</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 100, padding: '1em 1em 0' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr style={{ background: '#f6f9ff' }}>
              <th></th>
              <th>
                <span className="text" style={{ textAlign: 'left' }}>
                  Finished Product
                </span>
              </th>
              <th>
                <span className="text center">Requested</span>
              </th>
              <th>
                <span className="text center">Completed</span>
              </th>
              <th>
                <span className="text center">Processing</span>
              </th>
              <th>
                <span className="text center">Remaining</span>
              </th>
              <th>
                <span className="text center">Allocatable</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {outputItems?.map(
              ({ img, pId, sku, name, requested, completed, processing, remaining, allocatable }: any) => (
                <GlobalStyles.TableRow key={pId}>
                  <TableCell center input={<img src={img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />} />
                  <TableCell input={<Link to={`/product/variant/${pId}/`}>{sku}</Link>} style={{ width: '30%' }}>
                    <div>{name}</div>
                  </TableCell>
                  <TableCell input={<StatusBoxStyled className={'silver'}>{requested}</StatusBoxStyled>} center />
                  <TableCell
                    input={
                      <StatusBoxStyled className={requested === completed ? 'green' : 'blue'}>
                        {completed}
                      </StatusBoxStyled>
                    }
                    center
                  />
                  <TableCell input={processing} center />
                  <TableCell input={remaining} center />
                  <TableCell input={allocatable} center />
                </GlobalStyles.TableRow>
              )
            )}
            <SubHeader style={{ background: '#f6f9ff' }}>
              <th></th>
              <th>
                <span className="text" style={{ textAlign: 'left' }}>
                  Consuming
                </span>
              </th>
              <th>
                <span className="text center">Required</span>
              </th>
              <th>
                <span className="text center">Consumed</span>
              </th>
              <th>
                <span className="text center">Processing</span>
              </th>
              <th>
                <span className="text center">Remaining</span>
              </th>
              <th>
                <span className="text center">Allocatable</span>
              </th>
            </SubHeader>
            {inputItems?.map(
              ({ img, pId, sku, name, requested, completed, processing, remaining, allocatable }: any) => (
                <GlobalStyles.TableRow key={pId}>
                  <TableCell center input={<img src={img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />} />
                  <TableCell input={<Link to={`/product/variant/${pId}/`}>{sku}</Link>} style={{ width: '30%' }}>
                    <div>{name}</div>
                  </TableCell>
                  <TableCell input={<StatusBoxStyled className={'silver'}>{requested}</StatusBoxStyled>} center />
                  <TableCell
                    input={
                      <StatusBoxStyled className={requested === completed ? 'blue' : 'yellow'}>
                        {completed}
                      </StatusBoxStyled>
                    }
                    center
                  />
                  <TableCell input={processing} center />
                  <TableCell input={remaining} center />
                  <TableCell
                    input={
                      <StatusBoxStyled className={allocatable >= remaining ? 'green' : 'red'}>
                        {allocatable}
                      </StatusBoxStyled>
                    }
                    center
                  />
                </GlobalStyles.TableRow>
              )
            )}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
