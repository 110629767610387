import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { globalState } from '../../../../store'
import CardLoading from '../../../../components/component-items/loading-popover'

import { usePost, useFetch, notify } from '../../../../components/component-items/helpers'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { OrderInfo } from './styles'
import { toast } from 'react-toastify'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import GenericModal from '../../../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import { DragNDrop } from '../../../../components/component-items/dragndrop'

import { StatusRowProps } from './types'

export const UploadImageModal = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const productVariant = useParams<{ id: string }>().id
  const [file, setFile] = useState(null)

  const {
    state: { csrf },
  } = globalState()

  const uploadFile = () => {
    // Check there is a file first
    if (!file) {
      return
    }
    const name = 'photo'
    const extension = '.jpg'
    var formData = new window.FormData()
    formData.append(name, file, name + extension)
    usePost(`/api/product/variant/${productVariant}/upload_photo/`, formData, csrf, true, false)
  }

  return (
    <GenericModal
      heading={'Upload Product Image'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={uploadFile}>
          Upload
        </GlobalStyles.Button>
      }
    >
      <div style={{ justifyContent: 'center', margin: 'auto', padding: '1em' }}>
        <Form.Label style={{ marginTop: '1em' }}>Image File Upload</Form.Label>
        <DragNDrop {...{ file, setFile }} fileTypes={'.jpg, .jpeg, .png'} />
      </div>
    </GenericModal>
  )
}

const StatusRow = ({ status, url, items, orders }: StatusRowProps) => {
  return (
    <GlobalStyles.TableRow>
      <TableCell input={<Link to={url}>{status}</Link>} />
      <TableCell center input={items} />
      <TableCell center input={orders} />
    </GlobalStyles.TableRow>
  )
}

const ProductImage = styled.div`
  background-color: #fefefe;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  button {
    position: absolute;
    margin: 0;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }
  &:hover button,
  &:hover .shade {
    opacity: 1;
  }
  .shade {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.11);
  }
`

export const ProductAllocation = () => {
  const [open, setOpen] = useState(false)
  const productVariant = useParams<{ id: string }>().id

  const url = `/api/product/variant/${productVariant}/allocation/`
  const { response: resp, error, loaded, placeholder }: any = useFetch(url, {})

  const img = loaded ? resp.img : '/api/static/img/placeholder-product.png'
  const sku = loaded ? resp.sku : null

  const unfulfilled = loaded ? resp.allocation?.unfulfilled : 0
  const unfulfilledOrders = loaded ? resp.allocation?.unfulfilledOrders : 0
  const inFulfillment = loaded ? resp.allocation?.inFulfillment : 0
  const inFulfillmentOrders = loaded ? resp.allocation?.inFulfillmentOrders : 0
  const fulfilled = loaded ? resp.allocation?.fulfilled : 0
  const fulfilledOrders = loaded ? resp.allocation?.fulfilledOrders : 0
  const total = loaded ? resp.allocation?.total : 0
  const totalOrders = loaded ? resp.allocation?.totalOrders : 0

  useEffect(() => {
    if (loaded) {
      if (resp.error) {
        notify({ type: 'error', message: resp.error })
      }
    }
  }, [loaded])

  return (
    <div className="row" style={{ marginBottom: '20px' }}>
      <div className="col-xl-6">
        <OrderInfo style={{ position: 'relative' }}>
          <ProductImage onClick={() => setOpen(true)}>
            <img style={{ width: '100%', height: 'auto' }} src={img} />
            <div className="shade" />
            <GlobalStyles.Button className="secondary">Update</GlobalStyles.Button>
          </ProductImage>
          <UploadImageModal {...{ open, setOpen }} />
          {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
        </OrderInfo>
      </div>
      <div className="col-xl-6">
        <OrderInfo style={{ display: 'block', position: 'relative' }}>
          <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
            <GlobalStyles.CardTitle>
              <h3>
                Order Allocation<small>{total} Total</small>
              </h3>
            </GlobalStyles.CardTitle>
          </GlobalStyles.CardHeader>
          <div style={{ width: '100%' }}>
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text">Fulfillment</span>
                  </th>
                  <th>
                    <span className="text center">Units</span>
                  </th>
                  <th>
                    <span className="text center">Orders</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <StatusRow status={'All'} url={`/order/?sku=${sku}`} items={total} orders={totalOrders} />
                <StatusRow
                  status={'Unfulfilled'}
                  url={`/order/?fulfillment=unfulfilled&sku=${sku}`}
                  items={unfulfilled}
                  orders={unfulfilledOrders}
                />
                <StatusRow
                  status={'In Fulfillment'}
                  url={`/order/?fulfillment=in_fulfillment&sku=${sku}`}
                  items={inFulfillment}
                  orders={inFulfillmentOrders}
                />
                <StatusRow
                  status={'Fulfilled'}
                  url={`/order/?fulfillment=fulfilled&sku=${sku}`}
                  items={fulfilled}
                  orders={fulfilledOrders}
                />
              </tbody>
            </GlobalStyles.DataTable>
          </div>
          {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
        </OrderInfo>
      </div>
    </div>
  )
}
