import styled from 'styled-components'

import GlobalStyles from '../../../../components/component-items/styles'

export const Tooltip = styled(GlobalStyles.Tooltip)`
  width: 2rem;
  display: inline-flex;
  &:before {
    width: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`
