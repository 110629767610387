import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch, usePost } from '../../../../components/component-items/helpers'
import { Form, Col, InputGroup, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { globalState } from '../../../../store'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import Checkbox from '../../../../components/component-items/checkbox'

export const StartBatch = () => {
  const {
    state: { csrf },
  } = globalState()
  const { id: batch } = useParams<{ id: string }>()
  const { response, loaded }: any = useFetch(`/api/inventory/batch/${batch}/start/`, {
    redirect: 'follow',
  })

  const [paperPick, setPaperPick] = useState(false)
  const [batchLocation, setBatchLocation] = useState('')
  const [batchType, setBatchType] = useState('pick_and_sort')
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    if (loaded) {
      setBatchType(response.type || 'pick_and_sort')
    }
  }, [loaded])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget

    setValidated(true)

    if (form.checkValidity() === false) {
      return
    }

    usePost(
      `/api/inventory/batch/${batch}/start/`,
      { rack: batchLocation, type: batchType, paper_pick: paperPick },
      csrf,
      false
    )
  }

  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputElement?.current!.focus()
  }, [])

  const handleKeyPressed = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }

  return (
    <>
      {/*@ts-ignore*/}
      <Helmet>
        <meta name="viewport" content="initial-scale=1, maximum-scale=0.8" />
      </Helmet>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <GlobalStyles.FullPageCard>
          <GlobalStyles.CardHeader>
            <GlobalStyles.CardTitle>
              <h3>Start Batch {batch}</h3>
            </GlobalStyles.CardTitle>
          </GlobalStyles.CardHeader>
          <div style={{ margin: '2em auto', width: '80%' }}>
            <Row>
              <Form.Group as={Col} md="12" className="required mb-3">
                <InputGroup>
                  <Checkbox selected={paperPick} setSelected={() => setPaperPick(!paperPick)} />
                  <p style={{ padding: '0 1em', lineHeight: '24px' }}>Paper Pick</p>
                </InputGroup>
                <Form.Label>Batch Type</Form.Label>
                <Form.Select value={batchType} onChange={(e) => setBatchType(e.target.value)}>
                  {batchType === 'prepack' ? (
                    <option value={'prepack'}>Prepack</option>
                  ) : batchType === 'single_item' ? (
                    <option value={'single_item'}>Single Item</option>
                  ) : (
                    <>
                      <option value={'pick_and_sort'}>Pick &amp; Sort</option>
                      <option value={'fastpick'}>Fast Pick</option>
                    </>
                  )}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="required">
                <Form.Label>Rack Location</Form.Label>

                <Form.Control
                  type="text"
                  value={batchLocation}
                  onChange={(e) => setBatchLocation(e.target.value)}
                  placeholder="Barcode"
                  onKeyDown={handleKeyPressed}
                  ref={inputElement}
                />
              </Form.Group>
            </Row>
            <GlobalStyles.Button type="submit" style={{ minWidth: '100%', margin: '1em 0 0', float: 'right' }}>
              Start
            </GlobalStyles.Button>
          </div>
        </GlobalStyles.FullPageCard>
      </Form>
    </>
  )
}
