import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../../components/component-items/helpers'
import { Link } from 'react-router-dom'

// Components
import CardLoading from '../../../../components/component-items/loading-popover'
import ButtonDropdown from '../../../../components/component-items/button-dropdown'
import GlobalStyles from '../../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { TableCell, THSort } from '../../../../components/component-items/datatable'

import { OrderItemProps } from './types'

const OrderItem = ({
  statusd,
  img,
  p_id,
  sku,
  description,
  barcode,
  reserved,
  ordered,
  packed,
  remaining,
}: OrderItemProps) => (
  <GlobalStyles.TableRow>
    <TableCell center>
      <img src={img} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
    </TableCell>
    <TableCell input={barcode} style={{ userSelect: 'none' }} />
    <TableCell input={<Link to={`/product/variant/${p_id}/`}>{sku}</Link>}>
      <div>{description}</div>
    </TableCell>
    <TableCell
      center
      input={
        <StatusBoxStyled
          style={{ width: 75, margin: 'auto' }}
          className={reserved || statusd >= 200 ? 'silver' : 'red'}
        >
          {reserved}
        </StatusBoxStyled>
      }
    />
    <TableCell
      center
      input={
        <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={'silver'}>
          {ordered}
        </StatusBoxStyled>
      }
    />
    <TableCell
      center
      input={
        <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={packed === ordered ? 'green' : 'yellow'}>
          {packed}
        </StatusBoxStyled>
      }
    />
    <TableCell
      center
      input={
        <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={remaining === 0 ? 'green' : 'yellow'}>
          {remaining}
        </StatusBoxStyled>
      }
    />
  </GlobalStyles.TableRow>
)

export const OrderItemsCard = ({ updateList }: any) => {
  const order = useParams<{ id: string }>().id

  const filterOptions = ['All', 'Remaining', 'Packed']
  const [filter, setFilter] = useState('Remaining')
  const [sort, setSort] = useState({})

  const res: any = useFetch(
    `/api/order/${order}/pack_item_list/?filter=${filter}&order_by=${Object.keys(sort)}&sort=${Object.values(
      sort
    )}&update=${updateList}`,
    {}
  )
  const loaded = res.loaded
  const resp = loaded ? res.response : {}

  const statusd = loaded ? resp.status_id : 100
  const orderItems = loaded ? resp.order_items : []
  const itemTotal = loaded ? resp.item_total : 0

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Order Item List<small>{itemTotal} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <ButtonDropdown title={'Filter'} sort={filter} setSort={setFilter} options={filterOptions} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 100 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th />
              <THSort title={'Barcode'} {...{ sort, setSort }} />
              <THSort title={'SKU'} {...{ sort, setSort }} />
              <THSort title={'Reserved'} center {...{ sort, setSort }} />
              <THSort title={'Ordered'} center {...{ sort, setSort }} />
              <THSort title={'Packed'} center {...{ sort, setSort }} />
              <THSort title={'Remaining'} center {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>
            {orderItems.map((item: any, id: number) => (
              <OrderItem {...item} {...{ statusd }} key={id} />
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
