import React from 'react'
import { Link } from 'react-router-dom'
import { useFetch } from '../../../../components/component-items/helpers'
import CardLoading from '../../../../components/component-items/loading-popover'

import { OrderDetailCardProps, OrderDetailResponseProps } from './types'

// Enums
import { OrderStatus } from '../../../../enums'

export const OrderDetailCard = ({ order, statusId }: OrderDetailCardProps) => {
  const url = `/api/order/${order}/details/`
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})

  const {
    bToB,
    shipBefore,
    shipAfter,
    duplicateOf,
    tags,
    remoteData,
    duplicateOrders,
    shippingMethod,
    addressVerified,
    transferASN,
    totalBilling,
    projectID,
  }: OrderDetailResponseProps = loaded ? resp : {}
  return (
    <div className="order-section">
      <h3>
        {bToB ? 'B2B ' : ''}Order:<span>{order}</span>
      </h3>
      {remoteData?.remoteNumber && (
        <div>
          Remote Number:
          <span>{remoteData.remoteNumber}</span>
        </div>
      )}
      {remoteData?.remoteId && (
        <div>
          Remote ID:
          <span>{remoteData.remoteId}</span>
        </div>
      )}
      {bToB && shipAfter ? (
        <div>
          Ship {shipBefore ? <>between</> : <>After</>}:{' '}
          <span
            style={{ color: '#fd397a', backgroundColor: 'rgba(253,57,122,.1)', padding: '0em 1em', borderRadius: 4 }}
          >
            {shipAfter} {shipBefore && <>-</>} {shipBefore}
          </span>
        </div>
      ) : (
        <div>
          Ship Before:{' '}
          <span
            style={{ color: '#fd397a', backgroundColor: 'rgba(253,57,122,.1)', padding: '0em 1em', borderRadius: 4 }}
          >
            {shipBefore}
          </span>
        </div>
      )}
      {duplicateOf && (
        <div>
          Duplicated From:
          <span>
            <Link to={`/order/${duplicateOf}/`}>{duplicateOf}</Link>
          </span>
        </div>
      )}
      {projectID && (
        <div>
          Project:
          <span>
            <Link to={`/project/${projectID}/`}>{projectID}</Link>
          </span>
        </div>
      )}
      {duplicateOrders?.length ? (
        <div>
          Duplicate Orders:
          <span>
            {duplicateOrders.map((o: any, id: number) => (
              <Link to={`/order/${o.id}/`} key={id}>
                {o.id}{' '}
              </Link>
            ))}
          </span>
        </div>
      ) : null}
      {transferASN && (
        <div>
          Transfer ASN:
          <span>
            <Link to={`/asn/${transferASN}/`}>{transferASN}</Link>
          </span>
        </div>
      )}
      <div>
        Shipping:<span>{shippingMethod}</span>
      </div>
      {tags && (
        <div>
          Order Tags:<span>{tags}</span>
        </div>
      )}
      {statusId < OrderStatus.Shipped && (
        <div>
          Address:
          <span style={addressVerified ? { color: 'green' } : { color: '#795548' }}>
            {addressVerified ? 'Verified' : 'Unverified'}
          </span>
        </div>
      )}
      <div>
        Total Charges:<span>${totalBilling}</span>
      </div>
      {!loaded && <CardLoading text={placeholder} error={error} />}
    </div>
  )
}
