import styled from 'styled-components'

import { DropdownStyled } from '../../../components/component-items/export-tools'
import GlobalStyles from '../../../components/component-items/styles'

export const BackgroundLayer = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
`

export const OrderOptions = styled(BackgroundLayer)`
  flex-direction: column;
  ul {
    display: block;
    padding: 0.5rem 0;
    margin: 10px 0;
    list-style: none;
    border: 0;
  }
  li {
    position: relative;
    a {
      transition: all 0.3s;
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 0.75rem 1rem 0.75rem 1.5rem;
      &:hover {
        background-color: #f7f8fa;
      }
      .icon {
        flex: 0 0 30px;
        font-size: 1.25rem;
        color: #595d6e;
      }
      .text {
        font-weight: 500;
        flex-grow: 1;
        font-size: 1rem;
        color: #595d6e;
      }
    }
  }
`

export const OrderInfo = styled(BackgroundLayer)`
  justify-content: space-between;
  padding: 1em;
  margin-bottom: 0;
  height: 100%;
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 1em;
    color: #4a485d;
    div {
      width: 100%;
    }
    span {
      margin-left: 1rem;
      float: right;
      font-weight: 500;
      max-width: 60%;
      text-align: right;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    p {
      margin-bottom: 0;
    }
  }
  .return-empty {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5em;
    div {
      font-size: 1em;
    }
  }
`

export const Dropdown = styled(DropdownStyled)`
  margin: 0 !important;
  padding: 15px 0 10px !important;
  top: 0;
  left: 105%;
`

export const DateRangeStyled = styled.div`
  overflow: scroll;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em auto;
  width: fit-content;
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0;
  }
`

export const ProductModalStyled = styled.form`
  .scrolltable {
    max-height: 500px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
`

export const BomContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const BomItem = styled.div`
  margin-bottom: 2em;
  padding: 1em;
  width: 200px;
  box-shadow: rgba(67, 56, 93, 0.09) -4px 4px 15px 1px;
  border-radius: 4px;
  text-align: center;
  text-transform: capitalize;
`

export const ProductImage = styled.div`
  background-color: #fefefe;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  button {
    position: absolute;
    margin: 0;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }
  &:hover button,
  &:hover .shade {
    opacity: 1;
  }
  .shade {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.11);
  }
`

export const Tooltip = styled(GlobalStyles.Tooltip)`
  &:before {
    margin: 5px -50%;
    width: 200%;
  }
`
