import React from 'react'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/pro-light-svg-icons'

// Components
import ImageZoom from 'react-medium-image-zoom'
import { LabelStyled } from './styles'

import { LabelProps } from './types'

export const Label = ({ labels, print }: LabelProps) => {
  return (
    <LabelStyled>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>Label Preview</h3>
          {print ? (
            <a href={print}>
              <button>
                <FontAwesomeIcon icon={faPrint} />
              </button>
            </a>
          ) : null}
        </div>
      </div>
      <div className="order-section">
        {labels.length && labels[0].label_image ? (
          <ImageZoom>
            <img src={labels[0].label_image} alt="Shipping Label" className="img" />
          </ImageZoom>
        ) : (
          <div className="no-label">
            <div role="img" aria-label="none">
              🏷
            </div>
            No Label Preview
          </div>
        )}
      </div>
    </LabelStyled>
  )
}
