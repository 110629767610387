import React, { useState, useEffect } from 'react'
import { useFetch, usePut } from '../component-items/helpers'
import { globalState } from '../../store'

// Components
import GlobalStyles from '../component-items/styles'
import { Form, Col, InputGroup, Modal, Row } from 'react-bootstrap'

import { AddressModalProps } from './types'

export const AddressModal = ({ addressURL, address, addressModal, setAddressModal }: AddressModalProps) => {
  const {
    state: { csrf },
  } = globalState()

  const resp: any = useFetch('/api/address/countries/', {})
  const countries: any = resp.loaded ? resp.response.countries : []

  const [validated, setValidated] = useState(false)

  const [addressBusiness, setAddressBusiness] = useState('')
  const [addressName, setAddressName] = useState('')
  const [addressEmail, setAddressEmail] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [addressCity, setAddressCity] = useState('')
  const [addressState, setAddressState] = useState('')
  const [addressZip, setAddressZip] = useState('')
  const [addressCountry, setAddressCountry] = useState('')
  const [addressPhone, setAddressPhone] = useState('')

  useEffect(() => {
    if (address) {
      setAddressBusiness(address.business ? address.business : '')
      setAddressName(address.name ? address.name : '')
      setAddressEmail(address.email ? address.email : '')
      setAddressLine1(address.line1 ? address.line1 : '')
      setAddressLine2(address.line2 ? address.line2 : '')
      setAddressCity(address.city ? address.city : '')
      setAddressState(address.state ? address.state : '')
      setAddressZip(address.zip ? address.zip : '')
      setAddressCountry(address.country ? address.country : '')
      setAddressPhone(address.phone ? address.phone : '')
    }
  }, [address])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement | HTMLButtonElement | HTMLAnchorElement>) => {
    const form: any = event.currentTarget

    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (form?.checkValidity() === false) {
      return
    }

    usePut(
      addressURL,
      {
        ...{
          addressBusiness,
          addressName,
          addressEmail,
          addressLine1,
          addressLine2,
          addressCity,
          addressState,
          addressZip,
          addressCountry,
          addressPhone,
        },
        updateAddress: true,
      },
      csrf
    )
  }
  return (
    <Modal
      show={addressModal}
      onHide={() => setAddressModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Edit Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Full Name"
                value={addressName}
                onChange={(e) => setAddressName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">Please enter the customers full name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  required
                  value={addressEmail}
                  onChange={(e) => setAddressEmail(e.target.value)}
                />
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control.Feedback type="invalid">Please add an email.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address 1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Address.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address 2"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid Address.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>Business</Form.Label>
              <Form.Control
                type="text"
                placeholder="Business"
                value={addressBusiness}
                onChange={(e) => setAddressBusiness(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">Please enter the business name.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                value={addressCity}
                onChange={(e) => setAddressCity(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid city.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                value={addressState}
                onChange={(e) => setAddressState(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a valid state.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="required">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zip"
                value={addressZip}
                onChange={(e) => setAddressZip(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid zip. If there is none, enter 000000
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Country</Form.Label>
              <Form.Select value={addressCountry} onChange={(e) => setAddressCountry(e.target.value)}>
                {Object.keys(countries).map((c, id) => (
                  <option value={c} key={id}>
                    {countries[c]}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid country.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                value={addressPhone}
                onChange={(e) => setAddressPhone(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <GlobalStyles.Button style={{ minWidth: 125 }}>Save</GlobalStyles.Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
