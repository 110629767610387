import React from 'react'
import { useTheme } from 'styled-components'

import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

export const Receiving = () => {
  const theme = useTheme()
  return (
    <GlobalStyles.DataTable>
      <tbody>
        <GlobalStyles.TableRow>
          <TableCell style={{ fontSize: '1.1em', fontWeight: 500, color: theme.colors.comet }}>
            General Receiving
          </TableCell>
          <TableCell
            center
            input={
              <StatusBoxStyled className="blue" style={{ width: 125, margin: 'auto' }}>
                Free
              </StatusBoxStyled>
            }
          />
        </GlobalStyles.TableRow>
        <GlobalStyles.TableRow>
          <TableCell style={{ fontSize: '1.1em', fontWeight: 500, color: theme.colors.comet }}>Labelling</TableCell>
          <TableCell
            center
            input={
              <StatusBoxStyled className="blue" style={{ width: 125, margin: 'auto' }}>
                $0.15 / unit
              </StatusBoxStyled>
            }
          />
        </GlobalStyles.TableRow>
        <GlobalStyles.TableRow>
          <TableCell style={{ fontSize: '1.1em', fontWeight: 500, color: theme.colors.comet }}>
            Container Unloading (20' floor loaded container)
          </TableCell>
          <TableCell
            center
            input={
              <StatusBoxStyled className="blue" style={{ width: 125, margin: 'auto' }}>
                $275 / container
              </StatusBoxStyled>
            }
          />
        </GlobalStyles.TableRow>
        <GlobalStyles.TableRow>
          <TableCell style={{ fontSize: '1.1em', fontWeight: 500, color: theme.colors.comet }}>
            Container Unloading (40' floor loaded container)
          </TableCell>
          <TableCell
            center
            input={
              <StatusBoxStyled className="blue" style={{ width: 125, margin: 'auto' }}>
                $475 / container
              </StatusBoxStyled>
            }
          />
        </GlobalStyles.TableRow>
        <GlobalStyles.TableRow>
          <TableCell style={{ fontSize: '1.1em', fontWeight: 500, color: theme.colors.comet }}>
            Kitting / Special Projects
          </TableCell>
          <TableCell
            center
            input={
              <StatusBoxStyled className="blue" style={{ width: 125, margin: 'auto' }}>
                $45 / labor hour
              </StatusBoxStyled>
            }
          />
        </GlobalStyles.TableRow>
      </tbody>
    </GlobalStyles.DataTable>
  )
}
