import React from 'react'

// Components
import MyResponsiveLine from '../../components/component-items/line-graph'
import CardLoading from '../../components/component-items/loading-popover'
import { useFetch } from '../../components/component-items/helpers'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import { ShippedOrdersListStyled } from './styles'

export const ShippedOrders = ({ url, color }: { url: string; color: string }) => {
  var resp: any = useFetch(url, {}, 30)
  var total = resp.loaded ? resp.response.total : 0
  var defaultData = [
    { x: 0, y: 0 },
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
    { x: 5, y: 0 },
    { x: 6, y: 0 },
    { x: 7, y: 0 },
    { x: 8, y: 0 },
    { x: 9, y: 0 },
    { x: 10, y: 0 },
    { x: 11, y: 0 },
    { x: 12, y: 0 },
  ]
  var graphData = resp.loaded ? resp.response.shipped.map((o: any) => ({ x: o.shipped_Hour, y: o.count })) : defaultData

  var data = [
    {
      id: 'ASNs',
      data: resp.loaded && resp.response.shipped.length > 1 ? graphData : defaultData,
    },
  ]

  return (
    <ShippedOrdersListStyled>
      <div className="widget__head">
        <h3>Orders Shipped</h3>
        <div className="toolbar">
          <button
            onClick={() => {
              window.location.href = '/order/?status=200'
            }}
            type="button"
          >
            <i>
              <FontAwesomeIcon icon={faExternalLink} />
            </i>
          </button>
        </div>
      </div>
      <div className="widget__body">
        <div className="aside">
          <div className="label">{total}</div>
          <img src={'/api/static/assets/media/misc/iconbox_bg.png'} alt="bg" />
        </div>
        <div className="line-graph">
          <div className="line-container">
            <MyResponsiveLine data={data} color={color} pointName={''} yLabel={'Orders '} />
          </div>
        </div>
      </div>
      {!resp.loaded ? <CardLoading text={resp.placeholder} error={resp.error} /> : null}
    </ShippedOrdersListStyled>
  )
}

export default ShippedOrders
