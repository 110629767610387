import styled from 'styled-components'
import { OrderOptions } from '../../orders/order/styles'
import GlobalStyles from '../../../components/component-items/styles'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

export const ProgressList = styled(OrderOptions)`
  background-color: unset;
  box-shadow: unset;
  ul {
    position: relative;
    margin: 0;
    padding: 0;
    &::before {
      content: '';
      position: absolute;
      right: 50%;
      height: 100%;
      border-right: solid 2px grey;
    }
  }
  li {
    background-color: ${(props) => props.theme.colors.white};
    margin: 0 0 1.5em;
    border-radius: 8px;
    &.active a {
      border: 2px solid #000eb7;
      border-radius: 8px;
    }
    &.disabled {
      background-color: #c3c3c3;
      &:hover a {
        background-color: unset;
        cursor: not-allowed;
      }
    }
    &:last-of-type {
      margin: 0;
    }
  }
`
