import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useFetch } from '../../components/component-items/helpers'
import { globalState } from '../../store'

// Components
import Pager, { Pages } from '../../components/component-items/pager'
import ButtonDropdown from '../../components/component-items/button-dropdown'
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import { OrderStatusBox, StatusBoxStyled } from '../../components/component-items/status-box'
import { TableCell, THSort } from '../../components/component-items/datatable'
import { Badge } from 'react-bootstrap'
import { TableRow } from './styles'
import { SearchBox } from '../../components/component-items/search'
import { ExportOrders } from './export'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/pro-duotone-svg-icons'
import { FilterOrders } from './filter'

export const OrderList = () => {
  let history = useHistory()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const {
    state: { userIsWarehouse, actAs },
  } = globalState()

  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 50, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const [search, setSearch] = useState('')
  const [sku, setSku] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [statuses, setStatuses] = useState('')
  const [dateRangeType, setDateRangeType] = useState('')
  const [tags, setTags] = useState('')

  const sortOptions: any = {
    All: '',
    Unfulfilled: 'unfulfilled',
    'In Fulfillment': 'in_fulfillment',
    Fulfilled: 'fulfilled',
  }

  // Assign sort based on url params first
  const [sort, setSort] = useState('All')
  const [sortRow, setSortRow] = useState({})
  const [showFilterModal, setFilterModal] = useState(false)
  const [showExportModal, setExportModal] = useState(false)

  const url =
    '/api/order/order_list/?range=' +
    rows +
    '&page=' +
    (page - 1) +
    '&fulfillment=' +
    sortOptions[sort] +
    '&order_by=' +
    Object.keys(sortRow) +
    '&sort_row=' +
    Object.values(sortRow) +
    '&q=' +
    search +
    '&sku=' +
    sku +
    '&start_date=' +
    startDate +
    '&end_date=' +
    endDate +
    '&filter_status=' +
    statuses +
    '&date_range_type=' +
    dateRangeType +
    '&tags=' +
    tags

  const { response: res, loaded, placeholder, error }: any = useFetch(url, {})

  const body = {
    range: rows,
    page: page - 1,
    fulfillment: sortOptions[sort],
    order_by: Object.keys(sortRow),
    sort_row: Object.values(sortRow),
    q: search,
    sku: sku,
    start_date: startDate,
    end_date: endDate,
    filter_status: statuses,
    date_range_type: dateRangeType,
    tags: tags,
    download: true,
  }

  const [loading, setLoading] = useState(false)
  const [filterCount, setFilterCount] = useState(0)

  useEffect(() => {
    let counter = 0
    if (sort !== 'All') counter++
    if (statuses) counter++
    if (startDate || endDate) counter++
    setFilterCount(counter)
  }, [startDate, endDate, statuses, sort])

  useEffect(() => {
    setLoading(!loaded)
  }, [loaded])

  useEffect(() => {
    const status = urlParams.get('fulfillment')
    setSku(urlParams.get('sku') || '')
    setStartDate(urlParams.get('start_date') || '')
    setEndDate(urlParams.get('end_date') || '')
    setStatuses(urlParams.get('filter_status') || '')
    setDateRangeType(urlParams.get('date_range_type') || '')
    setTags(urlParams.get('tags') || '')
    const index = status ? Object.values(sortOptions).indexOf(status) : 0
    setSort(Object.keys(sortOptions)[index])
    setPage(1)
  }, [location])

  const total = loaded ? res.total : 0
  var totalPages = loaded && total ? Math.ceil(total / rows) : 1

  const customSetSort = (e: any) => {
    const params = new URLSearchParams(history.location.search)
    params.set('fulfillment', sortOptions[e])
    history.push(`/order/?${params.toString()}`)
    setPage(1)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Order List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <ButtonDropdown
            title={'Fulfillment'}
            sort={sort}
            setSort={customSetSort}
            options={Object.keys(sortOptions)}
          />
          <SearchBox
            style={{ marginLeft: '1em' }}
            {...{ search, setSearch, loading, setLoading, page, setPage }}
            setFocus
            updateURL
          />
          <GlobalStyles.Button onClick={() => setFilterModal(true)}>
            Filter {filterCount > 0 && <Badge pill>{filterCount}</Badge>}
          </GlobalStyles.Button>
          <FilterOrders {...{ showFilterModal, setFilterModal, setSort: customSetSort, setPage: setPage }} />
          {!userIsWarehouse || actAs ? (
            <>
              <GlobalStyles.Button
                className="secondary"
                onClick={() => {
                  setExportModal(true)
                }}
              >
                Export As CSV
              </GlobalStyles.Button>
              <ExportOrders {...{ showExportModal, setExportModal, body }} url={url + '&download=true'} />
            </>
          ) : null}
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <THSort center title="Order Number" sortKey={'id'} sort={sortRow} setSort={setSortRow} />
              <THSort center title="Created" sort={sortRow} setSort={setSortRow} />
              <th>
                <span className="text">Address</span>
              </th>
              <THSort title="Shipping" sort={sortRow} setSort={setSortRow} />
              <th>
                <span className="text center">Tags</span>
              </th>
              <th>
                <span className="text center">Order Items</span>
              </th>
              <THSort center title="Status" sort={sortRow} setSort={setSortRow} />
            </tr>
          </thead>
          <tbody>
            {loaded
              ? res.orders.map(
                  (
                    {
                      id,
                      remote_id,
                      name,
                      address,
                      shipping,
                      email,
                      created,
                      items,
                      status,
                      status_id,
                      b2b,
                      hot,
                      edi,
                    }: any,
                    k_id: number
                  ) => (
                    <TableRow key={k_id}>
                      <TableCell
                        center
                        input={
                          <Link to={`/order/${id}/`} style={{ maxWidth: 150 }}>
                            {id}
                          </Link>
                        }
                      >
                        {remote_id ? <div>({remote_id})</div> : null}
                      </TableCell>
                      <TableCell center input={<div style={{ minWidth: 80 }}>{created.date}</div>}>
                        <div>{created.time}</div>
                      </TableCell>
                      <TableCell input={name}>
                        {address ? (
                          <>
                            <div>
                              {address.line1}
                              {address.line2 ? ' - ' + address.line2 : ''} {address.state} {address.zip}{' '}
                              {address.country_code}
                            </div>
                            <div>
                              {email} | {address.phone}
                            </div>
                          </>
                        ) : null}
                      </TableCell>
                      <TableCell input={shipping.method}>
                        {
                          <>
                            <div>{shipping.tracking_method}</div>
                            <div>
                              <a href={shipping.tracking_url} rel="noreferrer" target="__blank">
                                {shipping.tracking}
                              </a>
                            </div>
                          </>
                        }
                      </TableCell>
                      <TableCell center>
                        {b2b && (
                          <StatusBoxStyled style={{ width: 75, margin: '0.5em auto' }} className={'purple bold'}>
                            B2B
                          </StatusBoxStyled>
                        )}
                        {hot && (
                          <StatusBoxStyled style={{ width: 75, margin: '0.5em auto' }} className={'red bold'}>
                            <FontAwesomeIcon icon={faFire} /> HOT
                          </StatusBoxStyled>
                        )}
                        {edi && (
                          <StatusBoxStyled style={{ width: 75, margin: '0.5em auto' }} className={'blue bold'}>
                            EDI
                          </StatusBoxStyled>
                        )}
                      </TableCell>
                      <TableCell>
                        {items.length ? (
                          <div
                            style={{
                              maxHeight: 200,
                              overflow: 'scroll',
                              boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px',
                            }}
                          >
                            <GlobalStyles.DataTable>
                              <tbody>
                                {items.map(({ id, img, name, sku, qty }: any) => (
                                  <TableRow key={id}>
                                    <TableCell
                                      center
                                      input={<img src={img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />}
                                    />
                                    <TableCell input={<Link to={`/product/variant/${id}/`}>{sku}</Link>}>
                                      <div>{name}</div>
                                    </TableCell>
                                    <TableCell input={qty} center />
                                  </TableRow>
                                ))}
                              </tbody>
                            </GlobalStyles.DataTable>
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell
                        center
                        input={<OrderStatusBox style={{ width: 132, margin: 'auto' }} {...{ status, status_id }} />}
                      />
                    </TableRow>
                  )
                )
              : null}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
