import React, { useState } from 'react'
import { usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import { DragNDropMany, DragNDrop } from '../../../components/component-items/dragndrop'

const uploadFile = (files: any, url: string, csrf: string, name?: string) => {
  var formData = new window.FormData()
  // Check there is a file first
  if (files) {
    if (!name) {
      files.map((file: any) => formData.append(file.name, file))
    } else {
      formData.append(name, files)
    }
  } else {
    return
  }
  usePut(url, formData, csrf, true, false)
}

export const UploadProductModal = ({ open, setOpen, order }: { open: boolean; setOpen: any; order: string }) => {
  const [file, setFile] = useState(null)
  const {
    state: { csrf },
  } = globalState()
  return (
    <GenericModal
      heading={'Upload Order Items'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => uploadFile(file, `/api/order/${order}/upload_products/`, csrf, 'orderItems')}
        >
          Upload
        </GlobalStyles.Button>
      }
    >
      <div style={{ justifyContent: 'center', margin: 'auto', padding: '1em' }}>
        <Form.Label style={{ marginTop: '1em' }}>
          CSV File Upload (<a href="/api/static/templates/order-import-template.csv">Download Template</a>)
        </Form.Label>
        <DragNDrop {...{ file, setFile }} fileTypes={'.csv'} />
      </div>
    </GenericModal>
  )
}

export const UploadLabelModal = ({ open, setOpen, order }: { open: boolean; setOpen: any; order: string }) => {
  const [files, setFiles] = useState<File[]>([])
  const {
    state: { csrf },
  } = globalState()
  return (
    <GenericModal
      heading={'Upload Labels'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => uploadFile(files, `/api/order/${order}/upload_labels/`, csrf)}
        >
          Upload
        </GlobalStyles.Button>
      }
    >
      <div style={{ justifyContent: 'center', margin: 'auto', padding: '1em' }}>
        <DragNDropMany {...{ files, setFiles }} />
      </div>
    </GenericModal>
  )
}

export const UploadDocumentModal = ({ open, setOpen, order }: { open: boolean; setOpen: any; order: string }) => {
  const [files, setFiles] = useState<File[]>([])
  const {
    state: { csrf },
  } = globalState()
  return (
    <GenericModal
      heading={'Upload Documents'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => uploadFile(files, `/api/order/${order}/upload_documents/`, csrf)}
        >
          Upload
        </GlobalStyles.Button>
      }
    >
      <div style={{ justifyContent: 'center', margin: 'auto', padding: '1em' }}>
        <DragNDropMany {...{ files, setFiles }} />
      </div>
    </GenericModal>
  )
}
