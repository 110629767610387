import styled from 'styled-components'

import { DropdownStyled } from '../../../../components/component-items/export-tools'
import { BackgroundLayer } from '../../../../components/component-items/status-bar'

export const OrderOptions = styled(BackgroundLayer)`
  flex-direction: column;
  ul {
    display: block;
    padding: 0.5rem 0;
    margin: 10px 0;
    list-style: none;
    border: 0;
  }
  li {
    position: relative;
    a {
      transition: all 0.3s;
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 0.75rem 1rem 0.75rem 1.5rem;
      &:hover {
        background-color: #f7f8fa;
        .icon,
        .text {
          color: #646c9a;
        }
      }
      .icon {
        flex: 0 0 30px;
        font-size: 1.25rem;
        color: #595d6e;
      }
      .text {
        font-weight: 500;
        flex-grow: 1;
        font-size: 1rem;
        color: #595d6e;
      }
    }
  }
`

export const OrderInfo = styled(BackgroundLayer)`
  justify-content: space-between;
  padding: 1em;
  margin-bottom: 0;
  height: 100%;
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 1em;
    color: #4a485d;
    div {
      width: 100%;
    }
    span {
      margin-left: 1rem;
      float: right;
      font-weight: 500;
      max-width: 60%;
      text-align: right;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    p {
      margin-bottom: 0;
    }
  }
  button {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: center;
    border-radius: 0.2rem;
    color: #595d6e;
    font-size: 1.25rem;
    &:hover {
      background: ${(props) => props.theme.colors.zircon};
      svg {
        color: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
  .return-empty {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5em;
    div {
      font-size: 1em;
    }
  }
`

export const Dropdown = styled(DropdownStyled)`
  margin: 0 !important;
  padding: 15px 0 10px !important;
  top: 0;
  left: 105%;
`
