import React, { useEffect, useState } from 'react'
import { useFetch, usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-light-svg-icons'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'
import { Form, InputGroup } from 'react-bootstrap'
import { BillingCellStyled, DataTable, BillingStyled, BillingRowStyled } from './styles'

import { BillingModalProps, BillingCellProps } from './types'

const BillingCell = ({ style, input, children, center, rowspan }: BillingCellProps) => (
  <BillingCellStyled style={style} rowSpan={rowspan}>
    <div style={{ textAlign: center ? 'center' : 'left', minWidth: '20px' }}>
      <span className="input">{input}</span>
      {children}
    </div>
  </BillingCellStyled>
)

const BillingModal = ({
  billingModal,
  setBillingModal,
  feeTypes,
  bypassProcessing,
  bypassPicking,
}: BillingModalProps) => {
  const {
    state: { csrf },
  } = globalState()

  const order: any = window.location.pathname
    .split('/order/')
    .filter((x) => x)[0]
    .split('/')[0]
  const [skipProcessing, setSkipProcessing] = useState(false)
  const [skipPicking, setSkipPicking] = useState(false)
  const [fees, setFees] = useState<any>({})
  useEffect(() => {
    const newFees: any = {}
    feeTypes.map((f: any) => {
      newFees[f.id] = f.quantity
    })
    setFees(newFees)
    setSkipProcessing(bypassProcessing)
    setSkipPicking(bypassPicking)
  }, [billingModal])

  const buttons = (
    <>
      <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setBillingModal(false)}>
        Close
      </GlobalStyles.Button>
      <GlobalStyles.Button
        className="primary"
        style={{ minWidth: 125 }}
        onClick={() =>
          usePut(
            `/api/order/${order}/billing/`,
            {
              ...fees,
              bypassProcessing: skipProcessing,
              bypassPicking: skipPicking,
              manualBilling: true,
            },
            csrf
          )
        }
      >
        Update
      </GlobalStyles.Button>
    </>
  )

  return (
    <GenericModal
      heading={'Manual Billing'}
      show={billingModal}
      onHide={() => setBillingModal(false)}
      buttons={buttons}
    >
      <DataTable>
        <thead>
          <tr>
            <th>
              <span className="text">Fee Type</span>
            </th>
            <th>
              <span className="text">Per Unit Fee</span>
            </th>
            <th>
              <span className="text">Units</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {feeTypes?.map((f: any, id: number) => (
            <BillingRowStyled key={id}>
              <BillingCell input={f.name} />
              <BillingCell input={'$' + f.perUnitFee} />
              <BillingCell
                input={
                  <Form.Control
                    type="number"
                    value={fees[f.id] ? fees[f.id] : null}
                    style={{ width: '100px' }}
                    onChange={(e) => setFees({ ...fees, [f.id]: e.target.value })}
                  />
                }
              />
            </BillingRowStyled>
          ))}
        </tbody>
      </DataTable>
      <Row style={{ margin: '2em 0 1em ' }}>
        <Col>
          <InputGroup style={{ justifyContent: 'center' }}>
            <Checkbox selected={skipProcessing} setSelected={() => setSkipProcessing(!skipProcessing)} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Bypass Processing Fees</p>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup style={{ justifyContent: 'center' }}>
            <Checkbox selected={skipPicking} setSelected={() => setSkipPicking(!skipPicking)} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Bypass Picking Fees</p>
          </InputGroup>
        </Col>
      </Row>
    </GenericModal>
  )
}

export const Billing = () => {
  const {
    state: { userIsWarehouse },
  } = globalState()
  const order = useParams<{ id: string }>().id

  const url = `/api/order/${order}/billing/`
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})

  const warehouse = userIsWarehouse
  const data = loaded ? resp.orderBilling : []
  const total = loaded ? resp.totalBilling : '0.00'
  const feeTypes = loaded ? resp.manualBilling : []
  const bypassProcessing = loaded ? resp.bypassProcessing : false
  const bypassPicking = loaded ? resp.bypassPicking : false

  const [billingModal, setBillingModal] = useState(false)
  return (
    <BillingStyled>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>Billing</h3>
          {warehouse ? ( // Only show modal for warehouse users
            <>
              <button onClick={() => setBillingModal(true)}>
                <FontAwesomeIcon icon={faPen} />
              </button>
              <BillingModal {...{ billingModal, setBillingModal, feeTypes, bypassPicking, bypassProcessing }} />
            </>
          ) : null}
        </div>
      </div>
      <div className="order-section">
        {data
          ? Object.keys(data).map((b, id) => (
              <div className="order-item" key={id}>
                <div>{b}</div>
                <div>${data[b]}</div>
              </div>
            ))
          : null}
        <hr />
        <div className="order-item">
          <div>Total:</div>
          <div>${total}</div>
        </div>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BillingStyled>
  )
}
