import React, { useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { store } from '../../../../store'

// Components
import { ProgressList } from '../styles'

// Keyboard Shortcuts
import { connectShortcuts, handleOrderPermissions } from '../../../../keyboard-shortcuts'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBoxAlt,
  faBarcodeRead,
  faRulerCombined,
  faPrint,
  faShippingFast,
  faChevronRight,
  faBoxCheck,
  faTruckContainer,
} from '@fortawesome/pro-duotone-svg-icons'

import { MenuOptionProps, ShippingWorkflowProps } from './types'

const MenuOption = ({
  url,
  onClick,
  icon,
  title,
  arrow,
  children,
  node,
  active,
  enabled,
  complete,
  id,
}: MenuOptionProps) => {
  return (
    <li
      id={id}
      ref={node}
      onClick={onClick}
      className={active ? 'active' : !enabled ? 'disabled' : complete ? 'complete' : ''}
    >
      <Link to={url && enabled ? url : '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </Link>
      {children}
    </li>
  )
}

export const ShippingWorkflow = ({
  current,
  nextOrder,
  fully_packed,
  needs_serial_numbers,
  serial_number_enabled,
  needs_dimensions,
  labeled,
  pending,
  freight,
  customer_pickup,
}: ShippingWorkflowProps) => {
  const globalState = useContext(store)
  const {
    state: { userIsWarehouse },
  } = globalState

  const history = useHistory()
  const order = useParams<{ id: string }>().id
  const url = `/order/${order}`

  // Keyboard Shortcuts:
  // Attaches listener on order page for keyboard shortcuts
  // such as: Reset Packing, Add Package, Confirm Dimensions, etc
  useEffect(() => {
    if (userIsWarehouse) {
      const handleKeyDown = (event: any) => {
        handleOrderPermissions(order, fully_packed, needs_dimensions, labeled, history)
        connectShortcuts(event, order, 'order', history)
      }
      document.addEventListener('keydown', handleKeyDown)
      return () => document.removeEventListener('keydown', handleKeyDown)
    }
  }, [fully_packed, needs_dimensions, labeled])

  return (
    <ProgressList>
      <ul>
        <MenuOption url={url + '/pack/'} active={current === 'pack'} enabled icon={faBoxAlt} title={'Pack Order'} />
        {serial_number_enabled ? (
          <MenuOption
            url={url + '/serial-numbers/'}
            active={current === 'serial_numbers'}
            icon={faBarcodeRead}
            enabled={fully_packed}
            title={'Add Serial Number'}
          />
        ) : null}
        <MenuOption
          url={url + '/confirm-dimensions/'}
          active={current === 'confirm_dimensions'}
          icon={faRulerCombined}
          enabled={fully_packed && !needs_serial_numbers}
          title={'Confirm Dimensions'}
        />

        {!freight && !customer_pickup && (
          <MenuOption
            url={url + '/label-purchase/'}
            active={current === 'label_purchase'}
            icon={faPrint}
            enabled={fully_packed && !needs_serial_numbers && !needs_dimensions}
            title={'Purchase Label'}
          />
        )}

        {freight && (
          <MenuOption
            url={url + '/freight/'}
            active={current === 'freight'}
            icon={faTruckContainer}
            enabled={fully_packed && !needs_serial_numbers && !needs_dimensions}
            title={'Add Freight'}
          />
        )}

        {nextOrder ? (
          <MenuOption
            id="ship-next-order"
            onClick={() => history.push(`/order/${nextOrder}/pack/`)}
            url={`/order/${nextOrder}/pack/`}
            icon={faShippingFast}
            enabled={fully_packed && (freight || labeled || pending || customer_pickup)}
            title={'Ship Next Order'}
          />
        ) : (
          <MenuOption
            id="ship-next-order"
            onClick={() => history.push('/batch/')}
            url={'/batch/'}
            icon={faBoxCheck}
            enabled={fully_packed && (freight || labeled || pending || customer_pickup)}
            title={'Complete'}
          />
        )}
      </ul>
    </ProgressList>
  )
}
