import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useFetch } from '../components/component-items/helpers'

// Components
import Pager, { Pages } from '../components/component-items/pager'
import CardLoading from '../components/component-items/loading-popover'
import GlobalStyles from '../components/component-items/styles'
import { TableCell, THSort } from '../components/component-items/datatable'
import { SearchBox } from '../components/component-items/search'
import ButtonDropdown from '../components/component-items/button-dropdown'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/pro-light-svg-icons'

const LogoStyled = styled.i`
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  img {
    max-width: 32px;
    max-height: 32px;
    width: 100%;
    align-self: center;
    height: auto;
  }
`

const CustomerRow = ({
  id,
  img,
  customer,
  email,
  balance,
  created,
}: {
  id: any
  img: any
  customer: any
  email: any
  balance: any
  created: any
}) => {
  return (
    <GlobalStyles.TableRow>
      <TableCell
        input={
          <LogoStyled>{img ? <img src={img} alt="carrier_logo" /> : <FontAwesomeIcon icon={faBuilding} />}</LogoStyled>
        }
      />
      <TableCell input={customer}>
        <div>Customer Since {created}</div>
      </TableCell>
      <TableCell input={<a href={'mailto:' + email}>{email}</a>} />
      <TableCell>
        <span className="input" style={balance < 0 ? { color: 'red' } : {}}>
          ${balance.toLocaleString()}
        </span>
      </TableCell>
      <TableCell
        center
        input={
          <a href={'/api/act-as?company=' + id + '&' + window.location.search.substr(1)}>
            <GlobalStyles.Button className="secondary" onClick={() => null} style={{ minWidth: 125 }}>
              Act As Customer
            </GlobalStyles.Button>
          </a>
        }
      />
    </GlobalStyles.TableRow>
  )
}

export const Companies = () => {
  const [page, setPage] = useState(1)
  const tableRowOptions = [100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])

  const [sort, setSort] = useState({})
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const options = ['Active', 'Inactive']
  const [activity, setActivity] = useState('Active')

  const url = `/api/company/?balance=true&range=${rows}&page=${page - 1}&order_by=${Object.keys(
    sort
  )}&sort=${Object.values(sort)}&activity=${activity}&q=${encodeURIComponent(search)}`
  const { response: res, error, loaded, placeholder }: any = useFetch(url, {})
  console.log(res)

  useEffect(() => {
    setLoading(!loaded)
  }, [loaded])

  const companies = loaded ? res : []
  const total = loaded ? res.length : 0
  const totalPages = loaded && total ? Math.ceil(total / rows) : 1

  return (
    <GlobalStyles.FullPageCard style={{ minHeight: 500 }}>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Customer List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <ButtonDropdown sort={activity} setSort={setActivity} options={options} height={40} />
          <SearchBox
            {...{ search, setSearch, loading, setLoading, page, setPage }}
            style={{ marginLeft: '.5em' }}
            updateURL
          />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <GlobalStyles.DataTable>
        <thead>
          <tr>
            <th>
              <span className="text"></span>
            </th>
            <THSort title={'Customer'} sortKey={'name'} {...{ sort, setSort }} />
            <THSort title={'Billing Email'} sortKey={'admin__email'} {...{ sort, setSort }} />
            <THSort title={'Balance'} {...{ sort, setSort }} />
            <th>
              <span className="text center">Act As Customer</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {companies.map((c: any, id: number) => (
            <CustomerRow
              id={c.id}
              img={c.logo}
              customer={c.name}
              email={c.billingEmail}
              balance={c.balance ? Number(c.balance) : 0.0}
              created={c.created}
              key={id}
            />
          ))}
        </tbody>
      </GlobalStyles.DataTable>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
