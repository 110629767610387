import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ToggleButtonProps } from './types'

const Toggle = styled.div`
  /* font-family: Arial, Helvetica, sans-serif; */
  height: 30px;
  display: flex;
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .button {
    position: relative;
    top: 20px;
    width: 80px;
    height: 30px;
    margin: -20px auto 0 auto;
    overflow: hidden;
  }
  .button.toggle-button {
    border-radius: 2px;
  }

  .checkbox {
    position: relative;
    width: 80px;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: #5d78ff19;
    transition: 0.3s ease all;
    z-index: 1;
  }

  /* Button 10 */
  .toggle-button .knobs:before,
  .toggle-button .knobs:after,
  .toggle-button .knobs span {
    position: absolute;
    top: 3px;
    width: 34px;
    height: 23px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 0.5;
    padding: 9px 4px;
    border-radius: 2px;
    transition: 0.3s ease all;
  }

  .toggle-button .knobs:before {
    content: '';
    left: 4px;
    background-color: #34c9b7;
  }

  .toggle-button .knobs:after {
    content: 'False';
    right: 4px;
    color: #4e4e4e;
  }

  .toggle-button .knobs span {
    display: inline-block;
    left: 4px;
    color: #fff;
    z-index: 1;
  }

  .toggle-button .checkbox:checked + .knobs span {
    color: #4e4e4e;
  }

  .toggle-button .checkbox:checked + .knobs:before {
    left: 42px;
    background-color: #001682;
  }

  .toggle-button .checkbox:checked + .knobs:after {
    color: #fff;
  }

  .toggle-button .checkbox:checked ~ .layer {
    background-color: #5d78ff19;
  }
`

export const ToggleButton: FunctionComponent<ToggleButtonProps> = ({ toggle, setToggle }) => (
  <Toggle>
    <div className="button toggle-button">
      <input type="checkbox" className="checkbox" checked={!toggle} onChange={() => setToggle(!toggle)} />
      <div className="knobs">
        <span>True</span>
      </div>
      <div className="layer"></div>
    </div>
  </Toggle>
)
