import React, { useState, useEffect } from 'react'
import { useFetch } from '../../components/component-items/helpers'
import { Link } from 'react-router-dom'
import { globalState } from '../../store'

// Components
import Pager, { Pages } from '../../components/component-items/pager'
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import { OrderStatusBox } from '../../components/component-items/status-box'
import { TableCell, THSort } from '../../components/component-items/datatable'
import { TableRow } from './styles'
import { SearchBox } from '../../components/component-items/search'

import { PackageRowProps } from './types'

const PackageRow = ({ id, sku, img, title, name, avail, inbound, on_hand, reserved, defective }: PackageRowProps) => {
  return (
    <TableRow>
      <TableCell center>
        <img src={img} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
      </TableCell>
      <TableCell input={<Link to={`/product/variant/${id}/`}>{name}</Link>}>
        <div>{title}</div>
      </TableCell>
      <TableCell input={sku} />
      <TableCell center input={on_hand} />
      <TableCell center input={defective} />
      <TableCell center input={reserved} />
      <TableCell
        center
        input={<OrderStatusBox style={{ width: 100, margin: 'auto' }} status={avail} status_id={avail > 0 ? 200 : 0} />}
      />
      <TableCell center input={inbound} />
    </TableRow>
  )
}

export const PackageList = () => {
  const {
    state: { userIsWarehouse },
  } = globalState()
  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 50, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])

  // Assign sort based on url params first
  const [sort, setSort] = useState({})
  const [search, setSearch] = useState('')

  const [loading, setLoading] = useState(false)

  const url =
    '/api/product/packaging/?range=' +
    rows +
    '&page=' +
    (page - 1) +
    '&order_by=' +
    Object.keys(sort) +
    '&sort=' +
    Object.values(sort) +
    '&q=' +
    search
  const res: any = useFetch(url, {})

  useEffect(() => {
    setLoading(!res.loaded)
  }, [res.loaded])

  const total = res.loaded ? res.response.total : 0
  var totalPages = res.loaded && total ? Math.ceil(total / rows) : 1
  const packages = res.loaded ? res.response.packages : []

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Package List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <Link to="/asn/new/">
            <GlobalStyles.Button className="secondary">Create Packaging ASN</GlobalStyles.Button>
          </Link>
          {userIsWarehouse ? (
            <a href="/package/new/">
              <GlobalStyles.Button>Create Packaging Item</GlobalStyles.Button>
            </a>
          ) : null}
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus updateURL />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th />
              <THSort title={'Package'} {...{ sort, setSort }} />
              <THSort title={'SKU'} {...{ sort, setSort }} />
              <THSort title={'On Hand'} center {...{ sort, setSort }} />
              <THSort title={'Defective'} sortKey={'quantity_defective'} center {...{ sort, setSort }} />
              <THSort title={'Reserved'} sortKey={'quantity_reserved'} center {...{ sort, setSort }} />
              <THSort title={'Available'} sortKey={'quantity_available'} center {...{ sort, setSort }} />
              <THSort title={'Inbound'} sortKey={'quantity_inbound'} center {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>
            {packages.map((o: any, id: number) => (
              <PackageRow {...o} key={id} />
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
