import React, { useState } from 'react'
import { Form, Col, Container, Row } from 'react-bootstrap'
import { useTheme } from 'styled-components'

import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { StatusBarStyled } from '../../../components/component-items/status-bar'
import { HoverNotes } from '../../../components/component-items/hover-notes'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoSquare } from '@fortawesome/pro-duotone-svg-icons'

export const Processing = () => {
  const theme = useTheme()

  const data_points = {
    '1 - 20': ['$2.40', '$2.25', '$2.10', '$1.95', '$1.75', '$1.60'],
    '21 - 40': ['$2.55', '$2.40', '$2.25', '$2.10', '$1.85', '$1.70'],
    '41 - 80': ['$2.70', '$2.55', '$2.40', '$2.25', '$2.10', '$1.90'],
    '81 - 100': ['$2.85', '$2.70', '$2.55', '$2.40', '$2.25', '$2.00'],
  }
  const skusList = Object.keys(data_points).sort((a: any, b: any) => a.split()[0] - b.split()[0])

  const [skus, setSkus] = useState(skusList[0])
  const [orders, setOrders] = useState(5)

  return (
    <Container>
      <Row style={{ minWidth: 800 }}>
        <StatusBarStyled style={{ backgroundColor: theme.colors.ghostWhite }}>
          <div className="header-item">
            <div className="status-icon">
              <FontAwesomeIcon icon={faInfoSquare} style={{ color: '#800080' }} />
            </div>
            <div className="status-text">
              <strong style={{ fontWeight: 500 }}>Note</strong>: Pricing reductions are made after 3 months of
              consistent breaks in volume tiers. SKU count is based on saleable products and promotional items upon
              receiving.
            </div>
          </div>
        </StatusBarStyled>
        <Form.Group as={Col} md="6">
          <Form.Label>Number of SKUs</Form.Label>
          <Form.Select value={skus} onChange={(e) => setSkus(e.target.value)}>
            {skusList.map((sku) => (
              <option value={sku} key={sku}>
                {sku}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label>Number of Orders</Form.Label>
          <Form.Select value={orders} onChange={(e: any) => setOrders(e.target.value)}>
            <option value="0">&lt; 500</option>
            <option value="1">500 - 1,000</option>
            <option value="2">1,000 - 2,000</option>
            <option value="3">2,000 - 5,000</option>
            <option value="4">5,000 - 10,000</option>
            <option value="5">10,000 +</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <GlobalStyles.DataTable style={{ margin: '3em auto 5em', width: 500 }}>
        <tbody>
          <GlobalStyles.TableRow>
            <TableCell style={{ fontSize: '1.1em', fontWeight: 500, color: theme.colors.comet }}>
              <HoverNotes
                description="Does not include multi-picks or casepacks"
                title="Processing Fee (first item on an order)"
              />
            </TableCell>
            <TableCell
              center
              input={
                <StatusBoxStyled className="blue" style={{ width: 125, margin: 'auto' }}>
                  {data_points[skus as keyof typeof data_points][parseInt(String(orders))]}
                </StatusBoxStyled>
              }
            />
          </GlobalStyles.TableRow>
        </tbody>
      </GlobalStyles.DataTable>
    </Container>
  )
}
