import React, { useState, useEffect, useRef } from 'react'
import { usePost, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcodeRead } from '@fortawesome/pro-duotone-svg-icons'
import { faQrcode } from '@fortawesome/pro-regular-svg-icons'

export const MoveInventoryPage = () => {
  const [validated, setValidated] = useState(false)
  const inputRef: any = useRef()
  const [QRCode, setQRCode] = useState('')
  const [location, setLocation] = useState('')
  const {
    state: { csrf },
  } = globalState()

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    if (inputRef?.current?.checkValidity() === false) {
      if (QRCode && !location) {
        locationRef?.current?.focus()
        locationRef?.current?.select()
      }
      return
    }
    setValidated(true)
    const reload = false
    const stringify = true
    const sendResponse = false
    await usePost(
      `/api/inventory/inventory-item/move_location/`,
      { move_to: location, qr_code: QRCode },
      csrf,
      reload,
      stringify,
      sendResponse
    ).then((response) => {
      if (!response.error) {
        notify({ type: 'success', message: response.success })
        setQRCode('')
        setLocation('')
        setValidated(false)
        itemRef?.current?.focus()
        itemRef?.current?.select()
      }
    })
  }

  // Add cursor to input field on render
  const itemRef = useRef<HTMLInputElement>(null)
  const locationRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    itemRef?.current?.focus()
    itemRef?.current?.select()
  }, [])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>Move Inventory</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>

      <Form
        style={{ width: 500, maxWidth: 800, minHeight: 110, margin: '2em auto' }}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        ref={inputRef}
      >
        <Form.Group className="required">
          <Form.Label>Inventory Barcode</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="Inventory Barcode"
              value={QRCode}
              maxLength={100}
              onChange={(e) => setQRCode(e.target.value)}
              ref={itemRef}
            />
            <InputGroup.Text
              id="inputGroupPrepend"
              style={{ width: 40, display: 'flex', justifyContent: 'center', borderRadius: '0 5px 5px 0' }}
            >
              <FontAwesomeIcon icon={faQrcode} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              Please scan an inventory item's QR code to proceed
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="required">
          <Form.Label style={{ marginTop: '2em' }}>Destination Location</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="Location"
              maxLength={100}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              ref={locationRef}
            />
            <InputGroup.Text
              id="inputGroupPrepend"
              style={{ width: 40, display: 'flex', justifyContent: 'center', borderRadius: '0 5px 5px 0' }}
            >
              <FontAwesomeIcon icon={faBarcodeRead} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please add a destination location</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <GlobalStyles.Button
          className="royal"
          style={{ marginTop: '2em', float: 'right', minWidth: 125 }}
          onSubmit={() => handleSubmit}
        >
          Submit
        </GlobalStyles.Button>
      </Form>
    </GlobalStyles.FullPageCard>
  )
}
