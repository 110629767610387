import React from 'react'
import { ResponsiveLine } from '@nivo/line'

import { MyResponsiveLineProps } from './types'

function formatTime(hour: number) {
  if (hour < 12) {
    return hour + 'AM'
  }
  if (hour == 12) {
    return hour + 'PM'
  }
  hour = hour - 12
  return hour + 'PM'
}

const MyResponsiveLine = ({ data, color, custom, pointName, yLabel }: MyResponsiveLineProps) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 10 }}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: 0, max: 'auto' }}
    curve="natural"
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={null}
    enableGridX={false}
    enableGridY={false}
    colors={color ? color : '#636B9E'}
    enablePoints={true}
    pointSize={3.5}
    pointColor={{ from: 'color', modifiers: [] }}
    pointBorderColor={{ from: 'serieColor', modifiers: [] }}
    pointLabel="y"
    pointLabelYOffset={-24}
    enableArea={true}
    areaOpacity={0.1}
    useMesh={true}
    legends={[]}
    motionStiffness={160}
    tooltip={(input: any) => {
      return (
        <div>
          {pointName} {formatTime(input?.point?.data?.x)} - {yLabel}
          {input?.point?.data?.y}
        </div>
      )
    }}
    {...custom}
  />
)

export default MyResponsiveLine
