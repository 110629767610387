import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { usePost, notify } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'

// Components
import ProgressBar from 'react-bootstrap/ProgressBar'
import { OrderOptions } from '../../../orders/order/styles'
import { ShippingWorkflow } from '../shipping-workflow'
import GlobalStyles from '../../../../components/component-items/styles'
import CardLoading from '../../../../components/component-items/loading-popover'
import { TableCell } from '../../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'

// Cards
import { StatusBar } from '../status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faPrint } from '@fortawesome/pro-duotone-svg-icons'

import { OrderOptionProps } from './types'

const OrderOption = ({
  url,
  onClick,
  icon,
  title,
  arrow,
  children,
  node,
  active,
  disabled,
  complete,
}: OrderOptionProps) => {
  return (
    <li ref={node} onClick={onClick} className={active ? 'active' : disabled ? 'disabled' : complete ? 'complete' : ''}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max))
}

export const LabelPurchase = () => {
  const order = useParams<{ id: string }>().id
  const [error, setError] = useState(false)
  const [message, setMessage] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const {
    state: { csrf },
  } = globalState()

  useEffect(() => {
    async function fetchdata() {
      const result = (await usePost(`/api/postage/purchase-label/${order}/`, {}, csrf, false, false, true, false)) || {}
      if (result.error) {
        setError(result.error)
        setPackStatus(result.pack_status)
        setProgress(100)
      } else if (result.message) {
        setPackStatus(result.pack_status)
        setRates(result.rates)
        setMessage(result.message)
        setProgress(100)
      } else if (result.freight) {
        notify({
          type: 'error',
          message: "Please enter freight details on the order page - under 'Add Freight'",
        })
        window.location.href = `/order/${order}/`
      } else if (result.rates) {
        setRates(result.rates)
        setPackStatus(result.pack_status)
        setProgress(100)
        if (result.pack_status.labeled) {
          window.location.href = `/api/order/${order}/print_labels/`
        }
      }
      setLoaded(true)
    }
    if (csrf) {
      fetchdata()
    }
  }, [csrf])

  const [progress, setProgress] = useState(5)
  const [rates, setRates] = useState([])
  const [packStatus, setPackStatus] = useState({
    fully_packed: false,
    needs_serial_numbers: false,
    serial_number_enabled: false,
    needs_dimensions: false,
    customer_pickup: false,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((progress) => (progress < 79 ? progress + getRandomInt(4) * 4 : progress))
    }, 800)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div className="row">
        <div className="col">
          <StatusBar />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-xl-2">
          <OrderOptions>
            <ul>
              <GlobalStyles.Tooltip data-title="Downloads shipping labels">
                <OrderOption url={`/api/order/${order}/print_labels/`} icon={faPrint} title={'Print Labels'} />
              </GlobalStyles.Tooltip>
            </ul>
          </OrderOptions>
        </div>
        <div className="col-lg-7 col-xl-8">
          <div className="row">
            <div className="col-xl-12">
              <div className="progress_bar" style={{ padding: '0 0 1em' }}>
                <div className="label" style={{ textAlign: 'center', fontWeight: 400, paddingBottom: '0.5em' }}>
                  Label {error ? 'FAILED' : progress === 100 ? 'Purchased' : 'Purchase in Progress'} {progress}%
                </div>
                <ProgressBar striped animated now={progress} variant={error ? 'danger' : loaded ? 'success' : ''} />
              </div>
            </div>
            <div className="col-xl-12">
              <GlobalStyles.FullPageCard>
                <GlobalStyles.CardHeader>
                  <GlobalStyles.CardTitle>
                    <h3>
                      Rates<small>{rates.length} Total</small>
                    </h3>
                  </GlobalStyles.CardTitle>
                </GlobalStyles.CardHeader>
                <div style={{ minHeight: 200, padding: '1em' }}>
                  {error ? <StatusBoxStyled className={'red'}>{error}</StatusBoxStyled> : null}
                  {message ? <StatusBoxStyled className={'blue'}>{message}</StatusBoxStyled> : null}
                  <GlobalStyles.DataTable>
                    <thead>
                      <tr>
                        <th>
                          <span className="text"></span>
                        </th>
                        <th>
                          <span className="text">Service Level</span>
                        </th>
                        <th>
                          <span className="text center">Cost</span>
                        </th>
                        <th>
                          <span className="text center">Tracking</span>
                        </th>
                        <th>
                          <span className="text center">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rates.map(({ id, img, carrier, service, cost, tracking_url, tracking_number }) => (
                        <GlobalStyles.TableRow key={id}>
                          <TableCell
                            input={
                              <img
                                src={img}
                                alt="carrier_logo"
                                style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }}
                              />
                            }
                          />
                          <TableCell input={carrier}>
                            <div className="input">{service}</div>
                          </TableCell>
                          <TableCell center input={cost} />
                          <TableCell center input={<a href={tracking_url}>{tracking_number}</a>} />
                          <TableCell
                            center
                            input={
                              <div className="dropdown__container">
                                <button
                                  onClick={() => {
                                    window.location.href = `/api/order/${order}/print_labels/`
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPrint} />
                                </button>
                              </div>
                            }
                          />
                        </GlobalStyles.TableRow>
                      ))}
                    </tbody>
                  </GlobalStyles.DataTable>
                </div>
                {!loaded ? <CardLoading error={error} /> : null}
              </GlobalStyles.FullPageCard>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <ShippingWorkflow {...packStatus} current={'label_purchase'} />
        </div>
      </div>
    </>
  )
}
