import React, { useState, useEffect } from 'react'
import { globalState } from '../../../store'
import { usePost, useDelete, usePut } from '../../../components/component-items/helpers'
import { useParams, useLocation, useHistory } from 'react-router-dom'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { Form } from 'react-bootstrap'
import { TableCell } from '../../../components/component-items/datatable'

// Enums
import { ShipNoticeStatus } from '../../../enums'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'

export const ComplianceModal = ({ complianceList, users, currentUser, laborCharge, open, setOpen, itemId }: any) => {
  const asn = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()

  const laborPerHour = laborCharge || 35

  const defaultCompliance = {
    id: '',
    user: currentUser,
    category: '',
    labor: '',
    materials: '',
    amount: '0.00',
    notes: '',
  }

  const [compliance, setCompliance] = useState<any>([])
  const [underReview, setUnderReview] = useState(false)
  const history = useHistory()
  const { hash } = useLocation()

  useEffect(() => {
    if (hash === '#review') setUnderReview(true)
  }, [hash])

  useEffect(() => {
    if (complianceList && complianceList.length) {
      setCompliance(complianceList)
    }
  }, [complianceList])

  function handleChange(id: number | string, name: string, value: any) {
    const newList = compliance.map((item: any) => {
      if (item.id === id) {
        // Calculate total cost for each row
        let labor = name === 'labor' ? value : item.labor
        labor = labor || 0
        const laborCost = Math.round((labor / 60) * laborPerHour * 100) / 100
        let materials = name === 'materials' ? value : item.materials
        materials = materials || 0
        const newAmount = laborCost + parseFloat(materials)
        const updatedItem = {
          ...item,
          [name]: value,
          amount: newAmount,
        }
        return updatedItem
      }
      return item
    })
    setCompliance(newList)
  }

  function addRow() {
    const id = 'added' + compliance.length
    setCompliance(compliance.concat({ ...defaultCompliance, id: id }))
  }
  const handleComplete = async () => {
    if (underReview) {
      // Mark ASN completed
      if (compliance) {
        await usePost(`/api/inventory/asn/${asn}/billing/`, { billing: compliance, sn_item_id: itemId }, csrf, false)
      }
      await usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Completed }, csrf, false)
      history.push(`/asn/${asn}/`)
    } else {
      // Adding additional compliance, not completing ASN
      usePost(`/api/inventory/asn/${asn}/billing/`, { billing: compliance, sn_item_id: itemId }, csrf)
    }
  }

  const buttons = (
    <>
      <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => addRow()}>
        Add Non-Compliance Fee
      </GlobalStyles.Button>
      <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleComplete}>
        {underReview ? 'Complete ASN' : 'Update'}
      </GlobalStyles.Button>
    </>
  )

  return (
    <GenericModal heading={'Non-Compliance Fees'} show={open} size="xl" onHide={() => setOpen(false)} buttons={buttons}>
      <GlobalStyles.DataTable>
        <thead>
          <tr>
            <th>
              <span className="text">User</span>
            </th>
            {underReview && (
              <th>
                <span className="text">Item</span>
              </th>
            )}

            <th>
              <span className="text">Category</span>
            </th>
            <th>
              <span className="text">Labor (Min)</span>
            </th>
            <th>
              <span className="text">Materials ($)</span>
            </th>
            <th>
              <span className="text">Notes</span>
            </th>
            <th>
              <span className="text center">Labor Cost ($&nbsp;/&nbsp;Hour)</span>
            </th>
            <th>
              <span className="text center">Total Cost</span>
            </th>
            <th>
              <span className="text center">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {compliance.map((c: any, id: number) => (
            <GlobalStyles.TableRow key={id}>
              <TableCell>
                <Form.Select value={c.user} onChange={(e) => handleChange(c.id, 'user', e.target.value)}>
                  <option value={''} disabled hidden>
                    -User-
                  </option>
                  {users && users.length
                    ? users.map((u: any) => (
                        <option value={u.id} key={u.id}>
                          {u.first_name} {u.last_name}
                        </option>
                      ))
                    : null}
                </Form.Select>
              </TableCell>

              {underReview && <TableCell center input={c.variant ? c.variant : 'ASN'}></TableCell>}
              <TableCell>
                <Form.Select value={c.category} onChange={(e) => handleChange(c.id, 'category', e.target.value)}>
                  <option value={''} disabled hidden>
                    -Category-
                  </option>
                  {/* TODO (@michealgriffin): Replace with API call to get list of categories */}
                  <option value={'labelling'}>Labelling Required</option>
                  <option value={'unit_count'}>Unit Count Required</option>
                  <option value={'mixed_cartons'}>Mixed Cartons</option>
                  <option value={'polybags'}>Polybags Required</option>
                  <option value={'non_standard_pallets'}>Oversized Pallets</option>
                  <option value={'devanning'}>Devanning</option>
                  <option value={'missing_incomplete_asn'}>Missing/Incomplete ASN</option>
                </Form.Select>
              </TableCell>
              <TableCell>
                <Form.Control
                  type="number"
                  min="0"
                  step="30"
                  placeholder="Labor (Min)"
                  required
                  value={c.labor}
                  onChange={(e) => handleChange(c.id, 'labor', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <Form.Control
                  type="number"
                  min="0"
                  step="5"
                  placeholder="Materials ($)"
                  required
                  value={c.materials}
                  onChange={(e) => handleChange(c.id, 'materials', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <Form.Control
                  type="text"
                  placeholder="Labor Notes"
                  maxLength={50}
                  value={c.notes}
                  onChange={(e) => handleChange(c.id, 'notes', e.target.value)}
                />
              </TableCell>
              <TableCell input={'$' + laborPerHour} center />
              <TableCell input={'$' + c.amount} center />
              <TableCell
                input={
                  <div className="dropdown__container" style={{ minWidth: 60 }}>
                    {c.id === 1 || compliance.length === 0 ? null : c.id !== parseInt(c.id, 10) ? (
                      <button onClick={() => setCompliance(compliance.filter((e: any) => e !== c))}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    ) : (
                      <button onClick={() => useDelete(`/api/inventory/asn/${asn}/billing/${c.id}/`, csrf)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    )}
                  </div>
                }
                center
              />
            </GlobalStyles.TableRow>
          ))}
        </tbody>
      </GlobalStyles.DataTable>
    </GenericModal>
  )
}
