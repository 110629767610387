import React, { useState, useEffect, useRef, FunctionComponent } from 'react'
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'

// Components
import Form from 'react-bootstrap/Form'
import { Spinner } from './loading-popover'
import { GlobalStyled } from './styles'
// @ts-ignore
import { useDebounce } from 'use-lodash-debounce'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

import { SearchBoxProps } from './types'

const SearchBoxStyled = styled.div`
  display: flex;
  position: relative;
  i.search {
    position: absolute;
    z-index: 5;
    left: 12px;
    top: 11px;
  }
  .form-control {
    border-radius: 0.25rem !important;
  }
  .close {
    align-self: center;
    cursor: pointer;
    position: absolute;
    right: 14px;
    font-size: 1.4rem;
  }
`

export const SpinnerSmall = styled(Spinner)`
  &::before {
    right: 30px;
    width: 15px;
    height: 15px;
    animation: ${GlobalStyled.Spinner} 0.3s linear infinite;
    top: 20px;
  }
`

export const SearchBox: FunctionComponent<SearchBoxProps> = ({
  search,
  setSearch,
  loading,
  setLoading,
  page,
  setPage,
  style,
  inputElement,
  title,
  setFocus,
  updateURL = false,
}) => {
  const location = useLocation()
  const history = useHistory()
  let urlParams = new URLSearchParams(location.search)

  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value, 1000)
  useEffect(() => {
    if (debouncedValue && debouncedValue === search) {
      // Handle the case when the search is set elsewhere
    } else if (debouncedValue) {
      setLoading(true)
      setSearch(debouncedValue)
    } else {
      setLoading(false)
      setSearch('')
    }

    if (page && setPage) {
      setPage(1)
    }
  }, [debouncedValue])

  // If the search is updated elsewhere, update value
  useEffect(() => {
    setValue(search)
  }, [search])

  // Add cursor to input field on render
  const searchElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (searchElement?.current) {
      searchElement.current.focus()
      searchElement.current.select()
    }
  }, [])

  useEffect(() => {
    const searchQuery = urlParams.get('search') || ''
    if (searchQuery && !search && updateURL) {
      setSearch(searchQuery)
    }
  }, [location])

  useEffect(() => {
    if (updateURL) {
      if (search) {
        urlParams.set('search', search)
      } else {
        urlParams.delete('search')
      }
      history.push({ search: urlParams.toString() })
    }
  }, [search])

  return (
    <SearchBoxStyled style={style}>
      <i className="search">
        <FontAwesomeIcon icon={faSearch} />
      </i>
      <Form.Control
        type="text"
        value={value || ''}
        ref={setFocus ? searchElement : inputElement}
        onChange={(e) => setValue(e.target.value)}
        placeholder={title || 'Search'}
        style={{ borderRadius: '.25rem', padding: '0 2.5em 0 34px' }}
      />
      {!loading ? (
        <div
          className="input-icon close"
          onClick={() => {
            setValue('')
            setSearch('')
          }}
        >
          <i>
            <FontAwesomeIcon icon={faTimes} />
          </i>
        </div>
      ) : (
        <SpinnerSmall />
      )}
    </SearchBoxStyled>
  )
}
