import React from 'react'
import styled from 'styled-components'
import { StatusBarStyled } from '../../../components/component-items/status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faFileInvoiceDollar, faUsdSquare } from '@fortawesome/pro-light-svg-icons'
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons'

import { StatusBarProps } from './types'

const Balance = styled.span`
  font-weight: 600;
  color: ${(props) => props.color};
`

export const StatusBar = ({ lastPayment, company, balance, owing }: StatusBarProps) => {
  const color = Math.sign(Number(balance.replace(/,/g, ''))) === -1 ? 'red' : 'green'
  return (
    <div className="row">
      <div className="col">
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              {!owing ? (
                <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#51e2d3' }} />
              ) : (
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
              )}
            </div>
            <div className="status-text">
              <div>{!owing ? 'Payment Up to Date' : 'Payment Required'}</div>
              <div>
                Last Payment <strong>{lastPayment}</strong>
              </div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
            </div>
            <div className="status-text">
              Billing for <strong>{company}</strong>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text">
              Balance: <Balance {...{ color }}>{'$' + balance}</Balance>
            </div>
            <div className="status-icon" style={{ marginLeft: '1em' }}>
              <FontAwesomeIcon icon={faUsdSquare} style={{ color: color }} />
            </div>
          </div>
        </StatusBarStyled>
      </div>
    </div>
  )
}
