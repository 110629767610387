// Import External Dependencies
import React, { useContext } from 'react'
import { store } from '../store'
import { Route, Redirect } from 'react-router-dom'
import { Breadcrumb } from 'react-breadcrumbs'
import { Helmet } from 'react-helmet'

import { CrumbRouteProps } from './types'

export const CrumbRoute = ({
  component: Component,
  companyRequired,
  includeSearch,
  title,
  render,
  ...rest
}: CrumbRouteProps) => {
  const globalState = useContext(store)
  const { state } = globalState
  const { isAuthenticated, userIsWarehouse, actAs } = state
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return isAuthenticated ? (
          userIsWarehouse && companyRequired && !actAs ? (
            <Redirect
              to={{
                pathname: '/warehouse/companies',
                search: '?returnto=' + props.location.pathname,
                state: { from: props.location },
              }}
            />
          ) : (
            <Breadcrumb
              data={{
                title: title || props.title,
                pathname: props.match.url,
                search: includeSearch ? props.location.search : null,
              }}
            >
              <>
                <Helmet>
                  <title>ShippingTree{title ? ` - ${title}` : ''}</title>
                  <meta name="viewport" content="initial-scale=1, maximum-scale=0.8" />
                </Helmet>
                {Component ? <Component {...props} /> : render(props)}
              </>
            </Breadcrumb>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: '?next=' + props.location.pathname,
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

export default CrumbRoute
