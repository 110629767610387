import styled from 'styled-components'

const QuickACtions = styled.div`
  .panel {
    transition: all 0.5s ease !important;
    right: 0;
    left: auto;
    opacity: 1;
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    width: 425px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.13);
    .panel_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem;
      h3 {
        font-size: 1.4rem;
        font-weight: 500;
        color: #48465b;
        margin: 0;
        padding: 0;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        background-color: #f7f8fa;
        transition: all 0.3s !important;
        border-radius: 4px;
        border: none;
        i {
          transition: all 0.3s;
          font-size: 1.2rem;
          color: #74788d;
        }
        &:hover {
          background: ${(props) => props.theme.colors.gallery};
        }
      }
    }
    .panel_body {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      position: relative;
      padding: 0 1.5rem 1.5rem;
    }
    .nav__item {
      display: flex;
      width: calc(50% - 3px);
      min-height: 180px;
      border-radius: 6px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      background: #f7f8fa;
      transition: all 0.3s;
      margin: 3px 3px 3px 0;
      transition: all 0.3s !important;
      .item-icon {
        font-size: 2.25rem;
        color: ${(props) => props.theme.colors.cornflowerBlue};
        display: inline-block;
        text-align: center;
        margin-bottom: 0.5rem;
      }
      .item-title {
        display: inline-block;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors.smoky};
      }
      &:hover {
        background: ${(props) => props.theme.colors.gallery};
      }
    }
  }
  .hidden {
    right: -100% !important;
    opacity: 0;
  }
  .overlay {
    transition: all 0.5s ease !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
`

export const Styled = {
  QuickACtions,
}
