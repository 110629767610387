import React, { useState, useEffect, useContext } from 'react'
import { useFetch } from '../../components/component-items/helpers'
import { store } from '../../store'

// Components
import { Tooltip } from 'react-tooltip'
import ButtonDropdown from '../../components/component-items/button-dropdown'
import { USCountyMap, USStateMap, WorldMap } from '../../components/component-items/map-geography'
import { BatchListStyled } from './styles'

export const OrderGeography = () => {
  const sortOptions = ['US County', 'US State', 'Country']
  const [sort, setSort] = useState(sortOptions[0])
  const [content, setContent] = useState('')
  const globalState = useContext(store)
  const {
    state: { companyId },
  } = globalState

  const [url, setUrl] = useState(`/api/company/${companyId}/dashboard/orders_shipped_county/`)
  const orders: any = useFetch(url, {})

  useEffect(() => {
    if (sort === sortOptions[0]) {
      setUrl(`/api/company/${companyId}/dashboard/orders_shipped_county/`)
    } else if (sort === sortOptions[1]) {
      setUrl(`/api/company/${companyId}/dashboard/orders_shipped_county/?state=True`)
    } else if (sort === sortOptions[2]) {
      setUrl(`/api/company/${companyId}/dashboard/orders_shipped_county/?world=True`)
    }
  }, [sort])

  var data = orders.loaded ? orders.response.results : []
  var range = orders.loaded ? Math.floor(orders.response.range / 5) * 5 : 0

  return (
    <BatchListStyled>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>
            Order Geography<small>{orders.loaded ? orders.response.total + ' Total' : ''}</small>
          </h3>
        </div>
        <div className="datatable__toolbar">
          <ButtonDropdown {...{ sort, setSort }} options={sortOptions} title={'Map Of'} />
        </div>
      </div>
      <div className="card-body">
        {sort === sortOptions[0] ? (
          <USCountyMap setTooltipContent={setContent} {...{ data, range }} />
        ) : sort === sortOptions[1] ? (
          <USStateMap setTooltipContent={setContent} {...{ data, range }} />
        ) : (
          <WorldMap setTooltipContent={setContent} {...{ data, range }} />
        )}
        <div id="legend">
          <div className="legend-title">Number of Orders Shipped</div>
          <div className="legend-gradient"></div>
          <div className="legend-values">
            <div className="legend-val">0</div>
            <div className="legend-val">{Math.floor(range / 3)}</div>
            <div className="legend-val">{Math.floor((2 * range) / 3)}</div>
            <div className="legend-val">{range}</div>
          </div>
        </div>
      </div>
      <Tooltip id="map">{content}</Tooltip>
    </BatchListStyled>
  )
}

export default OrderGeography
