import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useFetch, useDelete, usePost, notify } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'

// Components
import { OrderOptions } from '../../../orders/order/styles'
import { ShippingWorkflow } from '../shipping-workflow'
import { PrePackWorkflow } from '../shipping-workflow/pre-pack-workflow'
import GlobalStyles from '../../../../components/component-items/styles'
import { SSCCBarcodeModal } from '../../../edi/sscc_barcodes'

// Cards
import { StatusBar } from '../status-bar'
import { OrderItemsCard } from './order-items-card'
import { SuggestedPackagingCard } from './suggested-packaging-card'
import { ShipmentCard } from './shipment-card'

// Keyboard Shortcuts Helpers
import { keyboardMapping } from '../../../../keyboard-shortcuts'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faBoxFull, faBoxAlt, faTrashAlt, faBarcodeRead } from '@fortawesome/pro-duotone-svg-icons'

import { MenuOptionProps } from './types'

const MenuOption = ({
  url,
  onClick,
  icon,
  title,
  arrow,
  children,
  node,
  active,
  disabled,
  complete,
  id,
}: MenuOptionProps) => {
  return (
    <li
      id={id}
      ref={node}
      onClick={onClick}
      className={active ? 'active' : disabled ? 'disabled' : complete ? 'complete' : ''}
    >
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const PrePackOrder = () => <PackOrder prepack />

export const PackOrder = ({ prepack }: any) => {
  let history = useHistory()

  const order = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()

  const [updateList, setUpdateList] = useState(false)
  const { response: resp, loaded }: any = useFetch(`/api/order/${order}/pack_details/`, {})
  const suggestedPackaging = loaded ? resp.suggested_packaging : []
  const status = loaded ? resp.status : null

  const defaultShipment = {
    id: null,
    shipment_number: 1,
    total: 1,
    order_items: [],
    packaging_items: [],
  }
  const [shipments, setShipments] = useState([defaultShipment])
  const [packStatus, setPackStatus] = useState({
    fully_packed: false,
    needs_serial_numbers: false,
    serial_number_enabled: false,
    needs_dimensions: false,
    freight: false,
    customer_pickup: false,
  })
  const [barcodeModal, setBarcodeModal] = useState(false)

  useEffect(() => {
    if (loaded && resp.shipments.length) {
      setShipments(resp.shipments)
    }
    if (loaded) {
      if (status >= 160) {
        history.push(`/order/${order}/`)
      }
      setPackStatus(resp.pack_status)
      // On load, set to first shipment
      // @ts-ignore
      document.getElementById('shipment-1').focus()
    }
  }, [loaded])

  function deleteShipment(shipmentNumber: any, id: number | string) {
    if (id) {
      useDelete(`/api/shipment/${id}/`, csrf)
    } else {
      if (shipments.length === 1) {
        notify({ type: 'error', message: 'An order must have at least 1 shipment' })
      } else {
        setShipments(shipments.filter((s) => s.shipment_number !== shipmentNumber))
      }
    }
  }

  const handleAddPackage = async () => {
    const resp = await usePost(
      '/api/shipment/pack_item/',
      { pk: order, shipment: shipments.length + 1, barcode: '' },
      csrf,
      false
    )
    if (resp.success) {
      // Make sure pack status is updated since page reload does not occur
      setPackStatus((packStatus) => ({ ...packStatus, fully_packed: false }))
      setShipments([{ ...defaultShipment, shipment_number: shipments.length + 1, id: resp.id }, ...shipments])
    } else {
      notify({ type: 'error', message: 'There was an issue adding a package' })
    }
  }

  useEffect(() => {
    keyboardMapping['order']['u'].data.shipment_count = shipments.length
    keyboardMapping['order']['d'].data.shipment_count = shipments.length
  }, [shipments])

  return (
    <>
      <div className="row">
        <div className="col">
          <StatusBar />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-xl-2">
          <OrderOptions>
            <ul>
              <GlobalStyles.Tooltip data-title="Reverts all packing">
                <MenuOption
                  id="reset-packing"
                  onClick={() => usePost(`/api/order/${order}/reset_packing/`, {}, csrf)}
                  icon={faTrashAlt}
                  title={'Reset Packing'}
                />
              </GlobalStyles.Tooltip>
              <GlobalStyles.Tooltip data-title="Adds another Shipment">
                <MenuOption id="add-package" onClick={handleAddPackage} icon={faBoxAlt} title={'Add Package'} />
              </GlobalStyles.Tooltip>
              <GlobalStyles.Tooltip data-title="Displays order barcode with picking locations">
                <MenuOption url={`/api/order/${order}/picklist/`} icon={faBoxFull} title={'Order Picklist'} />
              </GlobalStyles.Tooltip>
              {/* {resp?.source === 'edi' && (
                <>
                  <GlobalStyles.Tooltip data-title="Edit and Print SSCC Barcodes for EDI Orders">
                    <MenuOption onClick={() => setBarcodeModal(true)} icon={faBarcodeRead} title={'SSCC Barcodes'} />
                  </GlobalStyles.Tooltip>
                  <SSCCBarcodeModal open={barcodeModal} setOpen={setBarcodeModal} />
                </>
              )} */}
            </ul>
          </OrderOptions>
        </div>
        <div className="col-lg-7 col-xl-8">
          <div className="row">
            <div className="col-xl-12">
              <OrderItemsCard {...{ updateList }} />
              {suggestedPackaging.length ? <SuggestedPackagingCard {...{ suggestedPackaging }} /> : null}
              <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: 25 }}>
                <GlobalStyles.FullPageCard style={{ borderTop: '2px solid #646c9a', marginRight: '2em', height: 2 }} />
                <h4 style={{ position: 'relative', top: '-10px', letterSpacing: 2 }}>SHIPMENTS</h4>
                <GlobalStyles.FullPageCard style={{ borderTop: '2px solid #646c9a', marginLeft: '2em', height: 2 }} />
              </div>
              {shipments.map((s, id) => (
                // @ts-ignore
                <ShipmentCard
                  {...s}
                  {...{ deleteShipment, updateList, setUpdateList, setPackStatus }}
                  shipments={shipments}
                  setShipments={setShipments}
                  total={shipments.length}
                  id={s.id || 'default'}
                  key={id || 'default'}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          {prepack ? (
            <PrePackWorkflow {...packStatus} current={'pre-pack'} />
          ) : (
            <ShippingWorkflow {...packStatus} current={'pack'} />
          )}
        </div>
      </div>
    </>
  )
}
