import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useFetch, usePost } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'
// @ts-ignore
import ReactTimeAgo from 'react-time-ago'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { CenteredSpinner } from '../../../../components/component-items/loading-popover'
import { Form, Row, Col } from 'react-bootstrap'
import { TableCell } from '../../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/pro-duotone-svg-icons'

export const PackSingleOrder = () => {
  const { id: batch } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [barcode, setBarcode] = useState('')
  const [packaging, setPackaging] = useState('')
  const {
    state: { csrf },
  } = globalState()

  const { response, loaded }: any = useFetch(`/api/inventory/batch/${batch}/packed_orders/`, { reload })
  const packedOrders = loaded ? response.packedOrders : []
  const ordersRemaining = loaded ? response.ordersRemaining : 0

  const history = useHistory()
  if (loaded && !ordersRemaining) {
    history.push(`/batch/${batch}/`)
  }

  const handleKeyPressed = (event: any) => {
    if (event.key === 'Enter') {
      if (event.target === inputElement?.current) {
        packageElement.current?.focus()
        packageElement.current?.select()
      } else {
        handleSubmit()
      }
    }
  }

  const handleSubmit = async () => {
    if (loading) {
      return
    }
    setLoading(true)

    const reload = false
    const stringify = true
    const sendResponse = false
    await usePost(
      `/api/inventory/batch/${batch}/pack_single_item_order/`,
      { barcode, packaging },
      csrf,
      reload,
      stringify,
      sendResponse
    )

    setLoading(false)
    setBarcode('')
    setPackaging('')
    setReload((reload) => !reload)

    if (inputElement.current) {
      inputElement.current.focus()
      inputElement.current.select()
    }
  }

  // Add cursor to input field on render
  const inputElement = useRef<HTMLInputElement>(null)
  const packageElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus()
      inputElement.current.select()
    }
  }, [])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>Pack Single Item Order</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 110, margin: '2em auto', width: '80%', maxWidth: 600 }}>
        <Row>
          <Form.Group as={Col} md="12">
            <StatusBoxStyled className="blue" style={{ marginBottom: '2em', textAlign: 'center' }}>
              {ordersRemaining + ' Orders Remaining'}
            </StatusBoxStyled>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="validationCustom01" className="required">
            <Form.Label>
              <strong>Pack</strong> (Product Variant)
            </Form.Label>
            <Form.Control
              type="text"
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
              placeholder="Barcode"
              onKeyDown={handleKeyPressed}
              ref={inputElement}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="required"
            style={{ paddingTop: '1em' }}
          >
            <Form.Label>Packaging</Form.Label>
            <Form.Control
              type="text"
              value={packaging}
              onChange={(e) => setPackaging(e.target.value)}
              placeholder="Packaging"
              onKeyDown={handleKeyPressed}
              ref={packageElement}
            />
          </Form.Group>
        </Row>
        {!loading ? (
          <GlobalStyles.Button
            className="royal"
            style={{ minWidth: 125, margin: '2em 0 0', float: 'right' }}
            onClick={() => handleSubmit()}
          >
            Submit
          </GlobalStyles.Button>
        ) : (
          <GlobalStyles.Button
            className="royal"
            style={{ minWidth: 125, margin: '2em 0 0', cursor: 'auto', float: 'right' }}
          >
            <CenteredSpinner />
            &nbsp;
          </GlobalStyles.Button>
        )}
        <GlobalStyles.DataTable style={{ marginTop: '8em' }}>
          <thead>
            <tr>
              <th>
                <span className="text center">Order</span>
              </th>
              <th>
                <span className="text center">Status</span>
              </th>
              <th>
                <span className="text center">Label</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {packedOrders.map(({ id, status, tracking_number, updated }: any, index: number) => (
              <GlobalStyles.TableRow key={index}>
                <TableCell center input={id}>
                  <div>
                    <ReactTimeAgo date={Date.parse(updated)} locale="en-US" />
                  </div>
                </TableCell>
                <TableCell
                  center
                  input={
                    <StatusBoxStyled style={{ margin: 'auto' }} className={'silver'}>
                      {status}
                    </StatusBoxStyled>
                  }
                />
                <TableCell
                  center
                  input={
                    <StatusBoxStyled style={{ margin: 'auto' }} className={'silver'}>
                      {tracking_number || 'N/A'}
                    </StatusBoxStyled>
                  }
                />
                <TableCell center>
                  <div className="dropdown__container">
                    <a href={`/api/order/${id}/print_labels/`}>
                      <button>
                        <FontAwesomeIcon icon={faPrint} />
                      </button>
                    </a>
                  </div>
                </TableCell>
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
    </GlobalStyles.FullPageCard>
  )
}
