import React from 'react'

// Components
import GraphCard from './graph-card'
import { useFetch } from '../../components/component-items/helpers'

export const UnShippedOrders = ({ url }: { url: string }) => {
  const { response: resp, error, loaded, placeholder }: any = useFetch(url, {}, 30)
  var total = loaded ? resp.total : 0
  var defaultData = [
    { x: 0, y: 0 },
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
    { x: 5, y: 0 },
    { x: 6, y: 0 },
    { x: 7, y: 0 },
    { x: 8, y: 0 },
    { x: 9, y: 0 },
    { x: 10, y: 0 },
    { x: 11, y: 0 },
    { x: 12, y: 0 },
  ]
  var graphData = loaded ? resp.unshipped?.map((o: any) => ({ x: o.created_Hour, y: o.count })) : defaultData

  var data = [
    {
      id: 'Unshipped Orders',
      data: loaded && resp.unshipped?.length > 1 ? graphData : defaultData,
    },
  ]

  return (
    <GraphCard
      title={'Unshipped Orders'}
      desc={0 + ' Daily Avg'}
      total={total}
      color={'#645ca1'}
      loaded={loaded}
      data={data}
      pointName={''}
      yLabel={'Orders '}
      error={error}
    />
  )
}

export default UnShippedOrders
