import React, { useState, useEffect } from 'react'
import { usePost, useFetch, useDelete, usePut } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'
import { useParams } from 'react-router-dom'
import { BackgroundLayer } from './styles'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import GenericModal from '../../../../components/component-items/modal'
import { TableCell } from '../../../../components/component-items/datatable'
import { Form } from 'react-bootstrap'
import CardLoading from '../../../../components/component-items/loading-popover'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/pro-duotone-svg-icons'

import { EditCasePackProps } from './types'

export const EditCasePack = ({ productVariant, currentCasepack, open, setOpen }: EditCasePackProps) => {
  const {
    state: { csrf },
  } = globalState()
  const defaultCasepack = {
    case_pack_type: 'M',
    sku: '',
    barcode: '',
    weight: '',
    width: '',
    height: '',
    length: '',
    multiplier: 0,
  }
  const [casepack, setCasepack] = useState(defaultCasepack)

  useEffect(() => {
    if (Object.keys(currentCasepack).length === 0 && currentCasepack.constructor === Object) {
      setCasepack(defaultCasepack)
    } else {
      setCasepack(currentCasepack)
    }
  }, [currentCasepack])

  const handleSubmit = () => {
    if (currentCasepack.id) {
      // Update
      usePut(`/api/product/variant/${productVariant}/casepack/${currentCasepack.id}/`, { ...casepack }, csrf)
    } else {
      // Create
      usePost(`/api/product/variant/${productVariant}/casepack/`, { ...casepack }, csrf)
    }
  }

  return (
    <GenericModal
      heading={currentCasepack.id ? 'Update Casepack' : 'Create Casepack'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button onClick={() => handleSubmit()}>
          {currentCasepack.id ? 'Update' : 'Create'}
        </GlobalStyles.Button>
      }
    >
      <Form.Group style={{ margin: '2em' }}>
        <Form.Label>Case Pack Type</Form.Label>
        <Form.Select
          value={casepack.case_pack_type}
          onChange={(e) => setCasepack({ ...casepack, case_pack_type: e.target.value })}
        >
          <option value={'M'}>Master</option>
          <option value={'I'}>Inner</option>
          <option value={'P'}>Pallet</option>
        </Form.Select>
        <Form.Label style={{ marginTop: '1em' }}>SKU</Form.Label>
        <Form.Control
          type="text"
          value={casepack.sku}
          onChange={(e) => setCasepack({ ...casepack, sku: e.target.value })}
        />
        <Form.Label style={{ marginTop: '1em' }}>Barcode</Form.Label>
        <Form.Control
          type="text"
          value={casepack.barcode}
          onChange={(e) => setCasepack({ ...casepack, barcode: e.target.value })}
        />
        <Form.Label style={{ marginTop: '1em' }}>Weight</Form.Label>
        <Form.Control
          type="number"
          value={casepack.weight}
          onChange={(e) => setCasepack({ ...casepack, weight: e.target.value })}
        />
        <Form.Label style={{ marginTop: '1em' }}>Length</Form.Label>
        <Form.Control
          type="number"
          value={casepack.length}
          onChange={(e) => setCasepack({ ...casepack, length: e.target.value })}
        />
        <Form.Label style={{ marginTop: '1em' }}>Width</Form.Label>
        <Form.Control
          type="number"
          value={casepack.width}
          onChange={(e) => setCasepack({ ...casepack, width: e.target.value })}
        />
        <Form.Label style={{ marginTop: '1em' }}>Height</Form.Label>
        <Form.Control
          type="number"
          value={casepack.height}
          onChange={(e) => setCasepack({ ...casepack, height: e.target.value })}
        />
        <Form.Label style={{ marginTop: '1em' }}>Multiplier</Form.Label>
        <Form.Control
          type="number"
          value={casepack.multiplier}
          min="1"
          onChange={(e) => setCasepack({ ...casepack, multiplier: Number(e.target.value) })}
        />
      </Form.Group>
    </GenericModal>
  )
}

export const CasePackCard = ({ id }: { id?: string | number }) => {
  const {
    state: { csrf },
  } = globalState()
  const [open, setOpen] = useState(false)
  const [currentCasepack, setCurrentCasepack] = useState({})
  const productVariant = id || useParams<{ id: string }>().id

  const url = `/api/product/variant/${productVariant}/casepack/`
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})
  const casepacks = loaded ? resp.results : []

  const deleteCasepack = (id: string | number) => {
    useDelete(url + `${id}/`, csrf, true, true, true)
  }

  return (
    <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            Case Pack<small>{casepacks.length} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button
            style={{ height: 40, marginRight: '-0.5em' }}
            onClick={() => {
              setCurrentCasepack({})
              setOpen(true)
            }}
          >
            Create Case Pack
          </GlobalStyles.Button>
          <EditCasePack {...{ productVariant, currentCasepack, open, setOpen }} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Type</span>
              </th>
              <th>
                <span className="text">SKU</span>
              </th>
              <th>
                <span className="text">Barcode</span>
              </th>
              <th>
                <span className="text center">Multiplier</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {casepacks?.map((c: any, id: number) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell
                  input={c.case_pack_type === 'P' ? 'Pallet' : c.case_pack_type === 'I' ? 'Inner' : 'Master'}
                />
                <TableCell>{c.sku}</TableCell>
                <TableCell>{c.barcode}</TableCell>
                <TableCell center>{c.multiplier}</TableCell>
                <TableCell
                  center
                  input={
                    <div className="dropdown__container">
                      <button
                        onClick={() => {
                          setCurrentCasepack(c)
                          setOpen(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button
                        onClick={() => {
                          deleteCasepack(c.id)
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  }
                />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BackgroundLayer>
  )
}
