import React, { useState, useEffect, useContext } from 'react'
import { globalState } from '../../../store'
import { useParams } from 'react-router-dom'
import { usePost, useDelete } from '../../../components/component-items/helpers'
import CardLoading from '../../../components/component-items/loading-popover'

// Components
import { useFetch } from '../../../components/component-items/helpers'
import { CarouselProvider, CarouselContext, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import GenericModal from '../../../components/component-items/modal'
import GlobalStyles from '../../../components/component-items/styles'
import { DragNDropMany } from '../../../components/component-items/dragndrop'
import ImageZoom from 'react-medium-image-zoom'
import { LabelStyled } from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { faPrint, faGem, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'

export const GoldenSampleUpload = ({ open, setOpen }: any) => {
  const params: any = useParams()
  const project = params.id
  const [files, setFiles] = useState([])
  const {
    state: { csrf },
  } = globalState()

  const updateNotes = () => {
    const formData = new window.FormData()
    files.map((file: any) => formData.append(file.name, file))

    // @ts-ignore
    formData.append('golden-sample', true)

    const reload = true
    const stringifyBody = false
    usePost(`/api/inventory/project/${project}/details/`, formData, csrf, reload, stringifyBody)
  }

  return (
    <GenericModal
      heading={'Upload Golden Samples'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={() => updateNotes()}>Update</GlobalStyles.Button>}
    >
      <div style={{ minHeight: 100, padding: '1em 1em 0' }}>
        {/* @ts-ignore */}
        <DragNDropMany {...{ files, setFiles }} image_only />
      </div>
    </GenericModal>
  )
}

export const GoldenSample = () => {
  const params: any = useParams()
  const project = params.id
  const {
    state: { csrf, userIsWarehouse },
  } = globalState()

  const { response, loaded, error, placeholder }: any = useFetch(`/api/inventory/project/${project}/samples/`, {}, 60)
  const images = loaded ? response.samples : null

  const total = images?.length || 0

  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    if (carouselContext) {
      carouselContext.subscribe(onChange)
      return () => carouselContext.unsubscribe(onChange)
    }
  }, [carouselContext])

  function handleDelete(id: string | number) {
    useDelete(`/api/inventory/project/${project}/sample/${id}/`, csrf)
  }
  return (
    <LabelStyled>
      <CarouselProvider
        naturalSlideWidth={300}
        naturalSlideHeight={150}
        totalSlides={total}
        infinite={true}
        isPlaying={true}
      >
        <div className="datatable__header">
          <div className="datatable__title">
            <h3>Golden Sample</h3>
            {images?.length ? (
              <div>
                <a href={images[currentSlide].url}>
                  <button>
                    <FontAwesomeIcon icon={faPrint} />
                  </button>
                </a>
                {userIsWarehouse && (
                  <a onClick={() => handleDelete(images[currentSlide].id)}>
                    <button>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </a>
                )}
                {total > 1 ? (
                  <>
                    <ButtonBack>
                      <i>
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </i>
                    </ButtonBack>
                    <ButtonNext>
                      <i>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </i>
                    </ButtonNext>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <div className="order-section">
          {images?.length ? (
            <Slider>
              {images?.map((img: any, id: any) => (
                <Slide index={id} key={id}>
                  <ImageZoom>
                    <img src={img.url} alt="Shipping Label" className="img" />
                  </ImageZoom>
                </Slide>
              ))}
            </Slider>
          ) : (
            <div className="no-label">
              <div role="img" aria-label="none">
                <FontAwesomeIcon icon={faGem} />
              </div>
              No Golden Sample
            </div>
          )}
        </div>
      </CarouselProvider>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </LabelStyled>
  )
}
