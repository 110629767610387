import styled from 'styled-components'

import { DropdownStyled } from '../../../components/component-items/export-tools'

export const BackgroundLayer = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
`

export const OverdueLabel = styled.span`
  position: relative;
  top: -1px;
  left: 6px;
`

export const OrderOptions = styled(BackgroundLayer)`
  flex-direction: column;
  ul {
    display: block;
    padding: 0.5rem 0;
    margin: 10px 0;
    list-style: none;
    border: 0;
  }
  li {
    position: relative;
    a {
      transition: all 0.3s;
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 0.75rem 1rem 0.75rem 1.5rem;
      &:hover {
        background-color: #f7f8fa;
      }
      .icon {
        flex: 0 0 30px;
        font-size: 1.25rem;
        color: #595d6e;
      }
      .text {
        font-weight: 500;
        flex-grow: 1;
        font-size: 1rem;
        color: #595d6e;
      }
    }
  }
`

export const OrderInfo = styled(BackgroundLayer)`
  justify-content: space-between;
  padding: 1em;
  margin-bottom: 0;
  height: 100%;
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 1em;
    color: #4a485d;
    div {
      width: 100%;
    }
    span {
      margin-left: 1rem;
      float: right;
      font-weight: 500;
      max-width: 60%;
      text-align: right;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    p {
      margin-bottom: 0;
    }
  }
  button {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: center;
    border-radius: 0.2rem;
    color: #595d6e;
    font-size: 1.25rem;
    &:hover {
      background: ${(props) => props.theme.colors.zircon};
      svg {
        color: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
  .return-empty {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5em;
    div {
      font-size: 1em;
    }
  }
`

export const Dropdown = styled(DropdownStyled)`
  margin: 0 !important;
  padding: 15px 0 10px !important;
  top: 0;
  left: 105%;
`

export const LabelStyled = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  display: block;
  padding: 0 25px 25px 25px;
  height: 100%;
  min-height: 220px;
  .datatable__header {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    min-height: 60px;
    .datatable__title {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #48465b;
        small {
          font-weight: 300;
          padding-left: 1rem;
          font-size: 1rem;
          color: #74788d;
        }
      }
    }
    .datatable__toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .order-section {
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;
    img {
      position: absolute;
      max-width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      max-width: 100%;
      max-height: 75%;
      margin: 0 auto;
    }
  }
  button {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: center;
    border-radius: 0.2rem;
    color: #595d6e;
    font-size: 1.25rem;
    &:hover {
      background: ${(props) => props.theme.colors.zircon};
      svg {
        color: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
  .no-label {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 1em 0;
    div {
      font-size: 2em;
    }
  }
`

export const ShippingStyled = styled.div`
  width: 100%;
  display: block;
  padding: 0 15px 15px 15px;
  .datatable__header {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    min-height: 60px;
    padding: 0 10px;
    .datatable__title {
      display: flex;
      align-items: center;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #48465b;
        small {
          font-weight: 300;
          padding-left: 1rem;
          font-size: 1rem;
          color: #74788d;
        }
      }
    }
    .datatable__toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export const TimelineStyled = styled.div`
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  .history-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    border-bottom: 1px solid #ebedf2;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .title {
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #48465b;
    }
  }
  .history-body {
    padding: 20px;
    max-height: 60vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
  .history-list {
    position: relative;
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 1px;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: #ebedf2;
    }
  }
  .list__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    .list__badge {
      display: flex;
      align-items: center;
      text-align: left;
      position: relative;
      width: 20px;
      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 7px;
        height: 7px;
        left: -3px;
        border-radius: 100%;
        background-color: #ebedf2;
      }
    }
    .list__text {
      font-weight: 500;
      width: 100%;
      padding: 0 5px 0 0;
      word-break: break-word;
    }
    .list__subitem {
      color: #74788d;
      min-width: 80px;
      text-align: right;
      padding: 0;
      font-size: 0.8rem;
    }
  }
  /* Note this can be refactored */
  button {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: center;
    border-radius: 0.2rem;
    &:hover {
      background: ${(props) => props.theme.colors.zircon};
      svg {
        color: ${(props) => props.theme.colors.dodgerBlue};
      }
    }
  }
`
