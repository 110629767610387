import React from 'react'

import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { getMonthNames, getOrdinal, getWeekdayNames } from '../../../../components/component-items/utils'
import { ScheduleOption, RecurrenceSummaryProps } from '../../../../components/component-items/recurrence/types'

// Helper function to get the correct schedule term
function getScheduleTerm(recurrenceInterval: number, scheduleType: string, scheduleOptions: ScheduleOption[]): any {
  const option = scheduleOptions.find(({ value }) => value === scheduleType)
  return recurrenceInterval === 1 ? option?.singular : `${recurrenceInterval} ${option?.plural}`
}

// Helper function to create status box styled component
function getStatusBox(content: any) {
  return (
    <StatusBoxStyled className="blue" style={{ display: 'inline-block', padding: '0 .4em' }}>
      {content}
    </StatusBoxStyled>
  )
}

// Main function to generate both HTML and text summaries
export function recurrenceSummary({
  recurrenceInterval,
  scheduleType,
  scheduleOptions,
  transactionAmount,
  dayOfMonth,
  monthOfYear,
  weekSegment,
  dayOfWeek,
  daysOfWeek,
  endDate,
  occurences,
}: RecurrenceSummaryProps) {
  const scheduleTerm = getScheduleTerm(recurrenceInterval, scheduleType, scheduleOptions)
  const weekdays = getWeekdayNames()
  const monthNames = getMonthNames()
  var dateString: any = ''
  if (scheduleType === 'yearly') {
    if (weekSegment) {
      dateString = `${weekSegment} ${weekdays[dayOfWeek || 0]} of ${monthNames[monthOfYear || 0]}`
    } else {
      dateString = `${getOrdinal(dayOfMonth)} of ${monthNames[monthOfYear || 0]}`
    }
  } else if (scheduleType === 'monthly') {
    if (weekSegment) {
      dateString = `${weekSegment} ${weekdays[dayOfWeek || 0]}`
    } else {
      dateString = `${getOrdinal(dayOfMonth)}`
    }
  } else if (scheduleType === 'weekly') {
    dateString = daysOfWeek?.map((d) => weekdays[d]).join(', ')
  }

  var endingString = ''
  if (endDate) {
    // convert endDate string from YYYY-MM-DD to Date object
    const date = new Date(endDate)
    endingString = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
  } else if (occurences) {
    endingString = `after ${occurences} occurrences`
  } else {
    endingString = 'never'
  }

  const htmlOutput = (
    <>
      {'A transaction of '}
      {getStatusBox('$' + transactionAmount)}
      {' will be created'}
      {['yearly', 'monthly'].includes(scheduleType) ? ' on the ' : scheduleType === 'weekly' ? ' on ' : ''}
      {dateString && getStatusBox(dateString)}
      {' every '}
      {getStatusBox(scheduleTerm)}
      {' ending '}
      {getStatusBox(endingString)}.
    </>
  )

  const textOutput = `Recurring${['yearly', 'monthly'].includes(scheduleType) ? ' on the ' : scheduleType === 'weekly' ? ' on ' : ''}${dateString} every ${scheduleTerm}${endingString === 'never' ? '' : ' ending ' + endingString}`

  return { htmlOutput, textOutput }
}
