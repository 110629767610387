import React from 'react'
import { usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'

import { HotOrderModalProps } from './types'

export const HotOrderModal = ({ order, hotModal, setHotModal }: HotOrderModalProps) => {
  const {
    state: { csrf },
  } = globalState()

  return (
    <GenericModal
      heading={'Make Order HOT'}
      show={hotModal}
      onHide={() => setHotModal(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => usePut(`/api/order/${parseInt(order)}/mark_order_hot/`, {}, csrf)}
        >
          Confirm
        </GlobalStyles.Button>
      }
    >
      <p style={{ fontSize: '1.25rem', textAlign: 'center' }}>
        Marking this order hot will prioritize this order over your remaining orders.
      </p>
      {/*
            <div className="search-bar" style={{ padding: 10 }}>
                <InputGroup style={{ justifyContent: 'center' }}>
                    <Checkbox selected={waiveFee} setSelected={setWaiveFee} />
                    <p style={{ padding: '0 1em', lineHeight: '24px' }} >Waive $5.00 Priority fee</p>
                </InputGroup>
            </div>
            */}
    </GenericModal>
  )
}
