import React, { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFetch, StickyElement, notify } from '../../../../components/component-items/helpers'
import { BackgroundLayer } from '../../../../components/component-items/status-bar'
import { OrderOptions } from './styles'
import { theme as customTheme } from '../../../../theme/ShippingTreeTheme'

// Components
import GlobalStyles from '../../../../components/component-items/styles'

// Cards
import { StepProgressBar } from './progress-bar'
import { OrderList } from './order-list'
import { InventoryList } from './inventory-list'
import { StatusBar } from './status-bar'
import { OrderStatusBar } from './order-status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import {
  faInventory,
  faPrintSearch,
  faPlayCircle,
  faPrint,
  faExclamationTriangle,
  faBoxCheck,
  faExclamationCircle,
  faObjectGroup,
  faClipboardList,
  faShippingFast,
} from '@fortawesome/pro-duotone-svg-icons'

type MenuOptionProps = {
  url?: string
  onClick?: any
  icon: any
  iconColor?: string | null
  title: string
  arrow?: any
  children?: any
  node?: any
}

const MenuOption: FunctionComponent<MenuOptionProps> = ({
  url,
  onClick,
  icon,
  iconColor,
  title,
  arrow,
  children,
  node,
}) => {
  return (
    <li ref={node} onClick={onClick}>
      <a href={url || '#'}>
        <span className="icon" style={{ color: iconColor || customTheme.colors.comet }}>
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const Batch = () => {
  const { id: batch } = useParams<{ id: string }>()

  const res = useFetch(`/api/inventory/batch/${batch}/header/`, {}, 60)
  const resp: any = res.response
  const loaded = res.loaded

  const [openInventoryTransactionsModal, setInventoryTransactionModal] = useState(false)

  const batchStatus = loaded ? resp.batchStatus : {}
  const batchType = loaded ? resp.type : {}
  const started = loaded ? resp.started : false
  const printedItemsPending = loaded ? resp.printedItemsPending : false
  const prepackEnabled = loaded ? resp.prepackEnabled : false
  const nextOrder = loaded ? resp.nextOrder : null
  const labelled = loaded ? resp.labelled : false
  const prepackOrder = loaded ? resp.prepackOrder : null
  const progress = loaded ? resp.progress : 0
  const counts = loaded ? resp.counts : {}

  useEffect(() => {
    if (printedItemsPending) {
      notify({
        type: 'warning',
        message: 'Required! Some of the inventory item barcodes need to be printed before picking this batch.',
      })
    }
  }, [loaded])

  const handleReleaseUnshipped = () => {
    if (window.confirm('Are you sure you want to release the remaining orders on this batch?')) {
      window.location.href = `/api/inventory/batch/${batch}/release_unshipped/`
    }
  }

  const printMissingInventoryBarcodes = () => {
    window.location.href = `/api/inventory/batch/${batch}/print_inventory_items/?print_type=not_printed`
  }

  const handleVoidAllLabels = () => {
    if (window.confirm('Are you sure you want to void all the labels on this prepack?')) {
      window.location.href = `/api/inventory/batch/${batch}/void_all_labels/`
    }
  }

  return (
    <>
      <StatusBar {...batchStatus} />
      <div className="row">
        <div className="col-xl-2">
          <StickyElement>
            <OrderOptions>
              <ul>
                {!started ? (
                  <MenuOption url={`/batch/${batch}/start/`} icon={faPlayCircle} title={'Start Batch'} />
                ) : (
                  <>
                    {counts['processing'] ? (
                      <MenuOption url={`/api/inventory/batch/${batch}/pick/`} icon={faInventory} title={'Picking'} />
                    ) : null}
                    {prepackEnabled ? (
                      <>
                        <MenuOption url={`/order/${prepackOrder}/pre-pack/`} icon={faBoxCheck} title={'Pre-Pack'} />
                        <MenuOption
                          onClick={() => handleVoidAllLabels()}
                          icon={faExclamationCircle}
                          title={'Void All Labels'}
                        />
                        {labelled ? (
                          <MenuOption
                            url={`/api/inventory/batch/${batch}/print_labels/`}
                            icon={faObjectGroup}
                            title={'Print Shipping Labels'}
                          />
                        ) : null}
                        {labelled ? (
                          <MenuOption
                            url={`/api/inventory/batch/${batch}/print_labels/?packslip=1`}
                            icon={faObjectGroup}
                            title={'Print All Labels'}
                          />
                        ) : null}
                      </>
                    ) : nextOrder ? (
                      batchType === 'single_item' ? (
                        <GlobalStyles.Tooltip data-title="Directs to Pack page">
                          <MenuOption url={`/batch/${batch}/pack-single/`} icon={faPrint} title={'Pack & Ship'} />
                        </GlobalStyles.Tooltip>
                      ) : (
                        <GlobalStyles.Tooltip data-title="Directs to Pack page">
                          <MenuOption url={`/order/${nextOrder}/pack/`} icon={faShippingFast} title={'Pack & Ship'} />
                        </GlobalStyles.Tooltip>
                      )
                    ) : null}
                  </>
                )}
                <GlobalStyles.Tooltip data-title="Releases remaining orders in batch">
                  <MenuOption
                    onClick={() => handleReleaseUnshipped()}
                    icon={faExclamationTriangle}
                    title={'Release Unshipped'}
                  />
                </GlobalStyles.Tooltip>
                <GlobalStyles.Tooltip data-title="Displays list of all inventory transactions">
                  <MenuOption
                    onClick={() => setInventoryTransactionModal(true)}
                    icon={faClipboardList}
                    title={'Inventory Transactions'}
                  />
                </GlobalStyles.Tooltip>
                <GlobalStyles.Tooltip data-title="Printable document displaying batch barcode with picking locations">
                  <MenuOption
                    url={`/api/inventory/batch/picklist/${batch}/`}
                    icon={faPrint}
                    title={'Print Pick List'}
                  />
                </GlobalStyles.Tooltip>
                <GlobalStyles.Tooltip data-title="Print all inventory item barcodes that have not yet been printed for this batch">
                  <MenuOption
                    onClick={() => printMissingInventoryBarcodes()}
                    iconColor={printedItemsPending ? customTheme.colors.mySin : null}
                    icon={faPrintSearch}
                    title={'Print Inventory Item Barcode'}
                  />
                </GlobalStyles.Tooltip>
              </ul>
            </OrderOptions>
          </StickyElement>
        </div>
        <div className="col-xl-7">
          <div className="row">
            <div className="col-xl-12">
              <StepProgressBar {...{ progress }} />
              <InventoryList open={openInventoryTransactionsModal} setOpen={setInventoryTransactionModal} />
              <BackgroundLayer>
                <OrderList {...{ counts }} />
              </BackgroundLayer>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <StickyElement>
            <OrderStatusBar {...{ counts }} />
          </StickyElement>
        </div>
      </div>
    </>
  )
}
