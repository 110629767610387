import React, { useState, useEffect } from 'react'
import { globalState } from '../../../store'
import { useFetch, usePost } from '../../../components/component-items/helpers'
import { useParams } from 'react-router-dom'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import CardLoading from '../../../components/component-items/loading-popover'
import GenericModal from '../../../components/component-items/modal'
import { Form } from 'react-bootstrap'
import { TableCell } from '../../../components/component-items/datatable'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faLayerPlus } from '@fortawesome/pro-duotone-svg-icons'

export const BillingModal = ({ users, open, setOpen }: any) => {
  const params: any = useParams()
  const project = params?.id
  const { response, loaded, error, placeholder }: any = useFetch(`/api/inventory/project/${project}/billing/`, {}, 60)
  const {
    state: { csrf },
  } = globalState()
  const billingList = loaded ? response.billingList : null
  const projectQuantity = loaded ? response.projectQuantity : null
  const currentUser = loaded ? response.currentUser : null
  const laborCharge = loaded ? response.laborCharge : null

  const laborPerHour = laborCharge || 35

  const defaultBilling = {
    id: 1,
    user: currentUser,
    category: '',
    labor: '',
    materials: '',
    unitCost: '',
    amount: '0.00',
    notes: '',
  }

  const [billing, setBilling] = useState([defaultBilling])

  useEffect(() => {
    if (loaded) {
      defaultBilling.user = currentUser
      if (billing.length === 1) {
        setBilling([defaultBilling])
      }
    }

    if (billingList && billingList.length) {
      setBilling(billingList)
    }
  }, [loaded])

  function handleChange(id: number, name: string, value: number) {
    const newList = billing.map((item) => {
      if (item.id === id) {
        // Labor Cost
        let labor: number = name === 'labor' ? value : parseFloat(item.labor)
        labor = labor || 0
        const laborCost = Math.round((labor / 60) * laborPerHour * 100) / 100

        // Materials Cost
        let materials: number = name === 'materials' ? value : parseFloat(item.materials)
        materials = materials || 0

        // Unit Costs
        let unitCost: number = name === 'unitCost' ? value : parseFloat(item.unitCost)
        unitCost = unitCost && projectQuantity ? unitCost * projectQuantity : 0

        // Total Cost
        const newAmount = laborCost + materials + unitCost
        const updatedItem = {
          ...item,
          [name]: value,
          amount: newAmount,
        }
        return updatedItem
      }
      return item
    })
    // @ts-ignore
    setBilling(newList)
  }

  function addRow() {
    const id = 'added' + billing.length
    // @ts-ignore
    setBilling(billing.concat({ ...defaultBilling, id: id }))
  }

  return (
    <GenericModal
      heading={'ASN Non-Compliance Fees'}
      show={open}
      size="xl"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => usePost(`/api/inventory/project/${project}/billing/`, { billing }, csrf)}
        >
          Update
        </GlobalStyles.Button>
      }
    >
      <GlobalStyles.DataTable>
        <thead>
          <tr>
            <th>
              <span className="text">User</span>
            </th>
            <th>
              <span className="text">Category</span>
            </th>
            <th>
              <span className="text">Labor (Min)</span>
            </th>
            <th>
              <span className="text">Materials ($)</span>
            </th>
            <th>
              <span className="text">Unit Cost ($)</span>
            </th>
            <th>
              <span className="text">Notes</span>
            </th>
            <th>
              <span className="text center">Labor Cost ($&nbsp;/&nbsp;Hour)</span>
            </th>
            <th>
              <span className="text center">Total Cost</span>
            </th>
            <th>
              <span className="text center">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {billing?.map((c, id) => (
            <GlobalStyles.TableRow key={id}>
              <TableCell>
                <Form.Select value={c.user} onChange={(e: any) => handleChange(c.id, 'user', e.target.value)}>
                  <option value={''} disabled hidden>
                    -User-
                  </option>
                  {users.map((u: any) => (
                    <option value={u.id} key={u.id}>
                      {u.first_name} {u.last_name}
                    </option>
                  ))}
                </Form.Select>
              </TableCell>
              <TableCell>
                <Form.Select value={c.category} onChange={(e: any) => handleChange(c.id, 'category', e.target.value)}>
                  <option value={''} disabled hidden>
                    --Category--
                  </option>
                  <option value={'project'}>Project Fees</option>
                  <option value={'labelling'}>Labelling Required</option>
                  <option value={'unit_count'}>Unit Count Required</option>
                  <option value={'mixed_cartons'}>Mixed Cartons</option>
                  <option value={'polybags'}>Polybags Required</option>
                  <option value={'non_standard_pallets'}>Oversized Pallets</option>
                </Form.Select>
              </TableCell>
              <TableCell>
                <Form.Control
                  type="number"
                  min="30"
                  step="30"
                  placeholder="Labor (Min)"
                  required
                  value={c.labor}
                  onChange={(e: any) => handleChange(c.id, 'labor', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <Form.Control
                  type="number"
                  step="5"
                  min="0"
                  placeholder="Materials ($)"
                  required
                  value={c.materials}
                  onChange={(e: any) => handleChange(c.id, 'materials', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <Form.Control
                  type="number"
                  step=".1"
                  min="0"
                  placeholder="Unit Cost ($)"
                  required
                  value={c.unitCost}
                  onChange={(e: any) => handleChange(c.id, 'unitCost', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <Form.Control
                  type="text"
                  placeholder="Labor Notes"
                  value={c.notes}
                  onChange={(e: any) => handleChange(c.id, 'notes', e.target.value)}
                />
              </TableCell>
              <TableCell input={<StatusBoxStyled className={'silver'}>{'$' + laborPerHour}</StatusBoxStyled>} center />
              <TableCell
                input={
                  <StatusBoxStyled className={parseFloat(c.amount) > 0 ? 'blue' : 'yellow'}>
                    {'$' + c.amount}
                  </StatusBoxStyled>
                }
                center
              />
              <TableCell
                input={
                  <div className="dropdown__container" style={{ minWidth: 60 }}>
                    {billing.length > 1 ? (
                      <button onClick={() => setBilling(billing.filter((e) => e !== c))}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    ) : null}
                    <button onClick={() => addRow()}>
                      <FontAwesomeIcon icon={faLayerPlus} />
                    </button>
                  </div>
                }
                center
              />
            </GlobalStyles.TableRow>
          ))}
        </tbody>
        {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      </GlobalStyles.DataTable>
    </GenericModal>
  )
}
