import React, { useRef, useState, useEffect } from 'react'
import { useFetch, usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'

import { LogisticsModalProps } from './types'
import { Calendar } from 'react-date-range'
import { DateRangeStyled } from '../styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/pro-duotone-svg-icons'

export const LogisticsModal = ({ order, logisticsModal, setLogisticsModal }: LogisticsModalProps) => {
  const {
    state: { csrf },
  } = globalState()
  const url = `/api/order/${order}/details/`
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})

  const [focused, setFocused] = useState('')
  const [validated, setValidated] = useState(false)
  const node = useRef<HTMLFormElement | null>(null)
  const [logistics, setLogistics] = useState<any>({
    ship_before: '',
    ship_after: '',
    b_to_b: false,
  })
  var minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)

  useEffect(() => {
    if (loaded) {
      setLogistics({
        ...logistics,
        ship_before: resp.shipBefore ? new Date(resp.shipBefore.replace(/-/g, '/')) : '',
        ship_after: resp.shipAfter ? new Date(resp.shipAfter.replace(/-/g, '/')) : '',
        b_to_b: resp.bToB,
      })
    }
  }, [loaded])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }
    const ship_before = logistics.ship_before
      ? `${logistics.ship_before.getFullYear()}-${logistics.ship_before.getMonth() + 1}-${logistics.ship_before.getDate()}`
      : null
    const ship_after = logistics.ship_after
      ? `${logistics.ship_after.getFullYear()}-${logistics.ship_after.getMonth() + 1}-${logistics.ship_after.getDate()}`
      : null
    usePut(`/api/order/${order}/`, { ...logistics, ...{ ship_before, ship_after } }, csrf)
  }

  return (
    <GenericModal
      heading={'Edit B2B Details'}
      show={logisticsModal}
      onHide={() => setLogisticsModal(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>Update</GlobalStyles.Button>}
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '1em' }}>
          <Form.Label style={{ marginTop: '1em' }}>Ship After Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={logistics.ship_after ? logistics.ship_after.toLocaleDateString('en-US').replace(/\//g, '-') : ''}
              onChange={(e) => null}
              placeholder="Choose A Date"
              onFocus={() => setFocused('ship_after')}
            />
            <GlobalStyles.ClearInputButton
              type="button"
              onClick={() => {
                setFocused('')
                setLogistics({ ...logistics, ship_after: '' })
              }}
            >
              Clear
            </GlobalStyles.ClearInputButton>
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </InputGroup.Text>
          </InputGroup>
          {focused === 'ship_after' && (
            <DateRangeStyled>
              <Calendar
                onChange={(item) => setLogistics({ ...logistics, ship_after: item })}
                date={logistics.ship_after}
                className="hide-in-percy"
                minDate={minDate}
              />
            </DateRangeStyled>
          )}
          <Form.Label style={{ marginTop: '1em' }}>Ship Before Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={logistics.ship_before ? logistics.ship_before.toLocaleDateString('en-US').replace(/\//g, '-') : ''}
              onChange={(e) => null}
              placeholder="Choose A Date"
              onFocus={() => setFocused('ship_before')}
            />
            <GlobalStyles.ClearInputButton
              type="button"
              onClick={() => {
                setFocused('')
                setLogistics({ ...logistics, ship_before: '' })
              }}
            >
              Clear
            </GlobalStyles.ClearInputButton>
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </InputGroup.Text>
          </InputGroup>
          {focused === 'ship_before' && (
            <DateRangeStyled>
              <Calendar
                onChange={(item) => setLogistics({ ...logistics, ship_before: item })}
                date={logistics.ship_before}
                className="hide-in-percy"
                minDate={minDate}
              />
            </DateRangeStyled>
          )}
          <InputGroup style={{ marginTop: '20px ' }}>
            <Checkbox
              selected={logistics.b_to_b}
              setSelected={() => setLogistics({ ...logistics, b_to_b: !logistics.b_to_b })}
            />
            <p style={{ padding: '0 1em' }}>B2B Order</p>
          </InputGroup>
        </Form.Group>
      </Form>
    </GenericModal>
  )
}
