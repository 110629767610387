import React, { useState, useEffect, useRef } from 'react'
import { useFetch, usePost } from '../../components/component-items/helpers'
import { globalState } from '../../store'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { BackgroundLayer } from '../products/product/variant/styles'

// Components
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'
import CardLoading from '../../components/component-items/loading-popover'
import { Pages } from '../../components/component-items/pager'
import { TableCell, THSort } from '../../components/component-items/datatable'
import { StatusBoxStyled } from '../../components/component-items/status-box'
import { SearchBox } from '../../components/component-items/search'
import { AdvancedSelect } from '../../components/component-items/advanced-select'
import { PrintInventoryItemsModal, PrintItemType } from '../../components/modals'
import { Form, InputGroup } from 'react-bootstrap'
import { UploadAdjustInventoryModal } from './adjust-inventory-modal'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar } from 'react-date-range'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faPrintSearch,
  faExchangeAlt,
  faCalendarWeek,
  faObjectGroup,
  faTally,
} from '@fortawesome/pro-duotone-svg-icons'

import { MoveInventoryItemProps, UpdateInventoryItemProps, InventoryItemQuantityProps } from './types'
import { LargeButton } from '../../components/component-items/buttons'

export const MoveInventoryItem = ({ id, location_barcode, available, open, setOpen }: MoveInventoryItemProps) => {
  const {
    state: { csrf },
  } = globalState()
  const node = useRef<HTMLFormElement>(null)

  const [moveTo, setMoveTo] = useState('')
  const [qty, setQty] = useState(0)
  const [locationType, setLocationType] = useState('pickable')

  const [validated, setValidated] = useState(false)

  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus()
      inputElement.current.select()
    }
  }, [open])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    usePost(`/api/inventory/inventory-item/${id}/move/`, { ...{ moveTo, qty, locationType } }, csrf)
  }

  return (
    <GenericModal
      heading={'Move Inventory to Another Location'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>Move Inventory</GlobalStyles.Button>}
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '1em' }}>
          <Form.Label>Move Inventory From</Form.Label>
          <Form.Control type="text" value={location_barcode + ` (${available})`} disabled />
          <Form.Label style={{ marginTop: '1em' }}>New Location Type</Form.Label>
          <Form.Select name="type" required value={locationType} onChange={(e) => setLocationType(e.target.value)}>
            <option value="pickable">Pickable</option>
            <option value="backstock">Backstock</option>
            <option value="defective">Defective</option>
          </Form.Select>
          <Form.Label style={{ marginTop: '1em' }}>Move Inventory To</Form.Label>
          <Form.Control
            type="text"
            required
            value={moveTo}
            ref={inputElement}
            onChange={(e) => setMoveTo(e.target.value)}
          />
          <Form.Label style={{ marginTop: '1em' }}>Quantity</Form.Label>
          <Form.Control type="number" required min="1" value={qty} onChange={(e: any) => setQty(e.target.value)} />
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

export const DateRangeStyled = styled.div`
  overflow: scroll;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em auto;
  width: fit-content;
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0;
  }
`

export const UpdateInventoryItem = ({
  id,
  location_barcode,
  inventory_control_category,
  lot_number,
  inventory_type,
  manufacture_date,
  best_before_date,
  expiry_date,
  received_date,
  open,
  setOpen,
}: UpdateInventoryItemProps) => {
  const {
    state: { csrf },
  } = globalState()

  const node = useRef<HTMLFormElement>(null)
  const [inventoryFields, setInventoryFields] = useState<any>({
    inventory_type: 'pickable',
    lot_number: '',
    expiry_date: new Date() || '',
    best_before_date: new Date() || '',
    manufacture_date: new Date() || '',
    received_date: new Date() || '',
  })

  const [focused, setFocused] = React.useState('')
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    setInventoryFields({
      inventory_type: inventory_type,
      lot_number: lot_number,
      manufacture_date: manufacture_date ? new Date(manufacture_date.replace(/-/g, '/')) : '',
      best_before_date: best_before_date ? new Date(best_before_date.replace(/-/g, '/')) : '',
      expiry_date: expiry_date ? new Date(expiry_date.replace(/-/g, '/')) : '',
      received_date: received_date ? new Date(received_date.replace(/-/g, '/')) : '',
    })
  }, [lot_number, inventory_type, manufacture_date, best_before_date, expiry_date, received_date])

  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus()
      inputElement.current.select()
    }
  }, [open])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    const expiry_date = inventoryFields.expiry_date
      ? inventoryFields.expiry_date.toLocaleDateString('en-US').replace(/\//g, '-')
      : null
    const best_before_date = inventoryFields.best_before_date
      ? inventoryFields.best_before_date.toLocaleDateString('en-US').replace(/\//g, '-')
      : null
    const manufacture_date = inventoryFields.manufacture_date
      ? inventoryFields.manufacture_date.toLocaleDateString('en-US').replace(/\//g, '-')
      : null
    const received_date = inventoryFields.received_date
      ? inventoryFields.received_date.toLocaleDateString('en-US').replace(/\//g, '-')
      : null

    usePost(
      `/api/inventory/inventory-item/${id}/details/`,
      { ...inventoryFields, ...{ expiry_date, best_before_date, manufacture_date, received_date } },
      csrf
    )
  }

  return (
    <GenericModal
      heading={'Update Inventory Item'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>Update Inventory</GlobalStyles.Button>}
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '1em' }}>
          <Form.Label>Location</Form.Label>
          <Form.Control type="text" value={location_barcode} disabled />
          <Form.Label style={{ marginTop: '1em' }}>Lot Number</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={inventoryFields.lot_number}
              onChange={(e) => setInventoryFields({ ...inventoryFields, lot_number: e.target.value })}
              placeholder="Lot Number"
              required={inventory_control_category == 'lot_number'}
            />
            <GlobalStyles.ClearInputButton
              type="button"
              onClick={() => setInventoryFields({ ...inventoryFields, lot_number: '' })}
            >
              Clear
            </GlobalStyles.ClearInputButton>
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faObjectGroup} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
          </InputGroup>
          <Form.Label style={{ marginTop: '1em' }}>Expiry Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={
                inventoryFields.expiry_date
                  ? inventoryFields.expiry_date.toLocaleDateString('en-US').replace(/\//g, '-')
                  : ''
              }
              onChange={(e) => null}
              placeholder="Choose A Date"
              onFocus={() => setFocused('expiry_date')}
              required={inventory_control_category == 'expiry_date'}
            />
            <GlobalStyles.ClearInputButton
              type="button"
              onClick={() => {
                setFocused('')
                setInventoryFields({ ...inventoryFields, expiry_date: '' })
              }}
            >
              Clear
            </GlobalStyles.ClearInputButton>
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
          </InputGroup>
          {focused === 'expiry_date' ? (
            <DateRangeStyled>
              <Calendar
                onChange={(item) => setInventoryFields({ ...inventoryFields, expiry_date: item })}
                date={inventoryFields.expiry_date}
                className="hide-in-percy"
              />
            </DateRangeStyled>
          ) : null}
          <Form.Label style={{ marginTop: '1em' }}>Received Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={
                inventoryFields.received_date
                  ? inventoryFields.received_date.toLocaleDateString('en-US').replace(/\//g, '-')
                  : ''
              }
              onChange={(e) => null}
              placeholder="Choose A Date"
              onFocus={() => setFocused('received_date')}
              required={inventory_control_category == 'received_date'}
            />
            <GlobalStyles.ClearInputButton
              type="button"
              onClick={() => {
                setFocused('')
                setInventoryFields({ ...inventoryFields, received_date: '' })
              }}
            >
              Clear
            </GlobalStyles.ClearInputButton>
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
          </InputGroup>
          {focused === 'received_date' ? (
            <DateRangeStyled>
              <Calendar
                onChange={(item) => setInventoryFields({ ...inventoryFields, received_date: item })}
                date={inventoryFields.received_date}
                className="hide-in-percy"
              />
            </DateRangeStyled>
          ) : null}
          <Form.Label style={{ marginTop: '1em' }}>Manufactured Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={
                inventoryFields.manufacture_date
                  ? inventoryFields.manufacture_date.toLocaleDateString('en-US').replace(/\//g, '-')
                  : ''
              }
              onChange={(e) => null}
              placeholder="Choose A Date"
              onFocus={() => setFocused('manufacture_date')}
              required={inventory_control_category == 'manufacture_date'}
            />
            <GlobalStyles.ClearInputButton
              type="button"
              onClick={() => {
                setFocused('')
                setInventoryFields({ ...inventoryFields, manufacture_date: '' })
              }}
            >
              Clear
            </GlobalStyles.ClearInputButton>
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
          </InputGroup>
          {focused === 'manufacture_date' ? (
            <DateRangeStyled>
              <Calendar
                onChange={(item) => setInventoryFields({ ...inventoryFields, manufacture_date: item })}
                date={inventoryFields.manufacture_date}
                className="hide-in-percy"
              />
            </DateRangeStyled>
          ) : null}
          <Form.Label style={{ marginTop: '1em' }}>Best Before Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={
                inventoryFields.best_before_date
                  ? inventoryFields.best_before_date.toLocaleDateString('en-US').replace(/\//g, '-')
                  : ''
              }
              onChange={(e) => null}
              placeholder="Choose A Date"
              onFocus={() => setFocused('best_before_date')}
              required={inventory_control_category == 'best_before_date'}
            />
            <GlobalStyles.ClearInputButton
              type="button"
              onClick={() => {
                setFocused('')
                setInventoryFields({ ...inventoryFields, best_before_date: '' })
              }}
            >
              Clear
            </GlobalStyles.ClearInputButton>
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
          </InputGroup>
          {focused === 'best_before_date' ? (
            <DateRangeStyled>
              <Calendar
                onChange={(item) => setInventoryFields({ ...inventoryFields, best_before_date: item })}
                date={inventoryFields.best_before_date}
                className="hide-in-percy"
              />
            </DateRangeStyled>
          ) : null}
          <Form.Label style={{ marginTop: '1em' }}>Inventory Type</Form.Label>
          <Form.Select
            name="type"
            required
            value={inventoryFields.inventory_type}
            onChange={(e) => setInventoryFields({ ...inventoryFields, inventory_type: e.target.value })}
          >
            <option value="pickable">Pickable</option>
            <option value="backstock">Backstock</option>
          </Form.Select>
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

export const InventoryItemQuantity = ({ id, location_barcode, open, setOpen }: InventoryItemQuantityProps) => {
  const {
    state: { csrf },
  } = globalState()

  const node = useRef<HTMLFormElement>(null)
  const [inventoryFields, setInventoryFields] = useState({
    quantity: 0,
    description: '',
  })
  const [validated, setValidated] = useState(false)

  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus()
      inputElement.current.select()
    }
  }, [open])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    usePost(`/api/inventory/inventory-item/${id}/quantity/`, { ...inventoryFields }, csrf)
  }

  return (
    <GenericModal
      heading={'Update Inventory Quantity'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>Update Inventory Quantity</GlobalStyles.Button>}
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '1em' }}>
          <Form.Label>Location</Form.Label>
          <Form.Control type="text" value={location_barcode} disabled />
          <Form.Label style={{ marginTop: '1em' }}>Add or Remove Quantity</Form.Label>
          <Form.Control
            type="number"
            value={inventoryFields.quantity}
            ref={inputElement}
            onChange={(e) => setInventoryFields({ ...inventoryFields, quantity: Number(e.target.value) })}
          />
          <Form.Label style={{ marginTop: '1em' }}>Reason</Form.Label>
          <Form.Select
            value={inventoryFields.description}
            onChange={(e) => setInventoryFields({ ...inventoryFields, description: e.target.value })}
            required
          >
            <option disabled value="">
              (Please select)
            </option>
            <option value="ASN Audit">ASN Audit</option>
            <option value="Cycle Count">Cycle Count </option>
            <option value="Damaged">Damaged/Defective</option>
            <option value="Manual Order">Manual Order</option>
          </Form.Select>
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

const Tooltip = styled(GlobalStyles.Tooltip)`
  width: 2rem;
  display: inline-flex;
  &:before {
    width: 100px;
    left: -25%;
    transform: translate(-50%, 0);
  }
`

export const InventoryList = () => {
  const {
    state: { inventoryFilterOptions, userIsManager },
    dispatch,
  } = globalState()

  const [buttonEnabled, setButtonEnabled] = useState(true)
  const [moveItemModal, setMoveItemModal] = useState(false)
  const [updateItemModal, setUpdateItemModal] = useState(false)
  const [itemQuantityModal, setItemQuantityModal] = useState(false)
  const [printInventoryItemModal, setPrintInventoryItemModal] = useState(false)
  const [printInventoryItemId, setPrintInventoryItemId] = useState()
  const [activeId, setActiveId] = useState({})
  const [adjustInventoryModal, setAdjustInventoryModal] = useState(false)

  const [sort, setSort] = useState({})
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const [filterOptions, setFilterOptions] = useState(inventoryFilterOptions)
  const setDefaultFilter = () => {
    dispatch({ type: 'inventoryFilterOptions', inventoryFilterOptions: filterOptions })
  }

  const url =
    `/api/inventory/inventory-item/inventory_list?` +
    `page=${page}` +
    `&q=${encodeURIComponent(search)}` +
    `&order_by=${Object.keys(sort).map((k) => k.toLowerCase().replace(' ', '_'))}` +
    `&sort_by=${Object.values(sort).map((k: any) => k.toLowerCase().replace(' ', '_'))}` +
    `&${filterOptions
      .filter(({ selected }: { selected: boolean }) => selected)
      .map(({ label, value, type }: any) =>
        type === 'boolean'
          ? label.toLowerCase().replace(' ', '_') + '=' + String(value)
          : type === 'multi-select'
            ? label.toLowerCase().replace(' ', '_') + '=' + value.map((v: any) => v.value).toString()
            : null
      )
      .join('&')}`
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})
  const count = loaded ? resp.count : 0

  useEffect(() => {
    if (loaded && !error) {
      setButtonEnabled(true)
      setLoading(!loaded)
    }
  }, [loaded])

  const inventoryItems = loaded ? resp.results : []
  const unchangeableInventoryTypes = ['production', 'staging', 'in_transit']
  const totalPages = loaded && inventoryItems.length ? Math.ceil(count / 25) : 1

  const exportCSV = () => {
    setButtonEnabled(false)
    // @ts-ignore
    window.location = url.replace('inventory_list', 'inventory_csv_list') + '&no_page=true'
  }

  return (
    <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            Inventory List<small>{count.toLocaleString()} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {userIsManager && (
            <GlobalStyles.Button
              style={{ minWidth: 125 }}
              onClick={() => setAdjustInventoryModal(true)}
              className="secondary"
            >
              Adjust Inventory
            </GlobalStyles.Button>
          )}
          <GlobalStyles.Button
            style={{ minWidth: 125 }}
            className="secondary"
            disabled={!buttonEnabled}
            onClick={() => exportCSV()}
          >
            Export
          </GlobalStyles.Button>
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus updateURL />
          <AdvancedSelect
            placeholder="Filter"
            {...{ filterOptions, setFilterOptions }}
            setDefault={setDefaultFilter}
            width={350}
          />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <PrintInventoryItemsModal
        itemType={PrintItemType.ProductVariant}
        open={printInventoryItemModal}
        onClose={() => setPrintInventoryItemModal(false)}
        id={printInventoryItemId || 'id'}
      />
      <div style={{ width: '100%', overflow: 'scroll' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <THSort title={'Company'} {...{ sort, setSort }} />
              <THSort title={'Location'} {...{ sort, setSort }} />
              <th>
                <span className="text center">Variant</span>
              </th>
              <th>
                <span className="text center">Inventory Control</span>
              </th>
              <THSort title={'Received'} center {...{ sort, setSort }} />
              <THSort title={'Lot Number'} center {...{ sort, setSort }} />
              <THSort title={'Expiry'} center {...{ sort, setSort }} />
              <THSort title={'Best Before'} center {...{ sort, setSort }} />
              <THSort title={'Manufactured'} center {...{ sort, setSort }} />
              <THSort title={'On Hand'} center {...{ sort, setSort }} />
              <THSort title={'Reserved'} center {...{ sort, setSort }} />
              <THSort title={'Available'} center {...{ sort, setSort }} />
              <THSort title={'Type'} center {...{ sort, setSort }} />
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {inventoryItems.map((i: any, id: number) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell input={i.company} />
                <TableCell
                  input={
                    <Link to={`/location/${i.location}/`} target="_blank">
                      {i.location_barcode}
                    </Link>
                  }
                />
                <TableCell
                  center
                  input={
                    <div>
                      <Link to={`/product/variant/${i.product_variant}/`} target="_blank">
                        {i.variant_sku}
                      </Link>
                    </div>
                  }
                >
                  {i.variant_barcode}
                </TableCell>
                <TableCell center input={i.inventory_control_category} />
                <TableCell center>{i.received_date}</TableCell>
                <TableCell center>{i.lot_number}</TableCell>
                <TableCell center>{i.expiry_date}</TableCell>
                <TableCell center>{i.best_before_date}</TableCell>
                <TableCell center>{i.manufacture_date}</TableCell>
                <TableCell center>
                  <StatusBoxStyled className={i.on_hand ? 'blue' : 'silver'} style={{ minWidth: 75, margin: 'auto' }}>
                    {i.on_hand}
                  </StatusBoxStyled>
                </TableCell>
                <TableCell center>
                  <StatusBoxStyled className={i.reserved ? 'blue' : 'silver'} style={{ minWidth: 75, margin: 'auto' }}>
                    {i.reserved}
                  </StatusBoxStyled>
                </TableCell>
                <TableCell
                  center
                  input={
                    <StatusBoxStyled
                      className={i.available ? 'blue' : 'silver'}
                      style={{ minWidth: 75, margin: 'auto' }}
                    >
                      {i.available}
                    </StatusBoxStyled>
                  }
                />
                <TableCell center style={{ textTransform: 'capitalize' }}>
                  <StatusBoxStyled
                    className={
                      ['pickable', 'backstock'].includes(i.inventory_type)
                        ? 'blue'
                        : ['in_transit', 'staging', 'production'].includes(i.inventory_type)
                          ? 'silver'
                          : 'yellow'
                    }
                    style={{ width: 100, margin: 'auto' }}
                  >
                    {i.inventory_type}
                  </StatusBoxStyled>
                </TableCell>
                <TableCell
                  center
                  input={
                    <div className="dropdown__container">
                      {!unchangeableInventoryTypes.includes(i.inventory_type) && (
                        <>
                          <Tooltip className="subheader__button button" data-title="Edit Inventory Item">
                            <LargeButton
                              onClick={() => {
                                setActiveId(i)
                                setUpdateItemModal(true)
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </LargeButton>
                          </Tooltip>
                          <Tooltip className="subheader__button button" data-title="Add / Subtract from Inventory Item">
                            <LargeButton
                              onClick={() => {
                                setActiveId(i)
                                setItemQuantityModal(true)
                              }}
                            >
                              <FontAwesomeIcon icon={faTally} />
                            </LargeButton>
                          </Tooltip>
                          {i.available > 0 && (
                            <Tooltip
                              className="subheader__button button"
                              data-title="Move Quantity to another Inventory Item"
                            >
                              <LargeButton
                                onClick={() => {
                                  setActiveId(i)
                                  setMoveItemModal(true)
                                }}
                              >
                                <FontAwesomeIcon icon={faExchangeAlt} />
                              </LargeButton>
                            </Tooltip>
                          )}
                          <Tooltip className="subheader__button button" data-title="Print Barcodes for Inventory Item">
                            <LargeButton
                              onClick={() => {
                                setPrintInventoryItemModal(true)
                                setPrintInventoryItemId(i.id)
                              }}
                            >
                              <FontAwesomeIcon icon={faPrintSearch} />
                            </LargeButton>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  }
                />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
      </GlobalStyles.CardFooter>
      {/* @ts-ignore */}
      <UploadAdjustInventoryModal open={adjustInventoryModal} setOpen={setAdjustInventoryModal} url={url} />
      <MoveInventoryItem {...activeId} open={moveItemModal} setOpen={setMoveItemModal} />
      <UpdateInventoryItem {...activeId} open={updateItemModal} setOpen={setUpdateItemModal} />
      <InventoryItemQuantity {...activeId} open={itemQuantityModal} setOpen={setItemQuantityModal} />
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BackgroundLayer>
  )
}
