import { toast } from 'react-toastify'

export const fetchNotifications = async (fetchURL: string) => {
  try {
    const response = await fetch(fetchURL)
    if (!response.ok) {
      toast('There was a problem fetching notifications', { type: 'error' })
      return []
    }
    const notifications = await response.json()
    return notifications
  } catch (error) {
    console.error('There was a problem fetching notifications:', error)
    throw error // Or handle the error as appropriate for your application
  }
}
