import React from 'react'

export type AddressModalProps = {
  addressURL: string
  address: any
  addressModal: boolean
  setAddressModal: any
}

export type PrintLabelsModalProps = {
  id: number | string
  location_id?: number
  qty?: number
  scancode: string
  open: boolean
  setOpen: any
}

export enum PrintItemType {
  InventoryItem,
  ProductVariant,
}

export type PrintInventoryItemsModalProps = {
  id: string
  open: boolean
  itemType: PrintItemType.InventoryItem | PrintItemType.ProductVariant
  onClose: () => void
}

export type MenuOptionProps = {
  url?: string
  onClick?: () => void
  icon: any
  title: string
  arrow?: boolean
  children?: any
  node?: React.RefObject<HTMLLIElement>
}
