import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { globalState } from '../../../../store'
import { useFetch, usePost, notify } from '../../../../components/component-items/helpers'

// Components
import { OrderOptions } from '../../../orders/order/styles'
import { ShippingWorkflow } from '../shipping-workflow'
import { PrePackWorkflow } from '../shipping-workflow/pre-pack-workflow'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import GlobalStyles from '../../../../components/component-items/styles'

// Cards
import { StatusBar } from '../status-bar'
import { ShipmentCard } from './shipment-card'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faRulerCombined } from '@fortawesome/pro-duotone-svg-icons'

import { OrderOptionProps } from './types'

// USPS Retail Ground pieces may measure up to 130 inches in combined length and girth
// https://www.ups.com/pt/en/support/shipping-support/shipping-dimensions-weight.page
const MAX_DIMENSIONS_WARNING = 60
// USPS and UPS Max is around 70 pounds https://www.ups.com/pt/en/support/shipping-support/shipping-dimensions-weight.page
const MAX_WEIGHT_WARNING = 1120

const OrderOption = ({
  url,
  onClick,
  icon,
  title,
  arrow,
  children,
  node,
  active,
  disabled,
  complete,
}: OrderOptionProps) => {
  return (
    <li ref={node} onClick={onClick} className={active ? 'active' : disabled ? 'disabled' : complete ? 'complete' : ''}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const PrePackDimensions = () => <ConfirmDimensions prepack />

export const ConfirmDimensions = ({ prepack }: any) => {
  const order = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()

  const res: any = useFetch(`/api/order/${order}/confirm_dimensions/`, {})
  const loaded = res.loaded
  const resp = loaded ? res.response : {}

  const [updated, setUpdated] = useState('')
  const [packStatus, setPackStatus] = useState({
    fully_packed: false,
    needs_serial_numbers: false,
    serial_number_enabled: false,
    needs_dimensions: false,
    customer_pickup: false,
  })
  const [shipments, setShipments] = useState<any[]>([])

  useEffect(() => {
    if (loaded && resp.shipments.length) {
      setShipments(resp.shipments)
    }
    if (loaded) {
      setPackStatus(resp.pack_status)
    }
  }, [loaded])

  const onSubmit = async () => {
    setUpdated('')
    const resp = await usePost(`/api/order/${order}/confirm_dimensions/`, { shipments }, csrf, false)
    if (!resp.error) {
      setPackStatus(resp.pack_status)
      setUpdated('Dimensions have been updated successfully')
    } else {
      setUpdated('')
    }
  }

  const handleSubmit = async () => {
    const unitFactor = 1
    const showDimensionsWarning = shipments.some(
      (shipment) =>
        Number(shipment.length || 0) >= MAX_DIMENSIONS_WARNING ||
        Number(shipment.width || 0) >= MAX_DIMENSIONS_WARNING ||
        Number(shipment.height || 0) >= MAX_DIMENSIONS_WARNING
    )
    const showWeightWarning = shipments.some(
      (shipment) => Number(shipment.weight || 0) * unitFactor >= MAX_WEIGHT_WARNING
    )
    if (showDimensionsWarning || showWeightWarning) {
      notify({
        title: 'Dimensions or weight are bigger than expected',
        message: 'Are you sure that you want to ship this order?',
        type: 'warning',
        onSubmit,
        autoClose: 0,
        requestConfirmation: true,
        // @ts-ignore
        onDismiss: (): void => {
          const newShipments = shipments.map((shipment) => ({
            ...shipment,
            ...(Number(shipment.weight || 0) >= MAX_WEIGHT_WARNING ? { weight: 0 } : { weight: shipment.weight }),
            ...(Number(shipment.length || 0) >= MAX_DIMENSIONS_WARNING ? { length: 0 } : { length: shipment.length }),
            ...(Number(shipment.width || 0) >= MAX_DIMENSIONS_WARNING ? { width: 0 } : { width: shipment.width }),
            ...(Number(shipment.height || 0) >= MAX_DIMENSIONS_WARNING ? { height: 0 } : { height: shipment.height }),
          }))
          setShipments(newShipments)
        },
      })
    } else {
      onSubmit()
    }
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <StatusBar />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-xl-2">
          <OrderOptions>
            <ul>
              <GlobalStyles.Tooltip data-title="Confirms new dimensions">
                <OrderOption onClick={() => handleSubmit()} icon={faRulerCombined} title={'Update dimensions'} />
              </GlobalStyles.Tooltip>
            </ul>
          </OrderOptions>
        </div>
        <div className="col-lg-7 col-xl-8">
          <div className="row">
            <div className="col-xl-12">
              {updated ? (
                <StatusBoxStyled className={'green'} style={{ marginBottom: '1em', textAlign: 'center' }}>
                  {updated}
                </StatusBoxStyled>
              ) : null}
              {shipments.map((s, id) => (
                <ShipmentCard {...s} {...{ shipments, setShipments }} sIndex={id} total={shipments.length} key={id} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          {prepack ? (
            <PrePackWorkflow {...packStatus} current={'pre_pack_dimensions'} />
          ) : (
            <ShippingWorkflow {...packStatus} current={'confirm_dimensions'} />
          )}
        </div>
      </div>
    </>
  )
}
