import React from 'react'
import { usePut } from '../../../components/component-items/helpers'
import { StatusBarStyled } from '../../../components/component-items/status-bar'
import { globalState } from '../../../store'
import { OverdueLabel } from './styles'
import Form from 'react-bootstrap/Form'

// Enums
import { ShipNoticeStatus } from '../../../enums'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlaneArrival,
  faHourglassHalf,
  faHourglassEnd,
  faRampLoading,
  faCheckDouble,
  faWarehouse,
  faDolly,
  faBoxCheck,
} from '@fortawesome/pro-light-svg-icons'

import type { StatusBarProps } from './types'

export const StatusBar = ({
  asn,
  asnStatus,
  statusId,
  lastUpdate,
  company,
  alternateWarehouses,
  warehouse,
  isOverdue,
}: StatusBarProps) => {
  const {
    state: { csrf },
  } = globalState()
  return (
    <div className="row">
      <div className="col">
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              {statusId === ShipNoticeStatus.Arrived ? (
                <FontAwesomeIcon icon={faPlaneArrival} style={{ color: '#5d78ff' }} />
              ) : statusId === ShipNoticeStatus.Completed ? (
                <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#51e2d3' }} />
              ) : statusId === ShipNoticeStatus.InTransit && isOverdue === true ? (
                <FontAwesomeIcon icon={faHourglassEnd} style={{ color: '#CC0000' }} />
              ) : statusId === ShipNoticeStatus.InTransit ? (
                <FontAwesomeIcon icon={faHourglassHalf} style={{ color: '#5d78ff' }} />
              ) : statusId === ShipNoticeStatus.Received ? (
                <FontAwesomeIcon icon={faBoxCheck} style={{ color: '#5d78ff' }} />
              ) : (
                <FontAwesomeIcon icon={faDolly} style={{ color: '#5d78ff' }} />
              )}
            </div>
            <div className="status-text">
              <span>{asnStatus}</span>
              {isOverdue === true ? (
                <OverdueLabel className="badge badge-pill badge-danger">OVERDUE</OverdueLabel>
              ) : null}
              <br />
              Last Update <strong>{lastUpdate}</strong>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon icon={faRampLoading} />
            </div>
            <div className="status-text">
              Company: <strong>{company}</strong>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text" style={{ paddingRight: '1em' }}>
              Facility:
            </div>
            <div className="status-text">
              {alternateWarehouses.length && statusId === ShipNoticeStatus.InTransit ? (
                <Form.Select
                  defaultValue={'default'}
                  onChange={(e) => usePut(`/api/inventory/asn/${asn}/`, { warehouse: e.target.value }, csrf)}
                >
                  <option value={'default'}>{warehouse}</option>
                  {alternateWarehouses.map((w, id) => (
                    <option value={w.id} key={id}>
                      {w.name}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <strong>{warehouse}</strong>
              )}
            </div>
            <div className="status-icon" style={{ marginLeft: '2em' }}>
              <FontAwesomeIcon icon={faWarehouse} />
            </div>
          </div>
        </StatusBarStyled>
      </div>
    </div>
  )
}
