import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { globalState } from '../../../../store'
import { useFetch, usePost, notify } from '../../../../components/component-items/helpers'
import { Form, Row, Col, InputGroup } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

// Components
import GlobalStyles from '../../../../components/component-items/styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode, faBarcode } from '@fortawesome/pro-regular-svg-icons'

export const PickingLocation = () => {
  const {
    state: { csrf },
  } = globalState()
  const { id: batch, inventory_item } = useParams<{ id: string; inventory_item: string }>()
  const {
    response: resp,
    loaded,
    error,
    placeholder,
  }: any = useFetch(`/api/inventory/batch/${batch}/pick/?inventory_item=${inventory_item}`, {
    redirect: 'follow',
  })

  // Update pick details after loading response
  useEffect(() => {
    if (loaded) {
      setPickDetails(resp)
      if (resp.pick_confirmation) {
        // Only confirm location if pick_confirmation is not required to use QR code
        // setConfirmed(true)
      }
    }
  }, [loaded])

  const company = loaded ? resp.company : 'N/A'
  const type = loaded ? resp.type : ''

  const [loading, setLoading] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [scanLocation, setScanLocation] = useState('')
  const [scanQRCode, setScanQRCode] = useState('')
  const [scanBarcode, setScanBarcode] = useState('')
  const [scanQty, setScanQty] = useState(1)
  const [pickDetails, setPickDetails] = useState({
    img: '',
    sku: '',
    name: '',
    location: '',
    qr_code: '',
    barcode: '',
    quantity: '',
    pending_items: false,
    pick_confirmation: false,
  })
  const [validated, setValidated] = useState(false)

  const node = useRef<HTMLFormElement>(null)
  const inputLocation = useRef<HTMLInputElement>(null)
  const inputBarcode = useRef<HTMLInputElement>(null)
  // Set input field box to location on load
  useEffect(() => {
    if (inputLocation.current) {
      inputLocation.current.focus()
      inputLocation.current.select()
    }
  }, [])

  // Set input field box to bacode after confirming location
  useEffect(() => {
    if (confirmed && inputBarcode.current) {
      inputBarcode.current.focus()
      inputBarcode.current.select()
    }
  }, [confirmed])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)

    // If the form is invalid, return
    if (node?.current?.checkValidity() === false) {
      return
    }

    if (!confirmed) {
      confirmLocation()
      setValidated(false)
      return
    }

    // Quantity must be entered here
    if (!scanQty) {
      notify({ type: 'error', message: 'Quantity required!' })
      return
    }

    // Prevent multiple submissions
    if (loading) {
      return
    }

    setLoading(true)
    const resp = await usePost(
      `/api/inventory/batch/${batch}/pick/?inventory_item=${inventory_item}`,
      { location: scanLocation, barcode: scanBarcode, qr_code: scanQRCode, quantity: scanQty },
      csrf,
      false
    )
    setLoading(false)
    if (resp && resp.quantity) {
      setPickDetails(resp)
      setScanBarcode('')
      setScanQRCode('')
      setValidated(false)
      if (inputBarcode.current) {
        inputBarcode?.current!.focus()
        inputBarcode?.current!.select()
      }
    }
  }

  const confirmLocation = () => {
    if (pickDetails.location === scanLocation) {
      setConfirmed(true)
    } else {
      notify({ type: 'error', message: 'Locations do not match!' })
    }
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, maximum-scale=0.8" />
      </Helmet>
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
        <GlobalStyles.FullPageCard>
          <GlobalStyles.CardHeader>
            <GlobalStyles.CardTitle>
              {confirmed ? (
                <h3>PICK ITEM</h3>
              ) : (
                <h3>
                  GO TO LOCATION <u>{pickDetails.location}</u>
                </h3>
              )}
            </GlobalStyles.CardTitle>
            <GlobalStyles.CardTitle>
              <div style={{ textAlign: 'right', paddingLeft: '1em' }}>
                Batch Picking for <u>{company}</u>
              </div>
            </GlobalStyles.CardTitle>
          </GlobalStyles.CardHeader>
          <div style={{ margin: '2em auto', width: '90%' }}>
            {!confirmed ? (
              <Row>
                <Form.Group as={Col} md="12" className="required mb-3">
                  <Form.Label>
                    Location: <strong>{pickDetails.location}</strong>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    ref={inputLocation}
                    value={scanLocation}
                    placeholder={pickDetails.location}
                    onChange={(e) => setScanLocation(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please input a valid location.</Form.Control.Feedback>
                </Form.Group>
              </Row>
            ) : (
              <Row>
                <Form.Group as={Col} md="12" style={{ display: 'flex' }} className="mb-3">
                  <img style={{ width: 'auto', height: 75, margin: 'auto' }} src={pickDetails.img} />
                </Form.Group>
                <Form.Group className="col-12 mb-3">
                  <Form.Label>Product Variant</Form.Label>
                  <Form.Control type="text" name="location" value={pickDetails.name} disabled />
                </Form.Group>
                <Form.Group className="col-6 mb-3">
                  <Form.Label>SKU</Form.Label>
                  <Form.Control type="text" name="location" value={pickDetails.sku} disabled />
                </Form.Group>
                <Form.Group className="col-6 mb-3">
                  <Form.Label>Barcode</Form.Label>
                  <Form.Control type="text" name="location" value={pickDetails.barcode} disabled />
                </Form.Group>
                {pickDetails.pick_confirmation ? (
                  <Form.Group as={Col} md="12" className="required mb-3">
                    <Form.Label>Inventory Item QR Code</Form.Label>
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={faQrcode} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="barcode"
                        required
                        disabled={!confirmed}
                        placeholder={pickDetails.qr_code}
                        value={scanQRCode}
                        onChange={(e) => setScanQRCode(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please input a valid inventory item barcode.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                ) : (
                  <Form.Group as={Col} md="12" className="required mb-3">
                    <Form.Label>
                      Product Barcode: <strong>{pickDetails.barcode}</strong>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={faBarcode} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="barcode"
                        ref={inputBarcode}
                        disabled={!confirmed}
                        placeholder={pickDetails.barcode}
                        value={scanBarcode}
                        required
                        onChange={(e) => setScanBarcode(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please input a valid product barcode.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                )}
                <Form.Group as={Col} md="12" className="required mb-3">
                  <Form.Label>Qty ({pickDetails.quantity} Remaining)</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder={''}
                      name="quantity"
                      disabled={!confirmed}
                      required
                      value={confirmed ? scanQty : pickDetails.quantity}
                      onChange={(e: any) => setScanQty(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Please input a valid quantity.</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
            )}
            <GlobalStyles.Button style={{ minWidth: '100%', margin: '1em 0 0' }} onClick={(e) => handleSubmit(e)}>
              Submit
            </GlobalStyles.Button>
            <a href={`/api/inventory/batch/${batch}/pick/?inventory_item=${inventory_item}&skip=1`}>
              <GlobalStyles.Button type="button" style={{ minWidth: '100%', margin: '1em 0 0' }} className="secondary">
                Skip
              </GlobalStyles.Button>
            </a>
            {/* Give option to slot items for bulk-picking batches */}
            {type !== 'pick_and_sort' && pickDetails.pending_items ? (
              <a href={`/api/inventory/batch/${batch}/slot/`}>
                <GlobalStyles.Button
                  type="button"
                  style={{ minWidth: '100%', margin: '1em 0 0' }}
                  className="secondary"
                >
                  Skip &amp; Drop Off Picked Inventory
                </GlobalStyles.Button>
              </a>
            ) : null}
          </div>
        </GlobalStyles.FullPageCard>
      </Form>
    </>
  )
}
