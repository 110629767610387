import styled from 'styled-components'

export const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
  }
`

export const CardHolder = styled.div`
  padding: 2em;
  max-width: 800px;
  margin: auto;
`
