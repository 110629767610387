import React, { useEffect } from 'react'
import { useState, useRef } from 'react'

// Components
import { Col, Form, InputGroup } from 'react-bootstrap'
import { DateRangeStyled, RecurringSection, SubHeader } from '../../../pages/billing/header/add-ons/styles'
import { RadioButton } from '../radio-button'
import { useClick } from '../helpers'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar } from 'react-date-range'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons'

export const EndRecurrence = ({ setEndRecurrence }: any) => {
  // Ending Recurrence Section
  const [recurrenceEnd, setRecurrenceEnd] = useState('stop-never')
  const [endDate, setEndDate] = useState<Date | undefined>()
  const [showCalendar, setShowCalendar] = useState(false)
  const [interval, setInterval] = useState<any>(1)

  // Handle click off calendar
  const clickNode = useRef(null)
  useClick(clickNode, setShowCalendar)

  useEffect(() => {
    var endData: any = { recurrenceEnd: recurrenceEnd }
    if (recurrenceEnd === 'stop-after-date') {
      // Format as YYYY-MM-DD.
      endData['endDate'] = endDate?.toISOString().split('T')[0]
    } else if (recurrenceEnd === 'stop-after-interval') {
      endData['occurences'] = interval
    }
    setEndRecurrence(endData)
  }, [recurrenceEnd, endDate, interval])

  return (
    <Form.Group as={Col} xl="9">
      <SubHeader>End Recurrence</SubHeader>
      <RecurringSection>
        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => setRecurrenceEnd('stop-never')}>
          <RadioButton
            name="recurrenceEndRadio"
            value="stop-never"
            checked={recurrenceEnd === 'stop-never'}
            onChange={(e: any) => setRecurrenceEnd(e.target.value)}
          />
          <div>
            <h4 style={{ fontSize: '1.2rem', margin: 0 }}>Never</h4>
            <p style={{ margin: 0 }}>Transaction will continue indefinitely</p>
          </div>
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center', padding: '2em 0 0' }}
          onClick={() => setRecurrenceEnd('stop-after-date')}
        >
          <RadioButton
            name="recurrenceEndRadio"
            value="stop-after-date"
            checked={recurrenceEnd === 'stop-after-date'}
            onChange={(e: any) => setRecurrenceEnd(e.target.value)}
          />
          <div>
            <h4 style={{ fontSize: '1.2rem', margin: 0 }}>Stop After&nbsp;</h4>
            <p style={{ margin: 0 }}>Transactions will run until this date</p>
          </div>
          <div ref={clickNode} style={{ position: 'relative' }}>
            <InputGroup>
              <Form.Control
                type="text"
                isInvalid={recurrenceEnd === 'stop-after-date' && !endDate}
                value={endDate?.toLocaleDateString('en-US').replace(/\//g, '-')}
                placeholder="Choose A Date"
                onFocus={() => setShowCalendar(true)}
                style={{ margin: '0 0 0 1em' }}
              />
              <InputGroup.Text id="inputGroupAppend">
                <FontAwesomeIcon icon={faCalendarWeek} />
              </InputGroup.Text>
            </InputGroup>
            {showCalendar && (
              <DateRangeStyled>
                <Calendar
                  onChange={(e) => {
                    setEndDate(e)
                    setShowCalendar(false)
                  }}
                  date={endDate}
                  className="hide-in-percy"
                  minDate={new Date()}
                />
              </DateRangeStyled>
            )}
          </div>
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center', padding: '2em 0 0' }}
          onClick={() => setRecurrenceEnd('stop-after-interval')}
        >
          <RadioButton
            name="recurrenceEndRadio"
            value="stop-after-interval"
            checked={recurrenceEnd === 'stop-after-interval'}
            onChange={(e: any) => setRecurrenceEnd(e.target.value)}
          />
          <h4 style={{ fontSize: '1.2rem', margin: 0 }}>Stop After&nbsp;</h4>
          <Form.Control
            type="number"
            isInvalid={recurrenceEnd === 'stop-after-interval' && (!interval || interval < 1)}
            value={interval}
            onChange={(e) => setInterval(e.target.value)}
            style={{ width: 100, margin: '0 1em' }}
          />
          <h4 style={{ fontSize: '1.2rem', margin: 0 }}>&nbsp;intervals</h4>
        </div>
      </RecurringSection>
      <p style={{ paddingTop: '1em' }}>Transactions that recur and never end will need to be cancelled manually.</p>
    </Form.Group>
  )
}
