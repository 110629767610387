import styled from 'styled-components'

import { DropdownStyled } from '../../../components/component-items/export-tools'

export const BackgroundLayer = styled.div`
  // box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
`
export const NavButton = styled.button`
  color: ${(props) => props.theme.colors.midGray};
`

export const OrderOptions = styled(BackgroundLayer)`
  flex-direction: column;
  ul {
    display: block;
    padding: 0.5rem 0;
    margin: 10px 0;
    list-style: none;
    border: 0;
  }
  li {
    position: relative;
    a {
      transition: all 0.3s;
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 0.75rem 1rem 0.75rem 1.5rem;
      &:hover {
        background-color: ${(props) => props.theme.colors.whiteLilac};
      }
      .icon {
        flex: 0 0 30px;
        font-size: 1.25rem;
        color: ${(props) => props.theme.colors.midGray};
      }
      .text {
        font-weight: 500;
        flex-grow: 1;
        font-size: 1rem;
        color: ${(props) => props.theme.colors.midGray};
      }
    }
  }
`

export const OrderInfo = styled(BackgroundLayer)`
  justify-content: space-between;
  padding: 1em;
  margin-bottom: 0;
  height: 100%;
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 1em;
    color: ${(props) => props.theme.colors.mulledWine};
    div {
      width: 100%;
    }
    span {
      margin-left: 1rem;
      float: right;
      font-weight: 500;
      max-width: 60%;
      text-align: right;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    p {
      margin-bottom: 0;
    }
  }
  .return-empty {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5em;
    div {
      font-size: 1em;
    }
  }
`

export const Dropdown = styled(DropdownStyled)`
  margin: 0 !important;
  padding: 15px 0 10px !important;
  top: 0;
  left: 105%;
`

export const FilterListItem = styled.div`
  margin: 0 auto 1em;
  width: 50%;
`

export const DateRangeStyled = styled.div`
  overflow: scroll;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.19);
  margin: 2em auto;
  width: fit-content;
`
