import styled from 'styled-components'

const Onboarding = styled.div`
  .panel {
    transition: all 0.5s ease !important;
    right: 0;
    left: auto;
    opacity: 1;
    z-index: 1001;
    position: fixed;
    bottom: 0;
    overflow-y: auto;
    width: 350px;
    height: auto;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.13);
    .panel_head {
      user-select: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem;
      background-color: #242939;
      h3 {
        font-size: 1.4rem;
        font-weight: 500;
        color: white;
        margin: 0;
        padding: 0;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        background-color: ${(props) => props.theme.colors.whiteLilac};
        transition: all 0.3s !important;
        border-radius: 4px;
        border: none;
        i {
          transition: all 0.3s;
          font-size: 1.2rem;
          color: ${(props) => props.theme.colors.raven};
        }
        &:hover {
          background: ${(props) => props.theme.colors.gallery};
        }
      }
    }
    .panel_body {
      user-select: none;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      position: relative;
      padding: 1em 1.5rem 0;
    }
    .nav__item {
      user-select: none;
      position: relative;
      display: flex;
      width: 100%;
      border-radius: 6px;
      align-items: center;
      padding: 0.75rem 1rem;
      background: ${(props) => props.theme.colors.whiteLilac};
      transition: all 0.3s;
      margin: 3px 0;
      .item-icon {
        font-size: 1.75rem;
        color: ${(props) => props.theme.colors.cornflowerBlue};
        display: inline-block;
        text-align: center;
      }
      .item-title {
        padding: 0 15px;
        max-width: 80%;
        display: inline-block;
        text-align: left;
        font-size: 1.2rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors.smoky};
      }
      .item-dropdown {
        position: absolute;
        right: 20px;
        font-size: 2rem;
        color: ${(props) => props.theme.colors.cornflowerBlue};
        text-align: center;
      }
      &.active {
        cursor: pointer;
      }
      &:hover {
        background: ${(props) => props.theme.colors.gallery};
      }
    }
    .nav_content {
      transform: scaleY(1);
      overflow: hidden;
      transition: all 0.3s ease;
    }
    .animated {
      transform: scaleY(0);
    }
  }
  .hidden {
    right: -100% !important;
    opacity: 0;
  }
`

export const Styled = {
  Onboarding,
}
