import React, { useState, useEffect, useRef } from 'react'
import { useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import Checkbox from '../../../components/component-items/checkbox'
import { Form, Col, InputGroup, Row } from 'react-bootstrap'

export const ShippingBlacklist = () => {
  const {
    state: { csrf, companyId },
  } = globalState()
  const {
    response: resp,
    loaded,
    error,
    placeholder,
  }: any = useFetch(`/api/company/${companyId}/shipping-method/blacklist/`, {})

  const blacklistedCarriers = loaded ? resp.blacklisted_carriers : []
  const blacklistedServiceLevels = loaded ? resp.blacklisted_service_levels : []
  const serviceLevelList = loaded ? resp.serviceLevelList : []
  const sortedServiceLevelList = serviceLevelList.sort(
    (a: any, b: any) =>
      serviceLevelList.filter((c: any) => c.carrier__name === b.carrier__name).length -
      serviceLevelList.filter((c: any) => c.carrier__name === a.carrier__name).length
  )
  const carrierGrouping = [...new Set(sortedServiceLevelList.map((sl: any) => sl.carrier__name))]

  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)
  const [carriers, setCarriers] = useState({})
  const [serviceLevels, setServiceLevels] = useState<any>({})

  useEffect(() => {
    setCarriers(blacklistedCarriers.reduce((ac: any, c: number) => ({ ...ac, [c]: true }), {}))
    setServiceLevels(blacklistedServiceLevels.reduce((ac: any, c: number) => ({ ...ac, [c]: true }), {}))
  }, [blacklistedCarriers, blacklistedServiceLevels])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    usePost(`/api/company/${companyId}/shipping-method/blacklist/`, { ...{ carriers, serviceLevels } }, csrf)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>BlackListed Carriers &amp; Service Levels</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button onClick={handleSubmit}>Save</GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <Form ref={node} validated={validated} onSubmit={handleSubmit}>
          <Row style={{ margin: '2em' }}>
            <Form.Group as={Col} md="12">
              <h3>Carrier list</h3>
              {carrierGrouping.map((name: any, id) => (
                <InputGroup key={id}>
                  <Checkbox
                    selected={carriers[name as keyof typeof carriers]}
                    setSelected={() => setCarriers({ ...carriers, [name]: !carriers[name as keyof typeof carriers] })}
                  />
                  <p style={{ padding: '0 1em', marginBottom: 0, lineHeight: '24px' }}>{name}</p>
                </InputGroup>
              ))}
            </Form.Group>
          </Row>
          <Row style={{ marginLeft: '2em' }}>
            <h3>Service Level list</h3>
          </Row>
          <Row style={{ margin: '0 2em 2em' }}>
            {carrierGrouping.map((c, id) => (
              <Form.Group as={Col} xl="4" lg="6" md="12" key={id}>
                <h3 style={{ fontSize: '1.25rem', margin: '10px 0' }}>{c as string}</h3>
                {serviceLevelList
                  .filter((sl: any) => sl.carrier__name === c)
                  .map((s: any, id: number) => (
                    <InputGroup key={id}>
                      <Checkbox
                        selected={serviceLevels[s.id]}
                        setSelected={() => setServiceLevels({ ...serviceLevels, [s.id]: !serviceLevels[s.id] })}
                      />
                      <p style={{ padding: '0 1em', marginBottom: 0, lineHeight: '24px' }}>{s.name}</p>
                    </InputGroup>
                  ))}
              </Form.Group>
            ))}
          </Row>
        </Form>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
