import React from 'react'
import { Link } from 'react-router-dom'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { TableCell } from '../../../../components/component-items/datatable'

import { SuggestedPackagingCardProps } from './types'

export const SuggestedPackagingCard = ({ suggestedPackaging }: SuggestedPackagingCardProps) => {
  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Suggested Packaging List<small>{suggestedPackaging.length} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 100 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th />
              <th>
                <span className="text">Barcode</span>
              </th>
              <th>
                <span className="text">SKU</span>
              </th>
              <th>
                <span className="text center">Qty</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {suggestedPackaging.map(({ img, p_id, sku, description, barcode, qty }: any) => (
              <GlobalStyles.TableRow key={p_id}>
                <TableCell center>
                  <img src={img} style={{ height: 'auto', maxWidth: 100, maxHeight: 50 }} />
                </TableCell>
                <TableCell input={barcode} />
                <TableCell input={<Link to={`/product/variant/${p_id}/`}>{sku}</Link>}>
                  <div>{description}</div>
                </TableCell>
                <TableCell
                  center
                  input={
                    <StatusBoxStyled style={{ width: 75, margin: 'auto' }} className={'silver'}>
                      {qty}
                    </StatusBoxStyled>
                  }
                />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
    </GlobalStyles.FullPageCard>
  )
}
