import React, { useState, useRef } from 'react'
import { globalState } from '../../../store'
import styled from 'styled-components'
import { usePost } from '../../../components/component-items/helpers'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { Form } from 'react-bootstrap'

import { CreditMerchantModalProps } from './types'

const Label = styled(Form.Label)`
  margin-top: 1em;
`

export const CreditMerchantModal = ({ activeWarehouses, open, setOpen }: CreditMerchantModalProps) => {
  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)
  const {
    state: { csrf },
  } = globalState()

  const [amount, setAmount] = useState('')
  const [category, setCategory] = useState('')
  const [warehouse, setWarehouse] = useState('')
  const [description, setDescription] = useState('')

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    usePost('/api/billing/credit/', { ...{ amount, category, warehouse, description } }, csrf)
  }

  return (
    <GenericModal
      heading={'Credit Merchant'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleSubmit}>
          Submit
        </GlobalStyles.Button>
      }
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit} style={{ margin: '0 2em 1em' }}>
        <Label>Amount</Label>
        <Form.Control type="text" value={amount} onChange={(e) => setAmount(e.target.value)} required />
        <Form.Control.Feedback type="invalid">Please choose an amount</Form.Control.Feedback>
        <Label>Category</Label>
        <Form.Select value={category} onChange={(e) => setCategory(e.target.value)} required>
          <option value="">--SELECT--</option>
          <option value="C">Credit</option>
          <option value="T">Payment</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">Please Choose a Category</Form.Control.Feedback>
        {activeWarehouses.length ? (
          <>
            <Label>Warehouse</Label>
            <Form.Select value={warehouse} onChange={(e) => setWarehouse(e.target.value)} required>
              <option value="">--SELECT--</option>
              {activeWarehouses.map((w, id) => (
                <option value={w.id} key={id}>
                  {w.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please choose a warehouse</Form.Control.Feedback>
          </>
        ) : null}
        <Label>Description</Label>
        <Form.Control type="text" value={description} onChange={(e) => setDescription(e.target.value)} required />
        <Form.Control.Feedback type="invalid">Please choose a description</Form.Control.Feedback>
      </Form>
    </GenericModal>
  )
}
