import React, { useState, useEffect, FunctionComponent, useRef } from 'react'
import { globalState } from '../../../store'
import { useFetch, usePost, usePut } from '../../../components/component-items/helpers'
import { Form } from 'react-bootstrap'
import Select from 'react-select'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { TableCell } from '../../../components/component-items/datatable'
import { NotificationsStyled } from '../../../components/notifications/styles'
import CardLoading from '../../../components/component-items/loading-popover'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { OrderInfo } from '../styles'

// Types
import { TagProps, EditTagProps } from '../types'

export const EditTagModal: FunctionComponent<EditTagProps> = ({ tag, editTagModal, closeModal }) => {
  const {
    state: { csrf },
  } = globalState()

  const [validated, setValidated] = useState(false)
  const node: any = useRef(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const categories = [
    { value: 'asn', label: 'ASN' },
    { value: 'inventory', label: 'Inventory / Cycle Count' },
    { value: 'fulfillment', label: 'Fulfillment' },
    { value: 'management', label: 'Management' },
    { value: 'projects', label: 'Projects / VAS' },
    { value: 'wholesale', label: 'Wholesale / B2B' },
  ]
  const [category, setCategory] = useState<any>(null)
  const departments = [
    { value: 'inbound', label: 'Inbound' },
    { value: 'outbound', label: 'Outbound' },
    { value: 'receiving', label: 'Receiving' },
    { value: 'support', label: 'Support' },
    { value: 'vas', label: 'VAS' },
  ]
  const [department, setDepartment] = useState<any>(null)

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false || !category || !department) {
      return
    }
    let update = { ...{ name, description }, category: category['value'], department: department['value'] }
    if (tag) {
      usePut(`/api/core/notification-tags/${tag.id}/`, update, csrf)
    } else {
      usePost(`/api/core/notification-tags/`, update, csrf)
    }
  }

  useEffect(() => {
    if (tag) {
      setName(tag.name)
      setDescription(tag.description)
      setCategory({ value: tag.category, label: tag.category_display_name })
      setDepartment({ value: tag.department, label: tag.department_display_name })
    } else {
      setName('')
      setDescription('')
      setCategory(null)
      setDepartment(null)
    }
  }, [tag])

  return (
    <GenericModal
      heading={'Add Notification Hashtag'}
      show={editTagModal}
      size="lg"
      onHide={closeModal}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleSubmit}>
          Submit
        </GlobalStyles.Button>
      }
    >
      {
        <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
          <div style={{ margin: '2em auto', padding: '0 2em', maxWidth: '1000px', minHeight: 250 }}>
            <Form.Group className="required">
              <Form.Label>Hashtag</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={tag !== undefined}
                required
              />
            </Form.Group>
            <Form.Group className="required">
              <Form.Label style={{ marginTop: '1em' }}>Tag Description</Form.Label>
              <Form.Control value={description} onChange={(e) => setDescription(e.target.value)} required />
            </Form.Group>
            <Form.Group className="required">
              <Form.Label style={{ marginTop: '1em' }}>Category</Form.Label>
              <Select options={categories} value={category} onChange={(e: any) => setCategory(e)} required />
            </Form.Group>
            <Form.Group className="required">
              <Form.Label style={{ marginTop: '1em' }}>Department</Form.Label>
              <Select options={departments} value={department} onChange={(e: any) => setDepartment(e)} required />
            </Form.Group>
          </div>
        </Form>
      }
    </GenericModal>
  )
}

export const NotificationTags = () => {
  const {
    state: { userIsExecutive },
  } = globalState()

  const [tag, setTag] = useState<TagProps | undefined>()
  const [tags, setTags] = useState<TagProps[]>([])
  const [editTagModal, setEditTagModal] = useState(false)

  const { response, loaded, error, placeholder } = useFetch('/api/core/notification-tags/', {})

  useEffect(() => {
    if (loaded) {
      setTags((response as any) || [])
    }
  }, [loaded])

  return (
    <OrderInfo style={{ display: 'block', height: 'unset' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>Hashtags</h3>
        </GlobalStyles.CardTitle>
        {userIsExecutive && (
          <GlobalStyles.CardToolbar>
            <GlobalStyles.Button onClick={() => setEditTagModal(true)}>Add Tag</GlobalStyles.Button>
            <EditTagModal
              {...{ tag, editTagModal }}
              closeModal={() => {
                setEditTagModal(false)
                setTag(undefined)
              }}
            />
          </GlobalStyles.CardToolbar>
        )}
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text center">Tag</span>
              </th>
              <th>
                <span className="text center">Category</span>
              </th>
              <th>
                <span className="text center">Department</span>
              </th>
              <th>
                <span className="text">Description</span>
              </th>
              {userIsExecutive && (
                <th>
                  <span className="text center">Actions</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {tags.map(({ id, name, description, category_display_name, department_display_name, color }) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell
                  center
                  input={
                    <NotificationsStyled.NotificationTag color={color} key={id} style={{ textTransform: 'uppercase' }}>
                      #{name}
                    </NotificationsStyled.NotificationTag>
                  }
                />
                <TableCell>
                  <StatusBoxStyled className="silver" style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                    {category_display_name}
                  </StatusBoxStyled>
                </TableCell>
                <TableCell>
                  <StatusBoxStyled className="silver" style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                    {department_display_name}
                  </StatusBoxStyled>
                </TableCell>
                <TableCell input={description} />
                {userIsExecutive && (
                  <TableCell center>
                    <div className="dropdown__container">
                      <button
                        onClick={() => {
                          setTag(tags.find((t) => t.id === id))
                          setEditTagModal(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </div>
                  </TableCell>
                )}
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </OrderInfo>
  )
}
