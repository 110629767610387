import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../../components/component-items/helpers'
import { OrderInfo } from './styles'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { Pages } from '../../../../components/component-items/pager'
import { TableCell } from '../../../../components/component-items/datatable'
import { SearchBox } from '../../../../components/component-items/search'
import CardLoading from '../../../../components/component-items/loading-popover'

import { InventoryTransactionProps } from './types'

export const ProductVariantHistory = () => {
  const productVariant = useParams<{ id: string }>().id

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const rows = 20
  const url = `/api/product/variant/${productVariant}/transactions/`
  const queryString = `?range=${rows}&page=${page - 1}&q=${encodeURIComponent(search)}`
  const { response: resp, loaded, error, placeholder }: any = useFetch(url + queryString, {})

  const inventoryTransactions = loaded ? resp.inventoryTransactions : []

  useEffect(() => {
    setLoading(!loaded)
  }, [loaded])

  const total = loaded ? resp.total : 0
  var totalPages = loaded && total ? Math.ceil(total / rows) : 1

  return (
    <OrderInfo style={{ display: 'block', position: 'relative', height: 'unset' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            Product Transactions<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} updateURL />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Date</span>
              </th>
              <th>
                <span className="text">Description</span>
              </th>
              <th>
                <span className="text center">Change</span>
              </th>
              <th>
                <span className="text center">Balance</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {inventoryTransactions.map(
              ({ id, project, order, asn, date, description, quantity, balance }: InventoryTransactionProps) => (
                <GlobalStyles.TableRow key={id}>
                  <TableCell>{date}</TableCell>
                  <TableCell
                    input={
                      project ? (
                        <Link to={`/project/${project}/`}>{description}</Link>
                      ) : order ? (
                        <Link to={`/order/${order}/`}>{description}</Link>
                      ) : asn ? (
                        <Link to={`/asn/${asn}/`}>{description}</Link>
                      ) : (
                        description
                      )
                    }
                  />
                  <TableCell center input={quantity} />
                  <TableCell center input={balance} />
                </GlobalStyles.TableRow>
              )
            )}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter style={{ justifyContent: 'center' }}>
        <Pages page={page} setPage={setPage} total={totalPages} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </OrderInfo>
  )
}
