import React, { useState, useEffect } from 'react'
import { useFetch } from '../../components/component-items/helpers'
import { Link } from 'react-router-dom'

// Components
import Pager, { Pages } from '../../components/component-items/pager'
import ButtonDropdown from '../../components/component-items/button-dropdown'
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import { OrderStatusBox } from '../../components/component-items/status-box'
import { TableCell, THSort } from '../../components/component-items/datatable'
import GenericModal from '../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { TableRow, FilterListItem, DateRangeStyled } from './styles'
import { SearchBox } from '../../components/component-items/search'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'

// Enums
import { ShipNoticeStatus } from '../../enums'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons'

import { ReturnRowProps } from './types'

export const ReturnReportModal = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [url, setUrl] = useState('')
  useEffect(() => {
    var startDate = state[0].startDate.toLocaleDateString('en-US')
    var endDate = state[0].endDate.toLocaleDateString('en-US')
    setStartDate(startDate)
    setEndDate(endDate)
    const start = startDate.replace(/\//g, '-')
    const end = endDate.replace(/\//g, '-')
    setUrl('/api/inventory/return/report/?start_date=' + start + '&end_date=' + end)
  }, [state])

  return (
    <GenericModal
      heading={'Return Notice Report'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <a href={url}>
          <GlobalStyles.Button style={{ minWidth: 125 }}>Download CSV</GlobalStyles.Button>
        </a>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Date Range</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={startDate + ' - ' + endDate}
              onChange={() => null}
              onFocus={() => {
                const startDate = state[0].startDate.toLocaleDateString('en-US')
                setStartDate(startDate)
                const endDate = state[0].endDate.toLocaleDateString('en-US')
                setEndDate(endDate)
              }}
              placeholder="Choose A Date"
              aria-describedby="inputGroupAppend"
              required
            />
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
          </InputGroup>
        </FilterListItem>
        <DateRangeStyled>
          <DateRangePicker
            onChange={(item: any) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            months={1}
            minDate={new Date(2018, 1, 1)}
            maxDate={new Date()}
            editableDateInputs={true}
            ranges={state}
            direction="horizontal"
            className="hide-in-percy"
          />
        </DateRangeStyled>
      </div>
    </GenericModal>
  )
}

const ReturnRow = ({ id, created, name, trackingNumber, trackingUrl, delivery, items, status }: ReturnRowProps) => {
  var statusId =
    status === ShipNoticeStatus.Completed
      ? 200
      : status === ShipNoticeStatus.ReturnSentToCustomer
        ? 100
        : status === ShipNoticeStatus.ReturnLabelPurchaseFail
          ? 10
          : 100
  var statusName =
    status === ShipNoticeStatus.Completed
      ? 'Completed'
      : status === ShipNoticeStatus.ReturnSentToCustomer
        ? 'Label Sent'
        : status === ShipNoticeStatus.ReturnLabelPurchaseFail
          ? 'Label Failed'
          : 'Processing'
  return (
    <TableRow>
      <TableCell center input={<Link to={`/asn/${id}/`}>{id}</Link>} />
      <TableCell input={created} />
      <TableCell input={name}>
        <div>Expected Delivery: {delivery}</div>
        {trackingUrl ? (
          <div>
            Tracking: <a href={trackingUrl}>{trackingNumber}</a>
          </div>
        ) : null}
      </TableCell>
      <TableCell center>
        {items.map((i: any, id: number) => (
          <div key={id}>
            <Link to={`/product/variant/${i.p_id}/`}>
              {i.sku} — {i.sent}
            </Link>
          </div>
        ))}
      </TableCell>
      <TableCell
        center
        input={<OrderStatusBox style={{ width: 100, margin: 'auto' }} status={statusName} status_id={statusId} />}
      />
    </TableRow>
  )
}

export const ReturnList = () => {
  const [open, setOpen] = useState(false)

  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 50, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])

  // Assign sort based on url params first
  const filterOptions = ['All', 'Processing', 'Label Sent', 'Completed']
  const [filter, setFilter] = useState('All')
  const [sort, setSort] = useState({})
  const [search, setSearch] = useState('')

  const [loading, setLoading] = useState(false)

  const url =
    '/api/inventory/return/?range=' +
    rows +
    '&page=' +
    (page - 1) +
    '&order_by=' +
    Object.keys(sort) +
    '&sort=' +
    Object.values(sort) +
    '&q=' +
    search +
    '&filter=' +
    filter
  const res: any = useFetch(url, {})

  useEffect(() => {
    setLoading(!res.loaded)
  }, [res.loaded])

  const total = res.loaded ? res.response.total : 0
  var totalPages = res.loaded && total ? Math.ceil(total / rows) : 1
  const returns = res.loaded ? res.response.returns : []

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Return List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <ButtonDropdown title={'Status'} sort={filter} setSort={setFilter} options={filterOptions} height={40} />
          <GlobalStyles.Button onClick={() => setOpen(true)} style={{ height: 40 }}>
            Return Report
          </GlobalStyles.Button>
          <ReturnReportModal {...{ open, setOpen }} />
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus updateURL />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <THSort title={'ASN'} sortKey={'id'} center {...{ sort, setSort }} />
              <THSort title={'Created'} {...{ sort, setSort }} />
              <THSort title={'Details'} sortKey={'id'} {...{ sort, setSort }} />
              <th>
                <span className="text center">Items</span>
              </th>
              <THSort title={'Status'} center {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>
            {returns.map((o: any, id: number) => (
              <ReturnRow {...o} key={id} />
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
