import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { Form, InputGroup } from 'react-bootstrap'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

export const ReturnlyIntegration = () => {
  const [apiKey, setApiKey] = useState('')
  const [success, setSucccess] = useState(false)
  const {
    state: { csrf },
  } = globalState()

  const returnlyURL = '/api/returnly/integration/'
  const res: any = useFetch(returnlyURL, {})

  const currentApiKey = res.loaded ? res.response.api_key : null
  useEffect(() => {
    setApiKey(currentApiKey)
  }, [currentApiKey])

  const handleSubmit = async () => {
    const reload = false
    const response = await usePost(returnlyURL, { ...{ apiKey } }, csrf, reload)
    if (response.success && !response.error) {
      setSucccess(true)
    }
  }

  return (
    <>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Returnly Integration</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '50%' }}>
          <StatusBoxStyled className={'silver'} style={{ marginBottom: '2em', textAlign: 'center' }}>
            Note: please add your API key from your returnly integration. The API Key can be found under:
            <br />
            <a href="https://dashboard.returnly.com/dashboard/account">
              Your Account &gt; Summary &gt; Profile &gt; API Token
            </a>
          </StatusBoxStyled>
          {success ? (
            <StatusBoxStyled className={'green'} style={{ marginBottom: '2em', textAlign: 'center' }}>
              Success! Your api integration is now enabled, returns will appear <Link to="/return/">here</Link>.
            </StatusBoxStyled>
          ) : null}
          <Form.Label>API Key</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="API Key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">Please enter a SKU</Form.Control.Feedback>
          </InputGroup>
          <GlobalStyles.Button
            onClick={() => handleSubmit()}
            style={{ minWidth: 150, margin: '2em 0', float: 'right' }}
          >
            Submit
          </GlobalStyles.Button>
        </div>
      </GlobalStyles.FullPageCard>
    </>
  )
}
