import React from 'react'
import { Link } from 'react-router-dom'
import { useFetch, usePost } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'
import { useParams } from 'react-router-dom'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import GenericModal from '../../../../components/component-items/modal'
import { TableCell } from '../../../../components/component-items/datatable'
import CardLoading from '../../../../components/component-items/loading-popover'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { StatusBarStyled } from '../../../../components/component-items/status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem, faArrowRight, faInfoSquare } from '@fortawesome/pro-duotone-svg-icons'

import { DuplicateProps } from './types'

export const DuplicateModal = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const productVariant = parseInt(useParams<{ id: string }>().id)
  const {
    state: { csrf },
  } = globalState()

  const url = `/api/product/variant/${productVariant}/duplicates/`
  const { response, loaded, error, placeholder }: any = useFetch(url, {})

  const duplicates: any = loaded ? response : []
  // We have a master variant set if all duplicates point to one variant, and only that variant has duplicate=null.
  const master = duplicates.find((variant: any) => {
    if (variant.duplicate === null) {
      // Ignore current variant, ensure all others have dup.duplicate === variant.id
      if (duplicates.filter((dup: any) => dup.id !== variant.id && dup.duplicate !== variant.id).length === 0) {
        return true
      }
    }
  })?.id

  return (
    <GenericModal
      heading={'Product Variant Duplicates'}
      show={open}
      size="xl"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <div style={{ margin: '0 2em' }}>
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              <FontAwesomeIcon icon={faInfoSquare} style={{ color: '#800080' }} />
            </div>
            <div className="status-text">
              <strong style={{ fontWeight: 500 }}>Note</strong>: Clicking on an{' '}
              <strong style={{ fontWeight: 500 }}>Integration ID</strong> will connect you to your integrations product
              variant page and clicking on a <strong style={{ fontWeight: 500 }}>SKU</strong> will send you to that
              ShippingTree's product variant page. The product variant set as{' '}
              <StatusBoxStyled className="purple" style={{ display: 'inline-block', padding: '0 1em' }}>
                Current
              </StatusBoxStyled>{' '}
              is the variant on this page. The product variant set as the{' '}
              <StatusBoxStyled className="green" style={{ display: 'inline-block', padding: '0 1em' }}>
                Master
              </StatusBoxStyled>{' '}
              should match with the product variant being sold in your e-commerce integration storefront, otherwise:
              quantity, product title, etc. may not match on our end.{' '}
              <strong style={{ fontWeight: 500 }}>
                We only guarnatee inventory quantity for the master variant set in ShippingTree
              </strong>
              .
            </div>
          </div>
        </StatusBarStyled>
        <GlobalStyles.DataTable style={{ boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px' }}>
          <thead>
            <tr>
              <th>
                <span className="text"></span>
              </th>
              <th>
                <span className="text center">Integration ID</span>
              </th>
              <th>
                <span className="text center">SKU</span>
              </th>
              <th>
                <span className="text center">Quantity (Available)</span>
              </th>
              <th>
                <span className="text center"></span>
              </th>
              <th>
                <span className="text center">Integration Quantity</span>
              </th>
              <th>
                <span className="text center">Duplicate</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {duplicates.map(
              (
                {
                  img,
                  id,
                  originator_id,
                  integration_url,
                  sku,
                  available,
                  duplicate,
                  integration_quantity,
                }: DuplicateProps,
                key: number
              ) => (
                <GlobalStyles.TableRow key={key}>
                  <TableCell
                    center
                    input={<img src={img} alt="Product Variant" className="product_image" style={{ maxWidth: 50 }} />}
                  />
                  <TableCell
                    center
                    input={
                      <a href={integration_url} rel="noreferrer" target="_blank">
                        {originator_id}
                      </a>
                    }
                  />
                  <TableCell center input={<Link to={`/product/variant/${id}/`}>{sku}</Link>} />
                  <TableCell center>
                    <StatusBoxStyled className="blue" style={{ width: 100, margin: 'auto' }}>
                      {available}
                    </StatusBoxStyled>
                  </TableCell>
                  <TableCell
                    center
                    input={
                      <GlobalStyles.Tooltip data-title={available === integration_quantity ? 'In Sync' : 'Out Of Sync'}>
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          style={{ color: available === integration_quantity ? '#5d78ff' : '#ffb822', width: 75 }}
                        />
                      </GlobalStyles.Tooltip>
                    }
                  />
                  <TableCell center>
                    <StatusBoxStyled className="silver" style={{ width: 100, margin: 'auto' }}>
                      {integration_quantity === null ? 'N/A' : integration_quantity}
                    </StatusBoxStyled>
                  </TableCell>
                  <TableCell
                    center
                    input={
                      <>
                        {id === productVariant && (
                          <StatusBoxStyled className="purple" style={{ width: 100, margin: '0 auto 1em' }}>
                            Current
                          </StatusBoxStyled>
                        )}
                        {master === id ? (
                          <StatusBoxStyled className="green" style={{ width: 100, margin: 'auto' }}>
                            Master
                          </StatusBoxStyled>
                        ) : (
                          duplicate && (
                            <StatusBoxStyled className="blue" style={{ width: 100, margin: 'auto' }}>
                              Duplicate
                            </StatusBoxStyled>
                          )
                        )}
                      </>
                    }
                  />
                  <TableCell
                    center
                    input={
                      <div className="dropdown__container">
                        {master !== id && (
                          <GlobalStyles.Tooltip data-title="Set as Master variant">
                            <button onClick={() => usePost(url, { ...{ id } }, csrf)}>
                              <FontAwesomeIcon icon={faGem} />
                            </button>
                          </GlobalStyles.Tooltip>
                        )}
                      </div>
                    }
                  />
                </GlobalStyles.TableRow>
              )
            )}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GenericModal>
  )
}
