import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { globalState } from '../../../../store'
import { useFetch, usePost, isNormalInteger } from '../../../../components/component-items/helpers'
import { useParams } from 'react-router-dom'
import { BackgroundLayer, DateRangeStyled, ProductModalStyled, BomContainer, BomItem } from './styles'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import GenericModal from '../../../../components/component-items/modal'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { TableCell } from '../../../../components/component-items/datatable'
import { Form, InputGroup } from 'react-bootstrap'
import CardLoading from '../../../../components/component-items/loading-popover'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar } from 'react-date-range'

import { BuildExplodeBomProps } from './types'

export const BuildExplodeBom = ({ bom, sku, desc, img, open, setOpen, explode }: BuildExplodeBomProps) => {
  const productVariant = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()

  const [quantity, setQuantity] = useState(1)

  const buttons = (
    <>
      <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
        Close
      </GlobalStyles.Button>
      <GlobalStyles.Button
        style={{ minWidth: 125 }}
        onClick={() =>
          usePost(
            `/api/product/variant/${productVariant}/project/`,
            { ...{ quantity, explode, date: date.toLocaleDateString('en-US').replace(/\//g, '-') } },
            csrf
          )
        }
      >
        Create Project
      </GlobalStyles.Button>
    </>
  )

  var currentDate = new Date()
  var minDays = 3
  if ([3, 4, 5].includes(currentDate.getDay())) {
    // Weednesday - Friday
    minDays += 2
  } else if (currentDate.getDay() === 6) {
    // Saturday
    minDays += 1
  }
  currentDate.setDate(currentDate.getDate() + minDays)
  const [date, setDate] = useState<any>(null)

  return (
    <GenericModal
      heading={explode ? 'Explode Product using BOM' : 'Create Product Using BOM'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={buttons}
    >
      <ProductModalStyled>
        <div style={{ margin: '0 2em' }}>
          <h2 style={{ margin: '1em 0' }}>{explode ? 'Consuming' : 'Creating'}</h2>
          <BomContainer>
            <BomItem>
              <p style={{ fontSize: '1.2em', fontWeight: 500, marginBottom: 0 }}>{sku}</p>
              <p>{desc}</p>
              <img src={img} alt="Product Variant" className="product_image" style={{ width: '100%', padding: 8 }} />
              <Form.Label style={{ marginTop: '1em' }}>Quantity</Form.Label>
              <Form.Control type="number" min="1" value={quantity} onChange={(e: any) => setQuantity(e.target.value)} />
            </BomItem>
          </BomContainer>
          <h2 style={{ margin: '1em 0' }}>{explode ? 'Creating' : 'Consuming'}</h2>
          {bom.length < 10 ? (
            <BomContainer>
              {bom.map(({ img, pId, sku, desc, qty, available }: any) => (
                <BomItem key={pId}>
                  <Link to={`/product/variant/${pId}/`} rel="noreferrer" target="__none">
                    <p style={{ fontSize: '1.2em', fontWeight: 500, marginBottom: 0 }}>{sku}</p>
                  </Link>
                  <p>{desc}</p>
                  <img
                    src={img}
                    alt="Product Variant"
                    className="product_image"
                    style={{ width: '100%', padding: 8 }}
                  />
                  <p style={{ fontSize: '1.5em', fontWeight: 500 }}>{quantity * qty}</p>
                  <StatusBoxStyled
                    className={quantity * qty <= available ? 'green' : 'red'}
                  >{`${available} available`}</StatusBoxStyled>
                </BomItem>
              ))}
            </BomContainer>
          ) : (
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text"></span>
                  </th>
                  <th>
                    <span className="text">Product Variant</span>
                  </th>
                  <th>
                    <span className="text center">Quantity</span>
                  </th>
                  <th>
                    <span className="text center">Available</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {bom.map(({ img, pId, sku, desc, qty, available }: any) => (
                  <GlobalStyles.TableRow key={pId}>
                    <TableCell
                      input={
                        <img
                          src={img}
                          alt="Product Variant"
                          className="product_image"
                          style={{ maxWidth: 50, justifyContent: 'center', display: 'flex' }}
                        />
                      }
                    />
                    <TableCell
                      input={
                        <>
                          <Link to={`/product/variant/${pId}/`} rel="noreferrer" target="__none">
                            {sku}
                          </Link>
                          <div>{desc}</div>
                        </>
                      }
                    />
                    <TableCell input={qty} center />
                    <TableCell
                      input={
                        <StatusBoxStyled className={qty <= available ? 'green' : 'red'}>{available}</StatusBoxStyled>
                      }
                      center
                    />
                  </GlobalStyles.TableRow>
                ))}
              </tbody>
            </GlobalStyles.DataTable>
          )}
          <h2 style={{ margin: '1em 0' }}>Project Deadline</h2>
          <DateRangeStyled>
            <Calendar
              onChange={(item) => setDate(item)}
              date={date}
              minDate={currentDate}
              showMonthAndYearPickers={false}
              className="hide-in-percy"
            />
          </DateRangeStyled>
        </div>
      </ProductModalStyled>
    </GenericModal>
  )
}

export const BomModal = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const productVariant = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()

  const url = `/api/product/variant/${productVariant}/bom/`
  const res: any = useFetch(url, {})

  const loaded = res.loaded
  const BomResp = res.response || {}
  const bom = loaded ? BomResp.bom : []

  const node = useRef<HTMLInputElement>(null)
  const [productCount, setProductCount] = useState<any[]>([])
  const [search, setSearch] = useState<any>('')
  const [added, setAdded] = useState<any>([])
  const resp: any = useFetch(`/api/product/variant/${productVariant}/bom_list/?q=${encodeURIComponent(search)}`, {})

  const productSearch = resp.loaded ? resp?.response.products : []

  useEffect(() => {
    if (bom.length) {
      const list: any = {}
      bom.map((p: any) => (list[p.pId] = p))
      setAdded(list)
    }
  }, [bom])

  const buttons = (
    <>
      <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
        Close
      </GlobalStyles.Button>
      <GlobalStyles.Button
        style={{ minWidth: 125 }}
        onClick={() => usePost(`/api/product/variant/${productVariant}/bom/`, { bom: added }, csrf)}
      >
        Update
      </GlobalStyles.Button>
    </>
  )

  return (
    <GenericModal heading={'Bill Of Materials (BOM)'} show={open} onHide={() => setOpen(false)} buttons={buttons}>
      <ProductModalStyled
        onSubmit={(e) => {
          e.preventDefault()
          setSearch(String(node.current?.value))
        }}
      >
        <div style={{ margin: '0 2em' }}>
          {Object.keys(added).length ? (
            <>
              <h2 style={{ marginTop: '1em' }}>Materials</h2>
              <GlobalStyles.DataTable style={{ boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px' }}>
                <thead>
                  <tr>
                    <th>
                      <span className="text"></span>
                    </th>
                    <th>
                      <span className="text">Product Variant</span>
                    </th>
                    <th>
                      <span className="text center">Quantity</span>
                    </th>
                    <th>
                      <span className="text center">Remove</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(added).map((a: any) => (
                    <GlobalStyles.TableRow className={added[a].qty ? 'active' : ''} key={a}>
                      <TableCell
                        input={
                          <img
                            src={added[a].img}
                            alt="Product Variant"
                            className="product_image"
                            style={{ maxWidth: 50 }}
                          />
                        }
                      />
                      <TableCell
                        input={
                          <>
                            <Link to={`/product/variant/${added[a].pId}/`} rel="noreferrer" target="__none">
                              {added[a].sku}
                            </Link>
                            <div>{added[a].desc}</div>
                          </>
                        }
                      />
                      <TableCell center>
                        <Form.Control
                          type="text"
                          value={added[a].qty}
                          onChange={() => null}
                          style={{ width: 100, margin: 'auto' }}
                          disabled
                        />
                      </TableCell>
                      <TableCell
                        center
                        input={
                          <GlobalStyles.Button
                            className="secondary"
                            style={{ margin: 'auto' }}
                            onClick={() => {
                              const clone = added
                              delete clone[a]
                              setAdded({ ...clone })
                            }}
                          >
                            Remove
                          </GlobalStyles.Button>
                        }
                      />
                    </GlobalStyles.TableRow>
                  ))}
                </tbody>
              </GlobalStyles.DataTable>
            </>
          ) : null}
          <h2 style={{ marginTop: '2em' }}>Add Items</h2>
          <div style={{ boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px' }}>
            <div className="search-bar" style={{ padding: 10 }}>
              <InputGroup>
                <Form.Control placeholder="Search" ref={node} />
                <GlobalStyles.Button type="submit" className="secondary" style={{ minWidth: 125, margin: 0 }}>
                  Search
                </GlobalStyles.Button>
              </InputGroup>
            </div>
            <div className="scrolltable">
              <GlobalStyles.DataTable>
                <thead>
                  <tr>
                    <th>
                      <span className="text"></span>
                    </th>
                    <th>
                      <span className="text">Product Variant</span>
                    </th>
                    <th>
                      <span className="text center">Quantity</span>
                    </th>
                    <th>
                      <span className="text center">Add</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productSearch.map((p: any) => {
                    const { img, pId, sku, desc } = p
                    const qty = productCount[pId] ? productCount[pId] : ''
                    return (
                      <GlobalStyles.TableRow className={qty ? 'active' : ''} key={pId}>
                        <TableCell
                          input={
                            <img src={img} alt="Product Variant" className="product_image" style={{ maxWidth: 50 }} />
                          }
                        />
                        <TableCell
                          input={
                            <>
                              <Link to={`/product/variant/${pId}/`} rel="noreferrer" target="__none">
                                {sku}
                              </Link>
                              <div>{desc}</div>
                            </>
                          }
                        />
                        <TableCell
                          input={
                            <Form.Control
                              type="text"
                              value={qty}
                              style={{ width: 100, margin: 'auto' }}
                              onChange={(e) => setProductCount({ ...productCount, [pId]: e.target.value })}
                            />
                          }
                          center
                        />
                        <TableCell
                          input={
                            <GlobalStyles.Button
                              onClick={() => {
                                if (isNormalInteger(productCount[pId])) {
                                  setAdded({ ...added, [pId]: { ...p, qty: productCount[pId] } })
                                  const newCount = productCount
                                  delete newCount[pId]
                                  setProductCount({ ...newCount })
                                }
                              }}
                              style={{ color: 'white' }}
                            >
                              Add
                            </GlobalStyles.Button>
                          }
                        />
                      </GlobalStyles.TableRow>
                    )
                  })}
                </tbody>
              </GlobalStyles.DataTable>
            </div>
          </div>
        </div>
      </ProductModalStyled>
    </GenericModal>
  )
}

export const BOM = () => {
  const productVariant = useParams<{ id: string }>().id
  const [open, setOpen] = useState(false)

  const url = `/api/product/variant/${productVariant}/bom/`
  const { response: resp, error, loaded, placeholder }: any = useFetch(url, {})
  const bom = loaded ? resp?.bom : []

  return (
    <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            Bill Of Materials (BOM)<small>{bom.length} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button style={{ height: 40, marginRight: '-0.5em' }} onClick={() => setOpen(true)}>
            Update BOM
          </GlobalStyles.Button>
          <BomModal {...{ open, setOpen, bom }} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text"></span>
              </th>
              <th>
                <span className="text">Product Variant</span>
              </th>
              <th>
                <span className="text center">Quantity</span>
              </th>
              <th>
                <span className="text center">Available</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {bom.map(({ img, pId, sku, desc, qty, available }: any) => (
              <GlobalStyles.TableRow key={pId}>
                <TableCell
                  input={
                    <img
                      src={img}
                      alt="Product Variant"
                      className="product_image"
                      style={{ maxWidth: 50, justifyContent: 'center', display: 'flex' }}
                    />
                  }
                />
                <TableCell
                  input={
                    <>
                      <Link to={`/product/variant/${pId}/`} rel="noreferrer" target="__none">
                        {sku}
                      </Link>
                      <div>{desc}</div>
                    </>
                  }
                />
                <TableCell input={qty} center />
                <TableCell
                  input={<StatusBoxStyled className={qty <= available ? 'green' : 'red'}>{available}</StatusBoxStyled>}
                  center
                />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BackgroundLayer>
  )
}
