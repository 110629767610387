import React, { useState } from 'react'
import { NotificationItemStyled, NotificationsStyled, EmptyPlaceholder } from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { NotificationItemProps } from './types'

const NotificationItem = ({ time, url, title, desc, icon, color }: NotificationItemProps) => (
  <NotificationItemStyled {...{ color }}>
    <div className="section">
      <div className="icon-border">
        <div className="icon">
          <i>
            <FontAwesomeIcon icon={icon} />
          </i>
        </div>
      </div>
      <span className="datetime">{time}</span>
    </div>
    {url ? (
      <a href={url} className="text">
        {title}
      </a>
    ) : (
      <div className="text">{title}</div>
    )}
    <div className="info">{desc}</div>
  </NotificationItemStyled>
)

/* <>
    <NotificationItem time={"01:20 AM"} url={"/"} title={"Your most recent ASN from A-Z company has arrived."} desc={"ASN, Inventory"} icon={faRampLoading} />
    <NotificationItem time={"Yestardey"} url={"/"} title={"Your most recent project to create camping kits is complete"} desc={"Project, Inventory"} icon={faTasks} color="blue" />
    <NotificationItem time={"Aug 13,2018"} url={"/"} title={"We're having an issue with the address on order 1234567"} desc={"Order, Address Error"} icon={faExclamationTriangle} color="yellow" />
</> */

export const Notifications = () => {
  const [section, setSection] = useState(1)
  return (
    <NotificationsStyled>
      <div className="head">
        <h3>Notifications</h3>
        <div className="toolbar">
          <ul>
            <li className="nav-item">
              <button onClick={() => setSection(1)} className={section === 1 ? 'active' : ''}>
                Today
              </button>
            </li>
            <li className="nav-item">
              <button onClick={() => setSection(2)} className={section === 2 ? 'active' : ''}>
                Week
              </button>
            </li>
            <li className="nav-item">
              <button onClick={() => setSection(3)} className={section === 3 ? 'active' : ''}>
                Month
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="body">
        {/* section === 1 ? (
        ) : section === 2 ? (
        ) : section === 3 ? (
        ) : null */}
        <EmptyPlaceholder>
          <div className="img" role="img" aria-label="celebrate">
            ⏳
          </div>
          Coming Soon!
        </EmptyPlaceholder>
      </div>
    </NotificationsStyled>
  )
}

export default Notifications
