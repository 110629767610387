import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { Form, InputGroup } from 'react-bootstrap'
import { FilterListItem, DateRangeStyled, MultiSelect } from '../styles'
import { Row, Col } from 'react-bootstrap'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons'

// Enums
import { OrderStatus } from '../../../enums'

import { FilterOrdersProps } from './types'
import { OrderStatusMultiSelect } from '../../../components/component-items/select/order-multi-select'

export const colourStyles = {
  menu: (styles: any) => ({ ...styles, zIndex: 1000 }),
  option: (styles: any, { data }: any) => {
    return {
      ...styles,
      zIndex: 1000,
      ...(data.group ? { fontWeight: 'normal' } : { paddingLeft: '30px' }),
    }
  },
}

export const FilterOrders = ({ showFilterModal, setFilterModal, setSort, setPage }: FilterOrdersProps) => {
  let history = useHistory()
  const {
    state: { userIsWarehouse, actAs },
  } = globalState()

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const [addDate, setAddDate] = useState(false)
  const [sku, setSku] = useState('')

  const [statuses, setStatuses] = useState([])

  const [dateRangeType, setDateRangeType] = useState('')
  const [dateTypeOptions, setDateTypeOptions] = useState<any>([])

  const defaultTags = [
    { label: 'B2B', value: 'b_to_b' },
    { label: 'EDI', value: 'edi' },
    { label: 'Hot', value: 'hot' },
  ]
  const [tags, setTags] = useState([])

  const startDate = addDate ? state[0].startDate.toLocaleDateString('en-US').replace(/\//g, '-') : ''
  const endDate = addDate ? state[0].endDate.toLocaleDateString('en-US').replace(/\//g, '-') : ''

  useEffect(() => {
    // Check if statuses only fulfilled (shipped and delivered)
    var is_fulfilled = true
    statuses.forEach(function (entry) {
      if (![OrderStatus.LabelPurchased, OrderStatus.Shipped, OrderStatus.Delivered].includes(entry['value'])) {
        is_fulfilled = false
      }
    })

    var is_shipped_or_delivered = true
    statuses.forEach(function (entry) {
      if (![OrderStatus.Shipped, OrderStatus.Delivered].includes(entry['value'])) {
        is_shipped_or_delivered = false
      }
    })

    var is_delivered = statuses.length === 1 && statuses[0]['value'] === OrderStatus.Delivered

    // 1. Label Purchased + Shipped + Delivered -> Show Fulfilled
    // 2. Shipped + Delivered -> Show Shipped + Fulfilled
    // 3. Delivered -> Show all 3

    // When order status is shipped or greater, show all options
    if (is_delivered) {
      setDateTypeOptions([
        { value: '', label: '--' },
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between' },
        { value: 'delivered_between', label: 'Delivered Between' },
        { value: 'fulfilled_between', label: 'Fulfilled Between' },
      ])
    } else if (is_shipped_or_delivered) {
      setDateTypeOptions([
        { value: '', label: '--' },
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between' },
        { value: 'delivered_between', label: 'Delivered Between [Invalid]', isDisabled: true },
        { value: 'fulfilled_between', label: 'Fulfilled Between' },
      ])
    } else if (statuses.length === 0 || is_fulfilled) {
      setDateTypeOptions([
        { value: '', label: '--' },
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between [Invalid]', isDisabled: true },
        { value: 'delivered_between', label: 'Delivered Between [Invalid]', isDisabled: true },
        { value: 'fulfilled_between', label: 'Fulfilled Between' },
      ])
    } else {
      setDateTypeOptions([
        { value: '', label: '--' },
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between [Invalid]', isDisabled: true },
        { value: 'delivered_between', label: 'Delivered Between [Invalid]', isDisabled: true },
        { value: 'fulfilled_between', label: 'Fulfilled Between [Invalid]', isDisabled: true },
      ])
      if (dateRangeType) setDateRangeType('')
    }
  }, [statuses])
  const handleFilter = () => {
    if ((dateRangeType && (!startDate || !endDate)) || (startDate && !dateRangeType)) {
      toast('Filter by Date Range must include range type and date range', {
        type: 'error',
      })
      return
    }
    setFilterModal(false)
    setSort('All')

    var tags_list: never[] = []
    tags.forEach((entry) => {
      tags_list.push(entry['value'])
    })

    var status_list: never[] = []
    statuses.forEach((entry) => {
      status_list.push(entry['value'])
    })

    history.push(
      '?sku=' +
        sku +
        '&start_date=' +
        startDate +
        '&end_date=' +
        endDate +
        '&filter_status=' +
        status_list.join(',') +
        '&date_range_type=' +
        dateRangeType +
        '&page=1' +
        '&tags=' +
        tags_list.join(',')
    )
  }

  const handleReset = () => {
    setSku('')
    setAddDate(false)
    setStatuses([])
    setDateRangeType('')
    setFilterModal(false)
    setPage(1)
    setTags([])
    history.push('?')
  }

  return (
    <GenericModal
      heading={'Filter Orders'}
      show={showFilterModal}
      size="lg"
      onHide={() => setFilterModal(false)}
      buttons={
        <>
          <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={handleReset}>
            Reset
          </GlobalStyles.Button>
          <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleFilter}>
            Filter
          </GlobalStyles.Button>
        </>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Filter by Order Type</Form.Label>
          <MultiSelect>
            <Select
              options={defaultTags}
              value={tags}
              onChange={(e: any) => setTags(e)}
              closeMenuOnSelect={false}
              isMulti
              className="basic-multi-select"
            />
          </MultiSelect>
        </FilterListItem>
        {!userIsWarehouse || actAs ? (
          <FilterListItem>
            <Form.Label>Filter by SKU</Form.Label>
            <Form.Control type="text" value={sku} onChange={(e) => setSku(e.target.value)} placeholder="Enter a SKU" />
          </FilterListItem>
        ) : null}
        <FilterListItem>
          <Form.Label>Filter by Order Status</Form.Label>
          <OrderStatusMultiSelect {...{ statuses, setStatuses }} />
        </FilterListItem>
        <FilterListItem className="large" style={{ marginTop: '20px' }}>
          <Row className="justify-content-md-center">
            <Form.Label className="text-center">Filter by Date Range</Form.Label>
            <Col md="6">
              <Select
                options={dateTypeOptions}
                value={dateTypeOptions.find((option: any) => option.value === dateRangeType)}
                onChange={(e: any) => setDateRangeType(e.value)}
                styles={colourStyles}
              />
            </Col>
            <Col md="6">
              <InputGroup>
                <Form.Control
                  type="text"
                  value={startDate ? startDate + ' to ' + endDate : ''}
                  onChange={() => null}
                  onFocus={() => setAddDate(true)}
                  placeholder="Choose A Date"
                  aria-describedby="inputGroupAppend"
                  required
                />
                <GlobalStyles.ClearInputButton onClick={() => setAddDate(false)}>Clear</GlobalStyles.ClearInputButton>
                <InputGroup.Text id="inputGroupAppend">
                  <FontAwesomeIcon icon={faCalendarWeek} />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        </FilterListItem>
        {addDate ? (
          <DateRangeStyled>
            <DateRangePicker
              onChange={(item: any) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              months={1}
              minDate={new Date(2018, 1, 1)}
              maxDate={new Date()}
              editableDateInputs={true}
              ranges={state}
              direction="horizontal"
              className="hide-in-percy"
            />
          </DateRangeStyled>
        ) : null}
      </div>
    </GenericModal>
  )
}
