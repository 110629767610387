import React, { useRef } from 'react'
import { usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { CreateASNModalProps } from './types'

export const CreateASNModal = ({ order, alternateWarehouses, asnModal, setAsnModal }: CreateASNModalProps) => {
  const asnRef = useRef<any>(null)
  const {
    state: { csrf },
  } = globalState()

  return (
    <GenericModal
      heading={'Create ASN From Order'}
      show={asnModal}
      onHide={() => setAsnModal(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => usePost(`/api/order/${order}/create_shipnotice/`, { wh: asnRef.current.value }, csrf, false)}
        >
          Save
        </GlobalStyles.Button>
      }
    >
      <InputGroup>
        <Form.Group controlId="exampleForm.ControlSelect1" style={{ width: '100%', padding: '2em 2em 3em' }}>
          <Form.Label>Choose a Warehouse:</Form.Label>
          <Form.Select ref={asnRef}>
            {alternateWarehouses
              ? alternateWarehouses?.map((wh: any, id: number) => (
                  <option value={wh.id} key={id}>
                    {wh.name}
                  </option>
                ))
              : null}
          </Form.Select>
        </Form.Group>
      </InputGroup>
    </GenericModal>
  )
}
