import React, { useState, useEffect } from 'react'
import { useFetch } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import Pager, { Pages } from '../../../components/component-items/pager'
import GlobalStyles from '../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import CardLoading from '../../../components/component-items/loading-popover'
import { TableCell, THSort } from '../../../components/component-items/datatable'
import { SearchBox } from '../../../components/component-items/search'

// Fontawesome
import { AdvancedSelect } from '../../../components/component-items/advanced-select'
import { AsyncTaskStatus } from '../../../enums'

export const AsyncTaskList = () => {
  const {
    state: { asyncTaskFilterOptions },
    dispatch,
  } = globalState()

  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 50, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const [search, setSearch] = useState('')

  // Assign sort based on url params first
  const [sort, setSort] = useState({})

  const [filterOptions, setFilterOptions] = useState(asyncTaskFilterOptions)

  const setDefaultFilter = () => {
    dispatch({ type: 'asyncTaskFilterOptions', asyncTaskFilterOptions: filterOptions })
  }

  const queryParamsString: any = {
    range: rows,
    page: page - 1,
    order_by: Object.keys(sort),
    sort: Object.values(sort),
    q: search,
  }
  const url =
    '/api/core/tasks/' +
    `?${Object.keys(queryParamsString)
      .map((key) => `${key}=${queryParamsString[key]}`)
      .join('&')}` +
    `&${filterOptions
      .filter(({ selected }: any) => selected)
      .map(({ label, value, type }: any) =>
        type === 'boolean'
          ? label.toLowerCase().replace(' ', '_') + '=' + String(value)
          : type === 'multi-select'
            ? label.toLowerCase().replace(' ', '_') + '=' + value.map((v: any) => v.value).toString()
            : null
      )
      .join('&')}`

  const { response, error, loaded, placeholder }: any = useFetch(url, {})
  const tasks = loaded ? response.results : []
  const total = loaded ? response.count : 0
  var totalPages = loaded && total ? Math.ceil(total / rows) : 1

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(!loaded)
  }, [loaded])

  const formatDate = (dateString: string) => {
    const dateOptions: any = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }
    const timeOptions: any = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }

    // Format date and time separately
    const date = new Date(dateString)
    const formattedDate = date.toLocaleDateString('en-US', dateOptions)
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions)

    // Combine date and time with a newline in between
    return (
      <div style={{ minWidth: 100 }}>
        <div>{formattedDate}</div>
        <div>{formattedTime}</div>
      </div>
    )
  }

  const statusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case AsyncTaskStatus.Pending:
        return 'yellow'
      case AsyncTaskStatus.Started:
        return 'blue'
      case AsyncTaskStatus.Success:
        return 'green'
      case AsyncTaskStatus.Failure:
        return 'red'
      case AsyncTaskStatus.Retry:
        return 'purple'
      case AsyncTaskStatus.Revoked:
        return 'black'
      default:
        return 'silver'
    }
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Async Tasks <small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <SearchBox
            style={{ marginLeft: '1em' }}
            {...{ search, setSearch, loading, setLoading, page, setPage }}
            setFocus
            updateURL
          />
          <AdvancedSelect placeholder="Filter" {...{ filterOptions, setFilterOptions }} setDefault={setDefaultFilter} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <GlobalStyles.DataTable>
        <thead>
          <tr>
            <THSort center title="Task Id" {...{ sort, setSort }} />
            <THSort center title="Task Name" {...{ sort, setSort }} />
            <THSort center title="Created" {...{ sort, setSort }} />
            <THSort center title="Started" {...{ sort, setSort }} />
            <THSort center title="Ended" {...{ sort, setSort }} />
            <THSort center title="Traceback" {...{ sort, setSort }} />
            <THSort center title="Result" {...{ sort, setSort }} />
            <th>
              <span className="text center">Status</span>
            </th>
          </tr>
        </thead>
        <tbody style={{ minHeight: 300 }}>
          {tasks?.map(({ taskId, taskName, status, traceback, result, started, ended, created }: any) => (
            <GlobalStyles.TableRow key={taskId}>
              <TableCell center input={taskId} />
              <TableCell center input={taskName} />
              <TableCell center input={formatDate(created)} />
              <TableCell center input={formatDate(started)} />
              <TableCell center input={formatDate(ended)} />
              <TableCell
                center
                input={
                  <StatusBoxStyled style={{ margin: 'auto' }} className={traceback ? 'yellow' : 'silver'}>
                    {traceback || 'N/A'}
                  </StatusBoxStyled>
                }
              />
              <TableCell
                center
                input={
                  <StatusBoxStyled style={{ margin: 'auto' }} className="silver">
                    {result || 'N/A'}
                  </StatusBoxStyled>
                }
              />
              <TableCell
                center
                input={
                  <StatusBoxStyled style={{ width: 100, margin: 'auto' }} className={statusColor(status)}>
                    {status}
                  </StatusBoxStyled>
                }
              />
            </GlobalStyles.TableRow>
          ))}
        </tbody>
      </GlobalStyles.DataTable>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
