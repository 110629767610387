import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

//Components
import GlobalStyles from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/pro-duotone-svg-icons'

import { TableCellProps } from './types'

export const TableCell: FunctionComponent<TableCellProps> = React.forwardRef<HTMLDivElement, TableCellProps>(
  ({ style, input, children, center, rowspan, colspan, bold, className }, ref) => {
    return (
      <GlobalStyles.TableCell className={className} style={style} rowSpan={rowspan} colSpan={colspan}>
        <div style={{ textAlign: center ? 'center' : 'left', minWidth: '20px' }} ref={ref}>
          <span className="input">{input}</span>
          {bold ? <div style={{ fontWeight: 600 }}>{children}</div> : children}
        </div>
      </GlobalStyles.TableCell>
    )
  }
)

const THStyled = styled.th`
  user-select: none;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.alabaster};
    border-radius: 4px;
  }
`

type THSortProps = {
  title: string
  center?: boolean
  sort?: any
  setSort: (sort: any) => void
  sortKey?: string
  style?: any
}

export const THSort: FunctionComponent<THSortProps> = ({ title, center, sort, setSort, sortKey, style }) => {
  const key = sortKey || title.replaceAll(' ', '_').toLowerCase()
  const next_sort = sort ? (sort[key] === 'desc' ? 'asc' : sort[key] === 'asc' ? '' : 'desc') : null
  const style_content = center ? { display: 'flex', width: 'fit-content', margin: 'auto' } : { display: 'flex' }
  return (
    <THStyled onClick={() => setSort({ [key]: next_sort })} {...{ style }}>
      <div style={style_content}>
        <span className="text">{title}</span>
        <span style={{ paddingLeft: '0.5em' }}>
          <FontAwesomeIcon icon={sort[key] === 'asc' ? faSortUp : sort[key] === 'desc' ? faSortDown : faSort} />
        </span>
      </div>
    </THStyled>
  )
}
TableCell.displayName = 'TableCell'
