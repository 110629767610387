import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../../components/component-items/helpers'
import styled from 'styled-components'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import CardLoading from '../../../../components/component-items/loading-popover'
import ButtonDropdown from '../../../../components/component-items/button-dropdown'
import { OrderStatusBox } from '../../../../components/component-items/status-box'
import { TableCell } from '../../../../components/component-items/datatable'
import { toTitleCase } from '../../../../components/component-items/utils'

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

const ItemContainer = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  max-height: 150px;
  overflow-y: scroll;
  box-shadow: rgba(67, 56, 93, 0.09) -4px 4px 15px 1px;
`

export const OrderListFiltered = ({ orders }: any) => (
  <GlobalStyles.DataTable>
    <thead>
      <tr>
        <th>
          <span className="text center">Company</span>
        </th>
        <th>
          <span className="text center">Order Details</span>
        </th>
        <th>
          <span className="text">Address</span>
        </th>
        <th>
          <span className="text center">Order Items</span>
        </th>
        <th>
          <span className="text center">Status</span>
        </th>
      </tr>
    </thead>
    <tbody>
      {orders.map(({ id, remoteId, company, address, shipping, created, items, status, statusId }: any) => (
        <TableRow key={id}>
          <TableCell center input={company} />
          <TableCell
            center
            input={
              <Link to={`/order/${id}/`} style={{ maxWidth: 150 }}>
                {id}
              </Link>
            }
          >
            {remoteId ? <div>({remoteId})</div> : null}
            <div style={{ minWidth: 80 }}>
              {created.date}&nbsp;{created.time}
            </div>
          </TableCell>
          <TableCell input={address ? address.name : null} style={{ width: '25%' }}>
            {address ? (
              <div style={{ wordBreak: 'break-word' }}>
                {address.line1}
                {address.line2 ? ' - ' + address.line2 : ''} {address.state} {address.zip} {address.country_code}
              </div>
            ) : null}
          </TableCell>
          <TableCell>
            {items.length ? (
              <ItemContainer>
                <GlobalStyles.DataTable>
                  <tbody>
                    {items.map(({ id, name, sku, qty }: any) => (
                      <TableRow key={id}>
                        <TableCell input={<Link to={`/product/variant/${id}/`}>{sku}</Link>}>
                          <div>{name}</div>
                        </TableCell>
                        <TableCell input={qty} center />
                      </TableRow>
                    ))}
                  </tbody>
                </GlobalStyles.DataTable>
              </ItemContainer>
            ) : null}
          </TableCell>
          <TableCell
            center
            input={<OrderStatusBox style={{ width: 128, margin: 'auto' }} {...{ status }} status_id={statusId} />}
            style={{ width: '20%', wordBreak: 'break-word' }}
          >
            <div style={{ paddingTop: '1em' }}>{shipping.tracking_method}</div>
            <div>
              <a href={shipping.tracking_url}>{shipping.tracking}</a>
            </div>
          </TableCell>
        </TableRow>
      ))}
    </tbody>
  </GlobalStyles.DataTable>
)

export const OrderList = ({ counts }: any) => {
  const [filter, setFilter] = useState('processing')
  const batch = useParams<{ id: string }>().id
  const res: any = useFetch(`/api/inventory/batch/${batch}/orders/?filter=${filter}`, {})
  const resp: any = res.response
  const loaded = res.loaded

  const orders = loaded ? resp.orders : []

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Order List<small>{orders.length} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <ButtonDropdown
            title={'Filter'}
            sort={filter}
            setSort={setFilter}
            options={Object.keys(counts).map((p) => `${toTitleCase(p)} (${counts[p]})`)}
          />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 300, maxWidth: '100%' }}>
        <OrderListFiltered {...{ orders }} />
      </div>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
