import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import { Pages } from '../../components/component-items/pager'
import Checkbox from '../../components/component-items/checkbox'
import { useFetch } from '../../components/component-items/helpers'
import { BatchListCellStyled, DataTable, InventoryListStyled } from './styles'
import { store } from '../../store'

import { InventoryCellProps } from './types'

const InventoryCell = ({ width, input, children, center }: InventoryCellProps) => (
  <BatchListCellStyled>
    <div style={{ width: width, textAlign: center ? 'center' : 'left' }}>
      <span className="input">{input}</span>
      {children}
    </div>
  </BatchListCellStyled>
)

const InventoryRow = ({
  id,
  sku,
  shipped,
  avail,
  selectAll,
  setActive,
}: {
  id: any
  sku: any
  shipped: any
  avail: any
  selectAll: any
  setActive: any
}) => {
  const [selected, setSelected] = useState(selectAll)

  useEffect(() => {
    setSelected(selectAll)
  }, [selectAll])

  useEffect(() => {
    setActive(id, selected)
  }, [selected])

  return (
    <GlobalStyles.TableRow className={selected ? 'active' : ''}>
      <InventoryCell input={<Checkbox {...{ selected, setSelected }} />} />
      <InventoryCell width={'80px'} input={<Link to={`/product/variant/${id}/`}>{sku}</Link>} />
      <InventoryCell width={'45px'} input={shipped} />
      <InventoryCell width={'45px'} input={avail} />
    </GlobalStyles.TableRow>
  )
}

export const InventoryVelocity = () => {
  const [selected, setSelected] = useState(false)
  const [page, setPage] = useState(1)
  const globalState = useContext(store)
  const {
    state: { companyId },
    dispatch,
  } = globalState

  var resp: any = useFetch(`/api/company/${companyId}/dashboard/inventory_velocity/?count=10&page=` + (page - 1), {})
  var products = resp.loaded ? resp.response : []
  var total = resp.loaded ? resp.response.total : 0
  var totalPages = resp.loaded && resp.response.total ? Math.ceil(total / 25) : 1

  const [active, setActive] = useState({})
  useEffect(() => {
    const list: any = {}
    products.map((p: any) => (list[p.id] = selected))
    setActive(list)
  }, [selected])

  const setActiveOrder = (id: number | string, val: any) => {
    setActive({ ...active, [id]: val })
  }

  const [view, setView] = useState('View All')
  useEffect(() => {
    let allInactive = true
    Object.entries(active).forEach(([_, val]) => {
      if (val) {
        allInactive = false
      }
    })
    allInactive ? setView('View All') : setView('View Selected')
  }, [active])

  const openOrder = () => {
    if (view === 'View All') {
      window.open('/product/', '_blank')
    } else {
      Object.entries(active).forEach(([key, val]) => (val ? window.open(`/product/variant/${key}/`, '_blank') : null))
    }
  }

  return (
    <InventoryListStyled>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>
            Inventory Velocity<small>{total}</small>
          </h3>
        </div>
        <div className="datatable__toolbar">
          <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => openOrder()}>
            {view}
          </GlobalStyles.Button>
        </div>
      </div>
      {products && products.length ? (
        <div className="card-body">
          <DataTable>
            <thead>
              <tr>
                <th>
                  <span>
                    <Checkbox {...{ selected, setSelected }} />
                  </span>
                </th>
                <th>
                  <span className="text" style={{ width: '80px' }}>
                    SKU
                  </span>
                </th>
                <th>
                  <span className="text" style={{ width: '45px' }}>
                    Sent
                  </span>
                </th>
                <th>
                  <span className="text" style={{ width: '45px', marginRight: '0' }}>
                    Avail
                  </span>
                </th>
              </tr>
            </thead>
            <tbody style={{ height: 350 }}>
              {products.map((p: any, id: number) => (
                <InventoryRow
                  selectAll={selected}
                  id={p.id}
                  sku={p.sku}
                  shipped={p.shipped}
                  avail={p.avail}
                  setActive={setActiveOrder}
                  key={id}
                />
              ))}
            </tbody>
          </DataTable>
          <div className="datatable__foooter">
            <Pages page={page} setPage={setPage} total={totalPages} />
          </div>
        </div>
      ) : resp.loaded ? (
        <div className="card-body">
          <div className="empty-card">
            <div role="img" aria-label="celebrate">
              😴
            </div>
            No Products Shipped Today!
          </div>
        </div>
      ) : null}
      {!resp.loaded ? <CardLoading text={resp.placeholder} error={resp.error} /> : null}
    </InventoryListStyled>
  )
}

export default InventoryVelocity
