import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFetch, usePut, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { toast } from 'react-toastify'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { DataTable, BatchListStyled, BatchListRowStyled, BatchListCellStyled, ProductModalStyled } from './styles'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink, faTrashAlt, faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons'

import {
  BatchListOrderRowProps,
  BatchListCellProps,
  ProductOrderRowProps,
  ProductModalOrderProps,
  AddItemOrderRowProps,
} from './types'

const BatchListCell = ({ style, input, children, center, rowspan }: BatchListCellProps) => {
  return (
    <BatchListCellStyled style={style} rowSpan={rowspan}>
      <div style={{ textAlign: center ? 'center' : 'left', minWidth: '20px' }}>
        <span className="input">{input}</span>
        {children}
      </div>
    </BatchListCellStyled>
  )
}

const ProductRow = ({ status, pId, itemId, img, sku, desc, qty, available }: ProductOrderRowProps) => {
  const warning = available < 0 && status < 90
  const order = useParams<{ id: string }>().id
  const {
    state: { csrf },
  } = globalState()
  return (
    <>
      <BatchListCell input={<img src={img} style={{ maxWidth: 50, maxHeight: 50 }} />} center />
      <BatchListCell
        input={
          <>
            <Link to={`/product/variant/${pId}/`} rel="noreferrer" target="__blank">
              {sku}
            </Link>
            <div style={{ whiteSpace: 'pre-wrap' }}>{desc}</div>
          </>
        }
      />
      <BatchListCell center>
        <StatusBoxStyled className={warning ? 'yellow' : 'blue'} style={{ width: 75, margin: 'auto' }}>
          {qty} {warning ? <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} /> : null}
        </StatusBoxStyled>
      </BatchListCell>
      <BatchListCell center>
        <StatusBoxStyled className={available < 0 ? 'yellow' : 'blue'} style={{ width: 75, margin: 'auto' }}>
          {available}
        </StatusBoxStyled>
      </BatchListCell>
      <BatchListCell
        input={
          <div className="dropdown__container">
            <Link to={`/product/variant/${pId}/`} rel="noreferrer" target="__blank">
              <button>
                <FontAwesomeIcon icon={faExternalLink} />
              </button>
            </Link>
            {status < 100 ? (
              <button onClick={() => usePut(`/api/order/${order}/remove_items/`, { item_id: itemId }, csrf)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            ) : null}
          </div>
        }
        center
      />
    </>
  )
}

const BatchListRow = ({ variants, type, status }: BatchListOrderRowProps) => {
  const items = variants.map((v: any, id: number) => <ProductRow {...{ status }} {...v} key={id} />)
  return (
    <>
      <BatchListRowStyled className={''}>
        <BatchListCell input={type} rowspan={items.length} />
        {items[0]}
      </BatchListRowStyled>
      {items.slice(1).map((i: any, id: number) => (
        <BatchListRowStyled className={''} key={id}>
          {i}
        </BatchListRowStyled>
      ))}
    </>
  )
}

export const AddItemRow = ({ img, pId, sku, desc, available, qty, onChange }: AddItemOrderRowProps) => {
  return (
    <BatchListRowStyled className={qty ? 'active' : ''}>
      <BatchListCell input={<img src={img} alt="Product Variant" className="product_image" />} />
      <BatchListCell
        input={
          <>
            <Link to={`/product/variant/${pId}/`} rel="noreferrer" target="__none">
              {sku}
            </Link>
            <div style={{ whiteSpace: 'pre-wrap' }}>{desc}</div>
          </>
        }
      />
      <BatchListCell center>
        <StatusBoxStyled className={available <= 0 ? 'yellow' : 'blue'} style={{ width: 75 }}>
          {available}
        </StatusBoxStyled>
      </BatchListCell>
      <BatchListCell
        input={<Form.Control type="number" min="0" value={qty} style={{ width: '100px' }} onChange={onChange} />}
      />
    </BatchListRowStyled>
  )
}

const ProductModal = ({ products, id }: ProductModalOrderProps) => {
  const {
    state: { csrf },
  } = globalState()

  const order = useParams<{ id: string }>().id
  const node = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)
  const [productCount, setProductCount] = useState<any[]>([])

  useEffect(() => {
    const list: any = {}
    products.map((p) => (list[p.pId] = p.qty))
    setProductCount(list)
  }, [products])

  const setNewQuantity = (id: any, val: any) => {
    setProductCount({ ...productCount, [id]: val })
  }
  const [search, setSearch] = useState('')
  const resp: any = useFetch(open ? `/api/order/${id}/product_search/?q=${encodeURIComponent(search)}` : '', {})
  const searchResults = resp.loaded ? resp.response.results : []
  const buttons = (
    <>
      <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
        Close
      </GlobalStyles.Button>
      <GlobalStyles.Button
        className="primary"
        style={{ minWidth: 125 }}
        onClick={() => usePut(`/api/order/${order}/add_items/`, { products: productCount }, csrf)}
      >
        Update
      </GlobalStyles.Button>
    </>
  )
  return (
    <>
      <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(true)}>
        Change Products
      </GlobalStyles.Button>
      <GenericModal heading={'Products'} show={open} onHide={() => setOpen(false)} buttons={buttons}>
        <ProductModalStyled
          onSubmit={(e) => {
            e.preventDefault()
            const inputElementCurrent = node.current as HTMLInputElement
            setSearch(inputElementCurrent.value)
          }}
        >
          <div className="search-bar" style={{ padding: 10 }}>
            <InputGroup>
              <Form.Control placeholder="Search" ref={node} />
              <GlobalStyles.Button type="submit" className="secondary" style={{ minWidth: 125, margin: 0 }}>
                Search
              </GlobalStyles.Button>
            </InputGroup>
          </div>
          <div className="scrolltable">
            <DataTable style={{ maxHeight: 'unset' }}>
              <thead>
                <tr>
                  <th>
                    <span className="text">Image</span>
                  </th>
                  <th>
                    <span className="text">Product Variant</span>
                  </th>
                  <th>
                    <span className="text">Available</span>
                  </th>
                  <th>
                    <span className="text">Quantity</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.concat(searchResults).map((p, id) => (
                  <AddItemRow
                    pId={p.pId}
                    img={p.img}
                    sku={p.sku}
                    desc={p.desc}
                    available={p.available}
                    qty={productCount[p.pId] ? productCount[p.pId] : ''}
                    onChange={(e) => setNewQuantity(p.pId, e.target.value)}
                    key={id}
                  />
                ))}
              </tbody>
            </DataTable>
          </div>
        </ProductModalStyled>
      </GenericModal>
    </>
  )
}

export const TableList = ({ id }: { id: string | number }) => {
  const url = `/api/order/${id}/products/`
  const { response: resp, error, loaded, placeholder }: any = useFetch(url, {})

  const orderItems = loaded ? resp.orderItems : {}
  const status = loaded ? resp.status : {}

  var products: any = orderItems?.products || []
  var promos: any = orderItems?.promoItems || []

  useEffect(() => {
    if (products) {
      const missing_prices = products
        .concat(promos)
        .filter((product: any) => !parseFloat(product.price))
        .map((product: any) => product.sku)

      if (missing_prices.length) {
        notify({
          type: 'warning',
          message: `The following SKUs do not have a price: ${missing_prices.join(', ')}`,
        })
      }
    }
  }, [products])

  return (
    <BatchListStyled>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>
            Products<small>{orderItems.total || 0} Total</small>
          </h3>
        </div>
        <div className="datatable__toolbar">
          {status < 100 ? <ProductModal products={products.concat(promos)} id={String(id)} /> : null}
        </div>
      </div>
      <div className="card-body">
        <DataTable style={{ maxHeight: 'unset' }}>
          <thead>
            <tr>
              <th>
                <span className="text">Item Type</span>
              </th>
              <th>
                <span className="text"></span>
              </th>
              <th>
                <span className="text">Product Variant</span>
              </th>
              <th>
                <span className="text" style={{ textAlign: 'center' }}>
                  QTY
                </span>
              </th>
              <th>
                <span className="text" style={{ textAlign: 'center' }}>
                  Available
                </span>
              </th>
              <th>
                <span className="text" style={{ textAlign: 'center' }}>
                  Actions
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {products.length ? <BatchListRow variants={products} type={'Order Item'} status={status} /> : null}
            {promos.length ? <BatchListRow variants={promos} type={'Promo Item'} status={status} /> : null}
          </tbody>
        </DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BatchListStyled>
  )
}

export default TableList
