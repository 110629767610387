import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useFetch, usePost, useDelete, usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'
import { TableCell } from '../../../components/component-items/datatable'
import { TableRow } from './styles'
import { Form, InputGroup } from 'react-bootstrap'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

export const AddRule = ({ editRule, promoList, open, setOpen }: any) => {
  const node = useRef<HTMLFormElement | null>(null)
  const [validated, setValidated] = useState(false)
  const {
    state: { csrf, companyId },
  } = globalState()
  const defaultRule = {
    company: companyId,
    exact_sku_match: '',
    partial_sku_match: '',
    exclude_sku_match: '',
    promo_item: '',
    order_recurring: '1',
    rule_type: '1',
    all_orders: false,
    enabled: false,
    customer_order_number: null,
  }
  const [rule, setRule] = useState<any>(defaultRule)

  useEffect(() => {
    if (Object.keys(editRule).length === 0 && editRule.constructor === Object) {
      setRule(defaultRule)
    } else {
      if (editRule.customers_first_order) {
        setRule({ ...editRule, order_recurring: '4' })
      } else if (editRule.recurring_orders && editRule.non_recurring_orders) {
        setRule({ ...editRule, order_recurring: '1' })
      } else if (editRule.recurring_orders && !editRule.non_recurring_orders) {
        setRule({ ...editRule, order_recurring: '2' })
      } else {
        setRule({ ...editRule, order_recurring: '3' })
      }
    }
  }, [editRule])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }
    if (rule.id) {
      // Update
      usePut(`/api/product/promo-rule/${rule.id}/`, { ...rule }, csrf)
    } else {
      // Create
      usePost('/api/product/promo-rule/', { ...rule }, csrf)
    }
  }

  return (
    <GenericModal
      heading={rule.id ? 'Update Promo Rule' : 'Create Promo Rule'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>{rule.id ? 'Update' : 'Create'}</GlobalStyles.Button>}
    >
      <Form ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '2em' }}>
          <Form.Label>Exact SKU Match</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="exact_sku_match"
            value={rule.exact_sku_match}
            onChange={(e) => setRule({ ...rule, exact_sku_match: e.target.value })}
          />
          <Form.Label style={{ marginTop: '1em' }}>Partial SKU Match</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="partial_sku_match"
            value={rule.partial_sku_match}
            onChange={(e) => setRule({ ...rule, partial_sku_match: e.target.value })}
          />
          <Form.Label style={{ marginTop: '1em' }}>Exclude SKU Match</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="exclude_sku_match"
            value={rule.exclude_sku_match}
            onChange={(e) => setRule({ ...rule, exclude_sku_match: e.target.value })}
          />
          <Form.Label style={{ marginTop: '1em' }}>Recurring Subscription Orders</Form.Label>
          <Form.Select
            value={rule.order_recurring}
            onChange={(e) => setRule({ ...rule, order_recurring: e.target.value })}
          >
            <option value="1">Both recurring and non-recurring orders</option>
            <option value="2">Only recurring orders</option>
            <option value="3">Only Non recurring orders</option>
            <option value="4">Only first-time customers</option>
          </Form.Select>
          <Form.Label style={{ marginTop: '1em' }}>Customers i-th Shopify Order</Form.Label>
          <Form.Control
            type="number"
            value={rule.customer_order_number}
            onChange={(e) => setRule({ ...rule, customer_order_number: e.target.value || null })}
          />
          <Form.Label style={{ marginTop: '1em' }}>Promo Item</Form.Label>
          <Form.Select
            value={rule.promo_item}
            required
            onChange={(e) => setRule({ ...rule, promo_item: e.target.value })}
          >
            <option value="" hidden>
              ---SELECT---
            </option>
            {promoList.map((p: any, id: number) => (
              <option value={p.id} key={id}>
                {p.sku}
              </option>
            ))}
          </Form.Select>
          <Form.Label style={{ marginTop: '1em' }}>Action</Form.Label>
          <Form.Select value={rule.rule_type} onChange={(e) => setRule({ ...rule, rule_type: e.target.value })}>
            <option value="1">Add one promo-item to the order</option>
            <option value="2">Add one promo-item per unit</option>
            <option value="3">Add one promo-item per SKU</option>
          </Form.Select>
          <InputGroup style={{ paddingTop: '2em' }}>
            <Checkbox
              selected={rule.all_orders}
              setSelected={() => setRule({ ...rule, all_orders: !rule.all_orders })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Apply to All Orders</p>
          </InputGroup>
          <InputGroup>
            <Checkbox selected={rule.enabled} setSelected={() => setRule({ ...rule, enabled: !rule.enabled })} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Enabled</p>
          </InputGroup>
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

export const PromoRules = () => {
  const {
    state: { csrf },
  } = globalState()

  const { response: resp, loaded, error, placeholder }: any = useFetch('/api/product/promo-rule/', {})

  const rules = loaded ? resp.rules : []
  const promoList = loaded ? resp.promo_list : []
  const total = loaded ? resp.total : 0

  const [open, setOpen] = useState(false)
  const [editRule, setRule] = useState({})

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Promo Rule List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button
            onClick={() => {
              setRule({})
              setOpen(true)
            }}
          >
            Add Promo Rule
          </GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text"></span>
              </th>
              <th>
                <span className="text">Promo Item</span>
              </th>
              <th>
                <span className="text">Exact SKU</span>
              </th>
              <th>
                <span className="text">Partial SKU</span>
              </th>
              <th>
                <span className="text">Exclude SKU</span>
              </th>
              <th>
                <span className="text center">All Orders</span>
              </th>
              <th>
                <span className="text center">Recurring Orders</span>
              </th>
              <th>
                <span className="text center">Non Recurring Orders</span>
              </th>
              <th>
                <span className="text center">Enabled</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {rules.map((r: any, id: number) => (
              <TableRow key={id}>
                <TableCell input={<img src={r.image} style={{ maxWidth: 50 }} />} />
                <TableCell input={<Link to={`/product/variant/${r.promo_item}/`}>{r.promo_sku}</Link>} />
                <TableCell input={r.exact_sku_match} />
                <TableCell input={r.partial_sku_match} />
                <TableCell input={r.exclude_sku_match} />
                <TableCell center input={r.all_orders ? <FontAwesomeIcon icon={faCheck} /> : null} />
                <TableCell center input={r.recurring_orders ? <FontAwesomeIcon icon={faCheck} /> : null} />
                <TableCell center input={r.non_recurring_orders ? <FontAwesomeIcon icon={faCheck} /> : null} />
                <TableCell
                  center
                  input={
                    <Form.Check
                      type="switch"
                      id={r.id}
                      label={r.enabled ? 'Enabled' : 'Disabled'}
                      checked={r.enabled}
                      onChange={() => usePut(`/api/product/promo-rule/${r.id}/toggle_enabled/`, {}, csrf)}
                    />
                  }
                />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container" style={{ width: 100 }}>
                      <button
                        onClick={() => {
                          setRule(r)
                          setOpen(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={() => useDelete(`/api/product/promo-rule/${r.id}/`, csrf)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  }
                />
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      <AddRule {...{ open, setOpen, editRule, promoList }} />
    </GlobalStyles.FullPageCard>
  )
}
