import React from 'react'

//Components
import Modal from 'react-bootstrap/Modal'

import { GenericModalFunctionProps } from './types'

function GenericModal(fn: GenericModalFunctionProps) {
  const { children, heading, show, buttons, onHide, size } = fn
  return (
    <Modal {...{ show, onHide }} size={size} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>{buttons}</Modal.Footer>
    </Modal>
  )
}

export default GenericModal
